import React, { useState, useEffect, Component } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import ApiService from '../../services/apiService';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Edit } from '../../Assets/svg/edit.svg';
import Select from 'react-select';
import { GetCountries, GetState, GetCity, GetLanguages, } from "react-country-state-city";
import { RiRefreshLine } from "react-icons/ri";
import { FaSave } from "react-icons/fa";
import { hasPermission } from "../../services/roleService"
import Pagination from '../pagination/pagination';

const BankDetail = () => {
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(true); // Add loading state
    let navigate = useNavigate();
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [countriesList, setCountries] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [glAccount, setGlAccount] = useState(true);
    const [permissions, setPermissions] = useState([]);
    const [pageSize, setPageSize] = useState(20);
    const [pagination, setPagination] = useState();

    useEffect(() => {
        GetCountries().then((result) => {
            setCountries(result);
        });
    }, []);
    useEffect(() => {
        GetState(selectedCountry?.value?.id).then((result) => {
            setStateList(result);
        });
    }, [selectedCountry]);
    useEffect(() => {
        GetCity(selectedCountry?.value?.id, selectedState?.value?.id).then((result) => {
            setCityList(result);
        });
    }, [selectedState]);

    useEffect(() => {
        getItems();
    }, []);
    useEffect(() => {
        const storedPermissions = JSON.parse(localStorage.getItem('RoleAccess')) || [];
        setPermissions(storedPermissions);
        if (pageSize) {
            getItems();
        }
    }, [pageSize]);

    const checkPermission = (moduleName, action) => {
        return hasPermission(moduleName, action, permissions);
    };
    // const canEditMaster = checkPermission('Supplier', 'edit');
    // const canCreateMaster = checkPermission('Supplier', 'create');
    const canEditMaster = true;
    const canCreateMaster = true;
    const getItems = async (newPageNo = pagination?.currentPage) => {
        try {
            const apiService = new ApiService();
            const params = new URLSearchParams();
           params.append('PageNo', newPageNo || 1);
            params.append('PageSize', pageSize || 20);
            const response = await apiService.getApiParamater('GET_SUPPLIER', `?${params.toString()}`);
            setPagination(response.data.paginationData);
            setRowData(response?.data?.result || []);
        } catch (error) {
        }
    }
    const columnDefs = [
        { headerName: 'Account Name', field: 'accountName', filter: true, floatingfilter: true, minWidth: 150 },
        { headerName: 'Bank Name', field: 'cP1ContactNo1', filter: true, floatingfilter: true, minWidth: 150 },
        { headerName: 'Branch', field: 'cP1ContactNo2', filter: true, floatingfilter: true, minWidth: 150 },
        { headerName: 'Swift Code', field: 'phoneNo1', filter: true, floatingfilter: true, minWidth: 150 },
        { headerName: 'IBAN', field: 'phoneNo2', filter: true, floatingfilter: true, minWidth: 150 },
        { headerName: 'Country', field: 'mobileNo1', filter: true, floatingfilter: true, minWidth: 150 },
        { headerName: 'City', field: 'faxNo1', filter: true, floatingfilter: true, minWidth: 150 },
        { headerName: 'Contact No', field: 'email1', filter: true, floatingfilter: true, minWidth: 150 },
        { headerName: 'Category', field: 'email2', filter: true, floatingfilter: true, minWidth: 150 },
        {
            headerName: 'Action', field: 'supplier_ID', filter: true, floatingfilter: true, minWidth: 150,
            cellRenderer: BtnCellRenderer,
            cellRendererParams: {
                clicked: function (field) {

                    // handleShow(field)
                },
            },
        },

    ];
    const [formData, setFormData] = useState({
        Name: "",
        City: "",
        Branch: "",
        ContactNo1: "",
        Country: "",
        ContactPerson: "",
        Category: "",
        ContactNo: "",
        MobileNo: "",
        IBAN: ""
    });
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleCountryChange = (selectedOption) => {
        setSelectedCountry(selectedOption);
        setSelectedCity(null);
    };
    const handleStateChange = (selectedOption) => {
        setSelectedState(selectedOption);
        setSelectedCity(null);
    };
    const handleCityChange = (selectedOption) => {
        setSelectedCity(selectedOption);
    };
    const frameworkComponents = {
        btnCellRenderer: BtnCellRenderer,
    };
    const renderPageNumbers = () => {
        const pageNumbers = [];
        const totalPageNumbers = Math.min(pagination?.totalPages, 4);
        let startPage = Math.max(pagination?.currentPage - Math.floor(totalPageNumbers / 2), 1);
        let endPage = startPage + totalPageNumbers - 1;

        if (endPage > pagination?.totalPages) {
            endPage = pagination?.totalPages;
            startPage = endPage - totalPageNumbers + 1;
            if (startPage < 1) {
                startPage = 1;
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers?.push(
                <div key={i} className={`${pagination?.currentPage === i ? "pActive" : "pagen"}`}>
                    {i}
                </div>
            );
        }
        return pageNumbers;
    }
    const handlePageSizeChange = (event) => {
        const newSize = Number(event.target.value);
        setPageSize(newSize);
    };
    return (
        <div className='pt-2'>
            <div className=''>
                {/* <h5 className='col mainInvHead'>Additional Details</h5> */}
                <div className='d-flex justify-content-end gap-1'>
                    <div className=''>
                        <>{canCreateMaster === true ?
                            <button className='mainbtn ' type="submit">
                                <FaSave className='center mt-1 mx-2' /> <div>Save</div></button>
                            :
                            <button className='mainbtn ' type="submit" style={{ cursor: "not-allowed" }}>
                                <FaSave className='center mt-1 mx-2' /> <div>Save</div></button>
                        } </>
                    </div>
                    <button className='mainbtnRed'><RiRefreshLine className='center mt-1 mx-2' /><div>Reset</div></button>
                </div>
            </div>
            <div className="cost-center-section mb-2">
                <div className="d-flex mt-3 cursor-pointer slide-header gap-4"
                    onClick={() => setGlAccount(!glAccount)}>
                    <div className='h6 pt-1'>Bank Details</div>
                </div>
                <div className={`slide-content ${glAccount ? 'open' : 'closed'}`}>
                    <div className="border-box2 p-3">
                        <div className="form-group row  pb-3">
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="validationCustom01" className="form-label  pt-2 start">Account Name</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    disabled={canCreateMaster === true ? false : true}
                                    id="validationCustom01"
                                    name="Name"
                                    value={formData?.Name}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="validationCustom01" className="form-label  pt-2 start">Contact Person</label>
                                <input
                                    type="number" onWheel={(e) => e.target.blur()}
                                    className="form-Input w-70"
                                    disabled={canCreateMaster === true ? false : true}
                                    id="validationCustom01"
                                    name="ContactPerson"
                                    value={formData?.ContactPerson}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="validationCustom01" className="form-label  pt-2 start">Swift Code</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    disabled={canCreateMaster === true ? false : true}
                                    id="validationCustom01"
                                    name="SwiftCode"
                                    value={formData?.SwiftCode}
                                    onChange={handleInputChange}
                                />
                                <div className="valid-feedback">
                                    Looks good!
                                </div>
                            </div>
                        </div>
                        <div className="form-group row  pb-3">
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="validationCustom01" className="form-label  pt-2 start">Bank Name</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                          color: state.isFocused ? '#000 ' : '#000',
                          ':hover': {
                            backgroundColor: '#F5F5F5',
                            color: '#000 ',
                          },
                        }),
                      }}
                                    className="w-70 p-0"
                                    isDisabled={canCreateMaster === true ? false : true}
                                    value={formData?.Bank}
                                    onChange={handleInputChange}
                                />
                                <div className="valid-feedback">
                                    Looks good!
                                </div>
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="validationCustom01" className="form-label  pt-2 start">Branch</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    disabled={canCreateMaster === true ? false : true}
                                    id="validationCustom01"
                                    name="Branch"
                                    value={formData?.Branch}
                                    onChange={handleInputChange}
                                />
                                <div className="valid-feedback">
                                    Looks good!
                                </div>
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="validationCustom01" className="form-label  pt-2 start">IBAN</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    disabled={canCreateMaster === true ? false : true}
                                    id="validationCustom01"
                                    name="IBAN"
                                    value={formData?.IBAN}
                                    onChange={handleInputChange}
                                />
                                <div className="valid-feedback">
                                    Looks good!
                                </div>
                            </div>
                        </div>
                        <div className="form-group row  pb-3">
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="validationCustom01" className="form-label  pt-2 start">Contact No</label>
                                <input
                                    type="number" onWheel={(e) => e.target.blur()}
                                    className="form-Input w-70"
                                    disabled={canCreateMaster === true ? false : true}
                                    id="validationCustom01"
                                    name="ContactNo"
                                    value={formData?.ContactNo}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="validationCustom01" className="form-label  pt-2 start">Category</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                          color: state.isFocused ? '#000 ' : '#000',
                          ':hover': {
                            backgroundColor: '#F5F5F5',
                            color: '#000 ',
                          },
                        }),
                      }}
                                    className="w-70 p-0"
                                    isDisabled={canCreateMaster === true ? false : true}
                                    value={formData?.Category}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="validationCustom01" className="form-label  pt-2 start">Country</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                          color: state.isFocused ? '#000 ' : '#000',
                          ':hover': {
                            backgroundColor: '#F5F5F5',
                            color: '#000 ',
                          },
                        }),
                      }}
                                    className="w-70 p-0"
                                    isDisabled={canCreateMaster === true ? false : true}
                                    value={selectedCountry}
                                    onChange={handleCountryChange}
                                    options={countriesList?.map(country => ({ value: country, label: country?.name }))}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-4 d-flex justify-content-between">
                                <label htmlFor="validationCustom19" className="form-label  pt-2 start  pt-2 start">State</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                          color: state.isFocused ? '#000 ' : '#000',
                          ':hover': {
                            backgroundColor: '#F5F5F5',
                            color: '#000 ',
                          },
                        }),
                      }}
                                    className="w-70 p-0"
                                    value={selectedState}
                                    isDisabled={canCreateMaster === true ? false : true}
                                    onChange={handleStateChange}
                                    options={stateList ? stateList?.map(state => ({ value: state, label: state?.name })) : []}
                                />
                            </div>
                            <div className="col-4 d-flex justify-content-between">
                                <label htmlFor="validationCustom01" className="form-label  pt-2 start">City</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                          color: state.isFocused ? '#000 ' : '#000',
                          ':hover': {
                            backgroundColor: '#F5F5F5',
                            color: '#000 ',
                          },
                        }),
                      }}
                                    className="w-70 p-0"
                                    value={selectedCity}
                                    isDisabled={canCreateMaster === true ? false : true}
                                    onChange={handleCityChange}
                                    options={cityList ? cityList?.map(city => ({ value: city, label: city?.name })) : []}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='brdor' style={{ height: 550 }}>
                <div className="ag-theme-quartz" style={{ height: 550 }}>
                    <AgGridReact
getRowStyle={(params) => {
                                        if (params.node.rowIndex % 2 === 0) {
                                            return { background: '#F0EDF5' };
                                        } else {
                                            return { background: '#fff' };
                                        }
                                    }}
                        rowData={rowData}
                        enableFilter={true}
                        floatingFilter={true}
                        columnDefs={columnDefs}
                        pagination={false}
                        suppressRowClickSelection={true}
                        frameworkComponents={frameworkComponents}
                    />
                </div>
            </div>
            <Pagination pageSize={pageSize} setPageSize={setPageSize} pagination={pagination} getItems={getItems} />
        </div>
    );
};
class BtnCellRenderer extends Component {
    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
    }

    btnClickedHandler() {
        const { value, clicked } = this.props;
        clicked(value);
    }

    render() {
        return (
            <div className='d-flex gap-3'>
                <div><Edit /></div>
                <div className="form-check form-switch">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id={`flexSwitchCheck`}
                        checked={this.props}
                        // onChange={(e) => btnClickedHandler(val, e.target.checked)}
                        onClick={this.btnClickedHandler}
                    />
                </div>
            </div>
            // <button onClick={this.btnClickedHandler} className="gridbtn">Delete</button>
        )
    }
}
export default BankDetail;
