// import React, { useEffect, useState } from "react";

// const ReportForm = () => {
//     const handleSubmit = (event) => {
//         event.preventDefault();
//         debugger
//         const token = localStorage.getItem("Token");
//         if (!token) {
//             alert("Auth token is missing! Unable to proceed.");
//             return;
//         }

//         // Popup window dimensions and positioning
//         const popupWidth = 1000;
//         const popupHeight = 600;
//         const popupLeft = (window.screen.width - popupWidth) / 2;
//         const popupTop = (window.screen.height - popupHeight) / 2;

//         const popup = window.open(
//             "",
//             "ReportPopup",
//             `width=${popupWidth},height=${popupHeight},left=${popupLeft},top=${popupTop},resizable=yes,scrollbars=yes`
//         );

//         if (popup) {
//             const form = document.createElement("form");
//             form.method = "POST";
//             form.action =
//                 "https://reports.pro360.freemyip.com/RDLReport/WebForm/ReportWebForm2.aspx";
//             form.target = "ReportPopup";

//             // Input fields
//             const inputs = [
//                 { name: "reportName", value: "LSESTMT" },
//                 { name: "tranId", value: "12" },
//                 { name: "voucherNo", value: "LV-23-01271" },
//                 { name: "rptMode", value: "asdf" },
//                 { name: "authToken", value: token },
//             ];

//             inputs.forEach(({ name, value }) => {
//                 const input = document.createElement("input");
//                 input.type = "hidden";
//                 input.name = name;
//                 input.value = value;
//                 form.appendChild(input);
//             });

//             document.body.appendChild(form);
//             form.submit();
//             document.body.removeChild(form);
//         } else {
//             alert("Popup blocked! Please allow popups for this site.");
//         }
//     };

//     return (
//         <div className="p-5">
//             <div className="center pb-2">
//                 <h4>Lease Report</h4>
//             </div>
//             <form onSubmit={handleSubmit}>
//                 <div className="row">
//                     <div className="d-flex col-3">
//                         <label htmlFor="reportName" className="form-label  pt-2 start">Report Name:</label>
//                         <input
//                             type="text"
//                             id="reportName"
//                             name="reportName"
//                             defaultValue="LSESTMT"
//                             placeholder="Enter report name"
//                             className="form-Input w-70"
//                             readOnly
//                         />
//                     </div>
//                     <div className="d-flex  col-3">
//                         <label htmlFor="tranId" className="form-label  pt-2 start">Transaction ID:</label>
//                         <input
//                             type="text"
//                             id="tranId"
//                             name="tranId"
//                             defaultValue="12"
//                             placeholder="Enter transaction ID"
//                             className="form-Input w-70"
//                             readOnly
//                         />
//                     </div>
//                     <div className="d-flex  col-3">
//                         <label htmlFor="voucherNo" className="form-label  pt-2 start">Voucher No:</label>
//                         <input
//                             type="text"
//                             id="voucherNo"
//                             name="voucherNo"
//                             defaultValue="LV-23-01271"
//                             placeholder="Enter voucher number"
//                             className="form-Input w-70"
//                             readOnly
//                         />
//                     </div>
//                     <div className="d-flex  col-3">
//                         <label htmlFor="rptMode" className="form-label  pt-2 start">Report Mode:</label>
//                         <input
//                             type="text"
//                             id="rptMode"
//                             name="rptMode"
//                             className="form-Input w-70"
//                             defaultValue="asdf"
//                             placeholder="Enter report mode"
//                             readOnly
//                         />
//                     </div>
//                 </div>
//                 <div className="p-5 center">
//                     <button type="submit" className='mainbtnRed'><div>Open Report</div></button>
//                 </div>
//             </form>
//         </div>
//     );
// };

// export default ReportForm;


import React, { useState } from "react";
import Modal from "react-modal";
import Iframe from "react-iframe";

const ReportForm = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [iframeUrl, setIframeUrl] = useState("");

    const handleSubmit = (event) => {
        event.preventDefault();
        const token = localStorage.getItem("Token");
        if (!token) {
            alert("Auth token is missing! Unable to proceed.");
            return;
        }

        // Prepare the URL with parameters
        const url = new URL(
            "https://reports.pro360.freemyip.com/RDLReport/WebForm/ReportWebForm2.aspx"
        );
        const params = {
            reportName: "LSESTMT",
            tranId: "12",
            voucherNo: "LV-23-01271",
            rptMode: "asdf",
            authToken: token,
        };

        Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));

        // Open the modal and set the iframe URL
        setIframeUrl(url.toString());
        setIsModalOpen(true);
    };

    const closeModal = () => setIsModalOpen(false);

    return (
        <div className="p-5">
            <div className="center pb-2">
                <h4>Lease Report</h4>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="d-flex col-3">
                        <label htmlFor="reportName" className="form-label pt-2 start">Report Name:</label>
                        <input
                            type="text"
                            id="reportName"
                            name="reportName"
                            defaultValue="LSESTMT"
                            placeholder="Enter report name"
                            className="form-Input w-70"
                            readOnly
                        />
                    </div>
                    <div className="d-flex col-3">
                        <label htmlFor="tranId" className="form-label pt-2 start">Transaction ID:</label>
                        <input
                            type="text"
                            id="tranId"
                            name="tranId"
                            defaultValue="12"
                            placeholder="Enter transaction ID"
                            className="form-Input w-70"
                            readOnly
                        />
                    </div>
                    <div className="d-flex col-3">
                        <label htmlFor="voucherNo" className="form-label pt-2 start">Voucher No:</label>
                        <input
                            type="text"
                            id="voucherNo"
                            name="voucherNo"
                            defaultValue="LV-23-01271"
                            placeholder="Enter voucher number"
                            className="form-Input w-70"
                            readOnly
                        />
                    </div>
                    <div className="d-flex col-3">
                        <label htmlFor="rptMode" className="form-label pt-2 start">Report Mode:</label>
                        <input
                            type="text"
                            id="rptMode"
                            name="rptMode"
                            className="form-Input w-70"
                            defaultValue="asdf"
                            placeholder="Enter report mode"
                            readOnly
                        />
                    </div>
                </div>
                <div className="p-5 center">
                    <button type="submit" className="mainbtnRed">
                        <div>Open Report</div>
                    </button>
                </div>
            </form>

            {/* Modal for displaying the report */}
            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel="Report Modal"
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80%',
                        height: '80%',
                        padding: '0',
                    },
                }}
            >
                <div style={{ height: '100%', position: 'relative' }}>
                    <button
                        onClick={closeModal}
                        style={{
                            position: "absolute",
                            top: "10px",
                            right: "10px",
                            zIndex: 1000,
                            padding: "5px 10px",
                            border: "none",
                            background: "#ff5c5c",
                            color: "#fff",
                            cursor: "pointer",
                        }}
                    >
                        Close
                    </button>
                    <Iframe
                        url={iframeUrl}
                        width="100%"
                        height="100%"
                        id="reportIframe"
                        className="iframe-class"
                        display="initial"
                        position="relative"
                        allowFullScreen
                    />
                </div>
            </Modal>
        </div>
    );
};

export default ReportForm;