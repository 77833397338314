import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import AuthorizationGrid from '../authorization';
import MessageGrid from '../messageGrid';
import ApiService from '../../../services/apiService';
import { useLocation, useNavigate } from 'react-router-dom';
import RequisitionItemList from './itemlist';
import { FaAngleLeft } from "react-icons/fa6";

const PurchaseRequisitionView = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [gridVisibility, setGridVisibility] = useState([true, false, false]);
    const apiService = new ApiService();
    const [messageData, setMessageData] = useState([]);
    const [SelectedCurrency, setSelectedCurency] = useState([]);
    const [currency, setCurrency] = useState([]);
    const [requsitionData, setPR] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState([]);
    const [authorizationData, setAuthorizationData] = useState([]);
    const [usersDetails, setUser] = useState([]);
    let navigate = useNavigate();
    const currentDatetimeUTC = new Date().toISOString();
    const location = useLocation();
    const itemData = location.state;
    const [SupplierTab, setSupplierTab] = useState(true);
    const [CostTab, setCostTab] = useState(false);

    const todayDate = new Date().toISOString().substr(0, 10);
    const [formData, setFormData] = useState({
        recordId: "",
        requisitionNo: "",
        itemType: "",
        quatotionNo: "",
        remarks: "",
        requestor: "",
        requisitionDate: currentDatetimeUTC,
        deliveryDate: todayDate,
        currencyCode: "",
        exchangeRate: "",
        department: [],
        costCenter1: "",
        costCenter2: "",
        costCenter3: "",
        costCenter4: "",
        deliveryPoint: "",
        location: "",
        contactPerson: "",
        contatNo: "",
        taskCode: "",
        subcatname: "",
    });
    useEffect(() => {
        getCurrency()
        getApprovalUser()
    }, [])

    useEffect(() => {
        const item = location.state;
        const SetData = async () => {
            try {
                const response = await apiService.get('GET_PR');
                const selectedPr = response.data.result?.find(rec => rec.requisitionNo === item.docno)
                setPR(selectedPr)
                setFormData({
                    recordId: item.docno,
                    requisitionDate: selectedPr?.requisitionDate || currentDatetimeUTC,
                    deliveryDate: selectedPr?.deliveryDate ? selectedPr.deliveryDate.substr(0, 10) : todayDate,
                    requisitionNo: selectedPr?.requisitionNo,
                    itemType: selectedPr?.type,
                    quatotionNo: selectedPr?.contractNo,
                    remarks: selectedPr?.remarks,
                    requestor: selectedPr?.requestor,
                    currencyCode: selectedPr?.currencyCode,
                    costCenter1: { label: selectedPr?.costCenter1, value: selectedPr?.costCenter1 },
                    costCenter2: { label: selectedPr?.costCenter2, value: selectedPr?.costCenter2 },
                    costCenter3: { label: selectedPr?.costCenter3, value: selectedPr?.costCenter3 },
                    costCenter4: { label: selectedPr?.costCenter4, value: selectedPr?.costCenter4 },
                    deliveryPoint: selectedPr?.deliveryPoint,
                    contactPerson: selectedPr?.contactPerson,
                    contatNo: selectedPr?.contatNo,
                    taskCode: selectedPr?.projectCode,
                    location: selectedPr?.location,
                });
                setSelectedLocation({ label: selectedPr.approvalUser, value: selectedPr?.approvalUser })
                setSelectedDepartment({ label: selectedPr.department, value: selectedPr?.department })
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        if (item) {
            SetData()
        }
    }, [location?.state, itemData]);
    const getApprovalUser = async () => {
        try {
            const params = new URLSearchParams();
            params.append('DocNo', itemData?.requisitionNo);
            params.append('DocumentType', "Purchase Requisition");
            const response = await apiService.getApiParamater('GET_DOC_STATUS_EDIT', `?${params.toString()}`);
            setUser(response.data.result);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    let param = `?isActive=true`
    const getCurrency = async () => {
        const apiService = new ApiService()
        let res = await apiService.getApiParamater('GET_CURRENCY_MASTER', param)
        setCurrency(res?.data?.result)
        const filteredDenominations = res?.data?.result?.find(currency => currency?.isDefault === true);
        if (filteredDenominations) {
            setSelectedCurency({ label: filteredDenominations.code, value: filteredDenominations });
        }
    }
    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
        const newGridVisibility = gridVisibility.map((_, index) => index === tabIndex);
        setGridVisibility(newGridVisibility);
    };
    const handleMessage = (data) => {
        setMessageData(data);
        console.log(data, 'reqData')

    };
    const handleAuthorization = (data) => {
        setAuthorizationData(data);
        console.log(data, 'reqData')
    };
    const handleBackClick = () => {
        window.history.back();
    };
    return (
        <div className='p-4'>
            <div className='d-flex justify-contant-between '>
                <h5 className='col mainInvHead'>Purchase Requisition</h5>
                <div className='d-flex justify-content-end gap-1'>
                    <button onClick={handleBackClick} className='mainbtn'><FaAngleLeft className='center mt-1 mx-2' /><div>Back</div></button>
                </div>
            </div>
            <div className="cost-center-section mb-2">
                <div className="d-flex mt-3 cursor-pointer slide-header gap-4"
                    onClick={() => setSupplierTab(!SupplierTab)}>
                    <div className='h6 pt-1'>Requisition Details</div>
                </div>
                <div className={`slide-content ${SupplierTab ? 'open' : 'closed'}`}>
                    <div className="border-box2 p-3">
                        <div className="form-group row pb-3">
                            <div className="col d-flex justify-content-between ">
                                <label htmlFor="recordId" className="form-label  pt-2 start">Requisition No</label>
                                <input
                                    className="form-Input w-70"
                                    disabled
                                    value={formData.recordId}
                                    name="recordId"
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="deliveryDate" className="form-label  pt-2 start">Delivery Date</label>
                                <input
                                    type='date'
                                    className='form-Input w-70' disabled
                                    value={formData.deliveryDate}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="subcatname" className="form-label  pt-2 start">Location</label>
                                <Select isDisabled value={selectedLocation}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0" />
                            </div>
                        </div>
                        <div className="form-group row  pb-3">
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="catcode" className="form-label  pt-2 start">Date </label>
                                <input
                                    className='form-Input w-70'
                                    type='date' disabled
                                    defaultValue={new Date().toISOString().substr(0, 10)}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="subcatname" className="form-label  pt-2 start">Delivery Point</label>
                                <input disabled
                                    className="form-Input w-70"
                                    value={formData.deliveryPoint}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-2 start">Copy Request</label>
                                <Select isDisabled
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0" />
                            </div>
                        </div>
                        <div className="form-group row  pb-3">
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-2 start">Currency</label>
                                <Select isDisabled value={SelectedCurrency}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0" />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="location" className="form-label  pt-2 start">Location *</label>
                                <input disabled value={formData.location} className="form-Input w-70" />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-2 start">Quotation No</label>
                                <Select isDisabled
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0" />
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="exchangeRate" className="form-label  pt-2 start">Exchange Rate</label>
                                <input disabled value={formData.exchangeRate} className="form-Input w-70" />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="contactPerson" className="form-label  pt-2 start">Contact Person</label>
                                <input disabled value={formData.contactPerson} className="form-Input w-70" />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="requestor" className="form-label  pt-2 start">Requester</label>
                                <input disabled className="form-Input w-70" value={formData.requestor} />
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-2 start">Item Type</label>
                                <Select isDisabled
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0" />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="contatNo" className="form-label  pt-2 start">Contact No</label>
                                <input disabled className="form-Input w-70" value={formData.contatNo} />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="department" className="form-label  pt-2 start">Department</label>
                                <Select isDisabled value={selectedDepartment}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0" />
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="col-4 d-flex justify-content-between">
                                <label htmlFor="department" className="form-label  pt-2 start">Task Code</label>
                                <Select isDisabled
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0" />
                            </div>
                            <div className="col-4 d-flex justify-content-between">
                                <label htmlFor="subcatname" className="form-label  pt-2 start">Remarks</label>
                                <input disabled
                                    className="form-Input w-70"
                                    value={formData.remarks}
                                />
                            </div>
                            <div className='col-1 px-5 mx-3'></div>
                            <div className='col-lg-2 brderbox center'>
                                <a disabled href=''>Attachment</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cost-center-section mb-2">
                <div className="d-flex mt-3 cursor-pointer slide-header gap-4"
                    onClick={() => setCostTab(!CostTab)}>
                    <div className='h6 pt-1'>Cost Center</div>
                </div>
                <div className={`slide-content ${CostTab ? 'open' : 'closed'}`}>
                    <div className="border-box2 p-3">
                        <div className="form-group row pb-3">
                            <div className="col d-flex justify-content-between ">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-2 start">Cost Center 1</label>
                                <Select isDisabled value={formData.costCenter1}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0" />
                            </div>
                            <div className="col d-flex justify-content-between ">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-2 start">Cost Center 2</label>
                                <Select isDisabled value={formData.costCenter2}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0" />
                            </div>
                            <div className="col d-flex justify-content-between ">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-2 start">Cost Center 3</label>
                                <Select isDisabled value={formData.costCenter3}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0" />
                            </div>
                            <div className="col d-flex justify-content-between ">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-2 start">Cost Center 4</label>
                                <Select isDisabled value={formData.costCenter4}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`cost-center-section`}>
                <div className="mt-3 cursor-pointer slide-header">
                    <div className="nav nav-pills overslid d-flex gap-4">
                        {/* Nav tabs */}
                        <div
                            className={`dashitems sidebar-text center ${activeTab === 0 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(0)}
                        >
                            Requisition
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 1 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(1)}
                        >
                            Authorization
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 2 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(2)}
                        >
                            Message
                        </div>
                    </div>
                </div>
            </div>
            <div className={`slide-content open`}>
                <div className={`grid-1 border-box2 p-3`}>
                    {gridVisibility[0] && (
                        <RequisitionItemList itemData={requsitionData} selectedLocation={selectedLocation} />
                    )}
                    {gridVisibility[1] && (
                        <AuthorizationGrid
                            handleAuthorization={handleAuthorization}
                            usersDetails={usersDetails}
                            setUser={setUser}
                            itemData={formData}
                        />
                    )}
                    {gridVisibility[2] && (
                        <div className="grid-3 border">
                            <MessageGrid handleMessage={handleMessage} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default PurchaseRequisitionView