import React, { useState, useRef, Component } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';

const PLUGridMaster = ({ rowData }) => {
    const gridRef = useRef();
    const columnDefs = [
        { headerCheckboxSelection: true, checkboxSelection: true, headerCheckboxSelectionFilteredOnly: true, suppressMovable: true, width: 50 },
        { field: 'ID', headerName: 'ID', filter: true, floatingfilter: true, suppressMovable: true, width: 200 },
        { field: 'Category Name', headerName: 'PLU Code', filter: true, floatingfilter: true, suppressMovable: true, width: 200 },
        { field: 'Inventory ACC ', headerName: 'Recipe', filter: true, floatingfilter: true, suppressMovable: true, width: 160 },
        { field: 'GRN ACC ', headerName: 'Category', filter: true, floatingfilter: true, suppressMovable: true, width: 160 },
        { field: 'Work in progress ', headerName: 'Sub Category', filter: true, floatingfilter: true, suppressMovable: true, width: 160 },
        { field: 'CGS ACC', headerName: 'Recipe Group', filter: true, floatingfilter: true, suppressMovable: true, width: 160 },
        { field: 'Invoice ACC', headerName: 'Cost', filter: true, floatingfilter: true, suppressMovable: true, width: 150 },
        { field: 'Wastage ACC', headerName: 'Modified By', filter: true, floatingfilter: true, suppressMovable: true, width: 150 },
        { field: 'Modified On', headerName: 'Modified On', filter: true, floatingfilter: true, suppressMovable: true, width: 150 },
    ];
    // const onSelectionChanged = (event) => {
    //     const selectedRows = event.api.getSelectedRows();
    //     setSelectedRow(selectedRows.length === 1 ? selectedRows[0] : null);
    // };
    // const onCellClicked = (event) => {
    //     if (event.colDef.field === 'catCode') {
    //         setSelectedRow(null);
    //         const clickedRowData = event.data;
    //         setSelectedRow(null)
    //         handleEdit(clickedRowData)
    //     }
    // };
    return (
        <div className='gridshad'>
            <div className="ag-theme-quartz" style={{ height: 500 }}>
                <AgGridReact
                    getRowStyle={(params) => {
                        if (params.node.rowIndex % 2 === 0) {
                            return { background: '#F0EDF5' };
                        } else {
                            return { background: '#fff' };
                        }
                    }}
                    rowData={rowData}
                    ref={gridRef}
                    rowSelection={'single'}
                    columnDefs={columnDefs}
                    // onCellClicked={onCellClicked}
                    // onSelectionChanged={onSelectionChanged}
                />
            </div>
        </div>
    );
};

class BtnCellRenderer extends Component {
    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
        this.btnEditClickedHandler = this.btnEditClickedHandler.bind(this); // Bind edit button handler
    }

    btnClickedHandler() {
        const { value, clicked, data } = this.props;
        const newActiveStatus = data.active === 1 ? 0 : 1;
        clicked(value, newActiveStatus, data);
    }

    btnEditClickedHandler() {
        const { data, handleEdit } = this.props;
        handleEdit(data);
    }

    render() {
        const { active } = this.props.data;
        const { canEditItemMaster } = this.props;

        return (
            <div className='d-flex gap-3'>
                <div className="form-check form-switch center">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id={`flexSwitchCheck`}
                        checked={active === 1}
                        onClick={this.btnClickedHandler}
                    />
                </div>
            </div>
        )
    }
}
export default PLUGridMaster;
