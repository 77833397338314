import React, { useState, useEffect } from 'react';
import ApiService from '../../services/apiService';
import moment from 'moment';

const InvoiceGrnList = ({ GrnData, itemData, SelectedPO }) => {
    const todayDate = new Date().toISOString().substr(0, 10);
    const [rows, setRows] = useState([{
        poNo: '',
        grnNo: '',
        invoiceNo: '',
        refNo: '',
        grnDate: '',
        currency: '',
        amount: '',
        checked: '',
        invoiceCheck: ''
    }]);
    useEffect(() => {
        getGrn();
    }, [itemData]);
    const totalAmount = rows.reduce((sum, row) => sum + (parseFloat(row?.amount) || 0), 0)
    useEffect(() => {
        getGrn();
    }, [SelectedPO, itemData]);
    const getGrn = async () => {
        try {
            const apiService = new ApiService();
            if (itemData?.pOrderNo) {
                const selectedPO = itemData?.grnDetails.filter(po => po?.pOrderNo === itemData?.pOrderNo);
                const formattedRows = selectedPO?.map(detail => {
                    return {
                        recordId: detail.grnNo,
                        poNo: detail.pOrderNo,
                        grnNo: detail?.grnNo,
                        invoiceNo: detail.invoiceNo,
                        refNo: detail?.invoiceRef,
                        grnDate: detail.delivery_Date,
                        currency: detail.currencyCode,
                        amount: detail?.amount,
                        invoiceCheck: detail.invoiceActive,
                        status: detail.status
                    };
                }).filter(row => row !== null);
                if (formattedRows?.length > 0) {
                    setRows(formattedRows || []);
                }
            } else {
                let param = `?POrderNo=${SelectedPO}`
                const grnres = await apiService.getApiParamater('GET_GRN_LIST_INVOICE', param);
                const formattedRows = grnres.data.result?.map(detail => {
                    return {
                        recordId: detail.grnNo,
                        poNo: detail.pOrderNo,
                        grnNo: detail?.grnNo,
                        invoiceNo: detail.invoiceNo,
                        refNo: detail?.invoiceRef,
                        grnDate: detail.delivery_Date,
                        currency: detail.currencyCode,
                        amount: detail?.amount,
                        invoiceCheck: detail.invoiceActive,
                        status: detail.status
                    };
                }).filter(row => row !== null);
                if (formattedRows?.length > 0) {
                    setRows(formattedRows || []);
                }
            }

        } catch (error) {
            console.error('Error fetching Grn:', error);
        }
    };
    const handleCheckboxChange = (index, checked, row) => {
        const updatedRows = rows.map((r, i) => ({
            ...r,
            checked: i === index ? (checked ? 1 : 0) : r.checked
        }));
        setRows(updatedRows);
        GrnData(updatedRows);
    };
    debugger
    return (
        <div>
            <div className='table-container ' style={{ overflowX: 'auto', height: "auto" }}>
                <table>
                    <thead className='dynoTable'>
                        <tr>
                            <th></th>
                            <th>Grn No</th>
                            <th>PO no</th>
                            <th>Invoice No</th>
                            <th>Ref NO</th>
                            <th>GRN Date</th>
                            <th>Currency</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(rows) && rows?.map((row, index) => (
                            <tr key={index}>
                                <td className='m-0 text-center  p-1' style={{ border: "1px solid rgba(118, 118, 118, 0.3)" }}>
                                    <input className='form-check-input' checked={row.checked === 1}
                                        onChange={(e) => handleCheckboxChange(index, e.target.checked, row)}
                                        type="checkbox" style={{ width: "26.81px", height: "26.81px" }} />
                                </td>
                                <td className='m-0 p-0'><input className='p-2' type="text" value={row.grnNo ? row.grnNo : " "} disabled style={{ width: "220px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' type="text" value={row.poNo ? row.poNo : " "} disabled style={{ width: "220px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' type="text" value={row.invoiceNo} disabled style={{ width: "221px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' type="text" value={row.refNo} disabled style={{ width: "221px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled type="text" value={moment(row.grnDate || todayDate).format('ll')} style={{ width: "220px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' type="text" value={row.currency} disabled style={{ width: "220px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled type="text" value={row.amount} style={{ width: "220px" }} /></td>
                            </tr>
                        ))
                        }
                    </tbody>
                </table>
            </div>
            <div className='d-flex justify-content-center boxs'>
                <div className='d-flex gap-2'>
                    <div className="totalhead center">Total Amount</div>
                    <div className='field'>{totalAmount || "---"}</div>
                </div>
            </div>
        </div>
    );
};

export default InvoiceGrnList;
