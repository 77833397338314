import React, { useState, useEffect } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import { useNavigate } from 'react-router-dom';
import ApiService from '../../../services/apiService';
import { AiOutlinePrinter } from "react-icons/ai"
import { FaRegEye } from "react-icons/fa6"
import moment from 'moment';
import { IoAddSharp } from "react-icons/io5";
import { GrEdit } from "react-icons/gr";
import Pagination from '../../pagination/pagination';
const GRNGrid = () => {
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(true);
    let navigate = useNavigate();
    const [pageSize, setPageSize] = useState(20);
    const [selectedRow, setSelectedRow] = useState(null);
    const [pagination, setPagination] = useState();
    const [selectedtem, setSelectedItem] = useState(null);
    const apiService = new ApiService();
    const [permissions, setPermissions] = useState([]);
    useEffect(() => {
        if (pageSize) {
            getItems();
        }
    }, [pageSize]);
    useEffect(() => {
        getItems();
        const storedPermissions = JSON.parse(localStorage.getItem('RoleAccess')) || [];
        const permissions = storedPermissions?.filter(acc => acc.formName === "GRN");
        setPermissions(permissions[0]);
    }, []);
    const handleEdit = () => {
        if (selectedRow?.status === 1 || selectedRow?.status === 5) {
            let eye = true
            const updatedData = { ...selectedRow, eye: eye };
            navigate(`/Inventory/GRN/Update/${selectedRow.grnNo}`, { state: updatedData })
        } else {
            navigate(`/Inventory/GRN/Update/${selectedRow.grnNo}`, { state: selectedRow })
        }
    }
    const getItems = async (newPageNo = pagination?.currentPage) => {
        try {
            const apiService = new ApiService();
            const params = new URLSearchParams();
            params.append('PageNo', newPageNo || 1);
            params.append('PageSize', pageSize || 20);
            const res = await apiService.getApiParamater('GET_GRN', `?${params.toString()}`);
            setPagination(res.data.paginationData)
            const reversedData = res?.data?.result;
            const grnData = reversedData?.filter(dep => dep.grnNo !== dep?.pOrderNo)
            setRowData(grnData || []);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching items:', error);
            setLoading(false);
        }
    }
    const handlePrint = () => {
        if (selectedRow) {
            navigate(`/Inventory/GRN/${selectedRow?.grnNo}`, { state: selectedRow })
        }
    }
    const handleEye = (clickedRowData) => {
        if (clickedRowData?.grnNo) {
            let eye = true
            const updatedData = { ...clickedRowData, eye: eye };
            navigate(`/Inventory/GRN/Listing/${updatedData?.grnNo}`, { state: updatedData })
        } else if (selectedRow) {
            let eye = true
            const updatedData = { ...selectedRow, eye: eye };
            navigate(`/Inventory/GRN/Listing/${updatedData?.grnNo}`, { state: updatedData })
        }
    };
    const dateComparator = (date1, date2) => {
        const momentDate1 = moment(date1);
        const momentDate2 = moment(date2);
        return momentDate1.isSame(momentDate2) ? 0 : momentDate1.isBefore(momentDate2) ? -1 : 1;
    };
    const dateFormatter = (params) => {
        const date = moment(params.value);
        return date.format("DD-MMM-YYYY") || "";
    };
    const columnDefs = [
        { headerCheckboxSelection: true, checkboxSelection: true, headerCheckboxSelectionFilteredOnly: true, suppressMovable: true, width: 50 },
        { headerName: 'GRN No', field: 'grnNo', filter: true, floatingfilter: true, suppressMovable: true, width: 200 },
        { headerName: 'PO No', field: 'pOrderNo', filter: true, floatingfilter: true, suppressMovable: true, width: 200 },
        {
            headerName: 'Date', field: 'modifiedOn', filter: true, floatingfilter: true, suppressMovable: true, width: 200,
            valueFormatter: dateFormatter,
            comparator: dateComparator,
        },
        { headerName: 'Supplier ID', field: 'vendorId', filter: true, floatingfilter: true, suppressMovable: true, width: 200 },
        { headerName: 'Supplier Name', field: 'vendorName', filter: true, floatingfilter: true, suppressMovable: true, width: 200 },
        { headerName: 'Remarks', field: 'remarks', filter: true, floatingfilter: true, suppressMovable: true, width: 200 },
        {
            headerName: 'Status', field: 'status', filter: true, floatingfilter: true, suppressMovable: true, width: 140,
            cellRenderer: function (params) {
                if (params.value === 0 || params.value === null) {
                    return (<button class="btn pend ">Pending</button>);
                }
                else if (params.value === 1 || params.value === null) {
                    return (<button class="btn aproved">Approved</button>);
                }
                else if (params.value === 5 || params.value === null) {
                    return (<button class="btn hold">Hold</button>);
                }
                else if (params.value === 2 || params.value === null) {
                    return (<button class="btn reject">Rejected</button>);
                } else {
                    return params.value;
                }
            }
        },
        {
            headerName: 'Approval date', field: 'approvalDate', filter: true, floatingfilter: true, suppressMovable: true, width: 200,
            valueFormatter: dateFormatter,
            comparator: dateComparator,
        },
    ];
    const onCellClicked = (event) => {
        if (event.colDef.field === 'grnNo') {
            const clickedRowData = event.data;
            setSelectedRow(clickedRowData);
            handleEye(clickedRowData)
        }
    };

    const onSelectionChanged = async (event) => {
        const selectedRows = event.api.getSelectedRows();
        setSelectedRow(selectedRows.length === 1 ? selectedRows[0] : null);
        const params = new URLSearchParams();
        params.append('DocNo', selectedRows[0]?.grnNo);
        params.append('DocumentType', "GRN");
        try {
            const res = await apiService.getApiParamater('GET_DOC_STATUS_EDIT', `?${params.toString()}`);
            const statuses = res?.data?.result?.map(item => item.status);
            const allAreZero = statuses.every(status => status === 0);
            const anyIsFive = statuses.some(status => status === 2);
            if (allAreZero || anyIsFive) {
                setSelectedItem(selectedRows.length === 1 ? selectedRows[0] : null);
            } else {
                console.warn("No valid status to set the item.");
                setSelectedItem(null);
            }
        } catch (error) {
            setSelectedItem(null)
            console.error("Error fetching document status:", error);
        }
    };
    const handlenew = () => {
        navigate('/Inventory/GRN/Create')
    }
    return (
        <div className='p-4'>
            {loading ? (
                <div>Loading...</div>
            ) : (
                <>
                    <div className='p-4 border-box2 justify-content-between d-flex align-items-center'>
                        <h4 className='mainInvHead'>
                            GRN List
                        </h4>
                        <div className='d-flex justify-content-end gap-1'>
                            <div
                                onClick={handleEye}
                                className={`mainbtnGry flex-end gap-2 ${!selectedRow ? 'disabled' : ''}`}
                                style={{ cursor: selectedRow ? 'pointer' : 'not-allowed' }}
                            >
                                <FaRegEye color='#fff' className='center mt-1' />
                                <div className='center'>View</div>
                            </div>
                            {permissions?.canUpdate === true ?
                                <div
                                    onClick={selectedtem && selectedtem?.status === 0 || selectedtem?.status === 2 ? handleEdit : null}
                                    className={`mainbtnSecondary flex-end gap-2 ${!selectedtem || selectedtem?.status === 0 || selectedtem?.status === 2 ? '' : 'disabled'
                                        }`}
                                    style={{
                                        cursor: selectedtem && selectedtem?.status === 0 || selectedtem?.status === 2 ? 'pointer' : 'not-allowed',
                                    }}
                                >
                                    <GrEdit color='#fff' className='center mt-1 ' />
                                    <div className='center'>Edit</div>
                                </div> : ''
                            }
                            <div
                                onClick={handlePrint}
                                className={`mainbtnRed flex-end gap-2 ${!selectedRow ? 'disabled' : ''}`}
                                style={{ cursor: selectedRow ? 'pointer' : 'not-allowed' }}
                            >
                                <AiOutlinePrinter color='#fff' className='center mt-1 mx-2' />
                                <div className='center'>Print</div>
                            </div>
                            {permissions?.canAdd === true ?
                                <div className='col center'>
                                    <button className='mainbtn ' type="submit" onClick={() => handlenew()}>
                                        <IoAddSharp color='#fff' className='center mt-1' /> <div>Add</div>
                                    </button>
                                </div>
                                : ''
                            }
                        </div>
                    </div>
                    <div className='gridshad ' style={{ height: "600px" }}>
                        <div className="ag-theme-quartz" style={{ height: "600px" }}>
                            <AgGridReact
                                getRowStyle={(params) => {
                                    if (params.node.rowIndex % 2 === 0) {
                                        return { background: '#F0EDF5' };
                                    } else {
                                        return { background: '#fff' };
                                    }
                                }}
                                rowData={rowData}
                                enableFilter={true}
                                floatingFilter={true}
                                rowSelection={'single'}
                                columnDefs={columnDefs}
                                onCellClicked={onCellClicked}
                                onSelectionChanged={onSelectionChanged}
                            />
                        </div>
                        <Pagination pageSize={pageSize} setPageSize={setPageSize} pagination={pagination} getItems={getItems} />
                    </div>
                </>
            )}

        </div>
    );
};
export default GRNGrid; 