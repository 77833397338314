import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation } from 'react-router-dom';
import ApiService from '../../services/apiService';
import Select from 'react-select';
import ApprovalUserGrid from './grid'
import React, { useState, useEffect, Component } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import { useSelector } from 'react-redux';
import { getName, getUserName } from '../../../Redux/selecters';
import { RiRefreshLine } from "react-icons/ri";
import { FaAngleLeft } from "react-icons/fa6";
import { FaSave } from "react-icons/fa";
import Pagination from '../pagination/pagination';
const Approval = () => {
    let navigate = useNavigate();
    const location = useLocation();
    const apiService = new ApiService();
    const [searchTerm, setSearchTerm] = useState({ value: 'Pending', label: 'Pending' });
    const [DocTerm, setDocTerm] = useState({ value: 'All', label: 'All' });
    const userName = useSelector(getUserName);
    const [rowData, setRowData] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [pageSize, setPageSize] = useState(20);
    const [pagination, setPagination] = useState();
    const [loader, setloader] = useState(false);
    const [SupplierTab, setSupplierTab] = useState(true);
    const [permissions, setPermissions] = useState([]);
    const [POTab, setPOTab] = useState(true);
    useEffect(() => {
        if (pageSize) {
            fetchApprovalData();
        }
    }, [pageSize]);
    useEffect(() => {
        fetchApprovalData();
        const storedPermissions = JSON.parse(localStorage.getItem('RoleAccess')) || [];
        const permissions = storedPermissions?.filter(acc => acc.formName === "Approval");
        setPermissions(permissions[0]);
    }, []);
    const [formData, setFormData] = useState({
        DocType: "",
        fromDate: '',
        toDate: '',
        DocumentNo: "",
        filter: '',
    });
    const StatusFilter = [
        { value: 'Pending', label: 'Pending' },
        { value: 'Approved', label: 'Approved' },
        { value: 'Hold', label: 'Hold' },
        { value: 'Rejected', label: 'Rejected' },
    ];
    const DocFilter = [
        { value: 'All', label: 'All' },
        { value: 'Requisition', label: 'Purchase Requisition' },
        { value: 'Price Comparison', label: 'Price Comparison' },
        { value: 'Quotation', label: 'Quotation' },
        { value: 'POrder', label: 'Purchase Order' },
        { value: 'GRN', label: 'GRN' },
        { value: 'Direct Purchase', label: 'Direct Purchase' },
        { value: 'Invoice', label: 'Invoice' },
        { value: 'Purchase Return', label: 'Purchase Return' },
        { value: 'Issue', label: 'Issue item' },
        { value: 'Direct Issue', label: 'Direct Issue' },
        { value: 'IReturn', label: 'Issue Return' },
        { value: 'IssueReceived', label: 'Issue Received' },
        { value: 'Store Request', label: 'Store Request' },
    ];
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'fromDate' || name === 'toDate') {
            const dateParts = e.target.value.split("-");
            if (dateParts[0].length > 4) {
                return;
            }
        }
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    useEffect(() => {
        fetchApprovalData();
    }, [searchTerm, DocTerm, formData]);
    const fetchApprovalData = async (newPageNo = pagination?.currentPage) => {
        try {
            const status = searchTerm?.value;
            let docType = DocTerm?.value.toString() || "All";
            if (docType === "Direct Issue") {
                docType = "Issue";
            }
            const defaultFromDate = moment().subtract(1, 'months').format("DD-MMM-YY");
            const defaultToDate = moment().add(1, 'months').format("DD-MMM-YY");
            // Use the default dates if fromDate or toDate are not selected
            const fromDate = formData.fromDate ? moment(formData.fromDate).format("DD-MMM-YY") : defaultFromDate;
            const toDate = formData.toDate ? moment(formData.toDate).format("DD-MMM-YY") : defaultToDate;

            if (docType === "All") {
                let param = `?UserName=${userName}&From=${fromDate}&To=${toDate}&Filter=${status || ""}&DocNo=${formData.DocumentNo}&PageNo=${newPageNo || 1}&PageSize=${pageSize || 20}`;
                const response = await apiService.getApiParamater('GET_DOC_AUTHORIZATION_STATUS', param);
                if (response?.data?.statusCode === 200) {
                    const Authorization = response?.data?.result;
                    Authorization.sort((a, b) => b.docno - a.docno);
                    setRowData(Authorization);
                    setPagination(response.data.paginationData);
                } else if (response?.data?.statusCode === 204) {
                    setRowData([]);
                }
            } else {
                let param = `?Filter=${status || "Pending"}&From=${fromDate}&To=${toDate}&FilterBy=${docType}&UserName=${userName}&PageNo=${newPageNo || 1}&PageSize=${pageSize || 20}`;
                const response = await apiService.getApiParamater('GET_DOC_AUTHORIZATION_STATUS', param);
                if (response?.data?.statusCode === 200) {
                    const Authorization = response?.data?.result;
                    Authorization.sort((a, b) => b.docdate - a.docdate);
                    let AuthorizationData;
                    if (DocTerm?.label === 'Direct Issue') {
                        const res = await apiService.get('GET_ITEM_ISSUE');
                        const filteredRes = res.data.result?.filter(issue =>
                            Authorization.some(auth => auth.docno === issue.issueNo)
                        );
                        let filterAuthorization = filteredRes?.filter(issue => issue.requisitionNo === null);
                        const filteredAuth = Authorization?.filter(issue =>
                            filterAuthorization.some(auth => auth.issueNo === issue.docno)
                        );
                        AuthorizationData = filteredAuth;
                    } else {
                        AuthorizationData = Authorization;
                    }
                    setRowData(AuthorizationData.reverse());
                    setPagination(response.data.paginationData);
                } else if (response?.data?.statusCode === 204) {
                    setRowData([]);
                }
            }

        } catch (error) {
            console.error("Error fetching packing data:", error);
        }
    };
    const handleFilter = (selectedOption) => {
        setSearchTerm(selectedOption)
    };
    const handleDocFilter = (selectedOption) => {
        localStorage.setItem('selectedOptionLabel', selectedOption.label);
        setDocTerm(selectedOption);
    };
    const handleStatusSave = (event, row, status) => {
        const { docno } = row;
        setSelectedItems(prevItems => {
            const filteredItems = prevItems?.filter(item => item.docno !== docno);
            if (event.target.checked) {
                const newItem = {
                    ...row,
                    approved: status === 'approved' && event.target.checked ? 1 : 0,
                    hold: status === 'hold' && event.target.checked ? 1 : 0,
                    rejected: status === 'rejected' && event.target.checked ? 1 : 0
                };
                return [...filteredItems, newItem];
            } else {
                return filteredItems;
            }
        });
        setRowData(prevRowData => prevRowData.map(r => {
            if (r.docno === docno) {
                return {
                    ...r,
                    approved: status === 'approved' && event.target.checked ? 1 : 0,
                    hold: status === 'hold' && event.target.checked ? 1 : 0,
                    rejected: status === 'rejected' && event.target.checked ? 1 : 0
                };
            } else {
                return r;
            }
        }));
    };
    const handleStatusChange = async () => {
        let allSuccess = true;
        setloader(true)
        const initialDocType = DocTerm?.value.toString() || "Purchase Requisition";
        try {
            const apiService = new ApiService();
            const currentDatetimeUTC = new Date().toISOString();
            for (const item of selectedItems) {
                let statusValue;
                if (item.approved === 1) {
                    statusValue = 1;
                } else if (item.hold === 1) {
                    statusValue = 5;
                    if (!item.remarks) {
                        toast.error(`Please enter remarks to Hold approval for ${item?.docno}`);
                        allSuccess = false;
                        setloader(false)
                        return
                    }
                } else if (item.rejected === 1) {
                    statusValue = 2;
                    if (!item.remarks) {
                        toast.error(`Please enter remarks to reject approval for ${item?.docno}`);
                        allSuccess = false;
                        setloader(false)
                        return
                    }
                } else {
                    statusValue = 0;
                }
                let docType = initialDocType;
                if (docType === "Purchase Requisition") {
                    const res = await apiService.get('GET_PR');
                    const storeData = res?.data?.result?.filter(pr => pr?.requisitionNo === item?.docno);
                    if (storeData.length > 0) {
                        docType = "Store Request";
                    }
                }
                const body = {
                    docId: item?.docno,
                    srNo: item?.srNo,
                    docType: item.doctype === "Purchase Order" ? "POrder" : item.doctype,
                    status: statusValue,
                    userName: userName,
                    remarks: item.remarks || "",
                };
                const response = await apiService.put('UPDATE_DOC_AUTHORIZATION', body);
                if (response?.data?.statusCode === 201 || response.data.statusCode === 200) {
                    const statusParam = new URLSearchParams();
                    statusParam.append('userCode', userName);
                    statusParam.append('docNo', item?.docno);
                    statusParam.append('srno', 1);
                    const statusRes = await apiService.getApiParamater('GET_APPROVAL_STATUS', `?${statusParam.toString()}`)
                    if (statusRes?.data?.isValid === true) {
                        const paramsGet = new URLSearchParams();
                        debugger
                        paramsGet.append('docType', item.doctype === "Requisition" ? "Purchase Requisition" : item.doctype === "Purchase Order" ? "POrder" : item.doctype);
                        paramsGet.append('docId', item?.docno);
                        paramsGet.append('srno', 2);
                        const response3 = await apiService.getApiParamater('GET_DOC_APPROVAL_USER', `?${paramsGet.toString()}`);
                        if (response3?.data?.result?.length > 0) {
                            const toEmails = response3?.data?.result?.map(user => user.userEmail);
                            const emailbody = {
                                toEmails: toEmails,
                                subject: item.doctype,
                                body: "",
                                docNo: item?.docno,
                                srNo: 2,
                                docType: item.doctype
                            };
                            const emailres = await apiService.post("DOC_APPROVAL_EMAIL_POST", emailbody);
                        }
                    }
                    fetchApprovalData()
                    toast.success(`${item?.docno} ${statusValue === 1 ? "Approved" : statusValue === 5 ? "Hold" : "Rejected"}`);
                    setSelectedItems([]);
                    setloader(false)
                } else {
                    allSuccess = false;
                    setloader(false)
                }
            }

            if (allSuccess) {
                setSelectedItems([]);
                setloader(false)
            } else {
                console.error('Not all operations were successful.');
                setloader(false)
            }
        } catch (error) {
            console.error('Error updating status:', error);
            setloader(false)
        }
    };
    const [colDefs, setColDefs] = useState([
        { headerName: "Doc Type", field: "doctype", filter: true, floatingfilter: true, suppressMovable: true, width: 150 },
        {
            headerName: "Doc No", field: "docno", filter: true, floatingfilter: true, suppressMovable: true, width: 150,
            cellRenderer: (params) => {
                const { doctype } = params.data;
                const handleButtonClick = () => handleview(params.data);
                const buttonStyle = {
                    color: '#1032E7',
                    textDecoration: "underline",
                    border: "none",
                    backgroundColor: "#fff"
                };
                if (doctype === "Purchase Requisition") {
                    return <button style={buttonStyle} onClick={handleButtonClick}>{params.value}</button>;
                } else if (doctype === "Price Comparison") {
                    return <button style={buttonStyle} onClick={() => handleCompview(params.data)}>{params.value}</button>;
                } else if (doctype === "Quotation") {
                    return <button style={buttonStyle} onClick={() => handleCompview(params.data)}>{params.value}</button>;
                } else if (doctype === "Purchase Order") {
                    return <button style={buttonStyle} onClick={() => handlePOView(params.data)}>{params.value}</button>;
                } else if (doctype === "GRN") {
                    return <button style={buttonStyle} onClick={() => handleGrnView(params.data)}>{params.value}</button>;
                } else if (doctype === "Direct Purchase") {
                    return <button style={buttonStyle} onClick={() => handleDirectPurchaseView(params.data)}>{params.value}</button>;
                } else if (doctype === "Invoice") {
                    return <button style={buttonStyle} onClick={() => handleInvoiceView(params.data)}>{params.value}</button>;
                } else if (doctype === "Issue") {
                    return <button style={buttonStyle} onClick={() => handleIssueView(params.data)}>{params.value}</button>;
                } else if (doctype === "Store Request") {
                    return <button style={buttonStyle} onClick={() => handleUserStore(params.data)}>{params.value}</button>;
                } else if (doctype === "Issue") {
                    return <button style={buttonStyle} onClick={() => handleIssueView(params.data)}>{params.value}</button>;
                } else {
                    return <button style={buttonStyle} onClick={handleButtonClick}>{params.value}</button>;
                }
            }
        },
        {
            headerName: "Date", field: "docdate", filter: true, floatingfilter: true, suppressMovable: true, width: 150
        },
        { headerName: "Amount", field: "amount", filter: true, floatingfilter: true, suppressMovable: true, width: 120 },
        {
            headerName: "Approved", field: "approved", editable: false, suppressMovable: true, width: 120,
            cellRenderer: (params) => (
                <input
                    type="checkbox"
                    style={{ width: "26.81px", height: "26.81px", border: "0.5px solid #5B93FF" }}
                    checked={params.data.approved === 1}
                    onChange={(event) => handleStatusSave(event, params.data, 'approved')}
                />
            )
        },
        {
            headerName: "Hold", field: "hold", editable: false, suppressMovable: true, width: 120,
            cellRenderer: (params) => (
                <input
                    type="checkbox"
                    style={{ width: "26.81px", height: "26.81px", border: "0.5px solid #5B93FF" }}
                    checked={params.data.hold === 1}
                    onChange={(event) => handleStatusSave(event, params.data, 'hold')}
                />
            )
        },
        {
            headerName: "Reject", field: "rejected", editable: false, suppressMovable: true, width: 120,
            cellRenderer: (params) => (
                <input
                    style={{ width: "26.81px", height: "26.81px", border: "0.5px solid #5B93FF" }}
                    type="checkbox"
                    checked={params.data.rejected === 1}
                    onChange={(event) => handleStatusSave(event, params.data, 'rejected')}
                />
            )
        },
        { headerName: "Remarks", field: "remarks", editable: true, suppressMovable: true, width: 120 },
        { headerName: "User", field: "userName", filter: true, floatingfilter: true, suppressMovable: true, width: 150 },
        { headerName: "Currency", field: "currency", filter: true, floatingfilter: true, suppressMovable: true, width: 120 },
        { headerName: "Terms", field: "terms", filter: true, floatingfilter: true, suppressMovable: true, width: 120 },
        { headerName: "Created On", field: "costcenter1", filter: true, floatingfilter: true, suppressMovable: true, width: 130 },
        { headerName: "Cost Center 1", field: "costcenter1", filter: true, floatingfilter: true, suppressMovable: true, width: 130 },
        { headerName: "Cost Center 2", field: "costCenter2", filter: true, floatingfilter: true, suppressMovable: true, width: 130 },
        { headerName: "Department", field: "department", filter: true, floatingfilter: true, suppressMovable: true, width: 130 },
        { headerName: "Order Type", field: "orderType", filter: true, floatingfilter: true, suppressMovable: true, width: 130 },
        { headerName: "Action", field: "isActive", filter: true, floatingfilter: true, suppressMovable: true, width: 120 }
    ]);
    const handleview = (data) => {
        navigate(`/Inventory/Requisition/PurchaseRequisition/${data.docno}`, { state: data })
    }
    const handleCompview = (data) => {
        navigate(`/Inventory/PriceComparison/View/${data.docno}`, { state: data })
    }
    const handlePOView = (data) => {
        navigate(`/Inventory/PurchaseOrder/${data.docno}`, { state: data })
    }
    const handleGrnView = (data) => {
        let eye = true
        const updatedData = { ...data, eye: eye };
        navigate(`/Inventory/GRN/Listing/${data.docno}`, { state: updatedData })
    }
    const handleDirectPurchaseView = (data) => {
        let eye = true
        const updatedData = { ...data, eye: eye };
        navigate(`/Inventory/DirectPurchase/Update`, { state: updatedData })
    }
    const handleInvoiceView = (data) => {
        let eye = true
        const updatedData = { ...data, eye: eye };
        navigate(`/Inventory/invoice/${data.docno}`, { state: updatedData })
    }
    const handleIssueView = (data) => {
        const updatedData = { ...data, eye: !data.eye?.active };
        navigate(`/Inventory/Issue/${updatedData.docno}`, { state: updatedData });
    }
    const handleUserStore = (data) => {
        const updatedData = { ...data, eye: !data.eye?.active };
        navigate(`/Inventory/UserStore/${updatedData.docno}`, { state: updatedData });
    }
    const handleRemarks = (event) => {
        const editedRow = rowData.find(row => row.docno === event.data.docno);
        if (editedRow) {
            editedRow[event.colDef.field] = event.value;
            setSelectedItems(prevItems => {
                const updatedItems = [...prevItems];
                const existingItemIndex = updatedItems.findIndex(item => item.rcNo === editedRow.rcNo);
                if (existingItemIndex !== -1) {
                    updatedItems[existingItemIndex] = { ...updatedItems[existingItemIndex], remarks: event.value };
                } else {
                    const existingItem = prevItems.find(item => item.rcNo === editedRow.rcNo);
                    updatedItems.push({ ...existingItem, ...editedRow });
                }
                return updatedItems;
            });
        }
    };
    const handleBackClick = () => {
        window.history.back();
    };
    const handleReset = () => {
        setSelectedItems([]);
        setloader(false)
        setFormData({
            DocType: "",
            fromDate: '',
            toDate: '',
            DocumentNo: "",
            filter: '',
        });
        setSearchTerm({ value: 'Pending', label: 'Pending' });
        setDocTerm({ value: 'All', label: 'All' });
    };
    return (
        <div className='px-4 mt-4'>
            <div className="cost-center-section">
                <div className='border-box2 p-4 d-flex justify-content-between pt-3'>
                    <h5 className='col mainInvHead'>Approvals</h5>
                    <div className='d-flex gap-3'>
                        {loader === true ?
                            <button className='mainbtn ' type="submit"><ClipLoader color="#ffffff" size={25} /></button> :
                            <>
                                {permissions?.canUpdate === true ?
                                    <button className='mainbtn ' type="submit" onClick={handleStatusChange}><FaSave className='center mt-1 mx-2' /><div>Save</div></button>
                                    : ''
                                }
                            </>
                        }
                        <button className='mainbtnRed' onClick={handleReset} ><RiRefreshLine className='center mt-1 mx-2' /><div>Reset</div></button>
                        <button onClick={handleBackClick} className='mainbtn'><FaAngleLeft className='center mt-1 mx-2' /><div>Back</div></button>
                    </div>
                </div>
                <div className="d-flexcursor-pointer slide-header"
                    onClick={() => setPOTab(!POTab)}>
                    <div className='h6 pt-1'>Approval Filters</div>
                </div>
                <div className={`slide-content ${POTab ? 'open' : 'closed'}`}>
                    <div className={`border-box2 p-3 ${POTab ? 'slide-in' : 'slide-out'}`}>
                        <div className="form-group row pb-3">
                            <div className="d-flex justify-content-between col-md-4 col-lg-4 ">
                                <label htmlFor="validationCustom02" className="form-label  pt-1 start">Document No</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    id="validationCustom02"
                                    name="DocumentNo"
                                    value={formData?.DocumentNo}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="d-flex justify-content-between col-md-4 col-lg-4">
                                <label htmlFor="validationCustom02" className="form-label  pt-1 start">Filter</label>
                                <Select
                                    value={searchTerm}
                                    options={StatusFilter}
                                    onChange={handleFilter}
                                    className="w-70 p-0"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                />
                            </div>
                            <div className="d-flex justify-content-between col-md-4 col-lg-4">
                                <label htmlFor="validationCustom01" className="form-label  pt-1 start">Document Type</label>
                                <Select
                                    value={DocTerm}
                                    options={DocFilter}
                                    onChange={handleDocFilter}
                                    className="w-70 p-0"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="d-flex justify-content-between col-md-4 col-lg-4">
                                <label htmlFor="validationCustom02" className="form-label  pt-1 start">From</label>
                                <input
                                    type="date"
                                    className="form-Input w-70"
                                    id="validationCustom02"
                                    name="fromDate"
                                    value={formData?.fromDate}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="d-flex justify-content-between col-md-4">
                                <label htmlFor="validationCustom02" className="form-label  pt-1 start">To</label>
                                <input
                                    type='date'
                                    className="form-Input w-70"
                                    id="validationCustom02"
                                    name="toDate"
                                    value={formData?.toDate}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header"
                    onClick={() => setSupplierTab(!SupplierTab)}>
                    <div className='h6 pt-1'>Approval List</div>
                </div>
                <div className={`slide-content ${SupplierTab ? 'open' : 'closed'}`}>
                    <div className={`border-box2 ${SupplierTab ? 'slide-in' : 'slide-out'}`}>
                        <div className="ag-theme-quartz" style={{ height: 500, suppressMovable: true, width: '100%' }}>
                            <AgGridReact
                                rowData={rowData}
                                columnDefs={colDefs}
                                onCellValueChanged={handleRemarks}
                                suppressRowClickSelection={true}
                                getRowStyle={(params) => {
                                    return params.node.rowIndex % 2 === 0
                                        ? { background: '#F0EDF5' }
                                        : { background: '#fff' };
                                }}
                            />
                        </div>
                        <Pagination pagination={pagination} getItems={fetchApprovalData} pageSize={pageSize} />
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Approval;
