import React, { useState, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation } from 'react-router-dom';
import PackingGrid from './packingGrid';
import ApiService from '../../services/apiService';
import { toast } from 'react-toastify';
import { hasPermission } from "../../services/roleService"
import { RiRefreshLine } from "react-icons/ri";
import { FaSave } from "react-icons/fa";
import Pagination from '../pagination/pagination';
import { GrEdit } from "react-icons/gr";
import { PropagateLoader } from 'react-spinners';


const PackingMaster = () => {
    let navigate = useNavigate();
    const location = useLocation();
    const [permissions, setPermissions] = useState([]);
    const [pageSize, setPageSize] = useState(20);
    const [pagination, setPagination] = useState();
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedRow, setSelectedRow] = useState(null);
    const [formData, setFormData] = useState({
        recordId: "",
        typeCode: "",
        description: "",
    });
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    useEffect(() => {
        fetchPackingData();
        const storedPermissions = JSON.parse(localStorage.getItem('RoleAccess')) || [];
        setPermissions(storedPermissions);
        if (pageSize) {
            fetchPackingData();
        }
    }, [pageSize]);
    const handleEdit = (clickedRowData) => {
        if (clickedRowData || selectedRow) {
            setFormData({
                recordId: clickedRowData?.recordId || selectedRow?.recordId,
                description: clickedRowData?.description || selectedRow?.recordId || "",
                typeCode: clickedRowData?.type || selectedRow?.recordId || "",
            });
        }
    };
    const checkPermission = (moduleName, action) => {
        return hasPermission(moduleName, action, permissions);
    };
    // const canEditMaster = checkPermission('Packing Master', 'edit');
    // const canCreateMaster = checkPermission('Packing Master', 'create');
    const canEditMaster = true;
    const canCreateMaster = true;
    const fetchPackingData = async (newPageNo = pagination?.currentPage) => {
        try {
            const apiService = new ApiService()
            const params = new URLSearchParams();
            params.append('PageNo', newPageNo || 1);
            params.append('PageSize', pageSize || 20);
            const response = await apiService.getApiParamater('GET_EPACKING', `?${params.toString()}`);
            setPagination(response.data.paginationData);
            const packingData = response?.data;
            setLoading(false);
            setRowData(packingData);
        } catch (error) {
            setLoading(false);
            console.error("Error fetching packing data:", error);
        }
    };
    const handleSubmit = async () => {
        if (!formData.typeCode || !formData.description) {
            if (!formData.description) { toast.warning("Enter Packing  Type Name") }
            if (!formData.typeCode) { toast.warning("Enter Packing  Type Code") }
            return;
        }
        try {
            const apiService = new ApiService()
            let data = {
                type: formData?.typeCode,
                description: formData?.description,
                active: 1,
                exp2: "true"
            }

            const response = await apiService.post('CREATE_EPACKING', data);
            if (response?.data?.statusCode === 201) {
                toast?.success("Packing Master Created Successfully")
                fetchPackingData();
                setFormData({
                    recordId: "",
                    typeCode: "",
                    description: "",
                });
            } else if (response?.data?.statusCode === 208) {
                toast.success(response?.data?.message)
                fetchPackingData();
            }
        } catch (error) {
            toast.error("Please try Again!")
        }
    };
    const updateFormData = async () => {
        try {
            const apiService = new ApiService();
            let body = {
                recordId: formData?.recordId,
                type: formData?.typeCode,
                description: formData?.description,
            }
            const res = await apiService.put(`UPDATE_EPACKING`, body);

            if (res?.data?.statusCode === 201) {
                toast.success('Form data updated successfully');
                setFormData({
                    recordId: "",
                    typeCode: "",
                    description: "",
                })
                fetchPackingData()
                setSelectedRow(null)
                window.history.replaceState({}, '')
            } else {
                console.error('Failed to update form data');
            }
        } catch (error) {
            console.error('Error updating form data:', error);
        }
    };
    const handleClearFilters = () => {
        setFormData({
            recordId: "",
            typeCode: "",
            description: "",
        })
        setSelectedRow(null)
        fetchPackingData()
    };
    return (
        <div className='px-4 pt-3'>

            <div className='py-lg-3'>
                <div className='gridBg p-3' style={{ borderRadius: "16px 16px 16px 16px" }}>
                    <div className='d-flex justify-contant-between mb-4'>
                        <h5 className='col mainInvHead'>Packing Type</h5>
                        <div className='d-flex justify-content-end gap-1'>
                            <div
                                onClick={handleEdit}
                                className={`mainbtnSecondary flex-end gap-2 ${!selectedRow ? 'disabled' : ''}`}
                                style={{ cursor: selectedRow ? 'pointer' : 'not-allowed' }}
                            >
                                <GrEdit color='#fff' className='center mt-1 mx-2' />
                                <div className='center'>Edit</div>
                            </div>
                            <div className='col center'>
                                {selectedRow ? (
                                    <button className='mainbtn ' onClick={updateFormData}><FaSave className='center mt-1 mx-2' /> <div>Update</div></button>
                                ) : (
                                    <>{canCreateMaster === true ?
                                        <button className='mainbtn ' type="submit" onClick={() => handleSubmit()}>
                                            <FaSave className='center mt-1 mx-2' /> <div>Save</div></button>
                                        :
                                        <button className='mainbtn ' type="submit" style={{ cursor: "not-allowed" }}>
                                            <FaSave className='center mt-1 mx-2' /> <div>Save</div></button>
                                    } </>
                                )}
                            </div>
                            <button className='mainbtnRed' onClick={handleClearFilters}><RiRefreshLine className='center mt-1 mx-2' /><div>Reset</div></button>
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-lg-4 d-flex justify-content-between">
                            <label htmlFor="validationCustom01" className="form-label  pt-2 start">Packing Type Code *</label>
                            <input
                                type="text"
                                className="form-Input w-70"
                                id="validationCustom01"
                                name="typeCode"
                                value={formData.typeCode}
                                disabled={canCreateMaster === true ? false : true}
                                onChange={handleInputChange}
                                required
                            />
                            <div className="valid-feedback">
                                Looks good!
                            </div>
                        </div>
                        <div className="col-lg-4 d-flex justify-content-between">
                            <label htmlFor="validationCustom02" className="form-label  pt-2 start">Packing Type Name *</label>
                            <input
                                type="text"
                                className="form-Input w-70"
                                id="validationCustom02"
                                name="description"
                                disabled={canCreateMaster === true ? false : true}
                                value={formData?.description}
                                onChange={handleInputChange}
                                required
                            />
                            <div className="valid-feedback">
                                Looks good!
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='gridBg mt-3'>
                {loading ? (
                    <div className='center'>
                        <PropagateLoader color="#6E5199" size={15} />
                    </div>
                ) : (
                    <>
                        <PackingGrid rowData={rowData?.result} fetchPackingData={fetchPackingData} canEditMaster={canEditMaster}
                            setSelectedRow={setSelectedRow} handleEdit={handleEdit} />
                        <Pagination pageSize={pageSize} setPageSize={setPageSize} pagination={pagination} getItems={fetchPackingData} />
                    </>
                )}
            </div>
        </div>
    )
}

export default PackingMaster;
