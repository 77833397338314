// UserService.js

import { jwtDecode } from 'jwt-decode';

class UserService {
  getUserRole(token) {
    // const token = localStorage.getItem('Token');

    if (token) {
      try {
        const decoded = jwtDecode(token, { complete: true });
        // const roleClaim = decoded?.['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];

        // return roleClaim ? roleClaim.split('/').pop() : null;
        const roleClaim = decoded?.userRole
        return roleClaim

      } catch (error) {
        console.error('Error decoding token:', error);
        return null;
      }
    } else {
      return null;
    }
  }

  getUserId(token) {
    // const token = localStorage.getItem('Token');

    if (token) {
      try {
        const decoded = jwtDecode(token, { complete: true });
        const Userid = decoded?.['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'];
        return Userid ? Userid?.split('/').pop() : decoded?.userID;
      } catch (error) {
        console?.error('Error decoding token:', error);
        return null;
      }
    } else {
      return null;
    }
  }
  getUserId2(token) {
    // const token = localStorage.getItem('Token');

    if (token) {
      try {
        const decoded = jwtDecode(token, { complete: true });
        const userId = decoded?.User_Id || decoded?.userID;
        return userId || null;
      } catch (error) {
        console.error('Error decoding token:', error);
        return null;
      }
    } else {
      return null;
    }
  }
  getUserName(token) {
    // const token = localStorage.getItem('Token');

    if (token) {
      try {
        const decoded = jwtDecode(token, { complete: true });
        const UserName = decoded?.userName;
        return UserName ? UserName.split('/').pop() : null;
      } catch (error) {
        console.error('Error decoding token:', error);
        return null;
      }
    } else {
      return null;
    }
  }
  getUserCounter(token) {
    // const token = localStorage.getItem('Token');
    if (token) {
      try {
        const decoded = jwtDecode(token, { complete: true });
        const Counter = decoded?.CounterNo;
        return Counter ? Counter : null;
      } catch (error) {
        console.error('Error decoding token:', error);
        return null;
      }
    } else {
      return null;
    }
  }
  getUserCrud(token) {
    if (token) {
      try {
        const decoded = jwtDecode(token, { complete: true });
        const crudValues = decoded?.crud;
        return crudValues ? crudValues : null;
      } catch (error) {
        console.error('Error decoding token:', error);
        return null;
      }
    } else {
      return null;
    }

  }


}

export default new UserService();