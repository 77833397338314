let BASEURL = 'https://demoinventoryapi.qfinity.net'
// let BASEURL = 'https://inventoryapi.qfinity.net'
// let BASEURL = 'https://barqinventoryapi.barq.freemyip.com'
let PosApi = 'http://posapi.qfinity.net'
let BASEURL_FIN = 'https://dev.api.newcrm.freemyip.com'
let INV_IDTENTITY_URL = 'https://demoidentityidentityapi.qfinity.net'
let PERMISSION_APIS =`https://api.pro360.freemyip.com`
// let PERMISSION_APIS =`https://api.barq.freemyip.com`
export const endPoint = {
  USER_LOGIN: `${BASEURL_FIN}/api/identity/auth/gettoken`,
  REFRESH_TOKEN: `${BASEURL_FIN}/api/identity/auth/refreshaccesstoken`,
  GET_CURRENCY_PER:`${BASEURL_FIN}/api/finanace/generalfinance/finance-settings`,
  GET_ENCRYPT_TOKEN:`${INV_IDTENTITY_URL}/api/identitypro/auth/getdecryptedusertoken`,
  GET_PERMISSIONS:`${PERMISSION_APIS}/api/usermanagement/usermaster/forms-access`,
  // Tax Master
  CREATE_INV_TAX: `${BASEURL}/api/Tax/Create`,
  // GET_INV_TAX: `${PosApi}/api/Tax/Get`,
  GET_INV_TAX: `${BASEURL}/api/Tax/Get`,
  UPDATE_INV_TAX: `${BASEURL}/api/Tax/Update`,
  DELETE_INV_TAX: `${BASEURL}/api/Tax/Delete`,

  //Company Master
  CREATE_COMPANY: `${BASEURL}/api/Company/Create`,
  GET_COMPANY: `${BASEURL}/api/Company/Get`,
  UPDATE_COMPANY: `${BASEURL}/api/Company/Update`,
  DELETE_COMPANY: `${BASEURL}/api/Company/Delete`,
  //Branch Master
  CREATE_BRANCH: `${BASEURL}/api/CompanyBranch/Create`,
  GET_BRANCH: `${BASEURL}/api/CompanyBranch/Get`,
  UPDATE_BRANCH: `${BASEURL}/api/CompanyBranch/Update`,
  DELETE_BRANCH: `${BASEURL}/api/CompanyBranch/Delete`,
  // File Upload 
  UPLOAD_FILE: `${BASEURL}/api/FileUpload/UploadFile`,
  // UPLOAD_FILE: `${PosApi}/api/FileUpload/UploadFile`,
  //Bank Master
  CREATE_BANK: `${BASEURL}/api/BankMaster/Create`,
  GET_BANK: `${BASEURL}/api/BankMaster/Get`,
  UPDATE_BANK: `${BASEURL}/api/BankMaster/Update`,
  DELETE_BANK: `${BASEURL}/api/BankMaster/Delete`,
  // User Role
  GET_USERS_LIST: `${BASEURL}/api/UserMaster/Get`,
  CREATE_USER_ROLE: `${BASEURL}/api/UserDetail/Create`,
  GET_USER_ROLE: `${BASEURL}/api/UserDetail/Get`,
  UPDATE_USER_ROLE: `${BASEURL}/api/UserDetail/Update`,
  //Category Master
  GET_CATEGORY: `${BASEURL}/api/ItemCategoryMaster/Get`,
  DELETE_CATEGORY: `${BASEURL}/api/ItemCategoryMaster/Delete`,
  CREATE_UPDATE_CATEGORY: `${BASEURL}/api/ItemCategoryMaster/CreateOrUpdate`,
  UPDATE_CATEGORY: `${BASEURL}/api/ItemCategoryMaster/Update`,
  GET_CATEGORY_CODE: `${BASEURL}/api/ItemCategoryMaster/GenerateNewCategoryCode`,
  //Sub Category Master
  CREATE_UPDATE_SUB_CATEGORY: `${BASEURL}/api/ItemSubCategoryMaster/CreateOrUpdate`,
  GET_SUB_CATEGORY: `${BASEURL}/api/ItemSubCategoryMaster/Get`,
  DELETE_SUB_CATEGORY: `${BASEURL}/api/ItemSubCategoryMaster/Delete`,
  GET_SUB_CATEGORY_CODE: `${BASEURL}/api/ItemSubCategoryMaster/GenerateNewSubCategoryCode`,
  UPDATE_SUB_CATEGORY: `${BASEURL}/api/ItemSubCategoryMaster/Update`,
  //EUNIT MASTER
  CREATE_EUNIT_MEASUREMENT: `${BASEURL}/api/EUnitMeasurement/Create`,
  GET_EUNIT_MEASUREMENT: `${BASEURL}/api/EUnitMeasurement/Get`,
  UPDATE_EUNIT_MEASUREMENT: `${BASEURL}/api/EUnitMeasurement/Update`,
  DELETE_EUNIT_MEASUREMENT: `${BASEURL}/api/EUnitMeasurement/Delete`,
  //EPackingType MASTER
  CREATE_EPACKING: `${BASEURL}/api/EPackingType/Create`,
  GET_EPACKING: `${BASEURL}/api/EPackingType/Get`,
  UPDATE_EPACKING: `${BASEURL}/api/EPackingType/Update`,
  DELETE_EPACKING: `${BASEURL}/api/EPackingType/Delete`,
  //EPayment MASTER
  CREATE_EPAYMENT: `${BASEURL}/api/PaymentTerm/Create`,
  GET_EPAYMENT: `${BASEURL}/api/PaymentTerm/Get`,
  UPDATE_EPAYMENT: `${BASEURL}/api/PaymentTerm/Update`,
  DELETE_EPAYMENT: `${BASEURL}/api/PaymentTerm/Delete`,
  //Stock Type MASTER
  CREATE_STOCK_TYPE: `${BASEURL}/api/StockType/Create`,
  GET_STOCK_TYPE: `${BASEURL}/api/StockType/Get`,
  UPDATE_STOCK_TYPE: `${BASEURL}/api/StockType/Update`,
  DELETE_STOCK_TYPE: `${BASEURL}/api/StockType/Delete`,
  //Supplier MASTER
  CREATE_SUPPLIER: `${BASEURL}/api/SupplierMaster/Create`,
  GET_SUPPLIER: `${BASEURL}/api/SupplierMaster/Get`,
  UPDATE_SUPPLIER: `${BASEURL}/api/SupplierMaster/Update`,
  DELETE_SUPPLIER: `${BASEURL}/api/SupplierMaster/Delete`,
  //Supplier Type MASTER
  CREATE_SUPPLIER_TYPE: `${BASEURL}/api/SupplierType/Create`,
  GET_SUPPLIER_TYPE: `${BASEURL}/api/SupplierType/Get`,
  UPDATE_SUPPLIER_TYPE: `${BASEURL}/api/SupplierType/Update`,
  DELETE_SUPPLIER_TYPE: `${BASEURL}/api/SupplierType/Delete`,
  //Item MASTER
  CREATE_ITEM: `${BASEURL}/api/ItemMaster/Create`,
  GET_ITEM: `${BASEURL}/api/ItemMaster/Get`,
  UPDATE_ITEM: `${BASEURL}/api/ItemMaster/Update`,
  DELETE_ITEM: `${BASEURL}/api/ItemMaster/Delete`,
  GET_ITEM_CODE: `${BASEURL}/api/ItemMaster/GenerateItemCode`,
  GET_ITEM_PACK: `${BASEURL}/api/ItemMaster/GetItemMasterForPackingUOM`,
  // Item Gl accounts
  CREATE_ITEM_GL: `${BASEURL}/api/ItemMasterAccount/CreateItemMasterAccount`,
  GET_ITEM_GL: `${BASEURL}/api/ItemMasterAccount/GetItemMasterAccount`,
  UPDATE_ITEM_GL: `${BASEURL}/api/ItemMasterAccount/UpdateItemMasterAccount`,
  //PR MASTER
  CREATE_PR: `${BASEURL}/api/PRMaster/Create`,
  GET_PR: `${BASEURL}/api/PRMaster/Get`,
  UPDATE_PR: `${BASEURL}/api/PRMaster/Update`,
  DELETE_PR: `${BASEURL}/api/PRMaster/Delete`,
  UPDATE_PR_STATUS: `${BASEURL}/api/PRMaster/UpdateStatus`,
  //PR MASTER
  CREATE_PR_DETAIL: `${BASEURL}/api/PRDetails/Create`,
  GET_PR_DETAIL: `${BASEURL}/api/PRDetails/Get`,
  UPDATE_PR_DETAIL: `${BASEURL}/api/PRDetails/Update`,
  DELETE_PR_DETAIL: `${BASEURL}/api/PRDetails/Delete`,
  // price Comparision
  CREATE_PC: `${BASEURL}/api/NewPriceComparison/Create`,
  GET_PC: `${BASEURL}/api/NewPriceComparison/Get`,
  UPDATE_PC: `${BASEURL}/api/NewPriceComparison/Update`,
  DELETE_PC: `${BASEURL}/api/NewPriceComparison/Delete`,

  // price Comparision Quotation
  CREATE_QUOTATION: `${BASEURL}/api/NewQuotation/Create`,
  GET_QUOTATION: `${BASEURL}/api/NewQuotation/Get`,
  GET_QUOTATION_GROUP: `${BASEURL}/api/RFQuotation/GetInGroup`,
  UPDATE_QUOTATION: `${BASEURL}/api/NewQuotation/Update`,
  DELETE_QUOTATION: `${BASEURL}/api/RFQuotation/Delete`,
  // Purchase Order
  CREATE_PO: `${BASEURL}/api/PurchaseOrder/Create`,
  GET_PO: `${BASEURL}/api/PurchaseOrder/Get`,
  UPDATE_PO: `${BASEURL}/api/PurchaseOrder/Update`,
  DELETE_PO: `${BASEURL}/api/PurchaseOrder/Delete`,
  // Purchase Order
  CREATE_GRN: `${BASEURL}/api/GRNMaster/Create`,
  GET_GRN: `${BASEURL}/api/GRNMaster/Get`,
  UPDATE_GRN: `${BASEURL}/api/GRNMaster/Update`,
  GET_PO_LIST: `${BASEURL}/api/GRNMaster/GetPOForGRN`,
  DELETE_GRN: `${BASEURL}/api/GRNMaster/Delete`,
  GET_GRN_PO: `${BASEURL}/api/GRNMaster/GetPODetailsForGRN`,
  GET_GRN_VIEW: `${BASEURL}/api/GRNMaster/GetGRNForView`,
  // PurchaseReturn 
  CREATE_P_RETURN: `${BASEURL}/api/PurchaseReturn/Create`,
  GET_P_RETURN: `${BASEURL}/api/PurchaseReturn/Get`,
  GET_GRN_P_RETURN: `${BASEURL}/api/PurchaseReturn/GetGRNForPR`,
  UPDATE_P_RETURN: `${BASEURL}/api/PurchaseReturn/Update`,
  DELETE_P_RETURN: `${BASEURL}/api/PurchaseReturn/Delete`,
  //Currency MASTER
  CREATE_CURRENCY_MASTER: `${BASEURL}/api/CurrencyMaster/Create`,
  GET_CURRENCY_MASTER: `${BASEURL}/api/CurrencyMaster/Get`,
  UPDATE_CURRENCY_MASTER: `${BASEURL}/api/CurrencyMaster/Update`,
  DELETE_CURRENCY_MASTERL: `${BASEURL}/api/CurrencyMaster/Delete`,
  //Department MASTER
  CREATE_DEPARTMENT_MASTER: `${BASEURL}/api/DepartmentMaster/Create`,
  GET_DEPARTMENT_MASTER: `${BASEURL}/api/DepartmentMaster/Get`,
  UPDATE_DEPARTMENT_MASTER: `${BASEURL}/api/DepartmentMaster/Update`,
  DELETE_DEPARTMENT_MASTER: `${BASEURL}/api/DepartmentMaster/Delete`,
  //WareHouse MASTER
  CREATE_WAREHOUSE_MASTER: `${BASEURL}/api/WarehouseMaster/Create`,
  GET_WAREHOUSE_MASTER: `${BASEURL}/api/WarehouseMaster/Get`,
  UPDATE_WAREHOUSE_MASTER: `${BASEURL}/api/WarehouseMaster/Update`,
  DELETE_WAREHOUSE_MASTER: `${BASEURL}/api/WarehouseMaster/Delete`,
  //GRN MASTER
  CREATE_GRN_MASTER: `${BASEURL}/api/GRNMaster/Create`,
  GET_GRN_MASTER: `${BASEURL}/api/GRNMaster/Get`,
  UPDATE_GRN_MASTER: `${BASEURL}/api/GRNMaster/Update`,
  DELETE_GRN_MASTER: `${BASEURL}/api/GRNMaster/Delete`,
  //WareHouse Category MASTER
  CREATE_WAREHOUSE_CAT_MASTER: `${BASEURL}/api/WarehouseCategory/Create`,
  GET_WAREHOUSE_CAT_MASTER: `${BASEURL}/api/WarehouseCategory/Get`,
  UPDATE_WAREHOUSE_CAT_MASTER: `${BASEURL}/api/WarehouseCategory/Update`,
  DELETE_WAREHOUSE_CAT_MASTER: `${BASEURL}/api/WarehouseCategory/Delete`,
  //Approval Users Category MASTER
  CREATE_APPROVAL_USER: `${BASEURL}/api/ApprovalUser/Create`,
  GET_APPROVAL_USER: `${BASEURL}/api/ApprovalUser/GetApprovalUserList`,
  GET_APPROVAL_USER_LIST: `${BASEURL}/api/ApprovalUser/GetApprovalUserForDoc`,
  UPDATE_APPROVAL_USER: `${BASEURL}/api/ApprovalUser/Update`,
  DELETE_APPROVAL_USER: `${BASEURL}/api/ApprovalUser/Delete`,
  //Approve Doc Req
  CREATE_DOC_AUTHORIZATION: `${BASEURL}/api/DocumentAuthorization/Create`,
  GET_DOC_AUTHORIZATION: `${BASEURL}/api/DocumentAuthorization/Get`,
  UPDATE_DOC_AUTHORIZATION: `${BASEURL}/api/DocumentAuthorization/Update`,
  UPDATE_DOC_AUTHORIZATION_STATUS: `${BASEURL}/api/DocumentAuthorization/UpdateDocumentStatus`,
  GET_DOC_AUTHORIZATION_STATUS: `${BASEURL}/api/DocumentAuthorization/Get`,
  DELETE_DOC_AUTHORIZATION: `${BASEURL}/api/DocumentAuthorization/Delete`,
  // Reports
  GET_ITEMS_REPORT: `${BASEURL}/api/ItemMasterReports/Get`,
  GET_PO_REPORT: `${BASEURL}/api/PurchaseOrderReport/Get`,
  GET_PR_REPORT: `${BASEURL}/api/PurchaseRequisitionReport/Get`,
  GET_GRN_REPORT: `${BASEURL}/api/GRNReports/Get`,
  GET_INVOICE_REPORT: `${BASEURL}/api/InvoiceReports/Get`,
  GET_PURCHASE_RETURN: `${BASEURL}/api/PurchaseReturnReport/Get`,
  GET_STOCK_TRANS_REPORT: `${BASEURL}/api/StockTransactionReport/Get`,
  GET_STOCK_VARIENCE_REPORT: `${BASEURL}/api/StockTakeVariance/StockTakeVarianceReport`,

  //Invoice 
  CREATE_INVOICE: `${BASEURL}/api/InvoiceRegister/Create`,
  GET_INVOICE: `${BASEURL}/api/InvoiceRegister/GetInvoiceWithGrn`,
  UPDATE_INVOICE: `${BASEURL}/api/InvoiceRegister/Update`,
  DELETE_INVOICE: `${BASEURL}/api/InvoiceRegister/Delete`,
  GET_PO_INVOICE: `${BASEURL}/api/PurchaseOrder/GetPOForInv`,
  GET_GRN_LIST_INVOICE: `${BASEURL}/api/InvoiceRegister/GRNInfoOFPOrderForInvoice`,
  GET_INVOICE_VIEW: `${BASEURL}/api/InvoiceRegister/InvoiceRegisterView`,

  // DocId
  GET_DOC_ID: `${BASEURL}/api/DocIdMaster/Get`,
  UPDATE_DOC_ID: `${BASEURL}/api/DocIdMaster/Update`,
  SEND_EMAIL: `${BASEURL}/api/Email/Create`,

  // IssueItem
  CREATE_ITEM_ISSUE: `${BASEURL}/api/IssueMaster/Create`,
  GET_ITEM_ISSUE: `${BASEURL}/api/IssueMaster/Get`,
  UPDATE_ITEM_ISSUE: `${BASEURL}/api/IssueMaster/Update`,
  DELETE_ITEM_ISSUE: `${BASEURL}/api/IssueMaster/Delete`,
  GET_REQ_ITEM_ISSUE: `${BASEURL}/api/IssueMaster/GetReqDetailsForIssue`,
  // Stock Take
  GET_STOCK_TAKE: `${BASEURL}/api/StockTake/GetItemsStockTake`,
  UPDATE_STOCK_TAKE: `${BASEURL}/api/StockTake/SetItemStock`,
  //  Issue Return

  CREATE_ISSUE_RETURN: `${BASEURL}/api/IssueReturn/Create`,
  GET_ISSUE_RETURN: `${BASEURL}/api/IssueReturn/Get`,
  GET_ISSUE_RETURN_ITEM: `${BASEURL}/api/IssueReturn/GetIssueDetailForIssueReturn`,
  UPDATE_ISSUE_RETURN: `${BASEURL}/api/IssueReturn/Update`,
  DELETE_ISSUE_RETURN: `${BASEURL}/api/IssueReturn/Delete`,
  GET_REQ_ISSUE_RETURN: `${BASEURL}/api/IssueReturn/GetReqDetailsForIssue`,

  // Issue Received
  CREATE_ISSUE_RECEIVED: `${BASEURL}/api/IssueReceived/Create`,
  GET_ISSUE_RECEIVED: `${BASEURL}/api/IssueReceived/GetIssueNoForReceived`,
  UPDATE_ISSUE_RECEIVED: `${BASEURL}/api/IssueReceived/Update`,
  DELETE_ISSUE_RECEIVED: `${BASEURL}/api/IssueReceived/Delete`,
  GET_ALL_ISSUE_RECEIVED: `${BASEURL}/api/IssueReceived/GetAllReceivedIssues`,
  GET_REQ_ISSUE_RECEIVED: `${BASEURL}/api/IssueReceived/GetIssueNoForReceived`,
  // Adjustment Create
  CREATE_ADJUSTMENT: `${BASEURL}/api/AdjMaster/Create`,
  GET_ADJUSTMENT: `${BASEURL}/api/AdjMaster/Get`,
  UPDATE_ADJUSTMENT: `${BASEURL}/api/AdjMasterUpdate`,
  DELETE_ADJUSTMENT: `${BASEURL}/api/AdjMaster/Delete`,
  GET_INFO_ADJUSTMENT: `${BASEURL}/api/AdjMaster/GetItemInfoForAdj`,
  // DOC FILE UPLOAD
  CREATE_ATTACHMENT: `${BASEURL}/api/DocFiles/CreateFile`,
  GET_ATTACHMENT: `${BASEURL}/api/DocFiles/GetFiles`,
  UPDATE_ATTACHMENT: `${BASEURL}/api/DocFiles/UpdateFile`,

  // Cost Centers
  GET_ACCOUNTS: `${BASEURL}/api/Account/Get`,
  GET_COST_ONE: `${BASEURL}/api/CostCenter/Get`,
  GET_COST_TWO: `${BASEURL}/api/CostCenter/GetCostCenterTwo`,
  GET_COST_THREE: `${BASEURL}/api/CostCenter/GetCostCCenterThree`,
  GET_COST_FOUR: `${BASEURL}/api/CostCenter/GetCostCenterFour`,
  // Posting
  CREATE_POSTING: `${BASEURL}/api/Posting/Posting`,
  GET_POSTING: `${BASEURL}/api/Posting/GetBatchItems`,
  GET_GL_POSTING: `${BASEURL}/api/Posting/GetGLPosting`,
  GET_GL_ACC_POST: `${BASEURL}/api/Posting/GetGLPostingEdit`,
  UPDATE_GL_ACC_POST: `${BASEURL}/api/Posting/UpdateGLPosting`,

}