import moment from 'moment';
import React, { useState } from 'react';
import Select from 'react-select';
import "../Styles/recipeMaster.css"
const RecipeDetails = ({ initialData = {}, onChange }) => {
    const [costTab, setCostTab] = useState(false);
    const [NotesTab, setNotesTab] = useState(false);

    const [detailTab, setDetailsTab] = useState(false);


    // States for the fields
    const [costCenter1, setCostCenter1] = useState(initialData.costCenter1 || null);
    const [costCenter2, setCostCenter2] = useState(initialData.costCenter2 || null);
    const [costCenter3, setCostCenter3] = useState(initialData.costCenter3 || null);
    const [costCenter4, setCostCenter4] = useState(initialData.costCenter4 || null);
    const [formData, setFormData] = useState({
        recordId: "",
        catcod: "",
        Category: "",
        InventoryAccounts: [],
        grnAccount: [],
        wipAccount: [],
        invoiceAccount: [],
        adjustmentAccount: [],
        cgsAccount: [],
        issueAccount: [],
        wastageAccount: [],
    });
    // Generic handler to update fields and notify parent
    const handleChange = (setter, key) => (selectedOption) => {
        setter(selectedOption);
        if (onChange) {
            onChange({ ...initialData, [key]: selectedOption });
        }
    };
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    return (
        <>
            <div className="cost-center-section mb-2">
                <div
                    className="d-flex mt-3 cursor-pointer slide-header gap-4"
                    onClick={() => setDetailsTab(!detailTab)}
                >
                    <div className="h6 pt-1">Recipe Details</div>
                </div>
                <div className={`slide-content ${detailTab ? 'open' : 'closed'}`}>
                    <div className="border-box2 ">
                        <div className="form-group row justify-content-between p-3">
                            <div className="form-group row  pb-3">
                                <div className="col-lg-4 d-flex justify-content-between">
                                    <label htmlFor="validationCustom01" className="form-label  pt-2 start">Recipe Name *</label>
                                    <input
                                        type="text"
                                        className="form-Input w-75"
                                        id="validationCustom01"
                                        name="catCode"
                                        required
                                    />
                                </div>
                                <div className="col-lg-4 d-flex justify-content-around">
                                    <label htmlFor="validationCustom01" className="form-label  pt-2 start">Description</label>
                                    <input
                                        type="text"
                                        className="form-Input w-100"
                                        id="validationCustom01"
                                        name="Description"
                                        value={formData.Category}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>
                                <div className='col-lg-4 d-flex justify-content-around'>

                                    <label htmlFor="propertyGroup" className="form-label pt-2 start">
                                        Source Recipe
                                    </label>
                                    <Select
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9,
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5' : null,
                                                color: state.isFocused ? '#000' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000',
                                                },
                                            }),
                                        }}
                                        className="w-70 p-0"
                                        value={costCenter1}
                                        onChange={handleChange(setCostCenter1, 'costCenter1')}
                                        options={[
                                            { value: 'Group1', label: 'Group 1' },
                                            { value: 'Group2', label: 'Group 2' },
                                        ]}
                                    />

                                </div>

                            </div>
                            <div className="form-group row d-flex  pb-3">
                                <div className="form-group row d-flex ">
                                    <div className='col-lg-4 d-flex align-center'>
                                        <label htmlFor="validationCustom01" className="form-label">Sub Recipe</label>
                                        <input
                                            type="checkbox"
                                            style={{ width: "20.81px", height: "20.81px", border: "0.5px solid #5B93FF" }}
                                        />
                                    </div>


                                </div>
                            </div>

                        </div>
                        <div className="bg-blue p-3">
                            <div className="form-group row d-flex justify-content-between pb-3 m-0">
                                {/* Portion / Yield */}
                                <div className="col-lg-2 d-flex justify-content-between">
                                    <label htmlFor="portionYield" className="form-label pt-2 start">Portion / Yield</label>
                                    <input
                                        type="text"
                                        className="form-Input w-75"
                                        id="portionYield"
                                        name="portionYield"
                                        required
                                    />
                                </div>
                                {/* Serving Weight */}
                                <div className="col-lg-2 d-flex justify-content-between">
                                    <label htmlFor="servingWeight" className="form-label pt-2 start">Serving Weight</label>
                                    <input
                                        type="text"
                                        className="form-Input w-75"
                                        id="servingWeight"
                                        name="servingWeight"
                                        required
                                    />
                                </div>
                                {/* Scaling Weight */}
                                <div className="col-lg-2 d-flex justify-content-between">
                                    <label htmlFor="scalingWeight" className="form-label pt-2 start">Scaling Weight</label>
                                    <input
                                        type="text"
                                        className="form-Input w-75"
                                        id="scalingWeight"
                                        name="scalingWeight"
                                        required
                                    />
                                </div>
                                {/* Waste % */}
                                <div className="col-lg-2 d-flex justify-content-between">
                                    <label htmlFor="wastePercentage" className="form-label pt-2 start">Waste %</label>
                                    <input
                                        type="text"
                                        className="form-Input w-75"
                                        id="wastePercentage"
                                        name="wastePercentage"
                                        required
                                    />
                                </div>
                                {/* Recipe Cost */}
                                <div className="col-lg-2 d-flex justify-content-between">
                                    <label htmlFor="recipeCost" className="form-label pt-2 start">Recipe Cost</label>
                                    <input
                                        type="text"
                                        className="form-Input w-75"
                                        id="recipeCost"
                                        name="recipeCost"
                                        required
                                    />
                                </div>
                                {/* Margin % */}
                                <div className="col-lg-2 d-flex justify-content-between">
                                    <label htmlFor="marginPercentage" className="form-label pt-2 start">Margin %</label>
                                    <input
                                        type="text"
                                        className="form-Input w-75"
                                        id="marginPercentage"
                                        name="marginPercentage"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="form-group row d-flex justify-content-between pb-3 m-0">
                                {/* Preparation Time */}
                                <div className="col-lg-2 d-flex justify-content-between">
                                    <label htmlFor="preparationTime" className="form-label pt-2 start">Preparation Time</label>
                                    <input
                                        type="text"
                                        className="form-Input w-75"
                                        id="preparationTime"
                                        name="preparationTime"
                                        required
                                    />
                                </div>
                                {/* Cooking Time */}
                                <div className="col-lg-2 d-flex justify-content-between">
                                    <label htmlFor="cookingTime" className="form-label pt-2 start">Cooking Time</label>
                                    <input
                                        type="text"
                                        className="form-Input w-75"
                                        id="cookingTime"
                                        name="cookingTime"
                                        required
                                    />
                                </div>
                                {/* Ready In */}
                                <div className="col-lg-2 d-flex justify-content-between">
                                    <label htmlFor="readyIn" className="form-label pt-2 start">Ready In</label>
                                    <input
                                        type="text"
                                        className="form-Input w-75"
                                        id="readyIn"
                                        name="readyIn"
                                        required
                                    />
                                </div>
                                {/* Shelf Life */}
                                <div className="col-lg-2 d-flex justify-content-between">
                                    <label htmlFor="shelfLife" className="form-label pt-2 start">Shelf Life</label>
                                    <input
                                        type="text"
                                        className="form-Input w-75"
                                        id="shelfLife"
                                        name="shelfLife"
                                        required
                                    />
                                </div>
                                {/* PLU No */}
                                <div className="col-lg-2 d-flex justify-content-between">
                                    <label htmlFor="pluNo" className="form-label pt-2 start">PLU No</label>
                                    <input
                                        type="text"
                                        className="form-Input w-75"
                                        id="pluNo"
                                        name="pluNo"
                                        required
                                    />
                                </div>
                                {/* Selling Cost */}
                                <div className="col-lg-2 d-flex justify-content-between">
                                    <label htmlFor="sellingCost" className="form-label pt-2 start">Selling Cost</label>
                                    <input
                                        type="text"
                                        className="form-Input w-75"
                                        id="sellingCost"
                                        name="sellingCost"
                                        required
                                    />
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </div>
            <div className="cost-center-section mb-2">
                <div
                    className="d-flex mt-3 cursor-pointer slide-header gap-4"
                    onClick={() => setCostTab(!costTab)}
                >
                    <div className="h6 pt-1">Cost Center</div>
                </div>
                <div className={`slide-content ${costTab ? 'open' : 'closed'}`}>
                    <div className="border-box2 p-3">
                        <div className="mb-3 form-group row justify-content-between">
                            {/* Property Group */}
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="propertyGroup" className="form-label pt-2 start">
                                    Property Group
                                </label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9,
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5' : null,
                                            color: state.isFocused ? '#000' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    value={costCenter1}
                                    onChange={handleChange(setCostCenter1, 'costCenter1')}
                                    options={[
                                        { value: 'Group1', label: 'Group 1' },
                                        { value: 'Group2', label: 'Group 2' },
                                    ]}
                                />
                            </div>
                            {/* Property */}
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="property" className="form-label pt-2 start">
                                    Property
                                </label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9,
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5' : null,
                                            color: state.isFocused ? '#000' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    value={costCenter2}
                                    onChange={handleChange(setCostCenter2, 'costCenter2')}
                                    options={[
                                        { value: 'Property1', label: 'Property 1' },
                                        { value: 'Property2', label: 'Property 2' },
                                    ]}
                                />
                            </div>

                            {/* Outlet */}
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="outlet" className="form-label pt-2 start">
                                    Outlet
                                </label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9,
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5' : null,
                                            color: state.isFocused ? '#000' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    value={costCenter4}
                                    onChange={handleChange(setCostCenter4, 'costCenter4')}
                                    options={[
                                        { value: 'Outlet1', label: 'Outlet 1' },
                                        { value: 'Outlet2', label: 'Outlet 2' },
                                    ]}
                                />
                            </div>
                        </div>
                        <div className="form-group row justify-content-between">
                            {/* Property Group */}
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="propertyGroup" className="form-label pt-2 start">
                                    Property Group
                                </label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9,
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5' : null,
                                            color: state.isFocused ? '#000' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    value={costCenter1}
                                    onChange={handleChange(setCostCenter1, 'costCenter1')}
                                    options={[
                                        { value: 'Group1', label: 'Group 1' },
                                        { value: 'Group2', label: 'Group 2' },
                                    ]}
                                />
                            </div>
                            {/* Property */}
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="property" className="form-label pt-2 start">
                                    Property
                                </label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9,
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5' : null,
                                            color: state.isFocused ? '#000' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    value={costCenter2}
                                    onChange={handleChange(setCostCenter2, 'costCenter2')}
                                    options={[
                                        { value: 'Property1', label: 'Property 1' },
                                        { value: 'Property2', label: 'Property 2' },
                                    ]}
                                />
                            </div>

                            {/* Outlet */}
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="outlet" className="form-label pt-2 start">
                                    Outlet
                                </label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9,
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5' : null,
                                            color: state.isFocused ? '#000' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    value={costCenter4}
                                    onChange={handleChange(setCostCenter4, 'costCenter4')}
                                    options={[
                                        { value: 'Outlet1', label: 'Outlet 1' },
                                        { value: 'Outlet2', label: 'Outlet 2' },
                                    ]}
                                />
                            </div>
                            <div className="form-group row justify-content-between mt-4">
                                {/* Property Group */}
                                <div className="col-lg-4 d-flex justify-content-between">
                                    <label htmlFor="propertyGroup" className="form-label pt-2 start">
                                        Sub Category
                                    </label>
                                    <Select
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9,
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5' : null,
                                                color: state.isFocused ? '#000' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000',
                                                },
                                            }),
                                        }}
                                        className="w-70 p-0"
                                        value={costCenter1}
                                        onChange={handleChange(setCostCenter1, 'costCenter1')}
                                        options={[
                                            { value: 'Group1', label: 'Group 1' },
                                            { value: 'Group2', label: 'Group 2' },
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cost-center-section mb-2">
                <div
                    className="d-flex mt-3 cursor-pointer slide-header gap-4"
                    onClick={() => setNotesTab(!NotesTab)}
                >
                    <div className="h6 pt-1">Notes</div>
                </div>
                <div className={`slide-content ${NotesTab ? 'open' : 'closed'}`}>
                    <div className="border-box2 p-3">
                        <div className="mb-3 form-group row justify-content-between">

                          
                            <div className="mb-3 form-group d-flex  justify-content-center center">
                            <label htmlFor="propertyGroup" className="form-label pt-2 start">
                                Notes
                            </label>
                                <textarea className='textArea' id="w3review" name="w3review" rows="6" cols="158">
                                </textarea>
                            </div>
                        </div>

                        <div className="mb-3 form-group row justify-content-between">

                            <div className="mb-3 form-group d-flex  justify-content-center center">
                            <label htmlFor="propertyGroup" className="form-label pt-2 start">
                                HACCP Notes
                            </label>
                                <textarea className='textArea' id="w3review" name="w3review" rows="6" cols="158">
                                </textarea>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>

    );
};

export default RecipeDetails;
