import React, { useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { RiRefreshLine } from "react-icons/ri";
import { GrEdit } from "react-icons/gr";
import { FaSave } from "react-icons/fa";
import "../Styles/recipeMaster.css"
import RecipeDetails from './recipeDetails';
import RecipePreparations from './recipePreparation';
import RecipeIngredients from './recipeIngredients';
import RecipeNutritions from './recipeNutritions';
import RecipeImages from './recipeImage';
import RecipeAllergens from './recipeAllergians';

const RecipeMaster = () => {
    const [catCode, setCatCode] = useState();
    const [pagination, setPagination] = useState();
    const [rowData, setRowData] = useState([]);
    const [pageSize, setPageSize] = useState(20);
    const [selectedRow, setSelectedRow] = useState(null);
    const [activeTab, setActiveTab] = useState('Recipe Details');

    const [formData, setFormData] = useState({
        recordId: "",
        catcod: "",
        Category: "",
        recipeDetails: {
            propertyGroup: null,
            property: null,
            department: null,
            outlet: null,
        },
        // Other fields
    });

    const handleInputChange = (key, field, value, index = null) => {
        setFormData((prev) => {
            if (index !== null) {
                // For array fields like allergens
                const updatedArray = [...prev[key]];
                updatedArray[index][field] = value;
                return { ...prev, [key]: updatedArray };
            } else {
                // For non-array fields
                return {
                    ...prev,
                    [key]: {
                        ...prev[key],
                        [field]: value,
                    },
                };
            }
        });
    };
    

    const handleClearFilters = () => {
        setFormData({
            recordId: "",
            catcod: "",
            Category: "",
            recipeDetails: {
                propertyGroup: null,
                property: null,
                department: null,
                outlet: null,
            },
            // Reset other fields
        });
    };

    // Tab contents
    const renderTabContent = () => {
        switch (activeTab) {
            case 'Recipe Details':
                return (
                    <RecipeDetails
                        data={formData.recipeDetails}
                        onChange={(field, value) =>
                            handleInputChange('recipeDetails', field, value)
                        }
                    />
                );
            case 'Nutritions':<RecipeNutritions
            data={formData.recipeDetails}
            onChange={(field, value) =>
                handleInputChange('recipeDetails', field, value)
            }
        />
                return 
            case 'Preparation':
                return <RecipePreparations
                data={formData.recipeDetails}
                onChange={(field, value) =>
                    handleInputChange('recipeDetails', field, value)
                }
            />
            case 'Ingredients':
                return <RecipeIngredients
                data={formData.recipeDetails}
                onChange={(field, value) =>
                    handleInputChange('recipeDetails', field, value)
                }
            />
            case "Images":
                return (
                    <RecipeImages
                    data={formData.images || [{ remarks: '', file: null }]} // Initialize images
                    onChange={(updatedImages) =>
                        handleInputChange("images", null, updatedImages)
                    }
                />
                
                
                );
            case "Allergens":
                return (
                    <RecipeAllergens
                    data={formData.allergens || []} // Pass allergens data or initialize with an empty array
                    onInputChange={handleInputChange} // Pass the parent's handleInputChange
                />
                
                );
            default:
                return null;
        }
    };

    return (
        <div className='px-4 pt-3'>
            <div className='py-lg-2'>
                <div className='gridBg p-3' style={{ borderRadius: "16px 16px 16px 16px" }}>
                    <div className='d-flex justify-contant-between mb-3'>
                        <div className='col d-flex gap-3'>
                            {['Recipe Details', 'Ingredients', 'Nutritions', 'Preparation', 'Allergens', 'Images'].map(tab => (
                                <div

                                    key={tab}
                                    className={`InActiveTabs ${activeTab === tab ? 'subactive' : ''}`}
                                    onClick={() => setActiveTab(tab)}
                                >
                                    {tab}
                                </div>
                            ))}
                        </div>             
                                   <div className='d-flex justify-content-end gap-3'>
                            <div
                                className={`mainbtnSecondary flex-end gap-2 ${!selectedRow ? 'disabled' : ''}`}
                                style={{ cursor: selectedRow ? 'pointer' : 'not-allowed' }}
                            >
                                <GrEdit color='#fff' className='center mt-1 mx-2' />
                                <div className='center'>Edit</div>
                            </div>
                            <div className=''>
                                <button className='mainbtn '>
                                    <FaSave className='center mt-1 mx-2' /> <div>Save</div>
                                </button>
                            </div>
                            <button className='mainbtnRed' onClick={handleClearFilters}><RiRefreshLine className='center mt-1 mx-2' /><div>Reset</div></button>
                        </div>
                    </div>
                    {/* Tab Navigation */}

                    {/* Tab Content */}
                </div>
            </div>
            {/* <div className='gridBg mt-3'> */}
            {renderTabContent()}

            {/* </div> */}
        </div>
    );
};

export default RecipeMaster;
