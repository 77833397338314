import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import MessageGrid from '../../purchaseRequisition/messageGrid';
import ItemGridGRN from './itemGrid';
import AuthorizationGrn from '../authorization';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaAngleLeft } from "react-icons/fa6";
import moment from 'moment';
import ApiService from '../../../services/apiService';

const GrnView = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [gridVisibility, setGridVisibility] = useState([true, false, false]);
    const [messageData, setMessageData] = useState([]);
    const [authorizationData, setAuthorizationData] = useState([]);
    const [ItemData, setItemData] = useState([]);
    const [PurchaseOrderDate, setPurchaseOrderDate] = useState();
    const [POData, setPOData] = useState([]);
    const [selectedPOData, setSelectedPOData] = useState([]);
    const [usersDetails, setUser] = useState([]);
    const [SelectedSupplier, setSelectedSupplier] = useState([]);
    const [SelectedCurrency, setSelectedCurency] = useState([]);
    const location = useLocation();
    const itemData = location.state;
    const [PurchaseTab, setPurchaseTab] = useState(true);
    const [CostTab, setCostTab] = useState(true);
    const apiService = new ApiService();
    debugger
    useEffect(() => {
        fetchPOData()
    }, [])
    const fetchPOData = async () => {
        let param = `?POrderNo=${itemData?.pOrderNo}`
        const PO = await apiService.getApiParamater("GET_GRN_PO", param);
        setPOData(PO.data.result[0])
        const params = new URLSearchParams();
        params.append('DocNo', itemData?.grnNo);
        params.append('DocumentType', "GRN");
        const response = await apiService.getApiParamater('GET_DOC_STATUS_EDIT', `?${params.toString()}`);
        setUser(response.data.result);
    }
    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
        const newGridVisibility = gridVisibility.map((_, index) => index === tabIndex);
        setGridVisibility(newGridVisibility);
    };
    const handleMessage = (data) => {
        setMessageData(data);
        console.log(data, 'reqData')
    };
    const handleRequisitionData = (data) => {
        setItemData(data);
        console.log(data, 'reqData')
    };
    const handleAuthorization = (data) => {
        setAuthorizationData(data);
        console.log(data, 'reqData')
    };
    const handleBackClick = () => {
        window.history.back();
    }
    return (
        <div className='p-4'>
            <div className='d-flex justify-contant-between '>
                <h5 className='col mainInvHead'>Goods Receipt Note</h5>
                <div className='d-flex justify-content-end gap-1'>
                    <button onClick={handleBackClick} className='mainbtnSecondary'><FaAngleLeft className='center mt-1 mx-2' /><div>Back</div></button>
                </div>
            </div>
            <div className="cost-center-section mb-2">
                <div className="d-flex mt-3 cursor-pointer slide-header gap-4"
                    onClick={() => setPurchaseTab(!PurchaseTab)}>
                    <div className='h6 pt-1'>GRN Details</div>
                </div>
                <div className={`slide-content ${PurchaseTab ? 'open' : 'closed'}`}>
                    <div className="border-box2 p-3">
                        <div className="form-group row pb-3">
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="grnNo" className="form-label  pt-1 start">GRN No</label>
                                <input
                                    className="form-Input w-70"
                                    value={itemData?.grnNo}
                                    disabled
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="catcode" className="form-label  pt-1 start">Delivery Date </label>
                                <input className='form-Input w-70' type='datetime'
                                    disabled
                                    value={moment(itemData.delivery_Date).format('DD-MMM-YYYY')}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="warehouse" className="form-label pt-1 start">Location</label>
                                <input className='form-Input w-70'
                                    disabled
                                    value={itemData.warehouse}
                                />
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-1 start">Purchase Order No *</label>
                                <input
                                    className="form-Input w-70"
                                    disabled
                                    value={itemData.pOrderNo}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="deliveryNote" className="form-label  pt-1 start">Delivery Note No</label>
                                <input
                                    className="form-Input w-70"
                                    disabled
                                    value={itemData.invoiceRef}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="TaxInvoice" className="form-label  pt-1 start">Tax Invoice No ( KRA )</label>
                                <input
                                    className="form-Input w-70"
                                    disabled
                                    value={itemData.transporter}
                                />
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="PurchaseOrderDate" className="form-label  pt-1 start">Purchase Order Date</label>
                                <input
                                    type="datetime"
                                    className="form-Input w-70"
                                    disabled
                                    value={moment(POData.prodDate).format('DD-MMM-YYYY')}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="InvoiceNo" className="form-label  pt-1 start">Invoice No</label>
                                <input
                                    className="form-Input w-70"
                                    disabled
                                    value={itemData.invoiceNo}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-1 start">Delivery Type</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 8,
                                        padding: 9
                                    })}
                                    className="w-70 p-0"
                                    isDisabled
                                    value={itemData.DeliveryType}
                                />
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-1 start">Supplier</label>
                                <input
                                    className="form-Input w-70"
                                    disabled
                                    value={itemData.vendorName}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="InvoiceDate" className="form-label  pt-1 start">Invoice Date</label>
                                <input
                                    type="datetime"
                                    className="form-Input w-70"
                                    value={moment(itemData.invoiceDate).format('DD-MMM-YYYY')}
                                    disabled
                                />
                            </div>
                            <div className="col d-flex justify-content-between gap-3">
                                <div className="col d-flex justify-content-between">
                                    <label htmlFor="Currency" className="form-label  pt-1 start">Currency</label>
                                    <input
                                        className="form-Input w-70"
                                        disabled
                                        value={itemData.currencyCode}
                                    />
                                </div>
                                <div className="col d-flex justify-content-between">
                                    <label htmlFor="EXRate" className="form-label  pt-1 start">EX Rate</label>
                                    <input
                                        className="form-Input w-70"
                                        disabled
                                        value={itemData.exchangeRate}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cost-center-section mb-2">
                <div className="d-flex mt-3 cursor-pointer slide-header gap-4"
                    onClick={() => setCostTab(!CostTab)}>
                    <div className='h6 pt-1'>Cost Center</div>
                </div>
                <div className={`slide-content ${CostTab ? 'open' : 'closed'}`}>
                    <div className="border-box2 p-3">
                        <div className="form-group row pb-3">
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="PropertyGroup" className="form-label  pt-1 start">Property Group</label>
                                <input
                                    className="form-Input w-70"
                                    disabled
                                    value={itemData.costCenter1}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="Property" className="form-label  pt-1 start">Property</label>
                                <input
                                    className="form-Input w-70"
                                    disabled
                                    value={itemData.costCenter2}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="Department" className="form-label  pt-1 start">Department</label>
                                <input
                                    className="form-Input w-70"
                                    disabled
                                    value={itemData.costCenter3}
                                />
                            </div>
                        </div>
                        <div className="form-group row ">
                            <div className="col-4 d-flex justify-content-between">
                                <label htmlFor="Outlet" className="form-label  pt-1 start">Outlet</label>
                                <input
                                    className="form-Input w-70"
                                    disabled
                                    value={itemData.costCenter4}
                                />
                            </div>
                            <div className="col-4 d-flex justify-content-between">
                                <label htmlFor="remarks" className="form-label  pt-1 start">Remarks</label>
                                <input
                                    className="form-Input w-70"
                                    disabled
                                    value={itemData.remarks}
                                />
                            </div>
                            <div className='col-1 px-5 mx-3'></div>
                            <div className="col-2 brderbox center">
                                {'Attachment'}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`cost-center-section`} >
                <div className="mt-3 cursor-pointer slide-header">
                    <div className="nav nav-pills overslid d-flex gap-4">
                        {/* Nav tabs */}
                        <div
                            className={`dashitems sidebar-text center ${activeTab === 0 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(0)}
                        >
                            Items
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 1 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(1)}
                        >
                            Authorization
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 2 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(2)}
                        >
                            Message
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 3 ? 'active' : ''}`}
                            onClick={() => handleTabClick(3)}
                        >
                            Additional Charges
                        </div>
                    </div>

                </div>
            </div>
            <div className={`slide-content open`}>
                <div className={`grid-1 border-box2 p-3`}>
                    {gridVisibility[0] && (
                        <ItemGridGRN onRequisitionData={handleRequisitionData} itemData={POData} grnData={itemData} selectedPOData={selectedPOData} />
                    )}
                    {gridVisibility[1] && (
                        <AuthorizationGrn handleAuthorization={handleAuthorization}
                            usersDetails={usersDetails}
                            setUser={setUser}
                            itemData={POData} />
                    )}
                    {gridVisibility[2] && (
                        <div className="grid-3 border">
                            <MessageGrid handleMessage={handleMessage} />
                        </div>
                    )}
                    {gridVisibility[3] && (
                        <ItemGridGRN onRequisitionData={handleRequisitionData} />
                    )}
                </div>
            </div>
        </div>
    )
}

export default GrnView