import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import ApiService2 from '../../../POS/services/apiService';
import ApiService from '../../services/apiService';
import InvoiceItemList from './invoiceItems';
import InvoiceGrnList from './invoiceGrntable';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthorizationInvoice from './invoiceAuthorization';
import { useSelector } from 'react-redux';
import { getName } from '../../../Redux/selecters';
import { RiRefreshLine } from "react-icons/ri";
import { FaAngleLeft } from "react-icons/fa6";
import { FaSave } from "react-icons/fa";
import { ClipLoader } from 'react-spinners';
import moment from 'moment';
import InvoiceViewItemList from './view/viewItems';
import InvoiceViewGrnList from './view/viewGrnList';

const InvoiceView = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [gridVisibility, setGridVisibility] = useState([true, false, false]);
    const [SupplierTab, setSupplierTab] = useState(true);
    const [CostTab, setCostTab] = useState(false);
    const [POTab, setPOTab] = useState(true);
    const [authorizationData, setAuthorizationData] = useState([]);
    const [SelectedSupplier, setSelectedSupplier] = useState([]);
    const [SelectedCurrency, setSelectedCurency] = useState([]);
    const [SelectedPO, setSelectedPO] = useState([]);
    const [GrnItemData, setGrnItems] = useState([]);
    const [GrnList, setGrnList] = useState([]);
    const [usersDetails, setUser] = useState([]);
    const [POData, setPOData] = useState([]);
    const apiService = new ApiService();
    const location = useLocation();
    const itemData = location.state;
    debugger
    const getCurrentLocalDatetime = () => {
        const localDate = new Date();
        const offset = localDate.getTimezoneOffset() * 60000;
        const localISOTime = new Date(localDate - offset).toISOString().slice(0, 16);
        return localISOTime;
    }
    useEffect(() => {
        const item = location.state;
        setSelectedPO({ label: item?.pOrderNo, value: item?.pOrderNo })
        setSelectedSupplier({ label: item.vendorName, value: item.vendorName })
        setSelectedCurency({ label: item.currencyCode, value: item?.exchangeRate });
        const SetData = async () => {
            let param = `?InvoiceNo=${item?.invoiceNo}`
            const res = await apiService.getApiParamater('GET_INVOICE_VIEW', param);
            let Inv = res.data.result[0]
            const params = new URLSearchParams();
            params.append('DocNo', item?.invoiceNo);
            params.append('DocumentType', "Invoice");
            const response = await apiService.getApiParamater('GET_DOC_STATUS_EDIT', `?${params.toString()}`);
            setUser(response.data.result);

            debugger
            try {
                setGrnList(Inv?.grnInfoInvoiceViews)
                setGrnItems(Inv?.invoiceRegDetailsForView)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        if (item) {
            SetData()
        }
    }, [location?.state, itemData]);
    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
        const newGridVisibility = gridVisibility.map((_, index) => index === tabIndex);
        setGridVisibility(newGridVisibility);
    }
    const handleAuthorization = (data) => {
        setAuthorizationData(data);
        console.log(data, 'reqData')
    }
    const [formData, setFormData] = useState({
        InvoiceNO: "",
        remarks: "",
        InvoiceDate: getCurrentLocalDatetime(),
        deliveryDate: getCurrentLocalDatetime(),
        PaymentTerm: "",
        POType: "",
        SuppInvoiceNo: "",
        Department: "",
        Currency: "",
        exchangeRate: "",
        DueDate: getCurrentLocalDatetime(),
        TaxInvoice: "",
        CostCentre5: "",
        Narration: "",
        costCenter1: "",
        costCenter2: "",
        costCenter3: "",
        costCenter4: "",
    })
    const handleBackClick = () => {
        window.history.back();
    }
    return (
        <div className='px-4'>
            <div className='d-flex justify-content-between pt-3'>
                <h5 className='col mainInvHead'>Supplier Invoice</h5>
                <button onClick={handleBackClick} className='mainbtnSecondary'><FaAngleLeft className='center mt-1 mx-2' /><div>Back</div></button>
            </div>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header"
                    onClick={() => setPOTab(!POTab)}>
                    <div className='h6 pt-1'>Invoice Details</div>
                </div>
                <div className={`slide-content ${POTab ? 'open' : 'closed'}`}>
                    <div className={`border-box2 p-3 ${POTab ? 'slide-in' : 'slide-out'}`}>
                        <div className="form-group row pb-3">
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="InvoiceNO" className="form-label  pt-1 start">Invoice No</label>
                                <input
                                    className="form-Input w-70"
                                    id="invoiceNo"
                                    value={itemData?.invoiceNo}
                                    name="invoiceNo"
                                    disabled
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="docDate" className="form-label  pt-1 start">Date </label>
                                <input className='form-Input w-70' type='datetime'
                                    name="docDate" disabled
                                    id="docDate"
                                    value={moment(itemData.docDate).format('DD-MMM-YYYY')}
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="POType" className="form-label  pt-1 start">PO Type</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    id="POType"
                                    value={formData.POType} disabled
                                    name="POType"
                                />
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="InvoiceDate" className="form-label  pt-1 start">Supp. Invoice Date</label>
                                <input
                                    type="datetime"
                                    className="form-Input w-70"
                                    id="InvoiceDate" disabled
                                    name="InvoiceDate"
                                    value={moment(itemData.invoiceDate).format('DD-MMM-YYYY')}
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-1 start">Supplier Name</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    isDisabled
                                    className="w-70 p-0"
                                    value={SelectedSupplier}
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="PaymentTerm" className="form-label  pt-1 start">Payment Term</label>
                                <input
                                    type="text" disabled
                                    className="form-Input w-70"
                                    id="PaymentTerm"
                                    value={itemData.terms}
                                    name="PaymentTerm"
                                />
                            </div>
                        </div>
                        <div className="form-group row  pb-3">
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-1 start">Purchase Order No *</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    className="w-70 p-0" isDisabled
                                    value={SelectedPO}
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="SuppInvoiceNo" className="form-label  pt-1 start">Supp. Invoice No</label>
                                <input
                                    type="text" disabled
                                    className="form-Input w-70"
                                    id="SuppInvoiceNo"
                                    value={itemData.invoiceRef}
                                    name="SuppInvoiceNo"
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="DueDate" className="form-label  pt-1 start">Due Date</label>
                                <input
                                    type="datetime"
                                    className="form-Input w-70"
                                    id="DueDate" disabled
                                    value={moment(itemData.dueDate).format('DD-MMM-YYYY')}
                                    name="DueDate"
                                />
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="TaxInvoice" className="form-label  pt-1 start">Tax Invoice No ( KRA )</label>
                                <input
                                    type="number"
                                    className="form-Input w-70"
                                    id="TaxInvoice"
                                    value={itemData.costCenter5} disabled
                                    name="TaxInvoice"
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="Currency" className="form-label  pt-1 start">Currency</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })} isDisabled
                                    className="w-70 p-0"
                                    value={SelectedCurrency}
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="EXRate" className="form-label  pt-1 start">EX Rate</label>
                                <input
                                    type="number"
                                    className="form-Input w-70"
                                    disabled
                                    id="exchangeRate"
                                    value={SelectedCurrency.value}
                                    name="exchangeRate"
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="d-flex justify-content-between col-4">
                                <label htmlFor="Narration" className="form-label  pt-1 start">Narration</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    id="Narration" disabled
                                    value={itemData?.remarks}
                                    name="Narration"
                                />
                            </div>
                            <div className='col-1 px-5 mx-3'></div>
                            <div className='col-lg-2 brderbox center'>
                                <a disabled>Attachment</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header"
                    onClick={() => setSupplierTab(!SupplierTab)}>
                    <div className='h6 pt-1'>GRN Details</div>
                </div>
                <div className={`slide-content ${SupplierTab ? 'open' : 'closed'}`}>
                    <div className="border-box2">
                        <div className="grid-1">
                            <InvoiceViewGrnList GrnList={GrnList} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header" onClick={() => setCostTab(!CostTab)}>
                    <div className="h6 pt-1">Cost Center Details</div>
                </div>
                <div className={`slide-content ${CostTab ? 'open' : 'closed'}`}>
                    <div className="border-box2 p-3">
                        <div className="form-group row pb-3">
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="PropertyGroup" className="form-label  pt-1 start">Property Group</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    className="w-70 p-0" isDisabled
                                    value={{ label: itemData.costCenter1, value: itemData?.costCenter1 }}
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="Property" className="form-label  pt-1 start">Property</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    className="w-70 p-0"
                                    value={{ label: itemData.costCenter2, value: itemData?.costCenter2 }}
                                    isDisabled
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="Department" className="form-label  pt-1 start">Department</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    className="w-70 p-0"
                                    value={{ label: itemData.costCenter3, value: itemData?.costCenter3 }}
                                    isDisabled
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="d-flex justify-content-between col-4">
                                <label htmlFor="Outlet" className="form-label  pt-1 start">Outlet</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    className="w-70 p-0"
                                    value={{ label: itemData.costCenter4, value: itemData?.costCenter4 }}
                                    isDisabled
                                />
                            </div>
                            <div className="d-flex justify-content-between col-4">
                                <label htmlFor="CostCentre5" className="form-label  pt-1 start">Cost Centre 5</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    className="w-70 p-0"
                                    isDisabled
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="slide-container p-2 mt-3">
                <div className="container-fluid">
                    <div className="nav nav-pills overslid d-flex gap-4">
                        {/* Nav tabs */}
                        <div
                            className={`dashitems sidebar-text center ${activeTab === 0 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(0)}
                        >
                            Items
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 1 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(1)}
                        >
                            Authorization
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 2 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(2)}
                        >
                            Message
                        </div>
                    </div>
                </div>
            </div>
            <div className="grid-1 brderbox2 p-4 mb-3">
                {gridVisibility[0] && (
                    <InvoiceViewItemList GrnItemData={GrnItemData} />
                )}
                {gridVisibility[1] && (
                    <AuthorizationInvoice handleAuthorization={handleAuthorization}
                        usersDetails={usersDetails}
                        setUser={setUser}
                        itemData={POData} />
                )}
                {gridVisibility[2] && (
                    <div className="grid-3 border">
                        {/* <MessageGrid handleMessage={handleMessage} /> */}
                    </div>
                )}
            </div>
        </div >
    )
}
export default InvoiceView