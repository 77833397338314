import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import AuthorizationGrid from '../purchaseRequisition/authorization';
import MessageGrid from '../purchaseRequisition/messageGrid';
import ApiService from '../../services/apiService';
import ApiService2 from '../../../POS/services/apiService';
import { toast } from 'react-toastify';
import userManagement from '../../../POS/services/userManagement';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { clearRows } from '../../../Redux/actions/rowActions';
import { ClipLoader } from 'react-spinners';
import { getUserId, getName, getUserName, getCompany } from '../../../Redux/selecters';
import { useSelector } from 'react-redux';
import UserStoreItems from './itemList';
import { RiRefreshLine } from "react-icons/ri";
import { FaAngleLeft } from "react-icons/fa6";
import { FaSave } from "react-icons/fa";
import Modal from 'react-bootstrap/Modal';
import StoreAuthorization from './authorization';

const UserStoreCreate = () => {
    const [gridVisibility, setGridVisibility] = useState([true, false, false]);
    const [selectedDepartment, setSelectedDepartment] = useState([]);
    const [authorizationData, setAuthorizationData] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState([]);
    const [SelectedCurrency, setSelectedCurency] = useState([]);
    const [requisitionData, setRequisitionData] = useState([]);
    const currentDatetimeUTC = new Date().toISOString();
    const [messageData, setMessageData] = useState([]);
    const apiService2 = new ApiService2()
    const [department, setDepartment] = useState([]);
    const [activeTab, setActiveTab] = useState(0);
    const [currency, setCurrency] = useState([]);
    const [Location, setLocation] = useState([]);
    const [usersDetails, setUser] = useState([]);
    const [StockType, setStockType] = useState();
    const [loader, setLoader] = useState(false);
    const [ReqNo, setReqNo] = useState([]);
    const userId = useSelector(getUserId);
    const userName = useSelector(getUserName);
    const Company = useSelector(getCompany);
    const apiService = new ApiService();
    const location = useLocation();
    const itemData = location.state;
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const [POTab, setPOTab] = useState(true);
    const [attachmentModal, setAttachmentModal] = useState(false);
    const [attachmentList, setAttachmentList] = useState([{ id: Date.now(), fileName: '', remarks: '' }]);
    const handleClose = () => setAttachmentModal(false);
    const handleOpen = () => setAttachmentModal(true);
    const [previewModal, setPreviewModal] = useState(false);
    const [previewImage, setPreviewImage] = useState(null);
    const formatDateToDatetimeLocal = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${year}-${month}-${day}T${hours}:${minutes}`;
    };
    const getCurrentLocalDatetime = () => {
        const localDate = new Date();
        const offset = localDate.getTimezoneOffset() * 60000;
        const localISOTime = new Date(localDate - offset).toISOString().slice(0, 16);
        return localISOTime;
    };
    const [formData, setFormData] = useState({
        recordId: "",
        requisitionNo: "",
        itemType: "",
        quatotionNo: "",
        remarks: "",
        systemUser: "",
        requisitionDate: getCurrentLocalDatetime(),
        deliveryDate: getCurrentLocalDatetime(),
        currencyCode: "",
        exchangeRate: "",
        costCenter1: [],
        costCenter2: "",
        costCenter3: "",
        costCenter4: "",
        deliveryPoint: "",
        location: "",
        reqName: "",
        contatNo: "",
        taskCode: "",
        subcatname: "",
        StockType: "",
    });
    useEffect(() => {
        getCurrency()
        getLocation()
        getApprovalUser()
    }, [])
    useEffect(() => {
        const item = location.state;
        const selectedDepartment = department?.find(dep => dep.description === item?.department)
        if (selectedDepartment) {
            setSelectedDepartment({ label: selectedDepartment.description, value: selectedDepartment })
        }
        const selectedLocation = Location?.find(dep => dep.description === item?.approvalUser)
        if (selectedLocation) {
            setSelectedLocation({ label: selectedLocation.description, value: selectedLocation })
        }
        setFormData({
            recordId: item?.recordId,
            requisitionDate: formatDateToDatetimeLocal(item?.requisitionDate) || getCurrentLocalDatetime(),
            deliveryDate: item?.deliveryDate ? formatDateToDatetimeLocal(item.deliveryDate) : getCurrentLocalDatetime(),
            requisitionNo: item?.requisitionNo,
            itemType: item?.type,
            quatotionNo: item?.contractNo,
            remarks: item?.remarks,
            systemUser: item?.requestor,
            currencyCode: item?.currencyCode,
            costCenter2: item?.costCenter2,
            costCenter3: item?.costCenter3,
            costCenter4: item?.costCenter4,
            deliveryPoint: item?.deliveryPoint,
            contactPerson: item?.contactPerson,
            contatNo: item?.contatNo,
            taskCode: item?.projectCode,
            location: item?.location,
        });
    }, [location?.state, department, Location]);
    const getApprovalUser = async () => {
        try {
            if (location.state) {
                const params = new URLSearchParams();
                params.append('DocNo', itemData?.requisitionNo);
                params.append('DocumentType', "Store Requisition");
                const response = await apiService.getApiParamater('GET_DOC_STATUS_EDIT', `?${params.toString()}`);
                setUser(response.data.result);
            } else {
                const approvalList = await apiService.get('GET_APPROVAL_USER');
                const approvals = approvalList?.data.result?.filter(dep => dep.active === 1)
                const approvalslist = approvals?.filter(dep => dep.docType === "Store Requisition")
                setUser(approvalslist)
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    let param = `?isActive=true`
    const getCurrency = async () => {
        const apiService = new ApiService()
        let res = await apiService.getApiParamater('GET_CURRENCY_MASTER', param)
        if (itemData) {
            setReqNo(itemData.requisitionNo);
        } else {
            let docParam = `?TypeOfDoc=Req&DocId=Requisition`
            let DocId = await apiService.getApiParamater('GET_DOC_ID', docParam)
            setReqNo(DocId?.data.result[0].docId);
        }
        setCurrency(res?.data?.result)
        const filteredDenominations = res?.data?.result?.find(currency => currency?.isDefault === true);
        setFormData({ ...formData, exchangeRate: filteredDenominations.conversionRate });
        setFormData({ ...formData, exchangeRate: filteredDenominations.conversionRate });
        if (filteredDenominations) {
            setSelectedCurency({ label: filteredDenominations.code, value: filteredDenominations });
        }
    }
    const getLocation = async () => {
        let res = await apiService.getApiParamater('GET_WAREHOUSE_MASTER', param)
        const stockType = await apiService.getApiParamater("GET_STOCK_TYPE", param);
        let DepRes = await apiService.getApiParamater('GET_DEPARTMENT_MASTER', param)
        setStockType(stockType?.data?.result);
        setDepartment(DepRes?.data?.result)
        setLocation(res?.data?.result)
    }
    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
        const newGridVisibility = gridVisibility.map((_, index) => index === tabIndex);
        setGridVisibility(newGridVisibility);
    };
    const handleMessage = (data) => {
        setMessageData(data);
        console.log(data, 'reqData')

    };
    const handleRequisitionData = (data) => {
        setRequisitionData(data);
        console.log(data, 'reqData')
    };
    const handleAuthorization = (data) => {
        setAuthorizationData(data);
        console.log(data, 'reqData')
    };
    const staticOptions = [];
    const totalAmount = requisitionData.reduce((sum, row) => sum + (row?.qty * row.baseAmount || 0), 0);
    const CreatePR = async () => {
        setLoader(true)
        if (!formData.location) {
            toast.warning("Enter location");
            setLoader(false)
            return;
        }
        if (!formData.deliveryPoint) {
            toast.warning("Enter Delivery Point");
            setLoader(false)
            return;
        }
        if (!formData.reqName) {
            toast.warning("Enter Req Name");
            setLoader(false)
            return;
        }
        // if (!selectedLocation) {
        //     toast.warning("Enter location");
        //     setLoader(false)
        //     return;
        // }
        if (requisitionData.length === 0) {
            toast.warning("Please add items");
            setLoader(false)
            return;
        }
        for (const item of requisitionData) {
            if (!item.qty) {
                toast.warning(`Please enter ${item.itemDescription} quantity`);
                setLoader(false);
                return;
            }
        }
        try {
            const prDetailsData = requisitionData?.map(item => ({
                requisitionNo: ReqNo.toString(),
                srno: parseInt(item.srno),
                itemCode: item?.itemNo.toString(),
                itemDescription: item.itemDescription,
                uom: item.uom,
                qty: parseInt(item.qty),
                baseUOM: item?.uom,
                baseQty: parseInt(item.qty),
                specification: item.specification,
                convValue: 1,
                unitPrice: parseFloat(item.baseAmount),
                amount: parseFloat(item.amount),
                supplierId: item.supplierId,
                warehouse: selectedLocation?.label,
                baseAmount: parseFloat(item.amount)
            }));
            const currentDatetimeUTC = new Date().toISOString();
            const userData = {
                type: "Inventory",
                requisitionNo: ReqNo,
                reqType: "Store Requisition",
                contractNo: formData?.quatotionNo,
                description: formData?.remarks,
                // requestor: formData?.systemUser,
                requestor: userName,
                requisitionDate: currentDatetimeUTC,
                deliveryDate: formData.deliveryDate,
                currencyCode: SelectedCurrency.label,
                exchangeRate: SelectedCurrency?.value.conversionRate,
                // department: selectedDepartment?.value?.description,
                department: 'IT',
                remarks: formData?.remarks,
                modifiedBy: userName,
                // approvalUser: authorizationData?.userNo || usersDetails[0]?.userNo || "defaultUserName",
                costCenter2: formData?.costCenter2,
                costCenter3: formData?.costCenter3,
                costCenter4: formData?.costCenter4,
                deliveryPoint: formData?.deliveryPoint,
                location: formData?.location,
                contactPerson: formData?.reqName,
                contatNo: formData?.contatNo,
                projectCode: formData?.taskCode,
                prDetails: prDetailsData,
                status: 0,
                baseAmount: totalAmount,
                amount: totalAmount,
                approvalUser: selectedLocation?.label,
            };
            const response = await apiService.post("CREATE_PR", userData);
            if (response?.data?.statusCode === 201) {
                const docNumber = response?.data?.result?.requisitionNo
                const userData2 = {
                    docType: "Store Requisition",
                    docId: docNumber ? docNumber.toString() : ReqNo,
                    department: "IT",
                    company: Company?.selectedCompany?.companyNo,
                    userCode: userId,
                    userName: userName,
                };
                const docBody = {
                    docId: "Requisition",
                    typeOfDoc: "Req",
                };
                const response2 = await apiService.post("CREATE_DOC_AUTHORIZATION", userData2);
                const paramsGet = new URLSearchParams();
                paramsGet.append('docType', "Store Requisition");
                paramsGet.append('docId', ReqNo);
                paramsGet.append('srno', 1);
                const response3 = await apiService.getApiParamater('GET_DOC_APPROVAL_USER', `?${paramsGet.toString()}`);
                if (response3?.data?.result.length > 0) {
                    debugger
                    const toEmails = response3?.data?.result?.map(user => user.userEmail);
                    const emailbody = {
                        toEmails: toEmails,
                        subject: "Store Requisition",
                        body: "",
                        docNo: ReqNo,
                        srNo: 1,
                        docType: "Store Requisition"
                    };
                    const emailres = await apiService.post("DOC_APPROVAL_EMAIL_POST", emailbody);
                }
                const docRes = await apiService.put("UPDATE_DOC_ID", docBody);
                toast.success(`${ReqNo} Created successfully`);
                setLoader(false)
                setFormData({
                    itemType: "",
                    quatotionNo: "",
                    remarks: "",
                    systemUser: "",
                    requisitionDate: "",
                    deliveryDate: "",
                    currencyCode: "",
                    department: "",
                    costCenter1: "",
                    costCenter2: "",
                    costCenter3: "",
                    costCenter4: "",
                    deliveryPoint: "",
                    location: "",
                    reqName: "",
                    contatNo: "",
                    taskCode: "",
                });
                dispatch(clearRows());
                navigate("/Inventory/UserStore/List")
            }
            setLoader(false)
        } catch (error) {
            setLoader(false)
            console.error("Error Creating PR:", error);
        }
    };
    const UpdatePR = async () => {
        try {
            setLoader(true)
            const prDetailsData = requisitionData?.map((item, index) => ({
                requisitionNo: ReqNo,
                srno: index + 1,
                itemCode: item?.itemNo,
                itemDescription: item.itemDescription,
                specification: item.specification,
                uom: item.uom,
                qty: parseInt(item.qty),
                baseUOM: item?.uom,
                baseQty: parseInt(item.qty),
                // convValue: parseInt(item?.baseQty),
                convValue: 1,
                unitPrice: parseFloat(item.baseAmount),
                amount: parseFloat(item.amount),
                baseAmount: parseFloat(item.amount),
                validated: 0,
                supplierId: item.supplierId,
                warehouse: selectedLocation?.label,
                deliveryDate: formData.deliveryDate,
            }));

            const currentDatetimeUTC = new Date().toISOString();
            const userData = {
                type: "Inventory",
                requisitionNo: ReqNo,
                reqType: "Store Request",
                contractNo: formData?.quatotionNo,
                description: formData?.remarks,
                requisitionDate: currentDatetimeUTC,
                deliveryDate: formData.deliveryDate,
                currencyCode: SelectedCurrency.label,
                exchangeRate: SelectedCurrency?.value.conversionRate,
                remarks: formData?.remarks,
                costCenter2: formData?.costCenter2,
                costCenter3: formData?.costCenter3,
                costCenter4: formData?.costCenter4,
                deliveryPoint: formData?.deliveryPoint,
                location: formData?.location,
                contactPerson: formData?.reqName,
                contatNo: formData?.contatNo,
                projectCode: formData?.taskCode,
                status: 0,
                baseAmount: totalAmount,
                amount: totalAmount,
                approvalUser: selectedLocation?.label,
                prDetails: prDetailsData,

                // requestor: formData?.systemUser,
                requestor: userName,
                // department: selectedDepartment?.value?.description,
                department: 'IT',
                modifiedBy: userName,
            };
            const response = await apiService.put("UPDATE_PR", userData);
            if (response?.data?.statusCode === 201) {
                toast.success(`${ReqNo} Update successfully`);
                setFormData({
                    itemType: "",
                    quatotionNo: "",
                    remarks: "",
                    systemUser: "",
                    requisitionDate: "",
                    deliveryDate: "",
                    currencyCode: "",
                    exchangeRate: "",
                    department: "",
                    costCenter1: "",
                    costCenter2: "",
                    costCenter3: "",
                    costCenter4: "",
                    deliveryPoint: "",
                    location: "",
                    reqName: "",
                    contatNo: "",
                    taskCode: "",
                });
                dispatch(clearRows());
                setLoader(false)
                navigate("/Inventory/UserStore/List")
            }
        } catch (error) {
            setLoader(false)
            console.error("Error Creating PR:", error);
        }
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if ((name === 'contatNo') && value.length > 16) {
            return;
        }
        if (name === 'requisitionDate' || name === 'deliveryDate') {
            const dateParts = e.target.value.split("-");
            if (dateParts[0].length > 4) {
                return;
            }
        }
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handlecurChange = (selectedOption) => {
        setFormData({ ...formData, currencyCode: selectedOption?.value?.code });
    };
    const handleDepChange = (selectedOption) => {
        setSelectedDepartment(selectedOption)
    };
    const handlelocChange = (selectedOption) => {
        setSelectedLocation(selectedOption)
    };
    const handleBackClick = () => {
        window.history.back();
    };
    const handleStockChange = (selectedOption) => {
        setFormData({ ...formData, StockType: selectedOption });
    }

    const handleFileSelect = (event, id) => {
        const file = event.target.files[0];
        if (file) {
            setAttachmentList((prevList) => [
                ...prevList.map((attachment) =>
                    attachment.id === id ? { ...attachment, fileName: file.name, file: file } : attachment
                ),
                { id: Date.now(), fileName: '', remarks: '' },
            ]);
        }
    };
    const handleView = (file) => {
        if (file && file instanceof File) {
            setPreviewImage(URL.createObjectURL(file));
            setPreviewModal(true);
        } else {
            console.error("No valid file to preview");
        }
    };
    const handleDelete = (id) => {
        const updatedList = attachmentList.filter((attachment) => attachment.id !== id);
        setAttachmentList(updatedList);
    };
    return (
        <div className='px-4 pb-5'>
            <div className='d-flex justify-content-between pt-3'>
                <h5 className='col mainInvHead'>User Store Request</h5>
                <div className='d-flex gap-3'>
                    {itemData?.eye === true ? " " :
                        <>
                            {location.state ? (
                                <>{loader === true ?
                                    <button className='mainbtn ' type="submit"><ClipLoader color="#ffffff" size={25} /></button> :
                                    <button className='mainbtn ' onClick={UpdatePR} type="submit">
                                        <FaSave className='center mt-1 mx-2' /><div>Update</div>
                                    </button>
                                }
                                </>
                            ) : (
                                <>{loader === true ?
                                    <button className='mainbtn ' type="submit"><ClipLoader color="#ffffff" size={25} /></button> :
                                    <button className='mainbtn ' onClick={CreatePR} type="submit">
                                        <FaSave className='center mt-1 mx-2' /><div>Save</div>
                                    </button>
                                }
                                </>
                            )}</>
                    }
                    {itemData?.eye === true ? " " : <button className='mainbtnRed' ><RiRefreshLine className='center mt-1 mx-2' /><div>Reset</div></button>}
                    <button onClick={handleBackClick} className='mainbtn'><FaAngleLeft className='center mt-1 mx-2' /><div>Back</div></button>
                </div>
            </div>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header"
                    onClick={() => setPOTab(!POTab)}>
                    <div className='h6 pt-1'>Request Details</div>
                </div>
                <div className={`slide-content ${POTab ? 'open' : 'closed'}`}>
                    <div className={`border-box2 p-3 ${POTab ? 'slide-in' : 'slide-out'}`}>
                        <div className="form-group row pb-3">
                            <div className="col-4 d-flex justify-content-between ">
                                <label htmlFor="ReqNo" className="form-label  pt-2 start">Requisition No</label>
                                <input
                                    className="form-Input w-70"
                                    disabled
                                    value={ReqNo}
                                    name="ReqNo"
                                />
                            </div>
                            <div className="col-4 d-flex justify-content-between">
                                <label htmlFor="deliveryDate" className="form-label  pt-2 start">Delivery Date</label>
                                {itemData?.eye === true ?
                                    <input
                                        type='datetime-local'
                                        className='form-Input w-70' disabled
                                        value={formData.deliveryDate}
                                    /> :
                                    <input
                                        className='form-Input w-70'
                                        type='datetime-local'
                                        value={formData.deliveryDate}
                                        name="deliveryDate"
                                        onChange={handleInputChange}
                                    />
                                }
                            </div>
                            <div className="col-4 d-flex justify-content-between">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-2 start">Property Group</label>
                                {itemData?.eye === true ?
                                    <Select
                                        className="w-70 p-0"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }} isDisabled /> :
                                    <Select
                                        className="w-70 p-0"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        options={staticOptions}
                                        onChange={(selectedOption) => handleInputChange('inventoryAccountCode', selectedOption)}
                                    />
                                }
                            </div>
                        </div>
                        {/* Dropdowns for other fields */}
                        <div className="form-group row pb-3">
                            <div className="col-4 d-flex justify-content-between">
                                <label htmlFor="catcode" className="form-label  pt-2 start">Date </label>
                                {itemData?.eye === true ?
                                    <input
                                        className='form-Input w-70'
                                        type='datetime-local' disabled value={formData.requisitionDate}
                                    /> :
                                    <input
                                        className='form-Input w-70'
                                        type='datetime-local' date
                                        value={getCurrentLocalDatetime()}
                                        name="requisitionDate"
                                    />
                                }
                            </div>
                            <div className="col-4 d-flex justify-content-between">
                                <label htmlFor="deliveryPoint" className="form-label  pt-2 start">Delivery Point</label>
                                {itemData?.eye === true ?
                                    <input disabled
                                        className="form-Input w-70"
                                        value={formData.deliveryPoint}
                                    /> :
                                    <input
                                        type="text"
                                        className="form-Input w-70"
                                        id="deliveryPoint"
                                        value={formData.deliveryPoint}
                                        onChange={handleInputChange}
                                        name="deliveryPoint"

                                    />
                                }
                            </div>
                            <div className="col-4 d-flex justify-content-between">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-2 start">Property</label>
                                {itemData?.eye === true ?
                                    <Select
                                        className="w-70 p-0"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }} isDisabled /> :
                                    <Select
                                        className="w-70 p-0"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        options={staticOptions}
                                        onChange={(selectedOption) => handleInputChange('inventoryAccountCode', selectedOption)}
                                    />
                                }
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="col-4 d-flex justify-content-between">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-2 start">Currency</label>
                                {itemData?.eye === true ?
                                    <Select
                                        className="w-70 p-0"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }} isDisabled value={SelectedCurrency} /> :
                                    <Select
                                        className="w-70 p-0"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        isSearchable={false}
                                        value={SelectedCurrency}
                                        onChange={(e) => handlecurChange(e)}
                                    />
                                }
                            </div>
                            <div className="col-4 d-flex justify-content-between">
                                <label htmlFor="location" className="form-label  pt-2 start">Req- Office Location</label>
                                {itemData?.eye === true ?
                                    <input disabled value={formData.location} className="form-Input w-70" /> :
                                    <input
                                        type="text"
                                        className="form-Input w-70"
                                        id="location"
                                        value={formData.location}
                                        onChange={handleInputChange}
                                        name="location"
                                    />
                                }
                            </div>
                            <div className="col-4 d-flex justify-content-between">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-2 start">Cost Center 3</label>
                                {itemData?.eye === true ?
                                    <Select
                                        className="w-70 p-0"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }} isDisabled /> :
                                    <Select
                                        className="w-70 p-0"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        options={staticOptions}
                                        onChange={(selectedOption) => handleInputChange('inventoryAccountCode', selectedOption)}
                                    />
                                }
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="col-4 d-flex justify-content-between">
                                <label htmlFor="exchangeRate" className="form-label  pt-2 start">Exchange Rate</label>
                                {itemData?.eye === true ?
                                    <input disabled value={formData.exchangeRate} className="form-Input w-70" /> :
                                    <input
                                        type="number"
                                        className="form-Input w-70"
                                        disabled
                                        onWheel={(e) => e.target.blur()}
                                        id="exchangeRate"
                                        value={formData.exchangeRate}
                                        onChange={handleInputChange}
                                        name="exchangeRate"
                                    />
                                }
                            </div>
                            <div className="col-4 d-flex justify-content-between">
                                <label htmlFor="reqName" className="form-label  pt-2 start">Req- Name</label>
                                {itemData?.eye === true ?
                                    <input disabled value={formData.reqName} className="form-Input w-70" /> :
                                    <input
                                        type="text"
                                        className="form-Input w-70"
                                        id="reqName"
                                        value={formData.reqName}
                                        onChange={handleInputChange}
                                        name="reqName"
                                    />
                                }
                            </div>
                            <div className="col-4 d-flex justify-content-between">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-2 start">Outlets</label>
                                {itemData?.eye === true ?
                                    <Select
                                        className="w-70 p-0"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }} isDisabled /> :
                                    <Select
                                        className="w-70 p-0"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        options={staticOptions}
                                        onChange={(selectedOption) => handleInputChange('inventoryAccountCode', selectedOption)}
                                    />
                                }
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="col-4 d-flex justify-content-between">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-2 start">Item Type</label>
                                {itemData?.eye === true ?
                                    <Select
                                        className="w-70 p-0"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }} isDisabled /> :
                                    <Select
                                        className="w-70 p-0"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        options={staticOptions}
                                        onChange={(selectedOption) => handleInputChange('inventoryAccountCode', selectedOption)}
                                    />}
                            </div>
                            <div className="col-4 d-flex justify-content-between">
                                <label htmlFor="contatNo" className="form-label  pt-2 start">Req- Contact No</label>
                                {itemData?.eye === true ?
                                    <input disabled className="form-Input w-70" value={formData.contatNo} /> :
                                    <input
                                        type="number"
                                        className="form-Input w-70"
                                        id="contatNo"
                                        onWheel={(e) => e.target.blur()}
                                        value={formData.contatNo}
                                        onChange={handleInputChange}
                                        name="contatNo"
                                    />}
                            </div>
                            <div className="col-4 d-flex justify-content-between">
                                <label htmlFor="subcatname" className="form-label  pt-2 start">Location</label>
                                {itemData?.eye === true ?
                                    <Select
                                        className="w-70 p-0"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }} isDisabled value={selectedLocation} /> :
                                    <Select
                                        className="w-70 p-0"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        value={selectedLocation}
                                        onChange={(selectedOption) => handlelocChange(selectedOption)}
                                        options={Location?.map((loc) => ({ value: loc, label: loc?.description }))}
                                    />}
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="col-4 d-flex justify-content-between">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-2 start">Stock Type</label>
                                {itemData?.eye === true ?
                                    <Select
                                        className="w-70 p-0"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }} isDisabled /> :
                                    <Select
                                        className="w-70 p-0"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        value={formData.StockType}
                                        onChange={(selectedOption) => handleStockChange(selectedOption)}
                                        options={StockType?.map((Stock) => ({ value: Stock, label: Stock?.stockTypeName }))}
                                    />}
                            </div>
                            <div className="col-4 d-flex justify-content-between">
                                <label htmlFor="systemUser" className="form-label  pt-2 start">System User</label>
                                {itemData?.eye === true ?
                                    <input disabled className="form-Input w-70" value={formData.systemUser} /> :
                                    <input
                                        type="text"
                                        className="form-Input w-70"
                                        id="systemUser"
                                        value={formData.systemUser}
                                        onChange={handleInputChange}
                                        name="systemUser"

                                    />}
                            </div>
                            <div className="col-4 d-flex justify-content-between">
                                <label htmlFor="subcatname" className="form-label  pt-2 start">Remarks</label>
                                {itemData?.eye === true ?
                                    <input disabled
                                        className="form-Input w-70"
                                        value={formData.remarks}
                                    /> :
                                    <input
                                        name="remarks"
                                        type='text'
                                        className="form-Input w-70"
                                        id="subcatname"
                                        value={formData.remarks}
                                        onChange={handleInputChange}
                                    />}
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-4 d-flex justify-content-between">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-2 start">Template</label>
                                {itemData?.eye === true ?
                                    <Select
                                        className="w-70 p-0"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }} isDisabled /> :
                                    <Select
                                        className="w-70 p-0"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        options={staticOptions}
                                        onChange={(selectedOption) => handleInputChange('inventoryAccountCode', selectedOption)}
                                    />}
                            </div>
                            <div className="col-4 d-flex justify-content-between">
                                <>
                                    <label htmlFor="selectedDepartment" className="form-label  pt-2 start">Task Code</label>
                                    {itemData?.eye === true ?
                                        <Select
                                            className="w-70 p-0"
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 5,
                                                padding: 9
                                            })}
                                            menuPortalTarget={document.body}
                                            styles={{
                                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                    color: state.isFocused ? '#000 ' : '#000',
                                                    ':hover': {
                                                        backgroundColor: '#F5F5F5',
                                                        color: '#000 ',
                                                    },
                                                }),
                                            }} isDisabled value={selectedDepartment} /> :
                                        <Select
                                            className="w-70 p-0"
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 5,
                                                padding: 9
                                            })}
                                            menuPortalTarget={document.body}
                                            styles={{
                                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                    color: state.isFocused ? '#000 ' : '#000',
                                                    ':hover': {
                                                        backgroundColor: '#F5F5F5',
                                                        color: '#000 ',
                                                    },
                                                }),
                                            }}
                                            value={selectedDepartment}
                                            options={department?.map((dep) => ({ value: dep, label: dep?.description }))}
                                            onChange={(selectedOption) => handleDepChange(selectedOption)}
                                        />}
                                </>
                            </div>
                            <div className="col-1"></div>
                            <div className="col-2 brderbox center p-2" onClick={() => handleOpen()} >
                                {'Attachment'}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="slide-container p-2 mt-3">
                <div className="container-fluid">
                    <div className="nav nav-pills overslid d-flex gap-4">
                        {/* Nav tabs */}
                        <div
                            className={`dashitems sidebar-text center ${activeTab === 0 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(0)}
                        >
                            Requisition
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 1 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(1)}
                        >
                            Authorization
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 2 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(2)}
                        >
                            Message
                        </div>
                    </div>

                </div>
            </div>
            <div className="grid-1 brderbox2 p-4 mb-3">
                {gridVisibility[0] && (
                    <UserStoreItems onRequisitionData={handleRequisitionData} itemData={itemData} selectedLocation={selectedLocation} formData={formData} />
                )}
                {gridVisibility[1] && (
                    <StoreAuthorization
                        handleAuthorization={handleAuthorization}
                        usersDetails={usersDetails}
                        setUser={setUser}
                        itemData={itemData}
                    />
                )}
                {gridVisibility[2] && (
                    <div className="grid-3 border">
                        <MessageGrid handleMessage={handleMessage} />
                    </div>
                )}

            </div>
            <div>
                <Modal show={attachmentModal} size="lg" onHide={handleClose} >
                    <Modal.Header closeButton>
                        <Modal.Title>Attachments</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="col d-flex justify-content-between">
                            <label htmlFor="docNp" className="form-label  pt-2 start">Document No</label>
                            <input disabled value={ReqNo} className="form-Input w-70" />
                        </div>
                        <div className="pt-2">
                            <table>
                                <thead className="dynoTable">
                                    <tr>
                                        <th>File Name</th>
                                        <th>Remarks</th>
                                        <th>Upload</th>
                                        <th>View</th>
                                        <th>Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {attachmentList?.map((attachment, index) => (
                                        <tr key={attachment.id}>
                                            <td className='m-0 p-0'><input className='p-0' type='text' disabled value={attachment.fileName} style={{ width: "320px" }} /></td>
                                            <td>
                                                <input type="text" value={attachment.remarks}
                                                    onChange={(e) => {
                                                        const updatedList = attachmentList?.map((item) =>
                                                            item.id === attachment.id ? { ...item, remarks: e.target.value } : item
                                                        );
                                                        setAttachmentList(updatedList);
                                                    }}
                                                    style={{ width: '345px' }}
                                                />
                                            </td>
                                            <td>
                                                <button className='mainbtn ' onClick={() => document.getElementById(`fileInput-${attachment.id}`).click()} type="submit"><div>Upload</div></button>
                                                <input
                                                    type="file" accept="image/*"
                                                    style={{ display: 'none' }}
                                                    onChange={(e) => handleFileSelect(e, attachment.id)}
                                                    id={`fileInput-${attachment.id}`}
                                                />
                                            </td>
                                            <td><button className='mainbtnRed ' onClick={() => handleView(attachment.file)} disabled={!attachment.fileName}>
                                                <div>View</div></button></td>
                                            <td>
                                                <button onClick={() => handleDelete(attachment.id)} disabled={!attachment.fileName} className='mainbtnSecondary'>
                                                    <div>Delete</div></button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className='mainbtn' onClick={handleClose} >Attach</button>
                    </Modal.Footer>
                </Modal>
                <Modal show={previewModal} size="lg" onHide={() => setPreviewModal(false)}
                    backdrop="static"
                    keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Image Preview</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {previewImage && <img src={previewImage} alt={previewImage} style={{ width: '100%', objectFit: 'cover' }} />}
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )
}

export default UserStoreCreate