import React, { useState } from "react";
import 'react-pro-sidebar/dist/css/styles.css';
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link, NavLink, useLocation } from "react-router-dom";
import YourLogo from "../../Assets/Images/Pro_Logo.png";
import "../../Assets/Style/style.css"
import "./sidebar.css"
import { RxHamburgerMenu } from "react-icons/rx";
import { AiOutlineStock } from "react-icons/ai";
import { GiSellCard } from "react-icons/gi";

const RecipeSideBar = () => {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [showSalesSegment, setSalesSegment] = useState(false);
  const [showIngredientSegment, setIngredientSegment] = useState(false);
  const PLUMaster = location.pathname.startsWith('/Recipe/Master/PLU');
  const ClassMaster = location.pathname.startsWith('/Recipe/Master/class');
  const Cuisine = location.pathname.startsWith('/Recipe/Master/Cuisine');
  const Type = location.pathname.startsWith('/Recipe/Master/Type');
  const Category = location.pathname.startsWith('/Recipe/Master/Category');
  const SubCategory = location.pathname.startsWith('/Recipe/Master/SubCategory');
  const Allergen = location.pathname.startsWith('/Recipe/Master/Allergen');
  const Nutrition = location.pathname.startsWith('/Recipe/Master/Nutrition');
  const Season = location.pathname.startsWith('/Recipe/Master/Season');
  const RecipeGroup = location.pathname.startsWith('/Recipe/Master/RecipeGroup');
  const RecipeCategory = location.pathname.startsWith('/Recipe/Master/RecipeCategory');
  const RecipeSubCategory = location.pathname.startsWith('/Recipe/Master/RecipeSubCategory');
  const IngredientsGroup = location.pathname.startsWith('/Recipe/Master/IngredientsGroup');
  const IngredientsCategory = location.pathname.startsWith('/Recipe/Master/IngredientsCategory');
  const IngredientSubCategory = location.pathname.startsWith('/Recipe/Master/IngredientSubCategory');
  const RestaurantType = location.pathname.startsWith('/Recipe/Master/RestaurantType');
  const Recipe = location.pathname.startsWith('/Recipe/Master/Recipe');

  const Ingredient = location.pathname.startsWith('/Recipe/Management/Ingredient');
  const IngredientsCategory4 = location.pathname.startsWith('/Recipe/Master/IngredientsCategory');
  const BeverageReport = location.pathname.startsWith('/Recipe/Reports/BevrageReport');
  const CostReport =location.pathname.startsWith('/Recipe/Reports/CostReport');
  const ActiveReport = location.pathname.startsWith('/Recipe/Reports/ActiveReport');
  const handleToggle = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div>
      <ProSidebar collapsed={collapsed}>
        {collapsed ?
          <a href="/Recipe" className="navbar-brand start"><img src={YourLogo} alt="Logo" style={{ height: "35px", paddingTop: "8px", paddingBottom: "8px" }} /></a> :
          <a href="/Recipe" className="navbar-brand start"><img src={YourLogo} alt="Logo" style={{ height: "30px" }} /></a>
        }
        <Menu className="mt-2">
          <SubMenu className="mt-2"
            title="Masters"
            icon={<AiOutlineStock size={22} />}
            open={showSalesSegment}
            onOpenChange={() => setSalesSegment(!showSalesSegment)}
          >
            <MenuItem><NavLink to="/Recipe/Master/PLU"><div className={`submenu ${PLUMaster ? 'subactive' : ''}`}>- PLU Master</div></NavLink></MenuItem>
            <MenuItem><NavLink to="/Recipe/Master/Category"><div className={`submenu ${Category ? 'subactive' : ''}`}>- Category Master</div></NavLink></MenuItem>
            <MenuItem><NavLink to="/Recipe/Master/SubCategory"><div className={`submenu ${SubCategory ? 'subactive' : ''}`}>- SubCategory Master</div></NavLink></MenuItem>
            <MenuItem><NavLink to="/Recipe/Master/Allergen"><div className={`submenu ${Allergen ? 'subactive' : ''}`}>- Allergen Master</div></NavLink></MenuItem>
            <MenuItem><NavLink to="/Recipe/Master/Nutrition"><div className={`submenu ${Nutrition ? 'subactive' : ''}`}>- Nutrition Master</div></NavLink></MenuItem>
            <MenuItem><NavLink to="/Recipe/Master/Cuisine"><div className={`submenu ${Cuisine ? 'subactive' : ''}`}>- Cuisine Master</div></NavLink></MenuItem>
            <MenuItem><NavLink to="/Recipe/Master/Type"><div className={`submenu ${Type ? 'subactive' : ''}`}>- Type Master</div></NavLink></MenuItem>
            <MenuItem><NavLink to="/Recipe/Master/class"><div className={`submenu ${ClassMaster ? 'subactive' : ''}`}>- Class Master</div></NavLink></MenuItem>
            <MenuItem><NavLink to="/Recipe/Master/Season"><div className={`submenu ${Season ? 'subactive' : ''}`}>- Season Master</div></NavLink></MenuItem>
            <MenuItem><NavLink to="/Recipe/Master/RecipeGroup"><div className={`submenu ${RecipeGroup ? 'subactive' : ''}`}>- Recipe Group Master</div></NavLink></MenuItem>
            <MenuItem><NavLink to="/Recipe/Master/RecipeCategory"><div className={`submenu ${RecipeCategory ? 'subactive' : ''}`}>- Recipe Category Master</div></NavLink></MenuItem>
            <MenuItem><NavLink to="/Recipe/Master/RecipeSubCategory"><div className={`submenu ${RecipeSubCategory ? 'subactive' : ''}`}>- Recipe Sub Category Master</div></NavLink></MenuItem>
            <MenuItem><NavLink to="/Recipe/Master/IngredientsGroup"><div className={`submenu ${IngredientsGroup ? 'subactive' : ''}`}>- Ingredient Group Master</div></NavLink></MenuItem>
            <MenuItem><NavLink to="/Recipe/Master/IngredientsCategory"><div className={`submenu ${IngredientsCategory ? 'subactive' : ''}`}>- Ingredient Category </div></NavLink></MenuItem>
            <MenuItem><NavLink to="/Recipe/Master/IngredientSubCategory"><div className={`submenu ${IngredientSubCategory ? 'subactive' : ''}`}>- Ingredient Sub Category </div></NavLink></MenuItem>
            <MenuItem><NavLink to="/Recipe/Master/RestaurantType"><div className={`submenu ${RestaurantType ? 'subactive' : ''}`}>- Restaurant Type Master </div></NavLink></MenuItem>
            <MenuItem><NavLink to="/Recipe/Master/Recipe/Listing"><div className={`submenu ${Recipe ? 'subactive' : ''}`}>- Recipe </div></NavLink></MenuItem>


          </SubMenu>
          
          <SubMenu className="mt-2"
            title="Recipe Management"
            icon={<AiOutlineStock size={22} />}
            open={showIngredientSegment}
            onOpenChange={() => setIngredientSegment(!showIngredientSegment)}
          >
            <MenuItem><NavLink to="/Recipe/Management/Ingredient"><div className={`submenu ${Ingredient ? 'subactive' : ''}`}>- Ingredient</div></NavLink></MenuItem>
            
          </SubMenu>
          <SubMenu className="mt-2"
            title="Reports"
            icon={<AiOutlineStock size={22} />}
            open={showIngredientSegment}
            onOpenChange={() => setIngredientSegment(!showIngredientSegment)}
          >
            <MenuItem><NavLink to="/Recipe/Reports/CostReport"><div className={`submenu ${CostReport ? 'subactive' : ''}`}>- Recipe Cost</div></NavLink></MenuItem>
            <MenuItem><NavLink to="/Recipe/Reports/ActiveReport"><div className={`submenu ${ActiveReport ? 'subactive' : ''}`}>- Active Recipe</div></NavLink></MenuItem>
            <MenuItem><NavLink to="/Recipe/Reports/BeverageReport"><div className={`submenu ${BeverageReport ? 'subactive' : ''}`}>- Food and Beverage</div></NavLink></MenuItem>
            
          </SubMenu>
        </Menu>
        <div
          onClick={handleToggle}
          className="sidebar-toggle-btn"
          style={{ left: collapsed ? '100px' : '300px' }}
        >
          <RxHamburgerMenu size={24} />
        </div>
      </ProSidebar>
    </div>
  );
};

export default RecipeSideBar;
