import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import ApiService from '../../services/apiService';
import Select from 'react-select';
import "./reports.css"
import Modal from 'react-bootstrap/Modal';
import { TfiEmail } from "react-icons/tfi";
import { HiOutlinePrinter } from "react-icons/hi2";
import { PiDownloadSimple } from "react-icons/pi";
import { toast } from 'react-toastify';
import moment from 'moment';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { jsPDF } from 'jspdf';
import { BsFileEarmarkPdf, BsFiletypeCsv } from "react-icons/bs";
import { FaFileCircleExclamation } from "react-icons/fa6";
import { RiRefreshLine } from "react-icons/ri";
import 'jspdf-autotable';
import Pagination from '../pagination/pagination';
import { useCurrency } from '../../Layouts/currencyContext';

const StoreTransectionReport = () => {
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [pageSize, setPageSize] = useState(20);
    const [Items, setItems] = useState([]);
    const [pagination, setPagination] = useState();
    const [toDate, setToDate] = useState("13-jun-25");
    const [fromDate, setFromDate] = useState("18-Oct-24");
    const [Location, setLocation] = useState([]);
    const [dateFilter, setdateFilter] = useState(false);
    const [emailModal, setEmailModal] = useState(false);
    const onOpenModal = () => setEmailModal(true);
    const { currencyPrecision } = useCurrency();
    const onCloseModal = () => setEmailModal(false);
    const gridRef = useRef();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const sortOrder = {
        "GRN": 0,
        "Purchase Return": 1,
        "Issue": 2,
        "Issue Received": 3,
        "Issue Return": 4,
        "Adjustment": 5,
    };

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };
    const [formData, setFormData] = useState({
        startdate: "13-jun-23",
        endDate: "13-jun-25",
        itemNo: { label: 'Select ItemNo', value: 'All' },
        ItemDescription: { label: 'Select Item', value: 'All' },
        category: { label: 'Select Category', value: 'All' },
        location: { label: 'Select Location', value: 'All' },
    });
    const handleClearFilters = () => {
        setFormData({
            startdate: "13-jun-23",
            endDate: "13-jun-25",
            itemNo: { label: 'Select ItemNo', value: 'All' },
            ItemDescription: { label: 'Select Item', value: 'All' },
            category: { label: 'Select Category', value: 'All' },
            location: { label: 'Select Location', value: 'All' },
        });
        setFromDate("")
        setToDate(" ")
        setdateFilter(false)
    };
    useEffect(() => {
        const fetchDropdownData = async () => {
            try {
                const apiService = new ApiService();
                let param = `?isActive=true`
                const categoryResponse = await apiService.getApiParamater("GET_CATEGORY", param);
                setCategoryOptions(categoryResponse?.data?.result);
                const Items = await apiService.getApiParamater("GET_ITEM", param);
                let res = await apiService.getApiParamater('GET_WAREHOUSE_MASTER', param)
                setLocation(res?.data?.result)
                setItems(Items?.data?.result);
            } catch (error) {
                console.error('Error fetching dropdown data:', error);
            }
        };
        fetchDropdownData();
        getItems()
    }, []);
    const getItems = async (newPageNo = pagination?.currentPage) => {
        try {
            const apiService = new ApiService();
            const params = new URLSearchParams();
            if (formData.itemNo.value.itemNo) {
                params.append('ItemCode', formData.itemNo.value.itemNo || 'All');
            }
            if (formData.category.value.catName) {
                params.append('Category', formData.category.value.catCode || 'All');
            }
            if (formData.location?.value.description) {
                params.append('Warehouse', formData.location?.value.description || 'All');
            }
            params.append('PageNo', newPageNo || 1);
            params.append('PageSize', pageSize || 20);
            params.append('FromDate', fromDate || "13-jun-2024");
            params.append('ToDate', toDate || "13-jun-2025");
            const res = await apiService.getApiParamater('GET_STOCK_TRANS_REPORT', `?${params.toString()}`);
            setRowData(res?.data?.result || []);
            setPagination(res.data.paginationData);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching items:', error);
            setLoading(false);
        }
    }
    const onBtnCSVExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv({
            fileName: 'PurchaseOrderReport.csv'
        });
    }, []);
    const handleItemChange = (selectedOption) => {
        setFormData({
            ...formData,
            itemNo: selectedOption ? { label: selectedOption.value.itemNo, value: selectedOption.value } : null,
            ItemDescription: selectedOption ? { label: selectedOption.value.itemDescription, value: selectedOption.value } : null,
        });
    }
    const handleCategoryChange = (selectedOption) => {
        setFormData({ ...formData, category: selectedOption });
    }
    const handleFromDate = (e) => {
        setFromDate(e.target.value);
    };
    const handleToDate = (e) => {
        setToDate(e.target.value);
    };
    const handleDateFilterSelection = (e) => {
        setdateFilter(e);
    };
    const handlelocChange = (selectedOption) => {
        setFormData({ ...formData, location: selectedOption });
    };
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    const SendEmail = async () => {
        try {
            if (!formData.email) {
                toast.warning("Enter User Email to send Report")
                return;
            }
            const requestBody = {
                // toEmail: "shahzaibawan864@gmail.com",
                toEmail: formData.email,
                subject: "Stock Transection Report",
                body: `
                <div className='printhead2 mb-5 px-4'>
                    <table>
                        <thead className='dynoTable' style='background-color: #5B93FF; color: #fff;'>
                            <tr>
                                <th>Item Code</th>
                                <th>Description</th>
                                <th>Trans Type</th>
                                <th>Trans Date</th>
                                <th>Trans No</th>
                                <th>Unit Type</th>
                                <th>Unit Price</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            ${rowData?.map((row, index) => `
                                <tr key=${index}>
                                    <td className='m-0 p-0'><input style='width: 100px;' className='p-2' type='text' value='${row.itemCode}' disabled /></td>
                                    <td className='m-0 p-0'><input style='width: 110px;' className='p-2' disabled value='${row.itemDescription}' /></td>
                                    <td className='m-0 p-0'><input style='width: 140px;' className='p-2' disabled value='${row.trans_Type}' /></td>
                                    <td className='m-0 p-0'><input style='width: 140px;' className='p-2' disabled value='${row.trans_Date}' /></td>
                                    <td className='m-0 p-0'><input style='width: 80px;' className='p-2' disabled value='${row.trans_No}' /></td>
                                    <td className='m-0 p-0'><input style='width: 150px;' className='p-2' disabled value='${row.unitType}' /></td>
                                    <td className='m-0 p-0'><input style='width: 120px;' className='p-2' disabled value='${row.unitPrice}' /></td>
                                    <td className='m-0 p-0'><input className='p-2 w-100' type='text' value='${row.amount?.toFixed(currencyPrecision) || 0}' disabled /></td>
                                </tr>
                            `).join('')}
                        </tbody>
                    </table>
                </div>`
            }
            const apiService = new ApiService();
            const response = await apiService.post("SEND_EMAIL", requestBody);
            if (response.status === 200) {
                onCloseModal()
                toast.success("Email send successfully")
                handleClearFilters()
                getItems()
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }
    const groupedData = rowData?.reduce((acc, row) => {
        if (!acc[row.itemCode]) {
            acc[row.itemCode] = [];
        }
        acc[row.itemCode].push(row);
        return acc;
    }, {});
    Object.keys(groupedData).forEach(itemCode => {
        groupedData[itemCode].sort((a, b) => {
            return sortOrder[a.trans_Type] - sortOrder[b.trans_Type];
        });
    });
    function calculateTotals(rows) {
        return rows.reduce(
            (acc, row) => {
                acc.totalQty += row.quantity;
                acc.totalAmount += row.amount;
                return acc;
            },
            { totalQty: 0, totalAmount: 0 }
        );
    }
    return (
        <div className='p-4'>
            <div className='gridBg m-0 row' style={{ borderRadius: "16px 16px 16px 16px" }}>
                <div className='col-8'>
                    <div className='row'>
                        <div className='col-4'>
                            <Select
                                className="p-0"
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 5,
                                    padding: 9
                                })}
                                menuPortalTarget={document.body}
                                styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                        color: state.isFocused ? '#000 ' : '#000',
                                        ':hover': {
                                            backgroundColor: '#F5F5F5',
                                            color: '#000 ',
                                        },
                                    }),
                                }}
                                value={formData?.category}
                                onChange={(selectedOption) => handleCategoryChange(selectedOption)}
                                options={categoryOptions?.map((CatOpt) => ({ value: CatOpt, label: CatOpt?.catName }))}
                            />
                        </div>
                        <div className='col-4'>
                            <Select
                                className="p-0"
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 5,
                                    padding: 9
                                })}
                                menuPortalTarget={document.body}
                                styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                        color: state.isFocused ? '#000 ' : '#000',
                                        ':hover': {
                                            backgroundColor: '#F5F5F5',
                                            color: '#000 ',
                                        },
                                    }),
                                }}
                                value={formData.itemNo}
                                onChange={(selectedOption) => handleItemChange(selectedOption)}
                                options={Items?.map((itm) => ({ value: itm, label: itm?.itemNo }))}
                            />
                        </div>
                        <div className='col-4'>
                            <Select
                                className="p-0"
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 5,
                                    padding: 9
                                })}
                                menuPortalTarget={document.body}
                                styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                        color: state.isFocused ? '#000 ' : '#000',
                                        ':hover': {
                                            backgroundColor: '#F5F5F5',
                                            color: '#000 ',
                                        },
                                    }),
                                }}
                                value={formData.ItemDescription}
                                onChange={(selectedOption) => handleItemChange(selectedOption)}
                                options={Items?.map((itm) => ({ value: itm, label: itm?.itemDescription }))}
                            />
                        </div>
                    </div>
                    <div className='row mt-2'>
                        <div className='col-4'>
                            <Select
                                className="p-0"
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 5,
                                    padding: 9
                                })}
                                menuPortalTarget={document.body}
                                styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                        color: state.isFocused ? '#000 ' : '#000',
                                        ':hover': {
                                            backgroundColor: '#F5F5F5',
                                            color: '#000 ',
                                        },
                                    }),
                                }}
                                value={formData?.location}
                                onChange={(selectedOption) => handlelocChange(selectedOption)}
                                options={Location?.map((loc) => ({ value: loc, label: loc?.description }))}
                            />
                        </div>
                        <div className='col-4'>
                            <input
                                type='date'
                                className="form-Input w-100"
                                value={fromDate}
                                name="fromDate"
                                disabled={!dateFilter}
                                onChange={handleFromDate}
                                placeholder='From  Date '
                            />
                        </div>
                        <div className='col'>
                            <input
                                type='date'
                                disabled={!dateFilter}
                                className="form-Input w-100"
                                placeholder='To  Date '
                                value={toDate}
                                name="toDate"
                                onChange={handleToDate}
                            />
                        </div>
                        <div className='col gap-3 mt-1 end'>
                            <input className="form-check-input"
                                type="checkbox"
                                id="dateFilter" style={{ width: "20px", height: "20px" }}
                                name="dateFilter"
                                checked={dateFilter}
                                onChange={(e) => handleDateFilterSelection(e.target.checked)} />
                            <label className=''>Date Filter</label>
                        </div>
                    </div>
                </div>
                <div className='col-1'></div>
                <div className='col-3 '>
                    <div onClick={() => getItems()} className='mainbtnGry flex-end gap-2'>
                        <FaFileCircleExclamation color='#fff' className='center mt-1 mx-2' />
                        <div className='center'>Search</div>
                    </div>
                    <div onClick={() => handleClearFilters()} className='mainbtnRed flex-end mt-2 gap-2'>
                        <RiRefreshLine color='#fff' className='center mt-1 mx-2' />
                        <div className='center'>Reset</div>
                    </div>
                </div>
            </div>
            <div className='d-flex justify-content-between p-2 gridBg mt-2' style={{ borderRadius: "8px 8px 0px 0px " }}>
                <div className='mainInvHead center' >Stock Transection Report</div>
                <div className='d-flex gap-3 end'>
                    <div className='mainbtnGry flex-end gap-2'>
                        <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                            <DropdownToggle tag="span" data-toggle="dropdown" aria-expanded={dropdownOpen}>
                                <PiDownloadSimple size={25} />
                            </DropdownToggle>
                            <DropdownMenu className='mt-3'>
                                <DropdownItem onClick={onBtnCSVExport} className='gap-3 d-flex center'><BsFiletypeCsv /><div>Export CSV</div></DropdownItem>
                                <DropdownItem className='gap-3 d-flex center'><BsFileEarmarkPdf /><div>Export PDF</div></DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                    <div className='mainbtnRed flex-end '><HiOutlinePrinter size={25} /></div>
                    <div className='mainbtn flex-end gap-1' onClick={onOpenModal}>
                        <div className='center'><TfiEmail size={25} /></div>
                    </div>
                </div>
            </div>
            <div className='gridshad' style={{ height: '550px', overflow: 'auto' }}>
                <table class="custom-table">
                    <thead style={{ background: '#C4C8CB' }}>
                        <tr>
                            <th>Item Code</th>
                            <th>Item Description</th>
                            <th>Trans Type</th>
                            <th>Trans Date</th>
                            <th>Trans No</th>
                            <th>Warehouse</th>
                            <th>Quantity</th>
                            <th>Unit Type</th>
                            <th>Unit Price</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(groupedData).map((itemCode) => {
                            const rows = groupedData[itemCode];
                            let row = rows
                            const totals = calculateTotals(row);

                            return (
                                <>
                                    {/* Row for Item Code and Description */}
                                    <tr>
                                        <th rowSpan={row.length + 2}>{row[0].itemCode}</th>
                                        <td rowSpan={row.length + 2}>{row[0].itemDescription}</td>

                                        {/* Render first row's transaction */}
                                        <td>{row[0].trans_Type}</td>
                                        <td>{moment(row[0].trans_Date).format('DD_MMM_YYYY')}</td>
                                        <td>{row[0].trans_No}</td>
                                        <td>{row[0].warehouse}</td>
                                        <td>{row[0].quantity}</td>
                                        <td>{row[0].unitType}</td>
                                        <td>{row[0].unitPrice}</td>
                                        <td>{row[0].amount}</td>
                                    </tr>
                                    {/* Render the remaining row for this item */}
                                    {row.slice(1).map((row, rowIndex) => (
                                        <tr key={rowIndex}>
                                            <td>{row.trans_Type}</td>
                                            <td>{moment(row.trans_Date).format('DD_MMM_YYYY')}</td>
                                            <td>{row.trans_No}</td>
                                            <td>{row.warehouse}</td>
                                            <td>{row.quantity}</td>
                                            <td>{row.unitType}</td>
                                            <td>{row.unitPrice}</td>
                                            <td>{row.amount}</td>
                                        </tr>
                                    ))}
                                    <tr class="close-balance-row">
                                        <td colSpan={4}><strong>Close Balance</strong></td>
                                        <td>{totals.totalQty}</td>
                                        <td colSpan={2}></td>
                                        <td>{totals.totalAmount}</td>
                                    </tr>
                                    {/* Total Row */}
                                    <tr class="total-row">
                                        <td colSpan={7}><strong>Total</strong></td>
                                        <td>{totals.totalAmount}</td>
                                    </tr>
                                </>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <Pagination pageSize={pageSize} setPageSize={setPageSize} pagination={pagination} getItems={getItems} />
            <Modal size="md"
                show={emailModal} onHide={() => onCloseModal()}
                onRequestClose={() => onCloseModal()}
                contentLabel='Email Modal'
                style={customStyles} closeButton
            >
                <Modal.Header closeButton>
                    <Modal.Title className='center'>Send Email</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <input
                            type='email'
                            className="form-Input w-100"
                            id="validationCustom02"
                            value={formData.email}
                            name="email"
                            onChange={handleInputChange}
                            placeholder='Enter Email'
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className='end p-3'>
                    <button className='mainbtnGry' onClick={onCloseModal}>Close</button>
                    <button className='mainbtnRed' onClick={SendEmail} >Save</button>
                </Modal.Footer>
            </Modal >
        </div>
    );
};
export default StoreTransectionReport;
const customStyles = {
    content: {
        width: '30%', // Set the desired width
        height: '75%', // Set the desired 
        top: '50%', // Center vertically
        left: '50%', // Center horizontally
        right: '50%', // Center horizontally
        transform: 'translate(-50%, -50%)', // Center both vertically and horizontally
    },
};