import React, { useState, useEffect, useRef, Component } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import ApiService from '../../services/apiService';
import { useNavigate } from 'react-router-dom';
import moment from 'moment/moment';
import { FaRegEye } from "react-icons/fa6";
import { AiOutlinePrinter } from "react-icons/ai";
import { clearRows } from '../../../Redux/actions/rowActions';
import { useDispatch } from 'react-redux';
import { hasPermission } from "../../services/roleService"
import { IoAddSharp } from "react-icons/io5";
import { GrEdit } from "react-icons/gr";
import Pagination from '../Pagination/pagination';

const RecipeListing = () => {
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pageSize, setPageSize] = useState(20);
    const [pagination, setPagination] = useState();
    const [selectedRow, setSelectedRow] = useState(null);
    let navigate = useNavigate();
    const gridRef = useRef();
    const [permissions, setPermissions] = useState([]);
    let dispatch = useDispatch()

    const checkPermission = (moduleName, action) => {
        return hasPermission(moduleName, action, permissions);
    };
    // const canEditItemMaster = checkPermission('Purchase Requisition', 'edit');
    // const canCreateItemMaster = checkPermission('Purchase Requisition', 'create');

    const canEditItemMaster = true;
    const canCreateItemMaster = true;
    useEffect(() => {
        getItems();
        const storedPermissions = JSON.parse(localStorage.getItem('RoleAccess')) || [];
        setPermissions(storedPermissions);
    }, []);
    useEffect(() => {
        if (pageSize) {
            getItems();
        }
    }, [pageSize]);

    const handleEdit = (clickedRowData) => {
        if (clickedRowData || selectedRow) {
            let eye = false
            if (clickedRowData) {
                const updatedData = { ...clickedRowData, eye: eye };
                if (updatedData?.status === 1) {
                    const updatedData = { ...clickedRowData, eye: !clickedRowData.eye?.active };
                    navigate("/Inventory/Requisition/PurchaseRequisition", { state: updatedData });
                } else {
                    navigate("/Inventory/Requisition/PurchaseRequisition", { state: clickedRowData });
                }
            } else {
                const updatedData = { ...selectedRow, eye: eye };
                if (updatedData?.status === 1) {
                    const updatedData = { ...selectedRow, eye: !selectedRow.eye?.active };
                    navigate("/Inventory/Requisition/PurchaseRequisition", { state: updatedData });
                } else {
                    navigate("/Inventory/Requisition/PurchaseRequisition", { state: selectedRow });
                }
            }
        }
    };
    const handlePrint = () => {
        if (selectedRow) {
            navigate(`/Inventory/Requisition/${selectedRow?.requisitionNo}`, { state: selectedRow })
        }
    }
    const handleEye = () => {
        if (selectedRow) {
            const updatedData = { ...selectedRow, eye: !selectedRow.eye?.active };
            navigate("/Inventory/Requisition/PurchaseRequisition", { state: updatedData });
        }
    }
    const getItems = async (newPageNo = pagination?.currentPage) => {
        try {
            const apiService = new ApiService();
            const params = new URLSearchParams();
            params.append('PageNo', newPageNo || 1);
            params.append('PageSize', pageSize || 20);
            params.append('reqType', "Purchase Request");
            const res = await apiService.getApiParamater('GET_PR', `?${params.toString()}`);
            setRowData(res?.data?.result || []);
            setPagination(res.data.paginationData);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching items:', error);
            // Handle error (e.g., show error message to user)
            setLoading(false); // Set loading to false even in case of error
        }
    }
    const dateFormatter = (params) => {
        const date = moment(params.value);
        return date.format("DD-MMM-YYYY");
    };
    const dateComparator = (date1, date2) => {
        const momentDate1 = moment(date1);
        const momentDate2 = moment(date2);
        return momentDate1.isSame(momentDate2) ? 0 : momentDate1.isBefore(momentDate2) ? -1 : 1;
    };
    const columnDefs = [

        { headerCheckboxSelection: true, checkboxSelection: true, headerCheckboxSelectionFilteredOnly: true, suppressMovable: true, width: 50 },
        {
            headerName: 'Code', field: 'recordId', filter: true, floatingfilter: true, suppressMovable: true, width: 180, cellEditor: "agTextCellEditor", cellEditorParams: {
                maxLength: 20,
            }
        },
        { headerName: 'Name', field: 'remarks', filter: true, floatingfilter: true, suppressMovable: true, width: 180 },
        { headerName: 'Description', field: 'location', filter: true, floatingfilter: true, suppressMovable: true, width: 180 },
        { headerName: 'Group', field: 'costCenter1', filter: true, floatingfilter: true, suppressMovable: true, width: 180 },
        { headerName: 'Category', field: 'costCenter1', filter: true, floatingfilter: true, suppressMovable: true, width: 180 },
        { headerName: 'Sub Category', field: 'costCenter1', filter: true, floatingfilter: true, suppressMovable: true, width: 180 },
        { headerName: 'Modified by', field: 'costCenter1', filter: true, floatingfilter: true, suppressMovable: true, width: 200 },
        {
            headerName: "Modified On", field: "requisitionDate", filter: true, floatingfilter: true,
            valueFormatter: dateFormatter,
            comparator: dateComparator, suppressMovable: true, width: 200
        },
    ];
    const onCellClicked = (event) => {
        if (event.colDef.field === 'requisitionNo') {
            const clickedRowData = event.data;
            setSelectedRow(clickedRowData);
            handleEdit(clickedRowData)
        }
    };
    const onSelectionChanged = (event) => {
        const selectedRows = event.api.getSelectedRows();
        setSelectedRow(selectedRows.length === 1 ? selectedRows[0] : null);
    };
    const handlenew = () => {
        navigate('/Recipe/Master/Recipe/Create')
    }
    return (
        <div className='p-4 '>
            <div className='border-box2 p-4'>

                {loading ? (
                    <div>Loading...</div>
                ) : (
                    <>
                        <div className='d-flex justify-contant-between '>
                            <h5 className='col mainInvHead'>Recipe Listing</h5>
                            <div className='d-flex justify-content-end gap-1'>
                                <div
                                    onClick={handleEye}
                                    className={`mainbtnGry flex-end gap-2 ${!selectedRow ? 'disabled' : ''}`}
                                    style={{ cursor: selectedRow ? 'pointer' : 'not-allowed' }}
                                >
                                    <FaRegEye color='#fff' className='center mt-1' />
                                    <div className='center'>View</div>
                                </div>
                                <div
                                    onClick={selectedRow && selectedRow.status === 1 ? handleEdit : null}
                                    className={`mainbtnSecondary flex-end gap-2 ${!selectedRow || selectedRow.status !== 1 ? 'disabled' : ''
                                        }`}
                                    style={{
                                        cursor: selectedRow && selectedRow.status === 1 ? 'pointer' : 'not-allowed',
                                    }}
                                >
                                    <GrEdit color='#fff' className='center mt-1 ' />
                                    <div className='center'>Edit</div>
                                </div>
                                <div
                                    onClick={handlePrint}
                                    className={`mainbtnRed flex-end gap-2 ${!selectedRow ? 'disabled' : ''}`}
                                    style={{ cursor: selectedRow ? 'pointer' : 'not-allowed' }}
                                >
                                    <AiOutlinePrinter color='#fff' className='center mt-1 ' />
                                    <div className='center'>Print</div>
                                </div>
                                <div className='col center'>
                                    {canCreateItemMaster === true ?
                                        <button className='mainbtn ' type="submit" onClick={() => handlenew()}>
                                            <IoAddSharp color='#fff' className='center mt-1' /> <div>Add</div>
                                        </button>
                                        :
                                        <button className='mainbtn ' type="submit" style={{ cursor: "not-allowed" }}>
                                            <IoAddSharp color='#fff' className='center mt-1' /> <div>Add</div></button>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='gridshad  mt-3'>
                            <div className="ag-theme-quartz" style={{ height: 650 }}>
                                <AgGridReact
                                    getRowStyle={(params) => {
                                        if (params.node.rowIndex % 2 === 0) {
                                            return { background: '#F0EDF5' };
                                        } else {
                                            return { background: '#fff' };
                                        }
                                    }}
                                    rowData={rowData}
                                    enableFilter={true}
                                    floatingFilter={true}
                                    columnDefs={columnDefs}
                                    onCellClicked={onCellClicked}
                                    onSelectionChanged={onSelectionChanged}
                                    editType='stringedit'
                                />
                            </div>
                            <Pagination pageSize={pageSize} setPageSize={setPageSize} pagination={pagination} getItems={getItems} />
                        </div>
                    </>
                )}
            </div>
        </div>

    );
};
export default RecipeListing;
