import React, { useState, useEffect } from 'react';
import moment from 'moment';

const InvoiceViewGrnList = ({ GrnList }) => {
    const todayDate = new Date().toISOString().substr(0, 10);
    const totalAmount = GrnList?.reduce((sum, row) => sum + (parseFloat(row?.amount) || 0), 0)
    return (
        <div>
            <div className='table-container' style={{ overflowX: 'auto', height: "auto" }}>
                <table>
                    <thead className='dynoTable'>
                        <tr>
                            <th></th>
                            <th>Grn No</th>
                            <th>PO no</th>
                            <th>Invoice No</th>
                            <th>Ref NO</th>
                            <th>GRN Date</th>
                            <th>Currency</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(GrnList) && GrnList?.map((row, index) => (
                            <tr key={index}>
                                <td className='m-0 text-center  p-1' style={{ border: "1px solid rgba(118, 118, 118, 0.3)" }}>
                                    <input className='form-check-input' checked={row.selected === 1} disabled
                                    type="checkbox" style={{ width: "26.81px", height: "26.81px" }} />
                                </td>
                                <td className='m-0 p-0'><input className='p-2' type="text" value={row.grnNo ? row.grnNo : " "} disabled style={{ width: "220px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' type="text" value={row.pOrderNo ? row.pOrderNo : " "} disabled style={{ width: "220px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' type="text" value={row.invoiceNo} disabled style={{ width: "221px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' type="text" value={row.invoiceRef} disabled style={{ width: "221px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled type="text" value={moment(row.delivery_Date || todayDate).format('ll')} style={{ width: "220px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' type="text" value={row.currencyCode} disabled style={{ width: "220px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled type="text" value={row.amount} style={{ width: "220px" }} /></td>
                            </tr>
                        ))
                        }
                    </tbody>
                </table>
            </div>
            <div className='d-flex justify-content-center boxs'>
                <div className='d-flex gap-2'>
                    <div className="totalhead center">Total Amount</div>
                    <div className='field'>{totalAmount || "---"}</div>
                </div>
            </div>
        </div>
    );
};

export default InvoiceViewGrnList;
