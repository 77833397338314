import React from 'react';

const InvoiceViewItemList = ({ GrnItemData }) => {

    const totalAmount = GrnItemData?.reduce((sum, row) => sum + (parseFloat(row?.amount) || 0), 0)
    return (
        <div className='table-container p-2 ' style={{ overflowX: 'auto', height: "auto" }}>
            <table>
                <thead className='dynoTable'>
                    <tr>
                        <th>Sr no</th>
                        <th>Item No</th>
                        <th>Description</th>
                        <th>Qty</th>
                        <th>Packing UOM</th>
                        <th>Inventory Qty</th>
                        <th>Discount</th>
                        <th>Unit Price</th>
                        <th>VAT %</th>
                        <th>Vat Desc</th>
                        <th>VAT Amount</th>
                        <th>Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {Array.isArray(GrnItemData) && GrnItemData?.map((row, index) => (
                        <tr key={index}>
                            <td className='m-0 p-0'><input className='p-2' disabled type="text" value={row.srno} style={{ width: "80px" }} /></td>
                            <td className='m-0 p-0'><input className='p-2' disabled type="text" value={row.itemCode} style={{ width: "150px" }} /></td>
                            <td className='m-0 p-0'><input className='p-2' disabled type="text" value={row.itemDescription} style={{ width: "180px" }} /></td>
                            <td className='m-0 p-0'><input className='p-2' disabled type="text" value={row.qty} style={{ width: "80px" }} /></td>
                            <td className='m-0 p-0'><input className='p-2' disabled type="text" value={row.uom} style={{ width: "150px" }} /></td>
                            <td className='m-0 p-0'><input className='p-2' disabled type="text" value={row.qty * row.convToInvUOM} style={{ width: "150px" }} /></td>
                            <td className='m-0 p-0'><input className='p-2' disabled type="text" value={0} style={{ width: "100px" }} /></td>
                            <td className='m-0 p-0'><input className='p-2' disabled type="text" value={row.unitPrice} style={{ width: "100px" }} /></td>
                            <td className='m-0 p-0'><input className='p-2' disabled type="text" value={row.vatPerc + " %"} style={{ width: "100px" }} /></td>
                            <td className='m-0 p-0'><input className='p-2' disabled type="text" value={row?.vatDesc} style={{ width: "180px" }} /></td>
                            <td className='m-0 p-0'><input className='p-2' disabled type="text" value={row.vatAmount} style={{ width: "120px" }} /></td>
                            <td className='m-0 p-0'><input className='p-2' disabled type="text" value={row.amount} style={{ width: "128px" }} /></td>
                        </tr>
                    ))
                    }
                </tbody>
            </table>
            <div className='d-flex justify-content-center boxs'>
                <div className='d-flex gap-2'>
                    <div className="totalhead center">Total Invoiced </div>
                    <div className='field'>{totalAmount || "---"}</div>
                </div>
            </div>
        </div>
    );
};

export default InvoiceViewItemList;
