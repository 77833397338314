import React, { useState, useEffect, useRef, Component } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import ApiService from '../../services/apiService';
import { useNavigate } from 'react-router-dom';
import moment from 'moment/moment';
import Button from 'react-bootstrap/Button';
import { ReactComponent as Edit } from '../../Assets/svg/edit.svg'
import { IoLink } from "react-icons/io5";
import { TbBallpenOff } from "react-icons/tb";
import { IoAddSharp } from "react-icons/io5";
import { FaRegEye } from "react-icons/fa6";
import Pagination from '../pagination/pagination';
const IssueReturn = ({ onRequisitionData, itemData }) => {
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(true);
    let navigate = useNavigate();
    const [pageSize, setPageSize] = useState(20);
    const [pagination, setPagination] = useState();
    const [selectedRow, setSelectedRow] = useState(null);
    const [permissions, setPermissions] = useState([]);
    const [selectedtem, setSelectedItem] = useState(null);
    const apiService = new ApiService();
    useEffect(() => {
        if (pageSize) {
            getItems();
        }
    }, [pageSize]);

    useEffect(() => {
        getItems();
        const storedPermissions = JSON.parse(localStorage.getItem('RoleAccess')) || [];
        const permissions = storedPermissions?.filter(acc => acc.formName === "Issue Return");
        setPermissions(permissions[0]);
    }, []);

    const handleEdit = (data) => {
        // navigate("/Inventory/Requisition/PurchaseRequisition", { state: data })
    };
    const handlePrint = (data) => {
        // navigate(`/Inventory/Requisition/PurchaseRequisition/${data?.reqositionNo}`, { state: data })
    };
    const handleEye = (data) => {
        if (selectedRow?.issueRNo) {
            const updatedData = { ...selectedRow, eye: !selectedRow.eye?.active };
            debugger
            navigate(`/Inventory/Issue/Return/${updatedData?.issueRNo}`, { state: updatedData })
        } else if (data?.issueRNo) {
            const updatedData = { ...selectedRow, eye: !selectedRow.eye?.active };
            debugger
            navigate(`/Inventory/Issue/Return/${updatedData?.issueRNo}`, { state: updatedData })
        }
    };
    const getItems = async (newPageNo = pagination?.currentPage) => {
        try {
            const params = new URLSearchParams();
            params.append('PageNo', newPageNo || 1);
            params.append('PageSize', pageSize || 20);
            const res = await apiService.getApiParamater('GET_ISSUE_RETURN', `?${params.toString()}`);
            setPagination(res.data.paginationData)
            let responseSort = res?.data?.result
            setRowData(responseSort || []);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching items:', error);
            setLoading(false); 
        }
    }
    const dateFormatter = (params) => {
        const date = moment(params.value);
        return date.format("DD-MMM-YYYY");
    };
    const dateComparator = (date1, date2) => {
        const momentDate1 = moment(date1);
        const momentDate2 = moment(date2);
        return momentDate1.isSame(momentDate2) ? 0 : momentDate1.isBefore(momentDate2) ? -1 : 1;
    };
    const columnDefs = [
        { headerCheckboxSelection: true, checkboxSelection: true, headerCheckboxSelectionFilteredOnly: true, suppressMovable: true, width: 50 },
        { headerName: 'Issue Return No', field: 'issueRNo', filter: true, floatingFilter: false },
        { headerName: 'Issue  No', field: 'issueNo', filter: true, floatingFilter: false },
        {
            headerName: "Date", field: "irDate", filter: true, floatingfilter: true,
            valueFormatter: dateFormatter,
            comparator: dateComparator,
        },
        { headerName: 'Department', field: 'costCenter1', filter: true, floatingFilter: false },
        { headerName: 'Ware House', field: 'warehouse', filter: true, floatingFilter: false },
        { headerName: 'Currency', field: 'currencyCode', filter: true, floatingFilter: false },
        { headerName: 'Issued By', field: 'approvalUser', filter: true, floatingFilter: false },
        { headerName: 'Remarks', field: 'remarks', filter: true, floatingFilter: false },
        {
            headerName: 'Status', field: 'status', filter: true, floatingfilter: true,
            cellRenderer: function (params) {
                if (params.value === 0 || params.value === null) {
                    return (<button class="btn pend ">Pending</button>);
                }
                else if (params.value === 1 || params.value === null) {
                    return (<button class="btn aproved">Approved</button>);
                }
                else if (params.value === 5 || params.value === null) {
                    return (<button class="btn hold">Hold</button>);
                }
                else if (params.value === 3 || params.value === null) {
                    return (<button class="btn reject">Rejected</button>);
                } else {
                    return params.value;
                }
            }
        },
    ];
    const handlenew = () => {
        navigate('/Inventory/Issue/Return/Create')
    }
    const onCellClicked = (event) => {
        if (event.colDef.field === 'issueRNo') {
            const clickedRowData = event.data;
            setSelectedRow(clickedRowData);
            handleEye(clickedRowData)
        }
    };
    const onSelectionChanged = async (event) => {
        const selectedRows = event.api.getSelectedRows();
        setSelectedRow(selectedRows.length === 1 ? selectedRows[0] : null);
        const params = new URLSearchParams();
        params.append('DocNo', selectedRows[0]?.issueRNo);
        params.append('DocumentType', "IReturn");
        try {
            const res = await apiService.getApiParamater('GET_DOC_STATUS_EDIT', `?${params.toString()}`);
            const statuses = res?.data?.result?.map(item => item.status);
            const allAreZero = statuses.every(status => status === 0);
            const anyIsFive = statuses.some(status => status === 2);
            if (allAreZero || anyIsFive) {
                setSelectedItem(selectedRows.length === 1 ? selectedRows[0] : null);
            } else {
                console.warn("No valid status to set the item.");
                setSelectedItem(null);
            }
        } catch (error) {
            setSelectedItem(null)
            console.error("Error fetching document status:", error);
        }
    };
    return (
        <div className='p-4'>
            {loading ? (
                <div>Loading...</div>
            ) : (
                <>
                    <div className='border-box2  mt-3 p-4 justify-content-between d-flex align-items-center'>
                        <h4 className='mainInvHead'>
                            Issue Return
                        </h4>
                        <div className='d-flex justify-content-end gap-1'>
                            <div
                                onClick={handleEye}
                                className={`mainbtnGry flex-end gap-2 ${!selectedRow ? 'disabled' : ''}`}
                                style={{ cursor: selectedRow ? 'pointer' : 'not-allowed' }}
                            >
                                <FaRegEye color='#fff' className='center mt-1' />
                                <div className='center'>View</div>
                            </div>
                            {permissions?.canAdd === true ?
                                <div className='col center'>
                                    <button className='mainbtn ' type="submit" onClick={() => handlenew()}>
                                        <IoAddSharp color='#fff' className='center mt-1' /> <div>Add</div>
                                    </button>
                                </div>
                                : ''
                            }
                        </div>
                    </div>
                    <div className='brderbox'>
                        <div className="ag-theme-quartz" style={{ height: 650 }}>
                            <AgGridReact
                                getRowStyle={(params) => {
                                    if (params.node.rowIndex % 2 === 0) {
                                        return { background: '#F0EDF5' };
                                    } else {
                                        return { background: '#fff' };
                                    }
                                }}
                                rowData={rowData}
                                columnDefs={columnDefs}
                                suppressExcelExport={true}
                                onCellClicked={onCellClicked}
                                onSelectionChanged={onSelectionChanged}
                            />
                        </div>
                    </div>
                    <Pagination pageSize={pageSize} setPageSize={setPageSize} pagination={pagination} getItems={getItems} />
                </>
            )}
        </div>
    );
};

export default IssueReturn;
