import React, { useState, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { RiRefreshLine } from "react-icons/ri";
import { GrEdit } from "react-icons/gr";
import { FaSave } from "react-icons/fa";
import AsyncSelect from 'react-select/async';
import Pagination from '../Pagination/pagination';
import AllergenGridMaster from './Grid';
const AllergenMaster = () => {
    const [catCode, setCatCode] = useState()
    const [pagination, setPagination] = useState();
    const [rowData, setRowData] = useState([]);
    const [pageSize, setPageSize] = useState(20);
    const [CostTab, setCostTab] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const [formData, setFormData] = useState({
        recordId: "",
        catcod: "",
        Category: "",
        InventoryAccounts: [],
        grnAccount: [],
        wipAccount: [],
        invoiceAccount: [],
        adjustmentAccount: [],
        cgsAccount: [],
        issueAccount: [],
        wastageAccount: [],
    });
    const handleIssueAccountChange = (selectedOption) => {
        setFormData({ ...formData, issueAccount: selectedOption });
    }
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    const handleGRNAccountChange = (selectedOption) => {
        setFormData({ ...formData, grnAccount: selectedOption });
    }
    const handleAdjustmentAccountChange = (selectedOption) => {
        setFormData({ ...formData, adjustmentAccount: selectedOption });
    }
    const handleWIPAccountChange = (selectedOption) => {
        setFormData({ ...formData, wipAccount: selectedOption });
    }
    const handleInvoiceAccountChange = (selectedOption) => {
        setFormData({ ...formData, invoiceAccount: selectedOption });
    }
    const handleCGSAccountChange = (selectedOption) => {
        setFormData({ ...formData, cgsAccount: selectedOption });
    }
    const handleWastageAccountChange = (selectedOption) => {
        setFormData({ ...formData, wastageAccount: selectedOption });
    }
    const handleInventoryAccountChange = (selectedOption) => {
        setFormData({ ...formData, InventoryAccounts: selectedOption });
    }
    const handleClearFilters = () => {
        setFormData({
            recordId: "",
            catcod: "",
            Category: "",
            InventoryAccounts: [],
            grnAccount: [],
            wipAccount: [],
            invoiceAccount: [],
            adjustmentAccount: [],
            cgsAccount: [],
            issueAccount: [],
            wastageAccount: [],
        })
        setSelectedRow(null)
    };
    return (
        <div className='px-4 pt-3'>

            <div className='py-lg-2'>
                <div className='gridBg p-3' style={{ borderRadius: "16px 16px 16px 16px" }}>
                    <div className='d-flex justify-contant-between mb-3'>
                        <h5 className='col mainInvHead'>Allergen Master</h5>
                        <div className='d-flex justify-content-end gap-3'>
                            <div
                                className={`mainbtnSecondary flex-end gap-2 ${!selectedRow ? 'disabled' : ''}`}
                                style={{ cursor: selectedRow ? 'pointer' : 'not-allowed' }}
                            >
                                <GrEdit color='#fff' className='center mt-1 mx-2' />
                                <div className='center'>Edit</div>
                            </div>
                            <div className=''>
                                <button className='mainbtn '>
                                    <FaSave className='center mt-1 mx-2' /> <div>Save</div>
                                </button>
                            </div>
                            <button className='mainbtnRed' onClick={handleClearFilters}><RiRefreshLine className='center mt-1 mx-2' /><div>Reset</div></button>
                        </div>
                    </div>
                    <div className="form-group row d-flex justify-content-between pb-3">
                        <div className="col-lg-4 d-flex justify-content-between">
                            <label htmlFor="validationCustom01" className="form-label  pt-2 start">ID</label>
                            <input
                                type="text"
                                className="form-Input w-75"
                                id="validationCustom01"
                                name="catCode"
                                value={catCode}
                                required
                            />
                        </div>
                        <div className="col-lg-4 d-flex justify-content-between">
                            <label htmlFor="validationCustom01" className="form-label  pt-2 start">Code</label>
                            <input
                                type="text"
                                className="form-Input w-75"
                                id="validationCustom01"
                                name="Category"
                                value={formData.Category}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="col-lg-4 d-flex justify-content-between">
                            <label htmlFor="validationCustom01" className="form-label  pt-2 start">Description</label>
                            <input
                                type="text"
                                className="form-Input w-75"
                                id="validationCustom01"
                                name="Category"
                                value={formData.Category}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="form-group row d-flex justify-content-between">
                        <div className="col-lg-4 d-flex justify-content-between">
                            <label htmlFor="validationCustom01" className="form-label  pt-2 start">vegetarian</label>
                            <input
                                type="text"
                                className="form-Input w-75"
                                id="validationCustom01"
                                name="catCode"
                                value={catCode}
                                required
                            />
                        </div>
                        <div className="col-lg-4 d-flex justify-content-between">
                            <label htmlFor="validationCustom01" className="form-label  pt-2 start">Non Vegetarian</label>
                            <input
                                type="text"
                                className="form-Input w-75"
                                id="validationCustom01"
                                name="Category"
                                value={formData.Category}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="col-lg-4 d-flex justify-content-between">
                            <label htmlFor="validationCustom01" className="form-label  pt-2 start">vegan</label>
                            <input
                                type="text"
                                className="form-Input w-75"
                                id="validationCustom01"
                                name="Category"
                                value={formData.Category}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='gridBg mt-3'>
                <AllergenGridMaster rowData={rowData?.result}
                    pageSize={pageSize}
                    setSelectedRow={setSelectedRow}
                    pagination={pagination} />
                <Pagination pageSize={pageSize} setPageSize={setPageSize} pagination={pagination} />
            </div>
        </div>
    )
}
export default AllergenMaster