import { useNavigate, useLocation } from 'react-router-dom';
import ApiService from '../../services/apiService';
import React, { useState, useEffect } from 'react';
import CategoryGridMaster from './categoryGrid';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { getUserId, getName } from '../../../Redux/selecters';
import { hasPermission } from "../../services/roleService"
import { ClipLoader } from 'react-spinners';
import { RiRefreshLine } from "react-icons/ri";
import { GrEdit } from "react-icons/gr";
import { FaSave } from "react-icons/fa";
import AsyncSelect from 'react-select/async';
import Pagination from '../pagination/pagination';
const CategoryMaster = () => {
    const [catCode, setCatCode] = useState()
    const userName = useSelector(getName);
    const [pagination, setPagination] = useState();
    const [page, setPage] = useState(1);
    const [rowData, setRowData] = useState([]);
    const [load, setLoad] = useState(false);
    const [pageSize, setPageSize] = useState(20);
    const [CostTab, setCostTab] = useState(false);
    const [accounts, setAccounts] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(true);
    const [loader, setLoader] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [accountsList, setAccountsList] = useState([]);
    const [permissions, setPermissions] = useState([]);
    useEffect(() => {
        fetchAccountData()
        fetchCategoryData();
        const storedPermissions = JSON.parse(localStorage.getItem('RoleAccess')) || [];
        setPermissions(storedPermissions);
    }, []);
    useEffect(() => {
        fetchCategoryData();
    }, [pageSize]);
    const [formData, setFormData] = useState({
        recordId: "",
        catcod: "",
        Category: "",
        InventoryAccounts: [],
        grnAccount: [],
        wipAccount: [],
        invoiceAccount: [],
        adjustmentAccount: [],
        cgsAccount: [],
        issueAccount: [],
        wastageAccount: [],
    });
    const checkPermission = (moduleName, action) => {
        return hasPermission(moduleName, action, permissions);
    };
    // const canEditItemMaster = checkPermission('Category Master', 'edit');
    // const canCreateItemMaster = checkPermission('Category Master', 'create');
    const canEditItemMaster = true;
    const canCreateItemMaster = true;
    const handleEdit = (clickedRowData) => {
        
        if (clickedRowData.catCode) {
            setSelectedRow(clickedRowData)
            setCatCode(clickedRowData?.catCode)
            const selectedInvntory = accountsList?.find(inv => inv.accountCode === clickedRowData?.inventoryAccountCode)
            const selectedAdjustmentAccount = accountsList?.find(adj => adj.accountCode === clickedRowData?.adjustmentAccountCode)
            const selectedGrnAccountCode = accountsList?.find(grn => grn.accountCode === clickedRowData?.grnAccountCode)
            const selectedissueAccount = accountsList?.find(issue => issue.accountCode === clickedRowData?.issueAccountCode)
            const selectedwastageAccount = accountsList?.find(waste => waste.accountCode === clickedRowData?.wastageAccountCode)
            const selectedcgsAccount = accountsList?.find(cgs => cgs.accountCode === clickedRowData?.cgsAccountCode)
            const selectedinvoiceAccountCode = accountsList?.find(invoice => invoice.accountCode === clickedRowData?.invoiceAccountCode)
            const selectedwipAccount = accountsList?.find(wip => wip.accountCode === clickedRowData?.workInProgressAccountCode)
            
            setFormData({
                recordId: clickedRowData?.recordId || "",
                catcod: clickedRowData?.catCode || "",
                Category: clickedRowData?.catName || "",
                InventoryAccounts: { label: selectedInvntory?.accountName, value: selectedInvntory },
                adjustmentAccount: { label: selectedAdjustmentAccount?.accountName, value: selectedAdjustmentAccount },
                grnAccount: { label: selectedGrnAccountCode?.accountName, value: selectedGrnAccountCode },
                issueAccount: { label: selectedissueAccount?.accountName, value: selectedissueAccount },
                wastageAccount: { label: selectedwastageAccount?.accountName, value: selectedwastageAccount },
                cgsAccount: { label: selectedcgsAccount?.accountName, value: selectedcgsAccount },
                invoiceAccount: { label: selectedinvoiceAccountCode?.accountName, value: selectedinvoiceAccountCode },
                wipAccount: { label: selectedwipAccount?.accountName, value: selectedwipAccount },
            })
        } else if (selectedRow) {
            setCatCode(selectedRow?.catCode)
            const selectedInvntory = accountsList?.find(inv => inv.accountCode === selectedRow?.inventoryAccountCode)
            const selectedAdjustmentAccount = accountsList?.find(adj => adj.accountCode === selectedRow?.adjustmentAccountCode)
            const selectedGrnAccountCode = accountsList?.find(grn => grn.accountCode === selectedRow?.grnAccountCode)
            const selectedissueAccount = accountsList?.find(issue => issue.accountCode === selectedRow?.issueAccountCode)
            const selectedwastageAccount = accountsList?.find(waste => waste.accountCode === selectedRow?.wastageAccountCode)
            const selectedcgsAccount = accountsList?.find(cgs => cgs.accountCode === selectedRow?.cgsAccountCode)
            const selectedinvoiceAccountCode = accountsList?.find(invoice => invoice.accountCode === selectedRow?.invoiceAccountCode)
            const selectedwipAccount = accountsList?.find(wip => wip.accountCode === selectedRow?.workInProgressAccountCode)
            
            setFormData({
                recordId: selectedRow?.recordId || "",
                catcod: selectedRow?.catCode || "",
                Category: selectedRow?.catName || "",
                InventoryAccounts: { label: selectedInvntory?.accountName, value: selectedInvntory },
                adjustmentAccount: { label: selectedAdjustmentAccount?.accountName, value: selectedAdjustmentAccount },
                grnAccount: { label: selectedGrnAccountCode?.accountName, value: selectedGrnAccountCode },
                issueAccount: { label: selectedissueAccount?.accountName, value: selectedissueAccount },
                wastageAccount: { label: selectedwastageAccount?.accountName, value: selectedwastageAccount },
                cgsAccount: { label: selectedcgsAccount?.accountName, value: selectedcgsAccount },
                invoiceAccount: { label: selectedinvoiceAccountCode?.accountName, value: selectedinvoiceAccountCode },
                wipAccount: { label: selectedwipAccount?.accountName, value: selectedwipAccount },
            })
        }
    };
    const loadOptions = async (inputValue, callback) => {
        const options = await fetchAccountData(inputValue, 1);
        callback(options);
    };
    const loadMoreOptions = async () => {
        if (!hasMore || loader) return;
        setPage(prevPage => prevPage + 1);
        await fetchAccountData("", page + 1);
    };
    useEffect(() => {
        fetchAccountData("", 1);
    }, []);
    const fetchAccountData = async (inputValue = "", pageNumber = 1) => {
        if (loader) return;
        setLoader(true);
        try {
            const apiService = new ApiService();
            const params = new URLSearchParams();
            params.append('PageNo', pageNumber || 1);
            params.append('PageSize', 20);
            params.append('AccountName', inputValue || '');
            const response = await apiService.getApiParamater('GET_ACCOUNTS', `?${params.toString()}`);
            const fetchedAccounts = response.data.result.map(account => ({
                label: account.accountName,
                value: account,
            }));
            if (fetchedAccounts.length < 20) setHasMore(false);

            setAccounts(prevAccounts => {
                const staticNoneOption = { label: 'None', value: null };
                if (pageNumber === 1) {
                    return [staticNoneOption, ...fetchedAccounts];
                }
                return [...prevAccounts, ...fetchedAccounts];
            });
            setLoader(false);
            return fetchedAccounts;
        } catch (error) {
            console.error("Error fetching account data:", error);
            setLoader(false);
            return [];
        }
    };
    const handleIssueAccountChange = (selectedOption) => {
        setFormData({ ...formData, issueAccount: selectedOption });
    }
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    const handleGRNAccountChange = (selectedOption) => {
        setFormData({ ...formData, grnAccount: selectedOption });
    }
    const handleAdjustmentAccountChange = (selectedOption) => {
        setFormData({ ...formData, adjustmentAccount: selectedOption });
    }
    const handleWIPAccountChange = (selectedOption) => {
        setFormData({ ...formData, wipAccount: selectedOption });
    }
    const handleInvoiceAccountChange = (selectedOption) => {
        setFormData({ ...formData, invoiceAccount: selectedOption });
    }
    const handleCGSAccountChange = (selectedOption) => {
        setFormData({ ...formData, cgsAccount: selectedOption });
    }
    const handleWastageAccountChange = (selectedOption) => {
        setFormData({ ...formData, wastageAccount: selectedOption });
    }
    const handleInventoryAccountChange = (selectedOption) => {
        setFormData({ ...formData, InventoryAccounts: selectedOption });
    }
    const handleSubmit = async () => {
        setLoad(true)
        
        if (!formData.Category) {
            if (!formData.Category) { toast.warning("Enter Category Name") }
            setLoad(false)
            return;
        }
        try {
            const apiService = new ApiService()
            let data = {
                recordId: 0,
                catCode: catCode,
                catName: formData?.Category,
                modifiedBy: userName,
                inventoryAccountCode: formData?.InventoryAccounts?.value?.accountCode,
                grnAccountCode: formData?.grnAccount?.value?.accountCode,
                invoiceAccountCode: formData?.invoiceAccount?.value?.accountCode,
                cgsAccountCode: formData?.cgsAccount?.value?.accountCode,
                adjustmentAccountCode: formData?.adjustmentAccount?.value?.accountCode,
                wastageAccountCode: formData?.wastageAccount?.value?.accountCode,
                workInProgressAccountCode: formData?.wipAccount?.value?.accountCode,
                issueAccountCode: formData?.issueAccount?.value?.accountCode,
                projectCode: formData?.recordId?.value?.accountCode,
                finishedGoodsAccountCode: formData?.recordId?.value?.accountCode
            }
            const response = await apiService.post('CREATE_UPDATE_CATEGORY', data);
            if (response?.data?.statusCode === 201) {
                toast.success(response?.data?.message)
                setFormData({
                    recordId: " ",
                    catcod: " ",
                    Category: " ",
                    InventoryAccounts: [],
                    grnAccount: [],
                    wipAccount: [],
                    invoiceAccount: [],
                    adjustmentAccount: [],
                    cgsAccount: [],
                    issueAccount: [],
                    wastageAccount: [],
                });
                setCatCode()
                setLoad(false)
                fetchCategoryData();
            } else if (response?.data?.statusCode === 208) {
                toast?.warning(response?.data?.message)
                fetchCategoryData();
                setLoad(false)
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            setLoad(false)
        }
    }
    const updateFormData = async () => {
        setLoad(true)
        try {
            const apiService = new ApiService();
            let body = {
                recordId: formData?.recordId,
                catCode: catCode,
                catName: formData?.Category,
                modifiedBy: userName,
                inventoryAccountCode: formData?.InventoryAccounts?.value?.accountCode,
                grnAccountCode: formData?.grnAccount?.value?.accountCode,
                invoiceAccountCode: formData?.invoiceAccount?.value?.accountCode,
                cgsAccountCode: formData?.cgsAccount?.value?.accountCode,
                adjustmentAccountCode: formData?.adjustmentAccount?.value?.accountCode,
                wastageAccountCode: formData?.wastageAccount?.value?.accountCode,
                workInProgressAccountCode: formData?.wipAccount?.value?.accountCode,
                issueAccountCode: formData?.issueAccount?.value?.accountCode,
                projectCode: formData?.recordId?.value?.accountCode,
                finishedGoodsAccountCode: formData?.recordId?.value?.accountCode
            }
            const res = await apiService.put(`UPDATE_CATEGORY`, body);
            if (res?.data?.statusCode === 201) {
                toast.success(` ${catCode} updated successfully`);
                setFormData({
                    recordId: "",
                    catcod: "",
                    Category: "",
                    InventoryAccounts: [],
                    grnAccount: [],
                    wipAccount: [],
                    invoiceAccount: [],
                    adjustmentAccount: [],
                    cgsAccount: [],
                    issueAccount: [],
                    wastageAccount: [],
                })
                setSelectedRow(null)
                window.history.replaceState({}, '')
                setLoad(false)
                fetchCategoryData()
            } else {
                console.error('Failed to update Category');
                setLoad(false)
            }
        } catch (error) {
            console.error('Error updating Category', error);
            setLoad(false)
        }
    }
    const fetchCategoryData = async (newPageNo = pagination?.currentPage) => {
        try {
            const apiService = new ApiService()
            const params = new URLSearchParams();
            params.append('PageNo', newPageNo || 1);
            params.append('PageSize', pageSize || 20);
            const response = await apiService.getApiParamater('GET_CATEGORY', `?${params.toString()}`);
            const Code = await apiService.get('GET_CATEGORY_CODE');
            const AccountResponse = await apiService.get('GET_ACCOUNTS');
            setCatCode(Code.data.catcode)
            setAccountsList(AccountResponse?.data?.result)
            const packingData = response?.data;
            setRowData(packingData);
            setLoading(false);
            setPagination(response.data.paginationData)
        } catch (error) {
            console.error("Error fetching packing data:", error);
            setLoading(false);
        }
    };
    const handleClearFilters = () => {
        setFormData({
            recordId: "",
            catcod: "",
            Category: "",
            InventoryAccounts: [],
            grnAccount: [],
            wipAccount: [],
            invoiceAccount: [],
            adjustmentAccount: [],
            cgsAccount: [],
            issueAccount: [],
            wastageAccount: [],
        })
        setSelectedRow(null)
        fetchCategoryData()
    };
    return (
        <div className='px-4 pt-3'>
        
            <div className='py-lg-2'>
                <div className='gridBg p-3' style={{ borderRadius: "16px 16px 16px 16px" }}>
                <div className='d-flex justify-contant-between mb-4 '>
                <h5 className='col mainInvHead'>Category</h5>
                <div className='d-flex justify-content-end gap-1'>
                    <div
                        onClick={handleEdit}
                        className={`mainbtnSecondary flex-end gap-2 ${!selectedRow ? 'disabled' : ''}`}
                        style={{ cursor: selectedRow ? 'pointer' : 'not-allowed' }}
                    >
                        <GrEdit color='#fff' className='center mt-1 mx-2' />
                        <div className='center'>Edit</div>
                    </div>
                    <div className=''>
                        {selectedRow ? (
                            <>{load === true ?
                                <button className='mainbtn '><ClipLoader size={30} color='#fff' /></button> :
                                <>
                                    {canEditItemMaster && (
                                        <button className='mainbtn ' onClick={updateFormData}>
                                            <FaSave className='center mt-1 mx-2' /> <div>Update</div></button>
                                    )}
                                </>
                            }
                            </>
                        ) : (
                            <>{load === true ?
                                <button className='mainbtn '><ClipLoader size={30} color='#fff' /></button> :
                                <>
                                    {canCreateItemMaster === true ?
                                        <button className='mainbtn ' onClick={handleSubmit}>
                                            <FaSave className='center mt-1 mx-2' /> <div>Save</div></button> :
                                        <button className='mainbtn disabled' style={{ cursor: "not-allowed" }}>
                                            <FaSave className='center mt-1 mx-2' /> <div>Save</div>
                                        </button>
                                    }
                                </>
                            }
                            </>
                        )}
                    </div>
                    <button className='mainbtnRed' onClick={handleClearFilters}><RiRefreshLine className='center mt-1 mx-2' /><div>Reset</div></button>
                </div>
            </div>
                    <div className="form-group row d-flex justify-content-between">
                        <div className="col-md-3 col-lg-6 d-flex justify-content-between">
                            <label htmlFor="validationCustom01" className="form-label  pt-2 start">Category Code</label>
                            <input
                                type="text"
                                disabled={true}
                                className="form-Input w-75"
                                id="validationCustom01"
                                name="catCode"
                                value={catCode}
                                required
                            />
                        </div>
                        <div className="col-md-3 col-lg-6 d-flex justify-content-between">
                            <label htmlFor="validationCustom01" className="form-label  pt-2 start">Category Name *</label>
                            <input
                                type="text"
                                className="form-Input w-75"
                                id="validationCustom01"
                                disabled={canCreateItemMaster === true ? false : true}
                                name="Category"
                                value={formData.Category}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                    </div>
                </div>
                <div className="cost-center-section">
                    <div className="d-flex mt-3 cursor-pointer slide-header gap-4"
                        onClick={() => setCostTab(!CostTab)}>
                        <div className='h6 pt-1'>GL Accounts</div>
                    </div>
                    <div className={`slide-content ${CostTab ? 'open' : 'closed'}`}>
                        <div className="border-box2 p-3">
                            <div className="form-group row d-flex justify-content-between pb-3">
                                <div className="col-md-3 col-lg-3 d-flex justify-content-between">
                                    <label htmlFor="validationCustom01" className="form-label  pt-2 start">Inventory Acc</label>
                                    <AsyncSelect
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        className="w-70 p-0"
                                        isDisabled={!canCreateItemMaster}
                                        value={formData.InventoryAccounts}
                                        onChange={handleInventoryAccountChange}
                                        loadOptions={loadOptions}
                                        defaultOptions={accounts}
                                        cacheOptions
                                        onMenuScrollToBottom={loadMoreOptions}
                                    />
                                </div>
                                <div className="col-md-3 col-lg-3 d-flex justify-content-between">
                                    <label htmlFor="validationCustom01" className="form-label  pt-2 start">GRN Acc</label>
                                    <AsyncSelect
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        className="w-70 p-0"
                                        isDisabled={!canCreateItemMaster}
                                        value={formData.grnAccount}
                                        onChange={handleGRNAccountChange}
                                        loadOptions={loadOptions}
                                        defaultOptions={accounts}
                                        cacheOptions
                                        onMenuScrollToBottom={loadMoreOptions}
                                    />
                                </div>
                                <div className="col-md-3 col-lg-3 d-flex justify-content-between">
                                    <label htmlFor="validationCustom01" className="form-label  pt-2 start">Adjustment Acc</label>
                                    <AsyncSelect
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        className="w-70 p-0"
                                        isDisabled={!canCreateItemMaster}
                                        value={formData.adjustmentAccount}
                                        onChange={handleAdjustmentAccountChange}
                                        loadOptions={loadOptions}
                                        defaultOptions={accounts}
                                        cacheOptions
                                        onMenuScrollToBottom={loadMoreOptions}
                                    />
                                </div>
                                <div className="col-md-3 col-lg-3 d-flex justify-content-between">
                                    <label htmlFor="validationCustom01" className="form-label  pt-2 start">Work In Progress</label>
                                    <AsyncSelect
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        className="w-70 p-0"
                                        isDisabled={!canCreateItemMaster}
                                        value={formData.wipAccount}
                                        onChange={handleWIPAccountChange}
                                        loadOptions={loadOptions}
                                        defaultOptions={accounts}
                                        cacheOptions
                                        onMenuScrollToBottom={loadMoreOptions}
                                    />
                                </div>
                            </div>
                            <div className="form-group row d-flex justify-content-between">
                                <div className="col-md-3 col-lg-3 d-flex justify-content-between">
                                    <label htmlFor="validationCustom01" className="form-label  pt-2 start">Invoice Acc</label>
                                    <AsyncSelect
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        className="w-70 p-0"
                                        isDisabled={!canCreateItemMaster}
                                        value={formData.invoiceAccount}
                                        onChange={handleInvoiceAccountChange}
                                        loadOptions={loadOptions}
                                        defaultOptions={accounts}
                                        cacheOptions
                                        onMenuScrollToBottom={loadMoreOptions}
                                    />
                                </div>
                                <div className="col-md-3 col-lg-3 d-flex justify-content-between">
                                    <label htmlFor="validationCustom01" className="form-label  pt-2 start">CGS Acc</label>
                                    <AsyncSelect
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        className="w-70 p-0"
                                        isDisabled={!canCreateItemMaster}
                                        value={formData.cgsAccount}
                                        onChange={handleCGSAccountChange}
                                        loadOptions={loadOptions}
                                        defaultOptions={accounts}
                                        cacheOptions
                                        onMenuScrollToBottom={loadMoreOptions} />
                                </div>
                                <div className="col-md-3 col-lg-3 d-flex justify-content-between">
                                    <label htmlFor="validationCustom01" className="form-label  pt-2 start">Wastage Acc</label>
                                    <AsyncSelect
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        className="w-70 p-0"
                                        isDisabled={!canCreateItemMaster}
                                        value={formData.wastageAccount}
                                        onChange={handleWastageAccountChange}
                                        loadOptions={loadOptions}
                                        defaultOptions={accounts}
                                        cacheOptions
                                        onMenuScrollToBottom={loadMoreOptions} />
                                </div>
                                <div className="col-md-3 col-lg-3 d-flex justify-content-between">
                                    <label htmlFor="validationCustom01" className="form-label  pt-2 start">Issue Acc</label>
                                    <AsyncSelect
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        className="w-70 p-0"
                                        isDisabled={!canCreateItemMaster}
                                        value={formData.issueAccount}
                                        onChange={handleIssueAccountChange}
                                        loadOptions={loadOptions}
                                        defaultOptions={accounts}
                                        cacheOptions
                                        onMenuScrollToBottom={loadMoreOptions} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='gridBg mt-3'>
                <CategoryGridMaster rowData={rowData?.result}
                    pageSize={pageSize}
                    setSelectedRow={setSelectedRow}
                    fetchCategoryData={fetchCategoryData}
                    canEditItemMaster={canEditItemMaster} pagination={pagination} loading={loading} handleEdit={handleEdit} />
                <Pagination pageSize={pageSize} setPageSize={setPageSize} pagination={pagination} getItems={fetchCategoryData} />
            </div>
        </div>
    )
}
export default CategoryMaster