import React, { useState } from "react";
import "../Styles/recipeMaster.css"; // Custom styles for allergens table
import edit from "../../Assets/Images/Editing.png";

const RecipeAllergens = () => {
    const [data, setData] = useState([
        { description: "Suitable for vegetarian", value: false, remarks: "" },
        { description: "Contains nuts", value: true, remarks: "Peanut allergy" },
    ]);
    const [editingRow, setEditingRow] = useState(null); // Track the row being edited

    const handleAddRow = () => {
        setData([...data, { description: "", value: false, remarks: "" }]);
    };

    const handleDeleteRow = (index) => {
        const updatedAllergens = data.filter((_, i) => i !== index);
        setData(updatedAllergens);
        if (editingRow === index) setEditingRow(null); // Reset editing state if the row is deleted
    };

    const handleInputChange = (field, value, index) => {
        const updatedData = [...data];
        updatedData[index][field] = value;
        setData(updatedData);
        setEditingRow(index); // Set the current row as being edited
    };

    return (
        <div className="allergens-container bg-white p-3">
            <div className="table-responsive">
                <table className="table allergens-table">
                    <thead>
                        <tr className="bg-purple text-white">
                            <th></th> {/* Column for the editing icon */}
                            <th>Description</th>
                            <th>Value</th>
                            <th>Remarks</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((allergen, index) => (
                            <tr key={index}>
                                {/* Editing icon */}
                                <td className="text-center">
                                    {editingRow === index && (
                                        <img
                                            src={edit}
                                            alt="Editing"
                                            className="editing-icon"
                                        />
                                    )}
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        className="form-control input-description"
                                        value={allergen.description}
                                        placeholder="Enter Description"
                                        onChange={(e) =>
                                            handleInputChange(
                                                "description",
                                                e.target.value,
                                                index
                                            )
                                        }
                                    />
                                </td>
                                <td className="text-center">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={allergen.value}
                                        onChange={(e) =>
                                            handleInputChange(
                                                "value",
                                                e.target.checked,
                                                index
                                            )
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        className="form-control input-remarks"
                                        value={allergen.remarks}
                                        placeholder="Enter Remarks"
                                        onChange={(e) =>
                                            handleInputChange(
                                                "remarks",
                                                e.target.value,
                                                index
                                            )
                                        }
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <button className="btn btn-primary mt-3 add-row-btn" onClick={handleAddRow}>
                Add Allergen
            </button>
        </div>
    );
};

export default RecipeAllergens;
