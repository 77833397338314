import React, { useState, useEffect } from 'react';
import ApiService from '../../../services/apiService';
import { toast } from 'react-toastify';
import moment from 'moment';

const ItemGridGRN = ({ onRequisitionData, itemData, grnData, selectedPOData }) => {
    const [rows, setRows] = useState([{
        requisitionNo: '',
        srno: '',
        recordId: '',
        ItemNo: '',
        itemDescription: '',
        specification: '',
        qty: '',
        uom: '',
        baseQty: '',
        baseAmount: '',
        amount: '',
        discount: '',
        vat: '',
        vatDes: '',
        vatAmount: '',
        balQty: "",
        freeQty: "",
        convValue: '',
        discount: '',
        discountAmount: '',
        calculatedAmount: "",
        expDate: null,
        inventoryAccountCode: "",
        inventoryAccountName: "",
    }]);
    const [items, setItems] = useState()
    useEffect(() => {
        const getItems = async () => {
            try {
                const apiService = new ApiService();
                const res = await apiService.get('GET_ITEM_PACK');
                setItems(res.data.result)
                const response = await apiService.get('GET_GRN');
                const selectedGrn = response.data.result?.find(rec => rec.grnNo === grnData?.docNo || grnData?.grnNo)
                let param = `?POrderNo=${grnData?.pOrderNo || selectedPOData?.pOrderNo || selectedGrn?.pOrderNo}`
                const PO = await apiService.getApiParamater("GET_GRN_PO", param);
                const formattedRows = itemData?.map(detail => {
                    const selectedGrnItem = PO.data.result?.find(rec => rec.itemDescription === detail.itemDescription)
                    const selecteditem = res.data.result?.find(rec => rec.itemDescription === detail.itemDescription)
                    if (!selecteditem) {
                        console.error(`Selected item with description ${detail.itemDescription} not found`);
                        return null;
                    }

                    return {
                        requisitionNo: detail.requisitionNo,
                        srno: detail.srno,
                        ItemNo: detail.itemCode,
                        qty: grnData?.eye === true ? detail.qty : "",
                        itemDescription: detail.itemDescription,
                        uom: detail.uom,
                        baseQty: 0,
                        convValue: selecteditem?.convToInvUOM,
                        baseAmount: grnData?.eye === true ? detail.baseAmount : selectedGrnItem?.unitPrice,
                        vatDes: detail.vatDesc,
                        vat: detail.vatPerc,
                        vatAmount: detail.vatAmount,
                        specification: detail ? detail.specification : '',
                        amount: grnData?.eye === true ? detail.amount : detail.amount,
                        balQty: grnData?.eye === true ? detail?.grnQty || detail?.balQty : detail?.balQty,
                        freeQty: grnData?.eye === true ? detail?.grnQty || detail?.balQty : detail?.balQty,
                        inventoryAccountCode: selecteditem.acCode,
                        inventoryAccountName: selecteditem.acName,
                        expDate: selectedGrnItem?.expDate
                    };
                }).filter(row => row !== null);
                if (formattedRows.length > 0) {
                    setRows(formattedRows);
                    onRequisitionData(formattedRows);
                }
            } catch (error) {
                console.error('Error fetching items:', error);
            }
        };

        if (itemData) {
            getItems();
        }
    }, [itemData]);
    const handleQtyChange = (index, value) => {
        const newQty = parseInt(value) || '';
        const balQty = rows[index].balQty;
        const unitPrice = rows[index].baseAmount;
        const vatPerc = rows[index].vat;
        const vatDes = rows[index].vatDes;
        const vatInclusive = rows[index].costCenter5;
        const UOM = rows[index].convValue;
        const item = rows[index].itemDescription;
        if (newQty < 0) {
            toast.warning('Quantity cannot be less than 0.');
            return;
        }
        if (newQty > balQty) {
            toast.warning('Enter a quantity less than or equal to the balance quantity.');
        } else {
            const amountBeforeVat = newQty * unitPrice;
            const vatAmount = (amountBeforeVat * vatPerc) / 100;
            let totalAmount;

            if (selectedPOData?.costCenter5 === "excluded") {
                totalAmount = amountBeforeVat + vatAmount;
            } else {
                totalAmount = amountBeforeVat;
            }
            setRows(prevRows => {
                const newRows = [...prevRows];
                newRows[index].qty = newQty;
                newRows[index].baseQty = newQty * UOM || 0;
                newRows[index].amount = totalAmount;
                newRows[index].vatAmount = vatAmount;
                newRows[index].vatDes = vatDes;
                return newRows;
            });
        }
    };
    const handleDateChange = (index, expDate) => {
        const updatedRows = [...rows];
        updatedRows[index].expDate = expDate;
        const updatedSelectedRows = rows.map((row, i) =>
            i === index ? { ...row, expDate: expDate } : row
        );
        setRows(updatedSelectedRows);
        onRequisitionData(updatedSelectedRows);
    };
    return (
        <div className='table-container p-1 mb-5' style={{ overflowX: 'auto', height: "400px" }}>
            <table>
                <thead className='dynoTable'>
                    <tr>
                        <th>Sr no</th>
                        <th>Item No</th>
                        <th>Description</th>
                        <th>Item Specs</th>
                        <th>Qty</th>
                        <th>Packing UOM</th>
                        <th>Inventory Qty</th>
                        <th>Bal Qty</th>
                        <th>Free Qty</th>
                        <th>Unit Price</th>
                        <th>Discount</th>
                        <th>VAT Desc</th>
                        <th>VAT %</th>
                        <th>VAT Amount</th>
                        <th>Amount</th>
                        <th>Exp Date</th>
                    </tr>
                </thead>
                {grnData?.eye === true ?
                    <tbody>
                        {grnData?.grnDetails?.map((row, index) => (
                            <tr key={index}>
                                <td className='m-0 p-0'><input className='p-2' value={index + 1} disabled style={{ width: "70px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.itemCode} style={{ width: "100px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.itemDescription} style={{ width: "130px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.specification} style={{ width: "120px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled style={{ width: "70px" }} value={row.qty} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.uom} style={{ width: "130px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.convToInvUOM * row.qty || 0} style={{ width: "130px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.balQty || 0} style={{ width: "80px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled value={0} style={{ width: "100px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.unitPrice || 0} style={{ width: "120px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' value={row.discount|| 0} disabled style={{ width: "100px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' value={row.vatDesc} disabled style={{ width: "100px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' value={row.vatPerc + "%"} disabled style={{ width: "80px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' value={row.vatAmount || 0} disabled style={{ width: "120px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.amount || 0} style={{ width: "100px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' value={moment(row?.expDate).format('DD-MMM-YYYY')}
                                    disabled style={{ width: "130px" }} /></td>
                            </tr>
                        ))}
                    </tbody> :
                    <tbody>
                        {rows?.map((row, index) => (
                            <tr key={index}>
                                <td className='m-0 p-0'><input className='p-2' type="text" value={index + 1} disabled style={{ width: "70px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.ItemNo} style={{ width: "100px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.itemDescription} style={{ width: "130px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.specification} style={{ width: "120px" }} /></td>
                                <td className='m-0 p-0'>
                                    {grnData?.eye === true ?
                                        <input
                                            className='p-2'
                                            type="number" onWheel={(e) => e.target.blur()}
                                            disabled style={{ width: "70px" }}
                                            value={row.qty}
                                        />
                                        :
                                        <input
                                            className='p-2' onWheel={(e) => e.target.blur()}
                                            type="number" style={{ width: "70px" }}
                                            value={row.qty}
                                            max={row.balQty}
                                            onChange={(e) => handleQtyChange(index, e.target.value)}
                                        />
                                    }
                                </td>
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.uom} style={{ width: "130px" }} /></td>
                                {grnData?.eye === true ?
                                    <td className='m-0 p-0'><input className='p-2' disabled value={row.convValue * row.qty || 0} style={{ width: "130px" }} /></td> :
                                    <td className='m-0 p-0'><input className='p-2' disabled value={row.baseQty || 0} style={{ width: "130px" }} /></td>
                                }
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.balQty || 0} style={{ width: "80px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled value={0} style={{ width: "100px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.baseAmount || 0} style={{ width: "120px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' type="text" value={row.discount} disabled style={{ width: "100px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' type="text" value={row.vatDes} disabled style={{ width: "100px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' type="text" value={row.vat + "%" || 0 + "%"} disabled style={{ width: "80px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' type="text" value={row.vatAmount || 0} disabled style={{ width: "120px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.amount || 0} style={{ width: "100px" }} /></td>
                                <td className='m-0 p-0'>
                                    {grnData?.eye === true ?
                                        <input className='p-2' type='datetime' value={moment(row?.expDate).format('DD-MMM-YYYY')} disabled style={{ width: "130px" }} /> :
                                        <input className='p-2' type='date' value={row?.expDate ? row.expDate.split('T')[0] : ''}
                                            onChange={(e) => handleDateChange(index, e.target.value)} style={{ width: "130px" }} />
                                    }
                                </td>
                            </tr>
                        ))}
                    </tbody>
                }

            </table>
        </div>
    );
};

export default ItemGridGRN;
