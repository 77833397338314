import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import RecipeSideLayout from "./recipeSideLayout"
import RecipeHeader  from "../Component/header/header"
const MainRecipeLayout = ({ children }) => {
    const location = useLocation();

    useEffect(() => {
        const isMainLayoutActive = location.pathname === "/Recipe/";
        if (isMainLayoutActive) {
            document.body.classList.add('body-with-sidebar');
        } else {
            document.body.classList.remove('body-with-sidebar');
        }
    }, [location.pathname]);

    return (
        <RecipeSideLayout>
            <RecipeHeader />
            <div className='mainbdy'>{children}</div>
        </RecipeSideLayout>
    );
};

export default MainRecipeLayout;
