import React, { useState, useEffect, useRef, Component } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import ApiService from '../../services/apiService';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Add } from '../../Assets/svg/Add.svg';
import moment from 'moment/moment';
import Button from 'react-bootstrap/Button';
import { ReactComponent as Edit } from '../../Assets/svg/edit.svg'
import { FaRegEye } from "react-icons/fa6";
import { TbBallpenOff } from "react-icons/tb";
import { IoAddSharp } from "react-icons/io5";
import { clearRows } from '../../../Redux/actions/rowActions';
import { useDispatch } from 'react-redux';
import { GrEdit } from "react-icons/gr";
import Pagination from '../pagination/pagination';
const IssueInventory = ({ onRequisitionData, itemData }) => {
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(true); // Add loading state
    const [selectedRow, setSelectedRow] = useState(null);
    let navigate = useNavigate();
    const gridRef = useRef();
    let dispatch = useDispatch()
    const [pageSize, setPageSize] = useState(20);
    const [pagination, setPagination] = useState();
    const [permissions, setPermissions] = useState([]);
    const [selectedtem, setSelectedItem] = useState(null);
    const apiService = new ApiService();
    useEffect(() => {
        if (pageSize) {
            getItems();
        }
    }, [pageSize]);
    useEffect(() => {
        getItems();
        const storedPermissions = JSON.parse(localStorage.getItem('RoleAccess')) || [];
        const permissions = storedPermissions?.filter(acc => acc.formName === "Issue Inventory Direct");
        setPermissions(permissions[0]);
    }, []);

    const handleEdit = (data) => {
        if (selectedRow) {
            dispatch(clearRows());
            navigate(`/Inventory/Issue/Direct/${data.issueNo}`, { state: data })
        };
    }
    const handlePrint = (data) => {
        if (selectedRow) {
            // navigate(`/Inventory/Requisition/PurchaseRequisition/${data?.reqositionNo}`, { state: data })
        };
    }
    const handleEye = (data) => {
        if (selectedRow?.issueNo) {
            const updatedData = { ...selectedRow, eye: !selectedRow.eye?.active };
            debugger
            navigate(`/Inventory/Issue/Direct/View/${updatedData?.issueNo}`, { state: updatedData })
        } else if (data?.issueNo) {
            const updatedData = { ...selectedRow, eye: !selectedRow.eye?.active };
            debugger
            navigate(`/Inventory/Issue/Direct/View/${updatedData?.issueNo}`, { state: updatedData })
        }
    }
    const getItems = async (newPageNo = pagination?.currentPage) => {
        try {
            const params = new URLSearchParams();
            params.append('PageNo', newPageNo || 1);
            params.append('PageSize', pageSize || 20);
            const res = await apiService.getApiParamater('GET_ITEM_ISSUE', `?${params.toString()}`);
            setPagination(res.data.paginationData)
            let responseSort = res?.data?.result
            let filterAuthorization = responseSort.filter(issue => issue.requisitionNo === null);
            setRowData(filterAuthorization || []);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching items:', error);
            // Handle error (e.g., show error message to user)
            setLoading(false); // Set loading to false even in case of error
        }
    }
    const dateFormatter = (params) => {
        const date = moment(params.value);
        return date.format("DD-MMM-YYYY");
    }
    const dateComparator = (date1, date2) => {
        const momentDate1 = moment(date1);
        const momentDate2 = moment(date2);
        return momentDate1.isSame(momentDate2) ? 0 : momentDate1.isBefore(momentDate2) ? -1 : 1;
    }
    const columnDefs = [
        { headerCheckboxSelection: true, checkboxSelection: true, headerCheckboxSelectionFilteredOnly: true, suppressMovable: true, width: 50 },
        { headerName: 'issue Note No', field: 'issueNo', filter: true, floatingFilter: false },
        {
            headerName: "Date", field: "issueDate", filter: true, floatingfilter: true,
            valueFormatter: dateFormatter,
            comparator: dateComparator,
        },
        { headerName: 'From Location', field: 'warehouse', filter: true, floatingFilter: false },
        { headerName: 'To Location', field: 'divisionName', filter: true, floatingFilter: false },
        { headerName: 'Property Group', field: 'remarks', filter: true, floatingFilter: false },
        { headerName: 'Property', field: 'costCenter2', filter: true, floatingFilter: false },
        { headerName: 'Department', field: 'department', filter: true, floatingFilter: false },
        { headerName: 'Issued By', field: 'issuedBy', filter: true, floatingFilter: false },
        { headerName: 'Remarks', field: 'remarks', filter: true, floatingFilter: false },
        {
            headerName: 'Status', field: 'status', filter: true, floatingfilter: true,
            cellRenderer: function (params) {
                if (params.value === 0 || params.value === null) {
                    return (<button class="btn pend ">Pending</button>);
                }
                else if (params.value === 1 || params.value === null) {
                    return (<button class="btn aproved">Approved</button>);
                }
                else if (params.value === 5 || params.value === null) {
                    return (<button class="btn hold">Hold</button>);
                }
                else if (params.value === 3 || params.value === null) {
                    return (<button class="btn reject">Rejected</button>);
                } else {
                    return params.value;
                }
            }
        },
        // {
        //     headerName: 'Actions', field: 'recordId', cellRenderer: BtnCellRenderer,
        //     cellRendererParams: {
        //         handleEdit: handleEdit,
        //         handleEye: handleEye,
        //         clicked: function (field, isActive) {
        //             updateActiveStatus(field, isActive);
        //         },
        //     },
        // }
    ]
    const onCellClicked = (event) => {
        if (event.colDef.field === 'issueNo') {
            const clickedRowData = event.data;
            setSelectedRow(clickedRowData);
        }
    }
    const handlenew = () => {
        dispatch(clearRows());
        navigate('/Inventory/Issue/Direct/Create')
    }

    const onSelectionChanged = async (event) => {
        const selectedRows = event.api.getSelectedRows();
        setSelectedRow(selectedRows.length === 1 ? selectedRows[0] : null);
        const params = new URLSearchParams();
        params.append('DocNo', selectedRows[0]?.issueNo);
        params.append('DocumentType', "Issue");
        try {
            const res = await apiService.getApiParamater('GET_DOC_STATUS_EDIT', `?${params.toString()}`);
            const statuses = res?.data?.result?.map(item => item.status);
            const allAreZero = statuses.every(status => status === 0);
            const anyIsFive = statuses.some(status => status === 2);
            if (allAreZero || anyIsFive) {
                setSelectedItem(selectedRows.length === 1 ? selectedRows[0] : null);
            } else {
                console.warn("No valid status to set the item.");
                setSelectedItem(null);
            }
        } catch (error) {
            setSelectedItem(null)
            console.error("Error fetching document status:", error);
        }
    };
    return (
        <div className='p-4'>
            {loading ? (
                <div>Loading...</div>
            ) : (
                <>
                    <div className='border-box2  mt-3 p-4 justify-content-between d-flex align-items-center'>
                        <h4 className='mainInvHead'>
                            Issue Inventory Direct
                        </h4>
                        <div className='d-flex gap-1'>
                            <div
                                onClick={handleEye}
                                className={`mainbtnGry flex-end gap-2 ${!selectedRow ? 'disabled' : ''}`}
                                style={{ cursor: selectedRow ? 'pointer' : 'not-allowed' }}
                            >
                                <FaRegEye color='#fff' className='center mt-1' />
                                <div className='center'>View</div>
                            </div>
                            {permissions?.canUpdate === true ?
                                <div
                                    onClick={selectedtem && selectedtem?.status === 0 || selectedtem?.status === 2 ? handleEdit : null}
                                    className={`mainbtnSecondary flex-end gap-2 ${!selectedtem || selectedtem?.status === 0 || selectedtem?.status === 2 ? '' : 'disabled'
                                        }`}
                                    style={{
                                        cursor: selectedtem && selectedtem?.status === 0 || selectedtem?.status === 2 ? 'pointer' : 'not-allowed',
                                    }}
                                >
                                    <GrEdit color='#fff' className='center mt-1 ' />
                                    <div className='center'>Edit</div>
                                </div> : ''
                            }
                            {permissions?.canAdd === true ?
                                <div className='col center'>
                                    <button className='mainbtn ' type="submit" onClick={() => handlenew()}>
                                        <IoAddSharp color='#fff' className='center mt-1' /> <div>Add</div>
                                    </button>
                                </div>
                                : ''
                            }
                        </div>
                    </div>
                    <div className='brdor'>
                        <div className="ag-theme-quartz" style={{ height: 650 }}>
                            <AgGridReact
                                getRowStyle={(params) => {
                                    if (params.node.rowIndex % 2 === 0) {
                                        return { background: '#F0EDF5' };
                                    } else {
                                        return { background: '#fff' };
                                    }
                                }}
                                rowData={rowData}
                                enableFilter={true}
                                floatingFilter={true}
                                rowSelection={'single'}
                                columnDefs={columnDefs}
                                onSelectionChanged={onSelectionChanged}
                                onCellClicked={onCellClicked}
                            />
                        </div>
                    </div>
                    <Pagination pageSize={pageSize} setPageSize={setPageSize} pagination={pagination} getItems={getItems} />
                </>
            )}
        </div>
    );
};
// class BtnCellRenderer extends Component {
//     constructor(props) {
//         super(props);
//         this.btnClickedHandler = this.btnClickedHandler.bind(this);
//         this.btnEditClickedHandler = this.btnEditClickedHandler.bind(this);
//         this.btnEyeClickedHandler = this.btnEyeClickedHandler.bind(this);
//         this.btnPrintClickedHandler = this.btnPrintClickedHandler.bind(this);
//     }

//     btnClickedHandler() {
//         const { value, clicked } = this.props;
//         clicked(value, !this.props.data.isActive); // Toggle isActive value
//     }
//     btnEditClickedHandler() {
//         const { data, handleEdit } = this.props;
//         handleEdit(data);
//     }
//     btnEyeClickedHandler() {
//         const { data, handleEye } = this.props;
//         handleEye(data);
//     }
//     btnPrintClickedHandler() {
//         const { data, handleEye } = this.props;
//         handleEye(data);
//     }
//     render() {
//         const { isActive } = this.props.data; // Extract isActive from rowData
//         return (
//             <div className='d-flex gap-3'>
//                 {/* <div><Link /></div> */}
//                 {this.props.data?.status === 1 ?
//                     <div><TbBallpenOff size={28} /></div>
//                     :
//                     <div onClick={this.btnEditClickedHandler}><Edit /></div>
//                 }
//                 <div>
//                     <FaRegEye onClick={this.btnEyeClickedHandler} size={25} />
//                 </div>
//             </div>
//             // <button onClick={this.btnClickedHandler} className="gridbtn">Delete</button>
//         )
//     }
// };

export default IssueInventory;
