import React from 'react';
import "./thirdP.css"
import Uber from "../../../../assets/images/Logos/UberEats.png"
import Carousel from "react-elastic-carousel";

const ThirdPartyCard = ({ val }) => {
    
    const breakPoints = [
        { width: 1, itemsToShow: 1, pagination: false },
        { width: 550, itemsToShow: 3, pagination: false },
        { width: 850, itemsToShow: 3, pagination: false },
        { width: 1150, itemsToShow: 4, pagination: false },
        { width: 1450, itemsToShow: 4, pagination: false },
        { width: 1750, itemsToShow: 5, pagination: false },
    ]
    const IMAGE_URL = process.env.REACT_APP_IMAGE_URL

    return (
        <Carousel loop breakPoints={breakPoints} className="d-flex justify-content-center" >
            {val?.map((data, index) => (
                <div className='' key={index}>
                    <div className='brder  d-flex gap-4'>
                        <div>
                            <img src={IMAGE_URL + data?.logo || Uber} className="img-fluid lgoimg" />
                        </div>
                        <div>
                            <div className='income'>{data?.orderCount || "0"}</div>
                            <div className='ptyName'>{data?.name || "Third Party"}</div>
                        </div>
                    </div>
                </div>
            ))}
        </Carousel>
    );
};

export default ThirdPartyCard;
