import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import SubCategoryGridMaster from './recipeSubCategoryGrid';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import { useSelector } from 'react-redux';
import { getName } from '../../../Redux/selecters';
import { RiRefreshLine } from "react-icons/ri";
import { FaSave } from "react-icons/fa";
import { GrEdit } from "react-icons/gr";
import ApiService from '../../services/apiService';

const RecipeSubCategoryMaster = () => {
    const [categories, setCategories] = useState([]);
    const [subcategories, setSubCategories] = useState([]);
    const apiService = new ApiService();
    const [loading, setLoading] = useState(true);
    const [catCode, setCatCode] = useState()
    const [pagination, setPagination] = useState();
    const userName = useSelector(getName);
    const [pageSize, setPageSize] = useState(20);
    const [selectedRow, setSelectedRow] = useState(null);

    const [load, setLoad] = useState(false);
    const [formData, setFormData] = useState({
        recordId: "",
        subcatcode: "",
        subcatname: "",
        category: [],
        catcode: "",
        active: 0,
        adduser: "",


    });
    useEffect(() => {
        fetchSubCategories()
        fetchCategories();
    }, []);

    const canEditItemMaster = true;
    const canCreateItemMaster = true;
    const handleEdit = (clickedRowData) => {
        if (clickedRowData.subcatname) {

            const selectedcategories = categories?.find(cat => cat.catCode === clickedRowData?.catcode)
            setCatCode(clickedRowData?.subcatcode)
            setFormData({
                recordId: clickedRowData?.recordid || "",
                subcatname: clickedRowData?.subcatname || "",
                category: { label: selectedcategories?.catName, value: selectedcategories }
            });
        } else if (selectedRow) {
            setCatCode(selectedRow?.subcatcode)

            const selectedcategories = categories?.find(cat => cat.catCode === selectedRow?.catcode)

            setFormData({
                recordId: selectedRow?.recordid || "",
                subcatname: selectedRow?.subcatname || "",
                category: { label: selectedcategories?.catName, value: selectedcategories },

            })
        }
    }

    const handleCategoryChange = (selectedOption) => {
        setFormData({ ...formData, category: selectedOption });
    }

    const fetchCategories = async () => {
        try {
            let param = `?IsActive=${true}`
            const response = await apiService.getApiParamater('GET_CATEGORY', param);
            const AccountResponse = await apiService.get('GET_ACCOUNTS');
            setCategories(response?.data?.result);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    }
    const fetchSubCategories = async (newPageNo = pagination?.currentPage) => {
        try {
            const apiService = new ApiService()
            const params = new URLSearchParams();
            params.append('PageNo', newPageNo || 1);
            params.append('PageSize', pageSize || 20);
            const response = await apiService.getApiParamater('GET_SUB_CATEGORY', `?${params.toString()}`);
            const Code = await apiService.get('GET_SUB_CATEGORY_CODE');
            setCatCode(Code.data.subcatcode)
            setPagination(response.data.paginationData)
            setLoading(false);
            setSubCategories(response?.data?.result);
        } catch (error) {
            setLoading(false);
            console.error('Error fetching Sub categories:', error);
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoad(true)

        if (!formData.category || !formData?.subcatname) {
            if (!formData.category) { toast.warning("Enter Category Name") }
            if (!formData.subcatname) { toast.warning("Enter Sub Category Name") }
            setLoad(false)
            return;
        }
        try {
            let data = {
                recordid: 0,
                subcatcode: catCode,
                subcatname: formData?.subcatname,
                catCode: formData?.category?.value?.catCode,
                catName: formData?.category?.label,
                addUser: userName,

            }
            const response = await apiService.post('CREATE_UPDATE_SUB_CATEGORY', data);
            if (response?.data?.statusCode === 201 || response?.data?.statusCode === 200) {
                toast.success(response?.data?.message)
                fetchSubCategories()
                setFormData({
                    recordId: "",
                    subcatcode: "",
                    subcatname: "",
                    category: [],
                    catcode: "",
                    active: 0,
                    adduser: "",

                })
                window.history.replaceState({}, '')
                setCatCode()
                setLoad(false)
            }
            else if (response?.data?.statusCode === 208) {
                toast.warning(response?.data?.message)
                setLoad(false)
            }

        } catch (error) {
            console.error('Error creating subcategory:', error);
            setLoad(false)
        }
    }
    const updateFormData = async () => {
        setLoad(true)
        try {
            const apiService = new ApiService();
            let body = {
                recordId: formData?.recordId,
                subcatcode: catCode,
                subcatname: formData?.subcatname,
                catCode: formData?.category?.value?.catCode,
                catName: formData?.category?.label,
                addUser: userName,

            }
            const res = await apiService.put(`UPDATE_SUB_CATEGORY`, body);
            if (res?.data?.statusCode === 201) {
                toast.success(`${catCode} updated successfully`);
                setFormData({
                    recordId: "",
                    subcatcode: "",
                    subcatname: "",
                    category: [],
                    catcode: "",
                    active: 0,
                    adduser: "",
                })
                setCatCode()
                window.history.replaceState({}, '')
                setSelectedRow(null)
                fetchCategories()
                fetchSubCategories()
                setLoad(false)
            } else {
                console.error('Failed to update form data');
                setLoad(false)
            }
        } catch (error) {
            console.error('Error updating form data:', error);
            setLoad(false)
        }
    }
    const handleClearFilters = () => {
        setFormData({
            recordId: "",
            subcatcode: "",
            subcatname: "",
            category: [],
            catcode: "",
            active: 0,
            adduser: "",

        })
        setSelectedRow(null)
        fetchCategories()
        fetchSubCategories()
    };
    return (
        <div className='px-4 pt-3'>

            <div className='py-lg-2'>
                <div className='gridBg p-3' style={{ borderRadius: "16px 16px 16px 16px" }}>
                    <div className='d-flex justify-contant-between mb-4'>
                        <h5 className='col mainInvHead'>Recipe Sub Category Master</h5>
                        <div className='d-flex justify-content-end gap-3'>
                            <div
                                onClick={handleEdit}
                                className={`mainbtnSecondary flex-end gap-2 ${!selectedRow ? 'disabled' : ''}`}
                                style={{ cursor: selectedRow ? 'pointer' : 'not-allowed' }}
                            >
                                <GrEdit color='#fff' className='center mt-1 mx-2' />
                                <div className='center'>Edit</div>
                            </div>
                            <div className=''>
                                {selectedRow ? (
                                    <>{load === true ?
                                        <button className='mainbtn '><ClipLoader size={30} color='#fff' /></button> :
                                        <>
                                            {canEditItemMaster && (
                                                <button className='mainbtn ' onClick={updateFormData}>
                                                    <FaSave className='center mt-1 mx-2' /> <div>Update</div></button>
                                            )}
                                        </>
                                    }
                                    </>
                                ) : (
                                    <>{load === true ?
                                        <button className='mainbtn '><ClipLoader size={30} color='#fff' /></button> :
                                        <>
                                            {canCreateItemMaster === true ?
                                                <button className='mainbtn ' onClick={handleSubmit}>
                                                    <FaSave className='center mt-1 mx-2' /> <div>Save</div></button> :
                                                <button className='mainbtn disabled' style={{ cursor: "not-allowed" }}>
                                                    <FaSave className='center mt-1 mx-2' /> <div>Save</div>
                                                </button>
                                            }
                                        </>
                                    }
                                    </>
                                )}
                            </div>
                            <button className='mainbtnRed' onClick={handleClearFilters}><RiRefreshLine className='center mt-1 mx-2' /><div>Reset</div></button>
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-md-3 col-lg-4 d-flex justify-content-between">
                            <label htmlFor="subcatcode" className="form-label  pt-2 start">ID</label>
                            <input
                                type="text"
                                className="form-Input w-70"
                                id="validationCustom01"
                                name="catCode"
                                disabled
                                value={catCode}
                                required
                            />
                        </div>
                        <div className="col-md-3 col-lg-4 d-flex justify-content-between">
                            <label htmlFor="subcatcode" className="form-label  pt-2 start">Code</label>
                            <input
                                type="text"
                                className="form-Input w-70"
                                id="validationCustom01"
                                name="catCode"
                                disabled
                                value={catCode}
                                required
                            />
                        </div>
                        <div className="col-md-3 col-lg-4 d-flex justify-content-between">
                            <label htmlFor="category" className="form-label  pt-2 start">Select Category *</label>
                            <Select
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 5,
                                    padding: 9
                                })}
                                menuPortalTarget={document.body}
                                styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                        color: state.isFocused ? '#000 ' : '#000',
                                        ':hover': {
                                            backgroundColor: '#F5F5F5',
                                            color: '#000 ',
                                        },
                                    }),
                                }}
                                className="w-70 p-0"
                                onChange={(selectedOption) => handleCategoryChange(selectedOption)}
                                isDisabled={canCreateItemMaster === true ? false : true}
                                value={formData?.category}
                                options={categories?.map(category => ({ value: category, label: category.catName }))}
                            />
                        </div>
                    </div>
                    <div className="form-group row mt-2">
                        <div className="col-md-3 col-lg-4 d-flex justify-content-between">
                            <label htmlFor="subcatname" className="form-label  pt-2 start">Description *</label>
                            <input
                                type="text"
                                className="form-Input w-70"
                                id="validationCustom01"
                                disabled={canCreateItemMaster === true ? false : true}
                                name="subcatname"
                                onChange={(e) => setFormData({ ...formData, subcatname: e.target.value })}
                                value={formData.subcatname}
                                required
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='gridBg mt-3'>
                <SubCategoryGridMaster rowData={subcategories} canEditItemMaster={canEditItemMaster}
                    setSelectedRow={setSelectedRow}
                    fetchSubCategories={fetchSubCategories} pagination={pagination} loading={loading} handleEdit={handleEdit} />
            </div>
        </div>
    )
}

export default RecipeSubCategoryMaster;
