import React from "react";
import Select from "react-select";
import "../Styles/reports.css"
const ActiveRecipe = () => {
  const recipeGroupOptions = [
    { value: "group1", label: "Recipe Group 1" },
    { value: "group2", label: "Recipe Group 2" },
  ];

  const categoryOptions = [
    { value: "category1", label: "Category 1" },
    { value: "category2", label: "Category 2" },
  ];

  const subCategoryOptions = [
    { value: "subcategory1", label: "Sub-Category 1" },
    { value: "subcategory2", label: "Sub-Category 2" },
  ];

  return (
    <div className="container">
      {/* Filter Section */}
      <div className="filters-section py-3 d-flex justify-content-between">
        <div className="filter-group">
          <label>Recipe Group</label>
          <Select options={recipeGroupOptions} className="filter-select" />
        </div>
        <div className="filter-group">
          <label>Category</label>
          <Select options={categoryOptions} className="filter-select" />
        </div>
        <div className="filter-group">
          <label>Sub-Category</label>
          <Select options={subCategoryOptions} className="filter-select" />
        </div>
        <div className="button-group d-flex align-items-center">
          <button className="btn btn-primary search-btn">Search</button>
          <button className="btn btn-danger reset-btn">Reset</button>
        </div>
      </div>

      {/* Table Section */}
      <div className="table-section">
        <table className="table table-bordered active-recipe-table">
          <thead className="bg-purple text-white">
            <tr>
              <th>Ingredients</th>
              <th>Quantity</th>
              <th>Unit</th>
              <th>Actual Qty</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            {/* First Recipe */}
            <tr className="recipe-header">
              <td colSpan="5" className="bg-light-purple">
                Masala Bread (Re-00012)
              </td>
            </tr>
            {[...Array(6)].map((_, i) => (
              <tr key={i}>
                <td>Coconut</td>
                <td>0.30</td>
                <td>KG</td>
                <td>0.30</td>
                <td>0.30</td>
              </tr>
            ))}
            <tr className="selling-cost-row">
              <td>Selling Cost</td>
              <td>Cost %</td>
              <td colSpan="2">#Error</td>
              <td>Total Cost: 3.50</td>
            </tr>
            {/* Second Recipe */}
            <tr className="recipe-header">
              <td colSpan="5" className="bg-light-purple">
                Masala Bread (Re-00012)
              </td>
            </tr>
            {[...Array(6)].map((_, i) => (
              <tr key={i}>
                <td>Coconut</td>
                <td>0.30</td>
                <td>KG</td>
                <td>0.30</td>
                <td>0.30</td>
              </tr>
            ))}
            <tr className="selling-cost-row">
              <td>Selling Cost</td>
              <td>Cost %</td>
              <td colSpan="2">#Error</td>
              <td>Total Cost: 3.50</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ActiveRecipe;
