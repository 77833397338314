import React, { useState, useEffect } from 'react';

const AuthorizationGrn = ({ handleAuthorization, usersDetails, setUser, itemData }) => {
    function getStatusButton(status) {
        if (status === 0 || status === null) {
            return (<input className="p-2 w-100" value={"Pending"} readOnly />);
        }
        else if (status === 1 || status === null) {
            return (<input value={"Approved"} className="p-2 w-100" readOnly />);
        }
        else if (status === 5 || status === null) {
            return (<input value={"Hold"} className="p-2 w-100" readOnly />);
        }
        else if (status === 2 || status === null) {
            return (<input value={"Rejected"} className="p-2 w-100" readOnly />);
        } else {
            return (<input className="p-2 w-100" value={"Pending"} readOnly />);
        }
    }
    return (
        <div className='table-container mb-5 p-2' style={{ height: 'auto' }}>
            <table className='w-100'>
                <thead className='dynoTable' >
                    <tr>
                        <th>User Level</th>
                        <th>User Code</th>
                        <th>User Name</th>
                        <th>Approval Date</th>
                        <th>Remarks</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {usersDetails?.map((user, index) => (
                        <tr key={user.rcid}>
                            <td className='m-0 p-0'><input type="text" className='p-2 w-100' value={user?.srno || user?.userLevel} disabled /></td>
                            <td className='m-0 p-0'><input type="text" className='p-2 w-100' value={user?.userCode} disabled /></td>
                            <td className='m-0 p-0'><input type="text" className='p-2 w-100' value={user?.userNo} disabled /></td>
                            <td className='m-0 p-0'><input type="text" className='p-2 w-100 ' value={user?.docdate} disabled /></td>
                            <td className='m-0 p-0'><input type="text" className='p-2 w-100' value={user?.remarks} disabled /></td>
                            <td className='m-0 p-0'>{getStatusButton(user?.status)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default AuthorizationGrn;
