import React, { useState, useRef, Component } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import { HiPencil } from "react-icons/hi2";
import { FaEye } from "react-icons/fa";
import { PropagateLoader } from 'react-spinners';
import Pagination from '../Pagination/pagination';
import { toast } from 'react-toastify';
import ApiService from '../../services/apiService';
const SubCategoryGridMaster = ({ rowData, canEditItemMaster, fetchSubCategories, pagination, loading, setSelectedRow, handleEdit }) => {
    const gridRef = useRef();
    const [pageSize, setPageSize] = useState(20);
    let navigate = useNavigate();
    const columnDefs = [
        { headerCheckboxSelection: true, checkboxSelection: true, headerCheckboxSelectionFilteredOnly: true, suppressMovable: true, width: 50 },
        { headerName: 'Code', field: 'subcatcode', filter: true, floatingfilter: true, suppressMovable: true, width: 90 },
        { headerName: 'SubCategory Name', field: 'subcatname', filter: true, floatingfilter: true, suppressMovable: true, width: 180 },
        { headerName: 'Category Code ', field: 'catcode', filter: true, floatingfilter: true, suppressMovable: true, width: 150 },
        { headerName: 'Category Name ', field: 'catName', filter: true, floatingfilter: true, suppressMovable: true, width: 150 },
        { headerName: 'Inventory ACC ', field: 'inventoryAccountCode', filter: true, floatingfilter: true, suppressMovable: true, width: 150 },
        { headerName: 'GRN ACC ', field: 'grnAccountCode', filter: true, floatingfilter: true, suppressMovable: true, width: 110 },
        { headerName: 'Adjustment ACC ', field: 'adjustmentAccountCode', filter: true, floatingfilter: true, suppressMovable: true, width: 160 },
        { headerName: 'Work in progress ', field: 'workInProgressAccountCode', filter: true, floatingfilter: true, suppressMovable: true, width: 160 },
        { headerName: 'CGS ACC', field: 'cgsAccountCode', filter: true, floatingfilter: true, suppressMovable: true, width: 110 },
        { headerName: 'Invoice ACC', field: 'invoiceAccountCode', filter: true, floatingfilter: true, suppressMovable: true, width: 130 },
        { headerName: 'Wastage ACC', field: 'wastageAccountCode', filter: true, floatingfilter: true, suppressMovable: true, width: 140 },
        { headerName: 'Issue ACC', field: 'issueAccountCode', filter: true, floatingfilter: true, suppressMovable: true, width: 110 },
        {
            headerName: 'Actions', field: 'recordid', cellRenderer: BtnCellRenderer,
            cellRendererParams: {
                canEditItemMaster: canEditItemMaster,
                handleEdit: handleEdit,
                clicked: function (field, isActive, recordid) {
                    updateActiveStatus(field, isActive, recordid);
                },
            }, suppressMovable: true, width: 150
        }
    ];
    const onSelectionChanged = (event) => {
        const selectedRows = event.api.getSelectedRows();
        setSelectedRow(selectedRows.length === 1 ? selectedRows[0] : null);
    };
    const updateActiveStatus = async (id, isActive, data) => {
        try {
            const apiService = new ApiService();
            let body = {
                subcatcode: data?.subcatcode,
                recordId: data?.recordid,
                active: isActive
            }
            const res = await apiService.put(`UPDATE_SUB_CATEGORY`, body);
            if (res?.data?.statusCode === 201) {
                toast.success('Status Updated successfully')
                fetchSubCategories();
            } else {
                console.error('Failed to update');
            }
        } catch (error) {
            console.error('Error updating item:', error);
        }
    };
    const onCellClicked = (event) => {
        if (event.colDef.field === 'subcatcode') {
            const clickedRowData = event.data;
            setSelectedRow(clickedRowData);
            handleEdit(clickedRowData)
        }
    };
    return (
        <div>
            {loading ? (
                <div className='center'>
                    <PropagateLoader color="#6E5199" size={15} />
                </div>
            ) : (
                <>
                    <div className='gridshad' style={{ height: 500 }}>
                        <div className="ag-theme-quartz" style={{ height: 500 }}>
                            <AgGridReact
                                getRowStyle={(params) => {
                                    if (params.node.rowIndex % 2 === 0) {
                                        return { background: '#F0EDF5' };
                                    } else {
                                        return { background: '#fff' };
                                    }
                                }}
                                rowData={rowData}
                                ref={gridRef}
                                onCellClicked={onCellClicked}
                                rowSelection={'single'}
                                suppressMovableColumns={true}
                                suppressRowDrag={true}
                                columnDefs={columnDefs} onSelectionChanged={onSelectionChanged}

                            />
                        </div>
                    </div>
                    <Pagination pageSize={pageSize} setPageSize={setPageSize}  pagination={pagination} getItems={fetchSubCategories} />
                </>
            )}
        </div>
    );
};

class BtnCellRenderer extends Component {
    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
        this.btnEditClickedHandler = this.btnEditClickedHandler.bind(this);
    }

    btnClickedHandler() {
        const { value, clicked, data } = this.props;
        const newActiveStatus = data.active === 1 ? 0 : 1;
        clicked(value, newActiveStatus, data);
    }

    btnEditClickedHandler() {
        const { data, handleEdit } = this.props;
        handleEdit(data);
    }

    render() {
        const { canEditItemMaster } = this.props;
        const { active } = this.props.data;

        return (
            <div className='d-flex gap-3'>
                {/* {canEditItemMaster && (
                    <div onClick={this.btnEditClickedHandler}><HiPencil size={25} /></div>
                )} */}
                {canEditItemMaster && (
                    <div className="form-check form-switch center">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id={`flexSwitchCheck`}
                            checked={active === 1}
                            onClick={this.btnClickedHandler}
                        />
                    </div>
                )}
            </div>
        )
    }
}
export default SubCategoryGridMaster;