import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import ApiService2 from '../../../POS/services/apiService';
import ApiService from '../../services/apiService';
import InvoiceItemList from './invoiceItems';
import InvoiceGrnList from './invoiceGrntable';
import { useNavigate } from 'react-router-dom';
import AuthorizationInvoice from './invoiceAuthorization';
import { useSelector } from 'react-redux';
import { getCompany, getName, getUserId, getUserName } from '../../../Redux/selecters';
import { RiRefreshLine } from "react-icons/ri";
import { FaAngleLeft } from "react-icons/fa6";
import { FaSave } from "react-icons/fa";
import { ClipLoader } from 'react-spinners';
import Modal from 'react-bootstrap/Modal';

const CreateInvoice = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [gridVisibility, setGridVisibility] = useState([true, false, false]);
    const apiService = new ApiService();
    const [messageData, setMessageData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [authorizationData, setAuthorizationData] = useState([]);
    const [ItemData, setItemData] = useState([]);
    const [GrnItemData, setGrnItems] = useState([]);
    const [grnData, setGrnData] = useState([]);
    const [selectedGrn, setSelectedGrn] = useState();
    const [SelectedCurrency, setSelectedCurency] = useState([]);
    const [currency, setCurrency] = useState([]);
    const [POData, setPOData] = useState([]);
    const [usersDetails, setUser] = useState([]);
    const [PurchaseOrder, setPurchaseOrder] = useState([]);
    const [PurchaseOrderList, setPurchaseOrderList] = useState([]);
    const [SelectedPO, setSelectedPO] = useState([]);
    debugger
    const [invoiceNo, setInvoiceNo] = useState([]);
    const [SelectedSupplier, setSelectedSupplier] = useState([]);
    const userId = useSelector(getUserId);
    const userName = useSelector(getUserName);
    const Company = useSelector(getCompany);
    const currentDatetimeUTC = new Date().toISOString().slice(0, 16);
    const todayDate = new Date().toISOString().substr(0, 10);
    const [SupplierTab, setSupplierTab] = useState(true);
    const [POTab, setPOTab] = useState(true);
    const [CostTab, setCostTab] = useState(false);
    const [cost1, setCost1] = useState([]);
    const [cost2, setCost2] = useState([]);
    const [cost3, setCost3] = useState([]);
    const [cost4, setCost4] = useState([]);
    const [attachmentModal, setAttachmentModal] = useState(false);
    const [attachmentList, setAttachmentList] = useState([{ id: Date.now(), fileName: '', remarks: '' }]);
    const handleClose = () => setAttachmentModal(false);
    const handleOpen = () => setAttachmentModal(true);
    const [previewModal, setPreviewModal] = useState(false);
    const [previewImage, setPreviewImage] = useState(null);
    const getCurrentLocalDatetime = () => {
        const localDate = new Date();
        const offset = localDate.getTimezoneOffset() * 60000;
        const localISOTime = new Date(localDate - offset).toISOString().slice(0, 16);
        return localISOTime;
    }
    const navigate = useNavigate();
    useEffect(() => {
        fetchData()
        getCurrency()
        getApprovalUser()
    }, [])
    const fetchData = async () => {
        const response = await apiService.get("GET_PO_INVOICE");
        setPurchaseOrder(response.data.result);
        const PoList = await apiService.get("GET_PO");
        setPurchaseOrderList(PoList.data.result)
        let cost1 = await apiService.get('GET_COST_ONE')
        let cost2 = await apiService.get('GET_COST_TWO')
        let cost3 = await apiService.get('GET_COST_THREE')
        let cost4 = await apiService.get('GET_COST_FOUR')
        setCost1(cost1?.data?.result)
        setCost2(cost2?.data?.result)
        setCost3(cost3?.data?.result)
        setCost4(cost4?.data?.result)
    }
    const fetchItemsData = async (grnList) => {
        const selectedGrns = grnList.filter(grn => grn.checked === 1);
        const items = await apiService.get("GET_GRN");
        let combinedGrn = {
            recordIds: [],
            requisitionDate: todayDate,
            invoiceNos: [],
            contractNos: [],
            remarks: [],
            requestors: [],
            currencyCodes: [],
            costCenters2: [],
            costCenters3: [],
            costCenters4: [],
            deliveryPoints: [],
            contactPersons: [],
            contactNos: [],
            taskCodes: [],
            locations: [],
            grnDetails: []
        };
        selectedGrns.forEach(selectedGrn => {
            const grnData = items.data.result.find(item => item?.grnNo === selectedGrn.grnNo);
            if (grnData) {
                combinedGrn.recordIds.push(grnData.grnNo);
                combinedGrn.invoiceNos.push(grnData.invoiceNo);
                combinedGrn.contractNos.push(grnData.contractNo);
                combinedGrn.remarks.push(grnData.remarks);
                combinedGrn.requestors.push(grnData.requestor);
                combinedGrn.currencyCodes.push(grnData.currencyCode);
                combinedGrn.costCenters2.push(grnData.costCenter2);
                combinedGrn.costCenters3.push(grnData.costCenter3);
                combinedGrn.costCenters4.push(grnData.costCenter4);
                combinedGrn.deliveryPoints.push(grnData.deliveryPoint);
                combinedGrn.contactPersons.push(grnData.contactPerson);
                combinedGrn.contactNos.push(grnData.contatNo);
                combinedGrn.taskCodes.push(grnData.projectCode);
                combinedGrn.locations.push(grnData.warehouse);
                combinedGrn.grnDetails = [...combinedGrn.grnDetails, ...grnData.grnDetails];
            }
        });
        setFormData({
            ...formData,
            recordIds: combinedGrn.recordIds.join(', '),
            invoiceNo: combinedGrn.invoiceNos.join(', '),
            contractNo: combinedGrn.contractNos.join(', '),
            remarks: combinedGrn.remarks.join(', '),
            requestor: combinedGrn.requestors.join(', '),
            currencyCode: combinedGrn.currencyCodes.join(', '),
            costCenter2: combinedGrn.costCenters2.join(', '),
            costCenter3: combinedGrn.costCenters3.join(', '),
            costCenter4: combinedGrn.costCenters4.join(', '),
            deliveryPoint: combinedGrn.deliveryPoints.join(', '),
            contactPerson: combinedGrn.contactPersons.join(', '),
            contactNo: combinedGrn.contactNos.join(', '),
            taskCode: combinedGrn.taskCodes.join(', '),
            location: combinedGrn.locations.join(', '),
        });
        if (combinedGrn.grnDetails.length === 0) {
            setGrnItems();
        } else {
            setGrnItems(combinedGrn.grnDetails || []);
        }
        setSelectedGrn(selectedGrns || []);
    }
    const getApprovalUser = async () => {
        try {
            const apiService = new ApiService();
            let docParam = `?TypeOfDoc=INV&DocId=Invoice`
            let DocId = await apiService.getApiParamater('GET_DOC_ID', docParam)
            setInvoiceNo(DocId?.data.result[0].docId);
            const approvalList = await apiService.get('GET_APPROVAL_USER');
            const approvals = approvalList?.data.result?.filter(dep => dep.active === 1)
            const approvalslist = approvals?.filter(dep => dep.docType === "Invoice")
            setUser(approvalslist)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const getCurrency = async () => {
        let param = `?isActive=true`
        let res = await apiService.getApiParamater('GET_CURRENCY_MASTER', param)
        setCurrency(res?.data?.result)
        const filteredDenominations = res?.data?.result?.find(currency => currency?.isDefault === true);
        setFormData({ ...formData, exchangeRate: filteredDenominations.conversionRate });
        if (filteredDenominations) {
            setSelectedCurency({ label: filteredDenominations.code, value: filteredDenominations });
        }
    }
    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
        const newGridVisibility = gridVisibility.map((_, index) => index === tabIndex);
        setGridVisibility(newGridVisibility);
    }
    const handleMessage = (data) => {
        setMessageData(data);
        console.log(data, 'reqData')

    }
    const handleRequisitionData = (data) => {
        setItemData(data);
        console.log(data, 'reqData')
    }
    const GrnData = (data) => {
        setGrnData(data);
        fetchItemsData(data)
        console.log(data, 'grnData')
    }
    const handleAuthorization = (data) => {
        setAuthorizationData(data);
        console.log(data, 'reqData')

    }
    const [formData, setFormData] = useState({
        InvoiceNO: "",
        remarks: "",
        InvoiceDate: getCurrentLocalDatetime(),
        deliveryDate: getCurrentLocalDatetime(),
        PaymentTerm: "",
        POType: "",
        SuppInvoiceNo: "",
        Department: "",
        Currency: "",
        exchangeRate: "",
        DueDate: getCurrentLocalDatetime(),
        TaxInvoice: "",
        CostCentre5: "",
        Narration: "",
        costCenter1: "",
        costCenter2: "",
        costCenter3: "",
        costCenter4: "",
    })
    const CreateInvoice = async () => {
        setLoader(true)
        if (ItemData.length === 0) {
            toast.warning("Please add items");
            setLoader(false)
            return;
        }

        if (!formData?.InvoiceDate) {
            toast.warning("Please enter Invoice Date");
            setLoader(false)
            return;
        }
        if (!formData?.DueDate) {
            toast.warning("Please enter Due Date");
            setLoader(false)
            return;
        }
        if (!formData?.SuppInvoiceNo) {
            toast.warning("Please enter Supp Invoice No");
            setLoader(false)
            return;
        }
        if (!SelectedPO) {
            toast.warning("Please enter Purchase Order No.");
            setLoader(false)
            return;
        }

        try {
            const currentDatetimeUTC = new Date().toISOString();
            const apiService = new ApiService();
            const totalAmount = ItemData.reduce((sum, row) => sum + (row?.amount || 0), 0);
            const invoiceRegDetails = ItemData?.map((item, index) => {
                const selectedGrn = grnData?.find(rec => rec.grnNo === item.grnNo);
                return {
                    itemCode: item?.ItemNo,
                    itemDescription: item.itemDescription,
                    uom: item.uom,
                    qty: parseInt(item.qty),
                    baseUOM: item?.uom,
                    baseQty: item.qty,
                    budgetCode: '',
                    specification: item.specification,
                    unitPrice: item.unitPrice,
                    amount: item.amount,
                    baseAmount: item.baseAmount,
                    convToInvUOM: item.stock,
                    isActive: true,
                    srno: index + 1,
                    vatPerc: item.vat,
                    vatAmount: item.vatAmount,
                    grnNo: selectedGrn?.grnNo,
                };
            }).filter(item => item);
            const body = {
                invoiceNo: invoiceNo,
                invoiceRef:formData?.SuppInvoiceNo,
                invoiceDate: formData?.InvoiceDate,
                pOrderNo: SelectedPO.label,
                vendorId: SelectedSupplier?.value?.vendorId,
                vendorName: SelectedSupplier.label,
                docDate: formData?.InvoiceDate,
                location: formData?.location,
                costCenter1: formData?.costCenter1?.label,
                costCenter2: formData?.costCenter2?.label,
                costCenter3: formData?.costCenter3?.label,
                costCenter4: formData?.costCenter4?.label,
                costCenter5: formData.TaxInvoice,
                currencyCode: SelectedCurrency.label,
                exchangeRate: SelectedCurrency?.value.conversionRate,
                terms: SelectedSupplier?.value?.terms,
                department: SelectedSupplier?.value?.department,
                aprrovalUser: authorizationData?.userNo || usersDetails[0]?.userNo,
                status: 0,
                remarks: formData.Narration,
                dueDate: formData.DueDate,
                isActive: true,
                isEditable: 0,
                invoiceRegDetails: invoiceRegDetails,
                amount: totalAmount,
                baseAmount: totalAmount,
            };
            const response = await apiService.post("CREATE_INVOICE", body);
            if (response?.data?.statusCode === 201) {
                const userData2 = {
                    docType: "Invoice",
                    docId: invoiceNo,
                    department: "IT",
                    company: Company?.selectedCompany?.companyNo,
                    userCode: userId,
                    userName: userName,
                };
                const docBody = {
                    docId: "Invoice",
                    typeOfDoc: "INV",
                };
                const response2 = await apiService.post("CREATE_DOC_AUTHORIZATION", userData2);
                const paramsGet = new URLSearchParams();
                paramsGet.append('docType', "Invoice");
                paramsGet.append('docId', invoiceNo);
                paramsGet.append('srno', 1);
                const response3 = await apiService.getApiParamater('GET_DOC_APPROVAL_USER', `?${paramsGet.toString()}`);
                if (response3?.data?.result.length > 0) {
                    const toEmails = response3?.data?.result?.map(user => user.userEmail);
                    const emailbody = {
                        toEmails: toEmails,
                        subject: "Invoice",
                        body: "",
                        docNo: invoiceNo,
                        srNo: 1,
                        docType: "Invoice"
                    };
                    const emailres = await apiService.post("DOC_APPROVAL_EMAIL_POST", emailbody);
                }
                const docRes = await apiService.put("UPDATE_DOC_ID", docBody);
                setLoader(false)
                toast.success(`${invoiceNo} Created successfully`);
                navigate("/Inventory/invoice/List");
            }
        } catch (error) {
            setLoader(false)
            console.error("Error Creating issue:", error);
        }
    }
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'deliveryDate' || name === 'InvoiceDate' || name === 'DueDate') {
            const dateParts = e.target.value.split("-");
            if (dateParts[0].length > 4) {
                return;
            }
        }
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    const handlePoChange = (selectedOption) => {
        const selectedPO = PurchaseOrderList.find(po => po?.pOrderNo === selectedOption?.label);
        setSelectedPO({ label: selectedPO?.pOrderNo, value: selectedPO?.pOrderNo })
        setSelectedSupplier({ label: selectedPO.vendorName, value: selectedPO })
        setFormData({ ...formData, PurchaseOrderDate: selectedPO.docDate });
        setFormData({ ...formData, PaymentTerm: selectedPO.terms })
        setPOData(selectedPO)
    }
    const handleBackClick = () => {
        window.history.back();
    }
    const handleCost1 = (selectedOption) => {
        setFormData({ ...formData, costCenter1: selectedOption });
    }
    const handleCost2 = (selectedOption) => {
        setFormData({ ...formData, costCenter2: selectedOption });
    }
    const handleCost3 = (selectedOption) => {
        setFormData({ ...formData, costCenter3: selectedOption });
    }
    const handleCost4 = (selectedOption) => {
        setFormData({ ...formData, costCenter4: selectedOption });
    }
    const handleFileSelect = (event, id) => {
        const file = event.target.files[0];
        if (file) {
            setAttachmentList((prevList) => [
                ...prevList.map((attachment) =>
                    attachment.id === id ? { ...attachment, fileName: file.name, file: file } : attachment
                ),
                { id: Date.now(), fileName: '', remarks: '' },
            ]);
        }
    }
    const handleView = (file) => {
        if (file && file instanceof File) {
            setPreviewImage(URL.createObjectURL(file));
            setPreviewModal(true);
        } else {
            console.error("No valid file to preview");
        }
    }
    const handleDelete = (id) => {
        const updatedList = attachmentList.filter((attachment) => attachment.id !== id);
        setAttachmentList(updatedList);
    }
    return (
        <div className='px-4'>
            <div className='d-flex justify-content-between pt-3'>
                <h5 className='col mainInvHead'>Supplier Invoice</h5>
                <div className='d-flex gap-3'>
                    {loader === true ?
                        <button className='mainbtn ' type="submit"><ClipLoader color="#ffffff" size={25} /></button> :
                        <button className='mainbtn ' type="submit" onClick={CreateInvoice}><FaSave className='center mt-1 mx-2' /><div>Save</div></button>
                    }
                    <button className='mainbtnRed' ><RiRefreshLine className='center mt-1 mx-2' /><div>Reset</div></button>
                    <button onClick={handleBackClick} className='mainbtnSecondary'><FaAngleLeft className='center mt-1 mx-2' /><div>Back</div></button>
                </div>
            </div>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header"
                    onClick={() => setPOTab(!POTab)}>
                    <div className='h6 pt-1'>Invoice Details</div>
                </div>
                <div className={`slide-content ${POTab ? 'open' : 'closed'}`}>
                    <div className={`border-box2 p-3 ${POTab ? 'slide-in' : 'slide-out'}`}>
                        <div className="form-group row pb-3">
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="InvoiceNO" className="form-label  pt-1 start">Invoice No</label>
                                <input
                                    className="form-Input w-70"
                                    id="invoiceNo"
                                    value={invoiceNo}
                                    name="invoiceNo"
                                    disabled
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="deliveryDate" className="form-label  pt-1 start">Date </label>
                                <input className='form-Input w-70' type='datetime-local'
                                    name="deliveryDate"
                                    id="deliveryDate" min={currentDatetimeUTC}
                                    value={formData.deliveryDate}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="POType" className="form-label  pt-1 start">PO Type</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    id="POType"
                                    value={formData.POType}
                                    onChange={handleInputChange}
                                    name="POType"
                                />
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="InvoiceDate" className="form-label  pt-1 start">Supp. Invoice Date</label>
                                <input
                                    type="datetime-local"
                                    className="form-Input w-70"
                                    id="InvoiceDate" min={currentDatetimeUTC}
                                    value={formData?.InvoiceDate}
                                    onChange={handleInputChange}
                                    name="InvoiceDate"
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-1 start">Supplier Name</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    value={SelectedSupplier}
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="PaymentTerm" className="form-label  pt-1 start">Payment Term</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    id="PaymentTerm"
                                    value={formData.PaymentTerm}
                                    onChange={handleInputChange}
                                    name="PaymentTerm"
                                />
                            </div>
                        </div>
                        <div className="form-group row  pb-3">
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-1 start">Purchase Order No *</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    value={SelectedPO}
                                    options={PurchaseOrder?.map((po) => ({ value: po?.pOrderNo, label: po?.pOrderNo }))}
                                    onChange={(selectedOption) => handlePoChange(selectedOption)}
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="SuppInvoiceNo" className="form-label  pt-1 start">Supp. Invoice No</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    id="SuppInvoiceNo"
                                    value={formData.SuppInvoiceNo}
                                    onChange={handleInputChange}
                                    name="SuppInvoiceNo"
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="DueDate" className="form-label  pt-1 start">Due Date</label>
                                <input
                                    type="datetime-local"
                                    className="form-Input w-70"
                                    id="DueDate" min={currentDatetimeUTC}
                                    value={formData.DueDate}
                                    onChange={handleInputChange}
                                    name="DueDate"
                                />
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="TaxInvoice" className="form-label  pt-1 start">Tax Invoice No ( KRA )</label>
                                <input
                                    type="number" onWheel={(e) => e.target.blur()}
                                    className="form-Input w-70"
                                    id="TaxInvoice"
                                    value={formData.TaxInvoice}
                                    onChange={handleInputChange}
                                    name="TaxInvoice"
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="Currency" className="form-label  pt-1 start">Currency</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    isSearchable={false}
                                    value={SelectedCurrency}
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="EXRate" className="form-label  pt-1 start">EX Rate</label>
                                <input
                                    type="number"
                                    className="form-Input w-70"
                                    disabled
                                    onWheel={(e) => e.target.blur()}
                                    id="exchangeRate"
                                    value={formData.exchangeRate}
                                    onChange={handleInputChange}
                                    name="exchangeRate"
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="d-flex justify-content-between col-4">
                                <label htmlFor="Narration" className="form-label  pt-1 start">Narration</label>
                                <input
                                    type="text" onWheel={(e) => e.target.blur()}
                                    className="form-Input w-70"
                                    id="Narration"
                                    value={formData.Narration}
                                    onChange={handleInputChange}
                                    name="Narration"
                                />
                            </div>
                            <div className='col-1 px-5 mx-3'></div>
                            <div className="col-2 brderbox center" onClick={() => handleOpen()} >
                                {'Attachment'}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header"
                    onClick={() => setSupplierTab(!SupplierTab)}>
                    <div className='h6 pt-1'>GRN Details</div>
                </div>
                <div className={`slide-content ${SupplierTab ? 'open' : 'closed'}`}>
                    <div className="border-box2">
                        <div className="grid-1">
                            <InvoiceGrnList GrnData={GrnData} SelectedPO={SelectedPO.value} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header" onClick={() => setCostTab(!CostTab)}>
                    <div className="h6 pt-1">Cost Center Details</div>
                </div>
                <div className={`slide-content ${CostTab ? 'open' : 'closed'}`}>
                    <div className="border-box2 p-3">
                        <div className="form-group row pb-3">
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="PropertyGroup" className="form-label  pt-1 start">Property Group</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    value={formData.costCenter1}
                                    onChange={handleCost1}
                                    options={cost1?.map((sup) => ({ value: sup, label: sup?.description }))}
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="Property" className="form-label  pt-1 start">Property</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0" value={formData.costCenter2}
                                    onChange={handleCost2}
                                    options={cost2?.map((sup) => ({ value: sup, label: sup?.description }))}
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="Department" className="form-label  pt-1 start">Department</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0" value={formData.costCenter3}
                                    onChange={handleCost3}
                                    options={cost3?.map((sup) => ({ value: sup, label: sup?.description }))}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="d-flex justify-content-between col-4">
                                <label htmlFor="Outlet" className="form-label  pt-1 start">Outlet</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0" value={formData.costCenter4}
                                    onChange={handleCost4}
                                    options={cost4?.map((sup) => ({ value: sup, label: sup?.description }))}
                                />
                            </div>
                            <div className="d-flex justify-content-between col-4">
                                <label htmlFor="CostCentre5" className="form-label  pt-1 start">Cost Centre 5</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    isSearchable={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="slide-container p-2 mt-3">
                <div className="container-fluid">
                    <div className="nav nav-pills overslid d-flex gap-4">
                        {/* Nav tabs */}
                        <div
                            className={`dashitems sidebar-text center ${activeTab === 0 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(0)}
                        >
                            Items
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 1 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(1)}
                        >
                            Authorization
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 2 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(2)}
                        >
                            Message
                        </div>
                    </div>
                </div>
            </div>
            <div className="grid-1 brderbox2 p-4 mb-3">
                {gridVisibility[0] && (
                    <InvoiceItemList onRequisitionData={handleRequisitionData} GrnItemData={GrnItemData} />
                )}
                {gridVisibility[1] && (
                    <AuthorizationInvoice handleAuthorization={handleAuthorization}
                        usersDetails={usersDetails}
                        setUser={setUser}
                        itemData={POData} />
                )}
                {gridVisibility[2] && (
                    <div className="grid-3 border">
                        {/* <MessageGrid handleMessage={handleMessage} /> */}
                    </div>
                )}
            </div>
            <div>
                <Modal show={attachmentModal} size="lg" onHide={handleClose} >
                    <Modal.Header closeButton>
                        <Modal.Title>Attachments</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="col d-flex justify-content-between">
                            <label htmlFor="docNp" className="form-label  pt-2 start">Document No</label>
                            <input disabled value={invoiceNo} className="form-Input w-70" />
                        </div>
                        <div className="pt-2">
                            <table>
                                <thead className="dynoTable">
                                    <tr>
                                        <th>File Name</th>
                                        <th>Remarks</th>
                                        <th>Upload</th>
                                        <th>View</th>
                                        <th>Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {attachmentList?.map((attachment, index) => (
                                        <tr key={attachment.id}>
                                            <td className='m-0 p-0'><input className='p-0' type='text' disabled value={attachment.fileName} style={{ width: "320px" }} /></td>
                                            <td>
                                                <input type="text" value={attachment.remarks}
                                                    onChange={(e) => {
                                                        const updatedList = attachmentList?.map((item) =>
                                                            item.id === attachment.id ? { ...item, remarks: e.target.value } : item
                                                        );
                                                        setAttachmentList(updatedList);
                                                    }}
                                                    style={{ width: '345px' }}
                                                />
                                            </td>
                                            <td>
                                                <button className='mainbtn ' onClick={() => document.getElementById(`fileInput-${attachment.id}`).click()} type="submit"><div>Upload</div></button>
                                                <input
                                                    type="file" accept="image/*"
                                                    style={{ display: 'none' }}
                                                    onChange={(e) => handleFileSelect(e, attachment.id)}
                                                    id={`fileInput-${attachment.id}`}
                                                />
                                            </td>
                                            <td><button className='mainbtnRed ' onClick={() => handleView(attachment.file)} disabled={!attachment.fileName}>
                                                <div>View</div></button></td>
                                            <td>
                                                <button onClick={() => handleDelete(attachment.id)} disabled={!attachment.fileName} className='mainbtnSecondary'>
                                                    <div>Delete</div></button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className='mainbtn'  onClick={handleClose} >Attach</button>
                    </Modal.Footer>
                </Modal>
                <Modal show={previewModal} size="lg" onHide={() => setPreviewModal(false)}
                    backdrop="static"
                    keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Image Preview</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {previewImage && <img src={previewImage} alt={previewImage} style={{ width: '100%', objectFit: 'cover' }} />}
                    </Modal.Body>
                </Modal>
            </div>
        </div >
    )
}

export default CreateInvoice