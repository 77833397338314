import React, { useEffect, useState, useRef } from 'react';

const IngredientsItemList = ({ onRequisitionData, itemData, selectedLocation }) => {
    const [itemRows, setItemRows] = useState([]);
    const [rows, setRows] = useState([{
        requisitionNo: '',
        srno: '',
        recordId: '',
        ItemNo: '',
        itemDescription: '',
        specification: '',
        qty: '',
        uom: '',
        totalQty: '',
        baseQty: '',
        baseAmount: '',
        stock: '',
        amount: '',
        discount: '',
        discountAmount: '',
        calculatedAmount: "",
        vat: 0,
        vatDec: '',
        vatAmount: ''
    }]);
    return (
        <div className='table-container p-2 mb-5' style={{ overflowX: 'auto', height: "400px" }}>
            <table>
                <thead className='dynoTable'>
                    <tr>
                        <th>Sr no</th>
                        <th>Item No</th>
                        <th>Description</th>
                        <th>Qty Weight</th>
                        <th>UOM</th>
                        <th>Rate</th>
                        <th>Waste</th>
                        <th>Price</th>
                        <th>Remarks</th>
                    </tr>
                </thead>
                <tbody>
                    {Array.isArray(rows) && rows?.map((row, index) => (
                        <tr key={index}>
                            <td className='m-0 p-0'><input className='p-2' disabled value={row.srno} style={{ width: "80px" }} /></td>
                            <td className='m-0 p-0'><input className='p-2' disabled value={row.ItemNo} /></td>
                            <td className='m-0 p-0'><input className='p-2' disabled value={row.itemDescription} style={{ width: "200px" }}/></td>
                            <td className='m-0 p-0'><input className='p-2' disabled value={row.specification} style={{ width: "130px" }} /></td>
                            <td className='m-0 p-0'><input className='p-2' disabled value={row.qty} style={{ width: "100px" }} /></td>
                            <td className='m-0 p-0'><input className='p-2' disabled value={row.uom} style={{ width: "130px" }} /></td>
                            <td className='m-0 p-0'><input className='p-2' disabled value={row.baseQty} style={{ width: "130px" }} /></td>
                            <td className='m-0 p-0'><input className='p-2' disabled value={row.baseAmount} style={{ width: "130px" }} /></td>
                            <td className='m-0 p-0'><input className='p-2' disabled value={row.amount} style={{ width: "130px" }} /></td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default IngredientsItemList;
