import React, { useState, useEffect, Component } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { ReactComponent as Link } from '../../Assets/svg/linkIcon.svg';
import moment from 'moment';
import ApiService from '../../services/apiService';
import { AiOutlinePrinter } from "react-icons/ai"
import { IoAddSharp } from "react-icons/io5";
import Pagination from '../pagination/pagination';
import { GrEdit } from "react-icons/gr";
import { hasPermission } from "../../services/roleService"
import { FaRegEye } from "react-icons/fa6";

const PurchaseReturn = () => {
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(true);
    let navigate = useNavigate();
    const apiService = new ApiService();
    const [pageSize, setPageSize] = useState(20);
    const [selectedRow, setSelectedRow] = useState(null);
    const [pagination, setPagination] = useState();
    const [selectedtem, setSelectedItem] = useState(null);
    const [formData, setFormData] = useState({
        Status: { label: 'All', value: 'All' },
    });
    const [permissions, setPermissions] = useState([]);
    useEffect(() => {
        getItems();
        const storedPermissions = JSON.parse(localStorage.getItem('RoleAccess')) || [];
        const permissions = storedPermissions?.filter(acc => acc.formName === "Purchase Return");
        setPermissions(permissions[0]);
    }, []);

    const StatusFilter = [
        { value: 'All', label: 'All' },
        { value: '0', label: 'Pending' },
        { value: '1', label: 'Approved' },
        { value: '5', label: 'Hold' },
        { value: '2', label: 'Rejected' },
    ];
    useEffect(() => {
        if (pageSize) {
            getItems();
        }
        if (formData) {
            getItems();
        }
    }, [pageSize, formData]);
    useEffect(() => {
        getItems();
    }, []);
    const handleEdit = () => {
        if (selectedRow) {
            if (selectedRow?.status === 1) {
                const updatedData = { ...selectedRow, eye: !selectedRow.eye?.active };
                navigate("/Inventory/PurchaseReturn/Create", { state: updatedData });
            } else {
                navigate("/Inventory/PurchaseReturn/Create", { state: selectedRow });
            }
        }
    };
    const handleEye = (clickedRowData) => {
        if (clickedRowData?.prNo) {
            const updatedData = { ...selectedRow, eye: !selectedRow.eye?.active };
            navigate("/Inventory/PurchaseReturn/Create", { state: updatedData });
        } else {
            const updatedData = { ...selectedRow, eye: !selectedRow.eye?.active };
            navigate("/Inventory/PurchaseReturn/Create", { state: updatedData });
        }
    }
    const dateComparator = (date1, date2) => {
        const momentDate1 = moment(date1);
        const momentDate2 = moment(date2);
        return momentDate1.isSame(momentDate2) ? 0 : momentDate1.isBefore(momentDate2) ? -1 : 1;
    };
    const dateFormatter = (params) => {
        const date = moment(params.value);
        return date.format("DD-MMM-YYYY");
    };
    const getItems = async (newPageNo = pagination?.currentPage) => {
        try {

            if (formData?.Status?.value === 'All') {
                const params = new URLSearchParams();
                params.append('PageNo', newPageNo || 1);
                params.append('PageSize', pageSize || 20);
                const res = await apiService.getApiParamater('GET_P_RETURN', `?${params.toString()}`);
                setPagination(res.data.paginationData)
                setRowData(res?.data?.result || []);
                setLoading(false);
            } else {
                const params = new URLSearchParams();
                params.append('PageNo', newPageNo || 1);
                params.append('PageSize', pageSize || 20);
                params.append('Status', parseInt(formData?.Status?.value));
                const res = await apiService.getApiParamater('GET_P_RETURN', `?${params.toString()}`);
                setPagination(res.data.paginationData)
                setRowData(res?.data?.result || []);
                setLoading(false);
            }
        } catch (error) {
            console.error('Error fetching items:', error);
            setLoading(false);
        }
    }
    const handlePrint = () => {
        debugger
        if (selectedRow) {
            navigate(`/Inventory/PurchaseReturn/${selectedRow?.prNo}`, { state: selectedRow })
        }
    }
    const Comparison = [
        { headerCheckboxSelection: true, checkboxSelection: true, headerCheckboxSelectionFilteredOnly: true, suppressMovable: true, width: 50 },
        {
            headerName: 'PR No', field: 'prNo', filter: true, floatingfilter: true, suppressMovable: true, width: 150,
        },
        {
            headerName: 'GRN No', field: 'grnNo', filter: true, floatingfilter: true, suppressMovable: true, width: 180,
        },
        {
            headerName: 'PO No', field: 'pOrderNo', filter: true, floatingfilter: true, suppressMovable: true, width: 180,
        },
        {
            headerName: 'Date', field: 'delivery_Date', filter: true, floatingfilter: true, suppressMovable: true, width: 180,
            valueFormatter: dateFormatter,
            comparator: dateComparator,
        },
        { headerName: 'Supplier', field: 'vendorName', filter: true, floatingfilter: true, minWidth: 250 },
        { headerName: 'Supplier ID', field: 'vendorId', filter: true, floatingfilter: true, minWidth: 200 },
        { headerName: 'Remarks', field: 'remarks', filter: true, floatingfilter: true, minWidth: 180 },
        {
            headerName: 'Status', field: 'status', filter: true, floatingfilter: true, minWidth: 130,
            cellRenderer: function (params) {
                if (params.value === 0 || params.value === null) {
                    return (<button class="btn pend ">Pending</button>);
                }
                else if (params.value === 1 || params.value === null) {
                    return (<button class="btn aproved">Approved</button>);
                }
                else if (params.value === 5 || params.value === null) {
                    return (<button class="btn hold">Hold</button>);
                }
                else if (params.value === 2 || params.value === null) {
                    return (<button class="btn reject">Rejected</button>);
                } else {
                    return params.value;
                }
            }
        },
    ];
    const handlenew = () => {
        navigate('/Inventory/PurchaseReturn/Create')
    }
    const handleStatusFilter = (selectedOption) => {
        setFormData({ ...formData, Status: selectedOption });
    }
    const onCellClicked = (event) => {
        if (event.colDef.field === 'prNo') {
            const clickedRowData = event.data;
            handleEye(clickedRowData)
        }
    };
    const onSelectionChanged = async (event) => {
        const selectedRows = event.api.getSelectedRows();
        setSelectedRow(selectedRows.length === 1 ? selectedRows[0] : null);
        const params = new URLSearchParams();
        params.append('DocNo', selectedRows[0]?.prNo);
        params.append('DocumentType', "PR");
        try {
            const res = await apiService.getApiParamater('GET_DOC_STATUS_EDIT', `?${params.toString()}`);
            const statuses = res?.data?.result?.map(item => item.status);
            const allAreZero = statuses.every(status => status === 0);
            const anyIsFive = statuses.some(status => status === 2);
            if (allAreZero || anyIsFive) {
                setSelectedItem(selectedRows.length === 1 ? selectedRows[0] : null);
            } else {
                console.warn("No valid status to set the item.");
                setSelectedItem(null);
            }
        } catch (error) {
            setSelectedItem(null)
            console.error("Error fetching document status:", error);
        }
    };
    return (
        <div className='p-4'>
            <div className=' border-box2 p-4 justify-content-between d-flex align-items-center pb-4'>
                <h4 className='mainInvHead'>Purchase Return List</h4>
                <div className='col-3'>
                    <Select
                        className="p-0"
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 5,
                            padding: 9
                        })}
                        menuPortalTarget={document.body}
                        styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            option: (provided, state) => ({
                                ...provided,
                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                color: state.isFocused ? '#000 ' : '#000',
                                ':hover': {
                                    backgroundColor: '#F5F5F5',
                                    color: '#000 ',
                                },
                            }),
                        }}
                        value={formData.Status}
                        options={StatusFilter}
                        onChange={handleStatusFilter}
                    />
                </div>
                <div className='d-flex justify-content-end gap-1'>
                    <div
                        onClick={handleEye}
                        className={`mainbtnGry flex-end gap-2 ${!selectedRow ? 'disabled' : ''}`}
                        style={{ cursor: selectedRow ? 'pointer' : 'not-allowed' }}
                    >
                        <FaRegEye color='#fff' className='center mt-1' />
                        <div className='center'>View</div>
                    </div>
                    {permissions?.canUpdate === true ?
                        <div
                            onClick={selectedtem && selectedtem?.status === 0 || selectedtem?.status === 2 ? handleEdit : null}
                            className={`mainbtnSecondary flex-end gap-2 ${!selectedtem || selectedtem?.status === 0 || selectedtem?.status === 2 ? '' : 'disabled'
                                }`}
                            style={{
                                cursor: selectedtem && selectedtem?.status === 0 || selectedtem?.status === 2 ? 'pointer' : 'not-allowed',
                            }}
                        >
                            <GrEdit color='#fff' className='center mt-1 ' />
                            <div className='center'>Edit</div>
                        </div> : ''
                    }
                    <div
                        onClick={handlePrint}
                        className={`mainbtnRed flex-end gap-2 ${!selectedRow ? 'disabled' : ''}`}
                        style={{ cursor: selectedRow ? 'pointer' : 'not-allowed' }}
                    >
                        <AiOutlinePrinter color='#fff' className='center mt-1 ' />
                        <div className='center'>Print</div>
                    </div>
                    {permissions?.canAdd === true ?
                        <div className='col center'>
                            <button className='mainbtn ' type="submit" onClick={() => handlenew()}>
                                <IoAddSharp color='#fff' className='center mt-1' /> <div>Add</div>
                            </button>
                        </div>
                        : ''
                    }
                </div>
            </div>
            <div className='gridshad'>
                <div className="ag-theme-quartz" style={{ height: 600 }}>
                    <AgGridReact
                        getRowStyle={(params) => {
                            if (params.node.rowIndex % 2 === 0) {
                                return { background: '#F0EDF5' };
                            } else {
                                return { background: '#fff' };
                            }
                        }}
                        rowData={rowData}
                        rowSelection={'single'}
                        columnDefs={Comparison}
                        onCellClicked={onCellClicked}
                        onSelectionChanged={onSelectionChanged}
                    />
                </div>
                <Pagination pageSize={pageSize} setPageSize={setPageSize} pagination={pagination} getItems={getItems} />
            </div>
        </div>
    );
};
export default PurchaseReturn; 