import { React, useState, useEffect } from 'react'
import "./header.css"
import { Navigate, useLocation, useNavigate, useNavigation } from 'react-router-dom';
import { connect, useDispatch } from "react-redux"
import Select from "react-select";
import { Link } from 'react-router-dom';
import { logout } from '../../../Redux/actions';
import { useSelector } from 'react-redux';
import { getProfile, getName } from '../../../Redux/selecters';
import { RiShutDownLine } from "react-icons/ri";
import Avatar from "../../Assets/Images/avatar.png"
const SalesHeader = ({ Currency }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const userName = useSelector(getName);
    const image = useSelector(getProfile);
    const handleOut = () => {
        dispatch(logout())
        localStorage.clear()
        navigate('/Login')
        window.location.reload(false);
    }
    const UserName = userName?.length > 20 ? userName.substring(0, 10) + '...' : userName;
    const DocFilter = [
        { value: '----', label: 'From 01 Jan,2024 To 31 Dec,2024' },
        { value: 'Youusuf LLC', label: 'From 01 Jan,2023 To 31 Dec,2023' },
        { value: 'Z PSRED test', label: 'From 01 Jan,2022 To 31 Dec,2022' },
    ];
    return (
        <nav class="navbar-expand-lg  invHead ">
            <div class="container-fluid">
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse d-flex end gap-4" id="navbarSupportedContent">
                    <h5 className='col-1'>Sales Trading</h5>
                    <div className='end col-5 d-flex gap-2'>
                        <div className='center'>Financial Year</div>
                        <Select
                            isSearchable={false}
                            defaultValue={{ label: 'From 01 Jan,2024 To 31 Dec,2024', value: 'From 01 Jan,2024 To 31 Dec,2024' }}
                            options={DocFilter}
                            className="w-50 p-0"
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 8,
                                padding: 9
                            })}
                        />
                    </div>
                    <div className='end col-3'>
                        <Select
                            isSearchable={false}
                            defaultValue={{ label: 'Demo', value: 'Demo' }}
                            // options={DocFilter}
                            className="w-75 p-0"
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 8,
                                padding: 9
                            })}
                        />
                    </div>
                    <div className='d-flex gap-2'>
                       
                        <div className='salesprofileimg'>
                            {image ?
                                <>{image ?
                                    <img src={image || Avatar} className='saleboxicon' /> :
                                    <img src={Avatar} className='saleboxicon' />
                                }
                                </>
                                :
                                <RiShutDownLine size={30} />
                            }
                        </div>
                        <div className="saleProfile dropdown">
                            {UserName || "Admin"}
                            <div className="dropdown-content-stats pt-1">
                                <Link to="/admin/Setting/Profile" className="p-2">Setting</Link>
                                <Link onClick={handleOut} className="p-2">Log Out</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
}

const mapStatetoProps = (state) => state;
export default connect(mapStatetoProps)(SalesHeader);