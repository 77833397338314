import React, { useEffect, useState, useRef } from 'react';
import Select from 'react-select';
import ApiService from '../../services/apiService';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import Pagination from '../Pagination/pagination';
import moment from 'moment';

const RecipeIngredients = () => {
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageSize, setPageSize] = useState(20);
  const [pagination, setPagination] = useState();
  const [selectedRow, setSelectedRow] = useState(null);
  let navigate = useNavigate();
  const gridRef = useRef();
  const [permissions, setPermissions] = useState([]);
  let dispatch = useDispatch()
  const getItems = async (newPageNo = pagination?.currentPage) => {
    try {
      const apiService = new ApiService();
      const params = new URLSearchParams();
      params.append('PageNo', newPageNo || 1);
      params.append('PageSize', pageSize || 20);
      params.append('reqType', "Purchase Request");
      const res = await apiService.getApiParamater('GET_PR', `?${params.toString()}`);
      setRowData(res?.data?.result || []);
      setPagination(res.data.paginationData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching items:', error);
      // Handle error (e.g., show error message to user)
      setLoading(false); // Set loading to false even in case of error
    }
  }
  const dateFormatter = (params) => {
    const date = moment(params.value);
    return date.format("DD-MMM-YYYY");
  };
  const dateComparator = (date1, date2) => {
    const momentDate1 = moment(date1);
    const momentDate2 = moment(date2);
    return momentDate1.isSame(momentDate2) ? 0 : momentDate1.isBefore(momentDate2) ? -1 : 1;
  };
  const columnDefs = [

    { headerCheckboxSelection: true, checkboxSelection: true, headerCheckboxSelectionFilteredOnly: true, suppressMovable: true, width: 50 },
    {
      headerName: 'Code', field: 'recordId', filter: true, floatingfilter: true, suppressMovable: true, width: 180, cellEditor: "agTextCellEditor", cellEditorParams: {
        maxLength: 20,
      }
    },
    { headerName: 'Name', field: 'remarks', filter: true, floatingfilter: true, suppressMovable: true, width: 180 },
    { headerName: 'Description', field: 'location', filter: true, floatingfilter: true, suppressMovable: true, width: 180 },
    { headerName: 'Group', field: 'costCenter1', filter: true, floatingfilter: true, suppressMovable: true, width: 180 },
    { headerName: 'Category', field: 'costCenter1', filter: true, floatingfilter: true, suppressMovable: true, width: 180 },
    { headerName: 'Sub Category', field: 'costCenter1', filter: true, floatingfilter: true, suppressMovable: true, width: 180 },
    { headerName: 'Modified by', field: 'costCenter1', filter: true, floatingfilter: true, suppressMovable: true, width: 200 },
    {
      headerName: "Modified On", field: "requisitionDate", filter: true, floatingfilter: true,
      valueFormatter: dateFormatter,
      comparator: dateComparator, suppressMovable: true, width: 200
    },
  ];
  const onCellClicked = (event) => {
    if (event.colDef.field === 'requisitionNo') {
      const clickedRowData = event.data;
      setSelectedRow(clickedRowData);
      handleEdit(clickedRowData)
    }
  };
  const onSelectionChanged = (event) => {
    const selectedRows = event.api.getSelectedRows();
    setSelectedRow(selectedRows.length === 1 ? selectedRows[0] : null);
  };

  const handleEdit = (clickedRowData) => {
    if (clickedRowData || selectedRow) {
      let eye = false
      if (clickedRowData) {
        const updatedData = { ...clickedRowData, eye: eye };
        if (updatedData?.status === 1) {
          const updatedData = { ...clickedRowData, eye: !clickedRowData.eye?.active };
          navigate("/Inventory/Requisition/PurchaseRequisition", { state: updatedData });
        } else {
          navigate("/Inventory/Requisition/PurchaseRequisition", { state: clickedRowData });
        }
      } else {
        const updatedData = { ...selectedRow, eye: eye };
        if (updatedData?.status === 1) {
          const updatedData = { ...selectedRow, eye: !selectedRow.eye?.active };
          navigate("/Inventory/Requisition/PurchaseRequisition", { state: updatedData });
        } else {
          navigate("/Inventory/Requisition/PurchaseRequisition", { state: selectedRow });
        }
      }
    }
  };
  return (
    <div className='gridshad  mt-3'>
      <div className="ag-theme-quartz" style={{ height: 650 }}>
        <AgGridReact
          getRowStyle={(params) => {
            if (params.node.rowIndex % 2 === 0) {
              return { background: '#F0EDF5' };
            } else {
              return { background: '#fff' };
            }
          }}
          rowData={rowData}
          enableFilter={true}
          floatingFilter={true}
          columnDefs={columnDefs}
          onCellClicked={onCellClicked}
          onSelectionChanged={onSelectionChanged}
          editType='stringedit'
        />
      </div>
      <Pagination pageSize={pageSize} setPageSize={setPageSize} pagination={pagination} getItems={getItems} />
    </div>
  )
}
export default RecipeIngredients