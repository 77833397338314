import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import ApiService from '../../services/apiService';
import Select from 'react-select';
import "./reports.css"
import { TfiEmail } from "react-icons/tfi";
import { HiOutlinePrinter } from "react-icons/hi2";
import { PiDownloadSimple } from "react-icons/pi";
import moment from 'moment';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { jsPDF } from 'jspdf';
import { BsFileEarmarkPdf, BsFiletypeCsv } from "react-icons/bs";
import 'jspdf-autotable';
import { FaFileCircleExclamation } from "react-icons/fa6";
import { RiRefreshLine } from "react-icons/ri";
import Pagination from '../pagination/pagination';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { useCurrency } from '../../Layouts/currencyContext';

const PurchaseOrderReports = () => {
    const { currencyPrecision } = useCurrency();
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [StockType, setStockType] = useState();
    const [SupplierList, setSuppliers] = useState();
    const [pageSize, setPageSize] = useState(20);
    const [Items, setItems] = useState([]);
    const [pagination, setPagination] = useState();
    const [toDate, setToDate] = useState();
    const [fromDate, setFromDate] = useState();
    const [dateFilter, setdateFilter] = useState(false);
    const currentDatetimeUTC = new Date().toISOString().slice(0, 16);
    const gridRef = useRef();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [emailModal, setEmailModal] = useState(false);
    const onOpenModal = () => setEmailModal(true);
    const onCloseModal = () => setEmailModal(false);
    const StatusFilter = [
        { value: '0', label: 'Pending' },
        { value: '1', label: 'Aproved' },
        { value: '2', label: 'Hold' },
        { value: '3', label: 'Rejected' },
    ];
    const FilterStatus = [
        { value: 'Partially Delivered', label: 'Partial Delievered' },
        { value: 'Completely Delivered', label: 'Completely Delivered' },
        { value: 'Not Delivered', label: 'Not Delivered' }
    ];
    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };
    const staticOptions = [];
    const [formData, setFormData] = useState({
        startdate: "",
        endDate: "",
        email: "",
        PoNum: "",
        Filterby: [{ label: 'Select Delivery Status', value: '0' }],
        VendorName: [{ label: 'Select Supplier ', value: '0' }],
        StockType: [{ label: 'Select Stock Type', value: '0' }],
        Status: [{ label: 'Filter By', value: '' }],
        Cost1: [{ label: 'Select Stock Type', value: '0' }],
        Cost2: [{ label: 'Select Stock Type', value: '0' }],
        Cost3: [{ label: 'Select Stock Type', value: '0' }],
        Cost4: [{ label: 'Select Stock Type', value: '0' }],
        ItemType: [{ label: 'Select Stock Type', value: '0' }],
        Items: [{ label: 'Select Items', value: '0' }],
    });
    const handleClearFilters = () => {
        setFormData({
            startdate: "",
            endDate: "",
            PoNum: "",
            Filterby: [{ label: 'Select Delivery Status', value: '0' }],
            VendorName: [{ label: 'Select Supplier ', value: '0' }],
            StockType: [{ label: 'Select Stock Type', value: '0' }],
            Status: [{ label: 'Filter By', value: '' }],
            Cost1: [{ label: 'Select Stock Type', value: '0' }],
            Cost2: [{ label: 'Select Stock Type', value: '0' }],
            Cost3: [{ label: 'Select Stock Type', value: '0' }],
            Cost4: [{ label: 'Select Stock Type', value: '0' }],
            ItemType: [{ label: 'Select Stock Type', value: '0' }],
            Items: [{ label: 'Select Items', value: '0' }],
        });
        setFromDate("")
        setToDate(" ")
        setdateFilter(false)
    };
    useEffect(() => {
        getItems();
    }, []);
    useEffect(() => {
        const fetchDropdownData = async () => {
            try {
                const apiService = new ApiService();
                let param = `?isActive=true`
                const stockType = await apiService.getApiParamater("GET_STOCK_TYPE", param);
                const Items = await apiService.getApiParamater("GET_ITEM", param);
                const suppliers = await apiService.get('GET_SUPPLIER');
                setSuppliers(suppliers?.data?.result);
                setStockType(stockType?.data?.result);
                setItems(Items?.data?.result);
            } catch (error) {
                console.error('Error fetching dropdown data:', error);
            }
        };
        fetchDropdownData();
        getItems()
    }, []);
    const getItems = async (newPageNo = pagination?.currentPage) => {
        try {
            const apiService = new ApiService();
            const params = new URLSearchParams();
            params.append('VendorName', formData.VendorName.label || "");
            params.append('Status', formData.Status.value || "");
            params.append('Filter', formData.Filterby.value || "");
            params.append('ItemDescription', formData.Items?.label || "");
            params.append('CostCenter1', formData.Cost1?.label || "");
            params.append('CostCenter2', formData.Cost2?.label || "");
            params.append('CostCenter3', formData.Cost3?.label || "");
            params.append('CostCenter4', formData.Cost4?.label || "");
            params.append('StockType', formData.StockType?.value?.stockTypeCode || "");
            params.append('PageNo', newPageNo || 1);
            params.append('PageSize', pageSize || 20);
            params.append('POrderNo', formData.PoNum || "");
            if (dateFilter === true) {
                params.append('FromDate', fromDate || "");
                params.append('ToDate', toDate || "");
            }

            const res = await apiService.getApiParamater('GET_PO_REPORT', `?${params.toString()}`);
            setRowData(res?.data?.result?.reverse() || []);
            setPagination(res.data.paginationData);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching items:', error);
            setLoading(false);
        }
    }
    const dateFormatter = (params) => {
        const date = moment.utc(params.value || currentDatetimeUTC).local();
        return date.format("DD-MMM-YYYY");
    };
    const dateComparator = (date1, date2) => {
        const momentDate1 = moment(date1);
        const momentDate2 = moment(date2);
        return momentDate1.isSame(momentDate2) ? 0 : momentDate1.isBefore(momentDate2) ? -1 : 1;
    }
    const Comparison = [
        { headerName: 'PO No', field: 'pOrderNo', filter: true, floatingfilter: true, suppressMovable: true, width: 110 },
        { headerName: 'Qout Ref', field: 'quotationRef', filter: true, floatingfilter: true, suppressMovable: true, width: 110 },
        { headerName: 'Requisition NO ', field: 'requisitionNo', filter: true, floatingfilter: true, suppressMovable: true, width: 110, },
        {
            headerName: 'PO Date', field: 'docDate', filter: true, floatingfilter: true, suppressMovable: true, width: 130, filter: 'agDateColumnFilter',
            valueFormatter: dateFormatter,
            comparator: dateComparator,
        },
        { headerName: 'Vendor', field: 'vendorName', filter: true, floatingfilter: true, suppressMovable: true, width: 110 },
        { headerName: 'Payment Term', field: 'terms', filter: true, floatingfilter: true, suppressMovable: true, width: 110 },
        { headerName: 'Supplier Contact', field: 'attention', filter: true, floatingfilter: true, suppressMovable: true, width: 110 },
        { headerName: 'Department', field: 'department', filter: true, floatingfilter: true, suppressMovable: true, width: 110 },
        {
            headerName: 'Delivery Date', field: 'deliveryDate', filter: true, floatingfilter: true, suppressMovable: true, width: 150, filter: 'agDateColumnFilter',
            valueFormatter: dateFormatter,
            comparator: dateComparator,
        },
        { headerName: 'Curr Code', field: 'currencyCode', filter: true, floatingfilter: true, suppressMovable: true, width: 110 },
        { headerName: 'Exchange Rate', field: 'exchangeRate', filter: true, floatingfilter: true, suppressMovable: true, width: 110 },
        {
            headerName: 'Status', field: 'status', filter: true, floatingfilter: true, suppressMovable: true, width: 110,
            cellRenderer: function (params) {
                if (params.value === 0 || params.value === null) {
                    return (<div>Pending</div>);
                }
                else if (params.value === 1 || params.value === null) {
                    return (<div>Approved</div>);
                }
                else if (params.value === 5 || params.value === null) {
                    return (<div>Hold</div>);
                }
                else if (params.value === 3 || params.value === null) {
                    return (<div>Rejected</div>);
                } else {
                    return params.value;
                }
            }
        },
        { headerName: 'Cost center 1', field: 'costCenter1', filter: true, floatingfilter: true, suppressMovable: true, width: 110 },
        { headerName: 'Cost center 2', field: 'costCenter2', filter: true, floatingfilter: true, suppressMovable: true, width: 110 },
        { headerName: 'Cost center 3', field: 'costCenter3', filter: true, floatingfilter: true, suppressMovable: true, width: 110 },
        { headerName: 'Cost center 4', field: 'costCenter4', filter: true, floatingfilter: true, suppressMovable: true, width: 110 },
        { headerName: 'Delivery Status', field: 'deliveryStatus', filter: true, floatingfilter: true, suppressMovable: true, width: 110 },
        { headerName: 'Item Code', field: 'itemCode', filter: true, floatingfilter: true, suppressMovable: true, width: 110 },
        { headerName: 'Item Description', field: 'itemDescription', filter: true, floatingfilter: true, suppressMovable: true, width: 300 },
        { headerName: 'UOM', field: 'uom', filter: true, floatingfilter: true, suppressMovable: true, width: 110 },
        { headerName: 'QTY', field: 'qty', filter: true, floatingfilter: true, suppressMovable: true, width: 110 },
        { headerName: 'Unit Price', field: 'unitPrice', filter: true, floatingfilter: true, suppressMovable: true, width: 110 },
        { headerName: 'Vat %', field: 'vatPerc', filter: true, floatingfilter: true, suppressMovable: true, width: 110 },
        { headerName: 'Vat Amount', field: 'vatAmount', filter: true, floatingfilter: true, suppressMovable: true, width: 110 },
        { headerName: 'Amount', field: 'amount', filter: true, floatingfilter: true, suppressMovable: true, width: 110 },
        { headerName: 'Net Amount', field: 'netAmount', filter: true, floatingfilter: true, suppressMovable: true, width: 110 },
        { headerName: 'GRN Qty', field: 'grnQty', filter: true, floatingfilter: true, suppressMovable: true, width: 110 },

    ];
    const onBtnCSVExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv({
            fileName: 'PurchaseOrderReport.csv'
        });
    }, []);
    const onBtPDFExport = useCallback(() => {
        const doc = new jsPDF();
        const rowData = [];
        const columnHeaders = [];
        const comparisonFields = [
            'recordId',
            'requisitionNo',
            'docDate',
            'vendorName',
            'terms',
            'status',
            'deliveryDate',
            'deliveryStatus',
            'itemCode',
            'itemDescription',
            'uom',
            'qty',
            'unitPrice',
        ];

        const translateStatus = (status) => {
            switch (status) {
                case 1:
                    return 'Approved';
                case 0:
                    return 'Pending';
                case 2:
                    return 'Hold';
                case 3:
                    return 'Rejected';
                default:
                    return status;
            }
        };

        comparisonFields.forEach(field => {
            const header = Comparison.find(col => col.field === field).headerName;
            columnHeaders.push(header);
        });

        gridRef.current.api.forEachNode((node) => {
            const row = [];
            comparisonFields.forEach(field => {
                let value = node.data[field];
                if (field === 'status') {
                    value = translateStatus(value);
                }
                row.push(value);
            });
            rowData.push(row);
        });

        const poppinsFont = 'data:font/ttf;base64,AAEAAAARAQAABAAw7HA...';
        doc.addFileToVFS("Poppins-Regular.ttf", poppinsFont);
        doc.addFont("Poppins-Regular.ttf", "Poppins", "normal");
        doc.setFont("Poppins");
        doc.setFontSize(18);
        doc.setTextColor(40, 40, 40);
        doc.text("Purchase Order Report", 72, 12);
        doc.text("__________________________", 60, 15);
        doc.autoTable({
            head: [columnHeaders],
            body: rowData,
            styles: {
                fontSize: 5,
            },
            headStyles: {
                fillColor: [209, 126, 6],
                textColor: [255, 255, 255],
                fontSize: 5,
            },
            columnStyles: {
                0: { cellWidth: 15 },
                1: { cellWidth: 15 },
                2: { cellWidth: 15 },
                3: { cellWidth: 15 },
                4: { cellWidth: 15 },
                5: { cellWidth: 15 },
                6: { cellWidth: 15 },
                7: { cellWidth: 20 },
                8: { cellWidth: 15 },
                9: { cellWidth: 15 },
                10: { cellWidth: 10 },
                11: { cellWidth: 10 },
                12: { cellWidth: 10 },
            },
            startY: 20
        });

        doc.save('PurchaseOrderReport.pdf');
    }, []);
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleStockChange = (selectedOption) => {
        setFormData({ ...formData, StockType: selectedOption });
    }
    const handleSupplierChange = (selectedOption) => {
        setFormData({ ...formData, VendorName: selectedOption });
    }
    const handleItemChange = (selectedOption) => {
        setFormData({ ...formData, Items: selectedOption });
    }
    const handleFilterbyChange = (selectedOption) => {
        setFormData({ ...formData, Filterby: selectedOption });
    }
    const handleStatusFilter = (selectedOption) => {
        setFormData({ ...formData, Status: selectedOption });
    }
    const handleFromDate = (e) => {
        setFromDate(e.target.value);
    };
    const handleToDate = (e) => {
        setToDate(e.target.value);
    };
    const handleDateFilterSelection = (e) => {
        setdateFilter(e);
    };
    const SendEmail = async () => {
        try {
            if (!formData.email) {
                toast.warning("Enter User Email to send Report")
                return;
            }
            const requestBody = {
                // toEmail: "shahzaibawan864@gmail.com",
                toEmail: formData.email,
                subject: "Purchase Order Report",
                body: `
                <div className='printhead2 mb-5 px-4'>
                    <table>
                        <thead className='dynoTable' style='background-color: #5B93FF; color: #fff;'>
                            <tr>
                                <th>PO No</th>
                                <th>Requisition NO </th>
                                <th>PO Date</th>
                                <th>Vendor</th>
                                <th>Payment Term</th>
                                <th>Supplier Contact</th>
                                <th>Department</th>
                                <th>Curr Code</th>
                                <th>status</th>
                                <th>Cost center 1</th>
                                <th>Cost center 2</th>
                                <th>Cost center 3</th>
                                <th>Cost center 4</th>
                                <th>Delivery Status</th>
                                <th>Item Code</th>
                                <th>Item Description</th>
                                <th>UOM</th>
                                <th>QTY</th>
                                <th>Unit Price</th>
                                <th>Vat %</th>
                                <th>Vat Amount</th>
                                <th>Amount</th>
                                <th>Net Amount</th>
                                <th>GRN Qty</th>
                            </tr>
                        </thead>
                        <tbody>
                            ${rowData?.map((row, index) => `
                                <tr key=${index}>
                                    <td className='m-0 p-0'><input style='width: 100px;' className='p-2' type='text' value='${row.pOrderNo}' disabled /></td>
                                    <td className='m-0 p-0'><input style='width: 110px;' className='p-2' disabled value='${row.requisitionNo}' /></td>
                                    <td className='m-0 p-0'><input style='width: 140px;' className='p-2' disabled value='${row.docDate}' /></td>
                                    <td className='m-0 p-0'><input style='width: 140px;' className='p-2' disabled value='${row.vendorName}' /></td>
                                    <td className='m-0 p-0'><input style='width: 80px;' className='p-2' disabled value='${row.terms}' /></td>
                                    <td className='m-0 p-0'><input style='width: 150px;' className='p-2' disabled value='${row.attention}' /></td>
                                    <td className='m-0 p-0'><input style='width: 120px;' className='p-2' disabled value='${row.department}' /></td>
                                    <td className='m-0 p-0'><input style='width: 110px;' className='p-2' disabled value='${row.currencyCode}' /></td>
                                    <td className='m-0 p-0'><input style='width: 110px;' className='p-2' type='text' value='${row.status}' disabled /></td>
                                    <td className='m-0 p-0'><input style='width: 110px;' className='p-2' type='text' value='${row.costCenter1}' disabled /></td>
                                    <td className='m-0 p-0'><input style='width: 110px;' className='p-2' type='text' value='${row.costCenter2}' disabled /></td>
                                    <td className='m-0 p-0'><input style='width: 110px;' className='p-2' type='text' value='${row.costCenter3}' disabled /></td>
                                    <td className='m-0 p-0'><input style='width: 110px;' className='p-2' type='text' value='${row.costCenter4}' disabled /></td>
                                    <td className='m-0 p-0'><input style='width: 110px;' className='p-2' type='text' value='${row.deliveryStatus}' disabled /></td>
                                    <td className='m-0 p-0'><input style='width: 110px;' className='p-2' type='text' value='${row.itemCode}' disabled /></td>
                                    <td className='m-0 p-0'><input style='width: 110px;' className='p-2' type='text' value='${row.itemDescription}' disabled /></td>
                                    <td className='m-0 p-0'><input style='width: 110px;' className='p-2' type='text' value='${row.uom}' disabled /></td>
                                    <td className='m-0 p-0'><input style='width: 110px;' className='p-2' type='text' value='${row.qty}' disabled /></td>
                                    <td className='m-0 p-0'><input style='width: 110px;' className='p-2' type='text' value='${row.unitPrice}' disabled /></td>
                                    <td className='m-0 p-0'><input style='width: 110px;' className='p-2' type='text' value='${row.vatPerc}' disabled /></td>
                                    <td className='m-0 p-0'><input style='width: 110px;' className='p-2' type='text' value='${row.vatAmount}' disabled /></td>
                                    <td className='m-0 p-0'><input className='p-2 w-100' type='text' value='${row.amount?.toFixed(currencyPrecision) || 0}' disabled /></td>
                                    <td className='m-0 p-0'><input style='width: 110px;' className='p-2' type='text' value='${row.netAmount}' disabled /></td>
                                    <td className='m-0 p-0'><input style='width: 110px;' className='p-2' type='text' value='${row.grnQty}' disabled /></td>
                                </tr>
                            `).join('')}
                        </tbody>
                    </table>
                </div>`
            }
            const apiService = new ApiService();
            const response = await apiService.post("SEND_EMAIL", requestBody);
            if (response.status === 200) {
                onCloseModal()
                toast.success("Email send successfully")
                handleClearFilters()
            }

        } catch (error) {
            console.error('Error:', error);
        }
    }

    return (
        <div className='p-4'>
            <div className='gridBg m-0 row' style={{ borderRadius: "16px 16px 16px 16px" }}>
                <div className='col-9'>
                    <div className='row pb-2' >
                        <div className='col'>
                            <input
                                type='number'
                                className="form-Input w-100"
                                placeholder='PO No'
                                value={formData.PoNum}
                                name="PoNum"
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className='col'>
                            <Select
                                className="p-0"
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 5,
                                    padding: 9
                                })}
                                menuPortalTarget={document.body}
                                styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                        color: state.isFocused ? '#000 ' : '#000',
                                        ':hover': {
                                            backgroundColor: '#F5F5F5',
                                            color: '#000 ',
                                        },
                                    }),
                                }}
                                value={formData.Status}
                                options={StatusFilter}
                                onChange={handleStatusFilter}
                            />
                        </div>
                        <div className='col'>
                            <Select
                                className="p-0"
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 5,
                                    padding: 9
                                })}
                                menuPortalTarget={document.body}
                                styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                        color: state.isFocused ? '#000 ' : '#000',
                                        ':hover': {
                                            backgroundColor: '#F5F5F5',
                                            color: '#000 ',
                                        },
                                    }),
                                }}
                                defaultValue={{ label: 'Item type', value: 'Select Sub Category' }}
                                options={staticOptions}
                            />
                        </div>
                        <div className='col'>
                            <Select
                                className="p-0"
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 5,
                                    padding: 9
                                })}
                                menuPortalTarget={document.body}
                                styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                        color: state.isFocused ? '#000 ' : '#000',
                                        ':hover': {
                                            backgroundColor: '#F5F5F5',
                                            color: '#000 ',
                                        },
                                    }),
                                }}
                                value={formData.Items}
                                onChange={(selectedOption) => handleItemChange(selectedOption)}
                                options={Items?.map((itm) => ({ value: itm, label: itm?.itemDescription }))}
                            />
                        </div>
                        <div className='col'>
                            <Select
                                className="p-0"
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 5,
                                    padding: 9
                                })}
                                menuPortalTarget={document.body}
                                styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                        color: state.isFocused ? '#000 ' : '#000',
                                        ':hover': {
                                            backgroundColor: '#F5F5F5',
                                            color: '#000 ',
                                        },
                                    }),
                                }}
                                onChange={(selectedOption) => handleFilterbyChange(selectedOption)}
                                value={formData.Filterby}
                                options={FilterStatus}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <Select
                                className="p-0"
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 5,
                                    padding: 9
                                })}
                                menuPortalTarget={document.body}
                                styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                        color: state.isFocused ? '#000 ' : '#000',
                                        ':hover': {
                                            backgroundColor: '#F5F5F5',
                                            color: '#000 ',
                                        },
                                    }),
                                }}
                                value={formData.VendorName}
                                onChange={(selectedOption) => handleSupplierChange(selectedOption)}
                                options={SupplierList?.map((sup) => ({ value: sup, label: sup?.supplierName }))}
                            />
                        </div>
                        <div className='col'>
                            <Select
                                className="p-0"
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 5,
                                    padding: 9
                                })}
                                menuPortalTarget={document.body}
                                styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                        color: state.isFocused ? '#000 ' : '#000',
                                        ':hover': {
                                            backgroundColor: '#F5F5F5',
                                            color: '#000 ',
                                        },
                                    }),
                                }}
                                value={formData.StockType}
                                onChange={(selectedOption) => handleStockChange(selectedOption)}
                                options={StockType?.map((Stock) => ({ value: Stock, label: Stock?.stockTypeName }))}
                            />
                        </div>
                        <div className='col gap-3 center'>
                            <input className="form-check-input"
                                type="checkbox"
                                id="dateFilter" style={{ width: "20px", height: "20px" }}
                                name="dateFilter"
                                checked={dateFilter}
                                onChange={(e) => handleDateFilterSelection(e.target.checked)} />
                            <label className=''>Date Filter</label>
                        </div>
                        <div className='col'>
                            <input
                                type='date'
                                className="form-Input w-100"
                                value={fromDate}
                                name="fromDate"
                                disabled={!dateFilter}
                                onChange={handleFromDate}
                                placeholder='From  Date '
                            />
                        </div>
                        <div className='col'>
                            <input
                                type='date'
                                disabled={!dateFilter}
                                className="form-Input w-100"
                                placeholder='To  Date '
                                value={toDate}
                                name="toDate"
                                onChange={handleToDate}
                            />
                        </div>
                    </div>
                </div>
                <div className='col-3 '>
                    <div onClick={() => getItems()} className='mainbtnGry flex-end gap-2'>
                        <FaFileCircleExclamation color='#fff' className='center mt-1 mx-2' />
                        <div className='center'>Search</div>
                    </div>
                    <div onClick={() => handleClearFilters()} className='mainbtnRed flex-end mt-2 gap-2'>
                        <RiRefreshLine color='#fff' className='center mt-1 mx-2' />
                        <div className='center'>Reset</div>
                    </div>
                </div>
            </div>
            <div className='d-flex justify-content-between p-2 gridBg mt-2' style={{ borderRadius: "8px 8px 0px 0px " }}>
                <div className='mainInvHead center' >Purchase Order Report</div>
                <div className='d-flex gap-3 end'>
                    <div className='mainbtnGry flex-end gap-2'>
                        <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                            <DropdownToggle tag="span" data-toggle="dropdown" aria-expanded={dropdownOpen}>
                                <PiDownloadSimple size={25} />
                            </DropdownToggle>
                            <DropdownMenu className='mt-3'>
                                <DropdownItem onClick={onBtnCSVExport} className='gap-3 d-flex center'><BsFiletypeCsv /><div>Export CSV</div></DropdownItem>
                                {/* <DropdownItem onClick={onBtExcelExport} className='gap-3 d-flex center'><LiaFileExcel/><div>Export Excel</div></DropdownItem> */}
                                <DropdownItem onClick={onBtPDFExport} className='gap-3 d-flex center'><BsFileEarmarkPdf /><div>Export PDF</div></DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                    <div className='mainbtnRed flex-end '><HiOutlinePrinter size={25} /></div>
                    <div className='mainbtn flex-end gap-1' onClick={onOpenModal}>
                        <div className='center'><TfiEmail size={25} /></div>
                    </div>
                </div>
            </div>
            <div className='gridshad'>
                <div className="ag-theme-quartz" style={{ height: 550 }}>
                    <AgGridReact
                        getRowStyle={(params) => {
                            if (params.node.rowIndex % 2 === 0) {
                                return { background: '#F0EDF5' };
                            } else {
                                return { background: '#fff' };
                            }
                        }}
                        rowData={rowData}
                        enableFilter={true}
                        floatingFilter={true}
                        rowSelection={'single'}
                        columnDefs={Comparison}
                        suppressExcelExport={true}
                        ref={gridRef}
                    />
                </div>
                <Pagination pageSize={pageSize} setPageSize={setPageSize} pagination={pagination} getItems={getItems} />
            </div>
            <Modal size="md"
                show={emailModal} onHide={() => onCloseModal()}
                onRequestClose={() => onCloseModal()}
                contentLabel='Email Modal'
                style={customStyles} closeButton
            >
                <Modal.Header closeButton>
                    <Modal.Title className='center'>Send Email</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <input
                            type='email'
                            className="form-Input w-100"
                            id="validationCustom02"
                            value={formData.email}
                            name="email"
                            onChange={handleInputChange}
                            placeholder='Enter Email'
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className='end p-3'>
                    <button className='mainbtnGry' onClick={onCloseModal}>Close</button>
                    <button className='mainbtnRed' onClick={SendEmail} >Save</button>
                </Modal.Footer>
            </Modal >
        </div>
    );
};
export default PurchaseOrderReports;
const customStyles = {
    content: {
        width: '30%', // Set the desired width
        height: '75%', // Set the desired 
        top: '50%', // Center vertically
        left: '50%', // Center horizontally
        right: '50%', // Center horizontally
        transform: 'translate(-50%, -50%)', // Center both vertically and horizontally
    },
};