import React, { useState, useEffect, Component } from 'react'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import ApiService from '../../services/apiService'
import { AiOutlinePrinter } from "react-icons/ai"
import { useNavigate } from 'react-router-dom'
import 'ag-grid-community/styles/ag-grid.css'
import { AgGridReact } from 'ag-grid-react'
import { FaRegEye } from "react-icons/fa6"
import moment from 'moment'
import { GrEdit } from "react-icons/gr";
import { IoAddSharp } from "react-icons/io5";
import Pagination from '../pagination/pagination'
import { useDispatch } from 'react-redux'
import { clearRows } from '../../../Redux/actions/rowActions'
const PurchaseOrderGrid = () => {
    const [loading, setLoading] = useState(true);
    const [rowData, setRowData] = useState([]);
    const [Quots, setPCRowData] = useState([]);
    const [pageSize, setPageSize] = useState(20);
    const [pagination, setPagination] = useState();
    const [selectedRow, setSelectedRow] = useState(null);
    const [formData, setFormData] = useState([]);
    const dispatch = useDispatch();
    const [pcPagination, setPCPagination] = useState();
    const [permissions, setPermissions] = useState([]);
    const [selectedtem, setSelectedItem] = useState(null);
    const apiService = new ApiService();
    let navigate = useNavigate();
    useEffect(() => {
        getItems();
        getPO()
        const storedPermissions = JSON.parse(localStorage.getItem('RoleAccess')) || [];
        const permissions = storedPermissions?.filter(acc => acc.formName === "Purchase Order");
        setPermissions(permissions[0]);
    }, []);
    useEffect(() => {
        if (pageSize) {
            getItems();
            getPO()
        }
    }, [pageSize]);
    const dateFormatter = (params) => {
        const date = moment(params.value);
        return date.format("DD-MMM-YYYY");
    };
    const dateComparator = (date1, date2) => {
        const momentDate1 = moment(date1);
        const momentDate2 = moment(date2);
        return momentDate1.isSame(momentDate2) ? 0 : momentDate1.isBefore(momentDate2) ? -1 : 1;
    };
    const getItems = async (newPageNo = pagination?.currentPage) => {
        try {
            const params = new URLSearchParams();
            params.append('PageNo', newPageNo || 1);
            params.append('PageSize', pageSize || 20);
            const res = await apiService.getApiParamater('GET_PO', `?${params.toString()}`);
            setPagination(res.data.paginationData)
            const sortedData = res?.data?.result.slice().sort((a, b) => {
                const idA = parseInt(a.pOrderNo.split('-')[2]);
                const idB = parseInt(b.pOrderNo.split('-')[2]);
                return idB - idA;
            });
            setRowData(sortedData || []);
        } catch (error) {
            console.error('Error fetching items:', error);
        }
    }
    const getPO = async (newPageNo = pcPagination?.currentPage) => {
        try {
            const params = new URLSearchParams();
            // params.append('PageNo', newPageNo || 1);
            // params.append('PageSize', pageSize || 20);
            params.append('Status', 1);
            const res = await apiService.getApiParamater('GET_PC', `?${params.toString()}`);
            setPCPagination(res.data.paginationData)
            const data = res?.data?.result || [];
            const modifiedData = data?.map((item, index) => ({
                ...item,
                requisitionNo: item?.requisitionNo
            }));
            setPCRowData(modifiedData.reverse());
        } catch (error) {
            console.error('Error fetching items:', error);
        }
    };
    useEffect(() => {
        const transformData = (data, rowData) => {
            const transformedData = [];
            const groupedData = data.reduce((acc, item) => {
                item.priceComparisonDetails.forEach(detail => {
                    for (let i = 1; i <= 6; i++) {
                        const supplier = detail[`supp${i}`];
                        const selected = detail[`selected${i}`];
                        if (supplier && selected === 1) {
                            const requisitionNo = item.requisitionNo;
                            const pcNo = item.pcNo;
                            const pcDate = item.pcDate;
                            const vendorKey = `${requisitionNo}-${supplier.trim()}`;
                            if (!acc[vendorKey]) {
                                acc[vendorKey] = {
                                    vendorName: supplier.trim(),
                                    requisitionNo: requisitionNo,
                                    pcDate: pcDate,
                                    pcNo: pcNo,
                                    paymentTerms: detail[`paymentTerms${i}`] || '',
                                    items: []
                                };
                            }
                            if (!acc[vendorKey].items.some(existingItem => existingItem.itemCode === detail.itemCode)) {
                                acc[vendorKey].items.push({
                                    itemCode: detail.itemCode,
                                    itemDescription: detail.itemDescription,
                                    qty: detail.qty,
                                    price: detail[`price${i}`] || 0,
                                    amount: (detail[`price${i}`] || 0) * detail.qty,
                                    ref: detail[`ref${i}`] || '',
                                    remarks: detail[`remarks${i}`] || '',
                                    uom: detail.uom
                                });
                            }
                        }
                    }
                });
                return acc;
            }, {});
            for (const key in groupedData) {
                transformedData.push(groupedData[key]);
            }
            const filteredData = transformedData.filter(item => {
                return !rowData.some(row => {
                    const match = row.vendorName === item.vendorName && row.requisitionNo === item.requisitionNo && row.quotationRef === item.pcNo;
                    if (match) {
                        console.log("Match found:", { item, row });
                    }
                    return match;
                });
            });
            setLoading(false)
            return filteredData.reverse();
        };
        const data = transformData(Quots, rowData);
        setFormData(data);
    }, [rowData]);
    const handlePO = (data) => {
        navigate("/Inventory/Purchase/Create", { state: data })
    }
    const handlePrint = () => {
        if (selectedRow) {
            navigate(`/Inventory/Purchase/Order/${selectedRow?.recordId}`, { state: selectedRow })
        }
    }
    const handleEdit = (clickedRowData) => {
        let eye = false
        if (clickedRowData?.pOrderNo) {
            const updatedData = { ...clickedRowData, eye: eye };
            if (updatedData?.status === 1) {
                let eye = true
                const updatedData = { ...clickedRowData, eye: eye };
                navigate(`/Inventory/Purchase/${updatedData.pOrderNo}`, { state: updatedData })
            } else {
                navigate(`/Inventory/Purchase/${updatedData.pOrderNo}`, { state: updatedData })
            }
        } else {
            const updatedData = { ...selectedtem, eye: eye };
            if (updatedData?.status === 1) {
                let eye = true
                const updatedData = { ...selectedtem, eye: eye };
                navigate(`/Inventory/Purchase/${updatedData.pOrderNo}`, { state: updatedData })
            } else {
                navigate(`/Inventory/Purchase/${updatedData.pOrderNo}`, { state: updatedData })
            }
        }
    };
    const handleEye = (clickedRowData) => {
        if (clickedRowData?.pOrderNo) {
            let eye = true
            const updatedData = { ...clickedRowData, eye: eye };
            navigate(`/Inventory/Purchase/${updatedData.pOrderNo}`, { state: updatedData })
        } else {
            let eye = true
            const updatedData = { ...selectedRow, eye: eye };
            navigate(`/Inventory/Purchase/${updatedData.pOrderNo}`, { state: updatedData })
        }
    };
    const Comparison = [
        { headerCheckboxSelection: true, checkboxSelection: true, headerCheckboxSelectionFilteredOnly: true, suppressMovable: true, width: 50 },
        { headerName: 'Po No', field: 'pOrderNo', filter: true, floatingfilter: true, suppressMovable: true, width: 210 },
        {
            headerName: 'Req No', field: 'requisitionNo', filter: true, floatingfilter: true, suppressMovable: true, width: 210,
        },
        {
            headerName: 'Date', field: 'docDate', filter: true, floatingfilter: true, suppressMovable: true, width: 210,
            valueFormatter: dateFormatter,
            comparator: dateComparator,
        },
        { headerName: 'Supplier ID', field: 'vendorId', filter: true, floatingfilter: true, suppressMovable: true, width: 180 },
        { headerName: 'Supplier Name', field: 'vendorName', filter: true, floatingfilter: true, suppressMovable: true, width: 210 },
        { headerName: 'Delivery Status', field: 'status', filter: true, floatingfilter: true, suppressMovable: true, width: 180 },
        {
            headerName: 'Status', field: 'status', filter: true, floatingfilter: true, suppressMovable: true, width: 180,
            cellRenderer: function (params) {
                if (params.value === 0 || params.value === null) {
                    return (<button class="btn pend ">Pending</button>);
                }
                else if (params.value === 1 || params.value === null) {
                    return (<button class="btn aproved">Approved</button>);
                }
                else if (params.value === 5 || params.value === null) {
                    return (<button class="btn hold">Hold</button>);
                }
                else if (params.value === 2 || params.value === null) {
                    return (<button class="btn reject">Rejected</button>);
                } else {
                    return params.value;
                }
            }
        },
        {
            headerName: 'Approval date', field: 'approvalDate', filter: true, floatingfilter: true, suppressMovable: true, width: 160,
            valueFormatter: dateFormatter,
            comparator: dateComparator,
        },
    ];
    const Requisition = [
        { headerName: 'Req No', field: 'requisitionNo', filter: true, floatingfilter: true, suppressMovable: true, width: 335 },
        {
            headerName: 'Date', field: 'pcDate', filter: true, floatingfilter: true, suppressMovable: true, width: 330,
            valueFormatter: dateFormatter,
            comparator: dateComparator,
        },
        { headerName: 'Supplier ID', field: 'vendorId', filter: true, floatingfilter: true, suppressMovable: true, width: 350 },
        { headerName: 'Supplier Name', field: 'vendorName', filter: true, floatingfilter: true, suppressMovable: true, width: 350 },
        {
            headerName: 'Active', field: 'id', cellRenderer: BtnCellRenderer,
            cellRendererParams: {
                permissions: permissions,
                handlePO: handlePO
            },
        }
    ];
    const frameworkComponents = {
        btnCellRenderer: BtnCellRenderer,
    };
    const handlenew = () => {
        dispatch(clearRows());
        navigate('/Inventory/Purchase/Create')
    }
    const onCellClicked = (event) => {
        if (event.colDef.field === 'pOrderNo') {
            const clickedRowData = event.data;
            handleEye(clickedRowData)
        }
    };
    const onSelectionChanged = async (event) => {
        const selectedRows = event.api.getSelectedRows();
        setSelectedRow(selectedRows.length === 1 ? selectedRows[0] : null);
        const params = new URLSearchParams();
        params.append('DocNo', selectedRows[0]?.pOrderNo);
        params.append('DocumentType', "POrder");
        try {
            const res = await apiService.getApiParamater('GET_DOC_STATUS_EDIT', `?${params.toString()}`);
            const statuses = res?.data?.result?.map(item => item.status);
            const allAreZero = statuses.every(status => status === 0);
            const anyIsFive = statuses.some(status => status === 2);
            if (allAreZero || anyIsFive) {
                setSelectedItem(selectedRows.length === 1 ? selectedRows[0] : null);
            } else {
                console.warn("No valid status to set the item.");
                setSelectedItem(null);
            }
        } catch (error) {
            setSelectedItem(null)
            console.error("Error fetching document status:", error);
        }
    };
    return (
        <div className='p-4'>
            {loading ? (
                <div>Loading...</div>
            ) : (
                <>
                    <div className='border-box2 p-4 justify-content-between d-flex align-items-center pb-3'>
                        <h4 className='col mainInvHead'>
                            Pending Purchase Order
                        </h4>
                        {permissions?.canAdd === true ?
                            <button className='mainbtn ' type="submit" onClick={() => handlenew()}>
                                <IoAddSharp color='#fff' className='center mt-1' /> <div>Add</div>
                            </button>
                            : ''
                        }
                    </div>
                    <div className='gridshad'>
                        <div className="ag-theme-quartz" style={{ height: 600 }}>
                            <AgGridReact
                                getRowStyle={(params) => {
                                    if (params.node.rowIndex % 2 === 0) {
                                        return { background: '#F0EDF5' };
                                    } else {
                                        return { background: '#fff' };
                                    }
                                }}
                                rowData={formData}
                                columnDefs={Requisition}
                                frameworkComponents={{ frameworkComponents }}
                            />
                        </div>
                        <Pagination pageSize={pageSize} setPageSize={setPageSize} pagination={pcPagination} getItems={getPO} />
                    </div>
                </>
            )}
            <div>
                <div className='border-box2 p-4 justify-content-between mt-4 d-flex'>
                    <h5 className='col mainInvHead'>Purchase Order</h5>
                    <div className='d-flex justify-content-end gap-1'>
                        <div
                            onClick={handleEye}
                            className={`mainbtnGry flex-end gap-2 ${!selectedRow ? 'disabled' : ''}`}
                            style={{ cursor: selectedRow ? 'pointer' : 'not-allowed' }}
                        >
                            <FaRegEye color='#fff' className='center mt-1 mx-2' />
                            <div className='center'>View</div>
                        </div>
                        {permissions?.canUpdate === true ?
                            <div
                                onClick={selectedtem && selectedtem?.status === 0 || selectedtem?.status === 2 ? handleEdit : null}
                                className={`mainbtnSecondary flex-end gap-2 ${!selectedtem || selectedtem?.status === 0 || selectedtem?.status === 2 ? '' : 'disabled'
                                    }`}
                                style={{
                                    cursor: selectedtem && selectedtem?.status === 0 || selectedtem?.status === 2 ? 'pointer' : 'not-allowed',
                                }}
                            >
                                <GrEdit color='#fff' className='center mt-1 ' />
                                <div className='center'>Edit</div>
                            </div>
                            : ''}
                        <div
                            onClick={handlePrint}
                            className={`mainbtnRed flex-end gap-2 ${!selectedRow ? 'disabled' : ''}`}
                            style={{ cursor: selectedRow ? 'pointer' : 'not-allowed' }}
                        >
                            <AiOutlinePrinter color='#fff' className='center mt-1 mx-2' />
                            <div className='center'>Print</div>
                        </div>
                    </div>
                </div>
                <div className='gridshad'>
                    <div className="ag-theme-quartz" style={{ height: 600 }}>
                        <AgGridReact
                            getRowStyle={(params) => {
                                if (params.node.rowIndex % 2 === 0) {
                                    return { background: '#F0EDF5' };
                                } else {
                                    return { background: '#fff' };
                                }
                            }}
                            rowData={rowData}
                            enableFilter={true}
                            floatingFilter={true}
                            columnDefs={Comparison}
                            onCellClicked={onCellClicked}
                            onSelectionChanged={onSelectionChanged}
                        />
                    </div>
                    <Pagination pageSize={pageSize} setPageSize={setPageSize} pagination={pagination} getItems={getItems} />
                </div>
            </div>
        </div>
    );
};
class BtnCellRenderer extends Component {
    btnClickedHandler() {
        const { value, clicked } = this.props;
        clicked(value);
    }
    constructor(props) {
        super(props);
        this.btnEditClickedHandler = this.btnEditClickedHandler.bind(this);
    }

    btnEditClickedHandler() {
        const { data, handlePO } = this.props;
        handlePO(data);
    }
    render() {
        const { permissions } = this.props;

        return (
            <div>
                {permissions?.canAdd === true ?
                    <button onClick={this.btnEditClickedHandler} className="gridbtn p-0">Create PO</button> :
                    <button style={{ cursor: "not-allowed" }} className="gridbtn p-0">Create PO</button>
                }
            </div>
        )
    }
}
export default PurchaseOrderGrid; 