import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import ApiService from '../../../INVENTORY/services/apiService';
import SubCategoryGridMaster from './subCategoryGrid';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { useSelector } from 'react-redux';
import { getName } from '../../../Redux/selecters';
import { hasPermission } from "../../services/roleService"
import { RiRefreshLine } from "react-icons/ri";
import { FaSave } from "react-icons/fa";
import { GrEdit } from "react-icons/gr";
import AsyncSelect from 'react-select/async';

const SubCategoryMaster = () => {
    const [categories, setCategories] = useState([]);
    const [subcategories, setSubCategories] = useState([]);
    const apiService = new ApiService();
    const [loading, setLoading] = useState(true);
    const [loader, setLoader] = useState(false);
    const [catCode, setCatCode] = useState()
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState();
    const userName = useSelector(getName);
    const [pageSize, setPageSize] = useState(20);
    const [permissions, setPermissions] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [accounts, setAccounts] = useState([]);
    const [accountsList, setAccountsList] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [load, setLoad] = useState(false);
    const [CostTab, setCostTab] = useState(false);
    const [formData, setFormData] = useState({
        recordId: "",
        subcatcode: "",
        subcatname: "",
        category: [],
        catcode: "",
        active: 0,
        adduser: "",
        InventoryAccounts: [],
        grnAccount: [],
        wipAccount: [],
        invoiceAccount: [],
        adjustmentAccount: [],
        cgsAccount: [],
        issueAccount: [],
        wastageAccount: [],

    });
    useEffect(() => {
        fetchSubCategories()
        fetchAccountData()
        fetchCategories();
        const storedPermissions = JSON.parse(localStorage.getItem('RoleAccess')) || [];
        setPermissions(storedPermissions);
    }, []);
    const checkPermission = (moduleName, action) => {
        return hasPermission(moduleName, action, permissions);
    };
    // const canEditItemMaster = checkPermission('SubCategory Master', 'edit');
    // const canCreateItemMaster = checkPermission('SubCategory Master', 'create');

    const canEditItemMaster = true;
    const canCreateItemMaster = true;
    const handleEdit = (clickedRowData) => {
        if (clickedRowData.subcatname) {
            const selectedInvntory = accountsList?.find(inv => inv.accountCode === clickedRowData?.inventoryAccountCode)
            const selectedAdjustmentAccount = accountsList?.find(adj => adj.accountCode === clickedRowData?.adjustmentAccountCode)
            const selectedGrnAccountCode = accountsList?.find(grn => grn.accountCode === clickedRowData?.grnAccountCode)
            const selectedissueAccount = accountsList?.find(issue => issue.accountCode === clickedRowData?.issueAccountCode)
            const selectedwastageAccount = accountsList?.find(waste => waste.accountCode === clickedRowData?.wastageAccountCode)
            const selectedcgsAccount = accountsList?.find(cgs => cgs.accountCode === clickedRowData?.cgsAccountCode)
            const selectedinvoiceAccountCode = accountsList?.find(invoice => invoice.accountCode === clickedRowData?.invoiceAccountCode)
            const selectedwipAccount = accountsList?.find(wip => wip.accountCode === clickedRowData?.workInProgressAccountCode)
            const selectedcategories = categories?.find(cat => cat.catCode === clickedRowData?.catcode)
            setCatCode(clickedRowData?.subcatcode)
            setFormData({
                recordId: clickedRowData?.recordid || "",
                subcatname: clickedRowData?.subcatname || "",
                InventoryAccounts: { label: selectedInvntory?.accountName, value: selectedInvntory },
                adjustmentAccount: { label: selectedAdjustmentAccount?.accountName, value: selectedAdjustmentAccount },
                grnAccount: { label: selectedGrnAccountCode?.accountName, value: selectedGrnAccountCode },
                issueAccount: { label: selectedissueAccount?.accountName, value: selectedissueAccount },
                wastageAccount: { label: selectedwastageAccount?.accountName, value: selectedwastageAccount },
                cgsAccount: { label: selectedcgsAccount?.accountName, value: selectedcgsAccount },
                invoiceAccount: { label: selectedinvoiceAccountCode?.accountName, value: selectedinvoiceAccountCode },
                wipAccount: { label: selectedwipAccount?.accountName, value: selectedwipAccount },
                category: { label: selectedcategories?.catName, value: selectedcategories }
            });
        } else if (selectedRow) {
            setCatCode(selectedRow?.subcatcode)
            const selectedInvntory = accountsList?.find(inv => inv.accountCode === selectedRow?.inventoryAccountCode)
            const selectedAdjustmentAccount = accountsList?.find(adj => adj.accountCode === selectedRow?.adjustmentAccountCode)
            const selectedGrnAccountCode = accountsList?.find(grn => grn.accountCode === selectedRow?.grnAccountCode)
            const selectedissueAccount = accountsList?.find(issue => issue.accountCode === selectedRow?.issueAccountCode)
            const selectedwastageAccount = accountsList?.find(waste => waste.accountCode === selectedRow?.wastageAccountCode)
            const selectedcgsAccount = accountsList?.find(cgs => cgs.accountCode === selectedRow?.cgsAccountCode)
            const selectedinvoiceAccountCode = accountsList?.find(invoice => invoice.accountCode === selectedRow?.invoiceAccountCode)
            const selectedwipAccount = accountsList?.find(wip => wip.accountCode === selectedRow?.workInProgressAccountCode)
            const selectedcategories = categories?.find(cat => cat.catCode === selectedRow?.catcode)

            setFormData({
                recordId: selectedRow?.recordid || "",
                subcatname: selectedRow?.subcatname || "",
                category: { label: selectedcategories?.catName, value: selectedcategories },
                InventoryAccounts: { label: selectedInvntory?.accountName, value: selectedInvntory },
                adjustmentAccount: { label: selectedAdjustmentAccount?.accountName, value: selectedAdjustmentAccount },
                grnAccount: { label: selectedGrnAccountCode?.accountName, value: selectedGrnAccountCode },
                issueAccount: { label: selectedissueAccount?.accountName, value: selectedissueAccount },
                wastageAccount: { label: selectedwastageAccount?.accountName, value: selectedwastageAccount },
                cgsAccount: { label: selectedcgsAccount?.accountName, value: selectedcgsAccount },
                invoiceAccount: { label: selectedinvoiceAccountCode?.accountName, value: selectedinvoiceAccountCode },
                wipAccount: { label: selectedwipAccount?.accountName, value: selectedwipAccount },
            })
        }
    }
    const loadOptions = async (inputValue, callback) => {
        const options = await fetchAccountData(inputValue, 1);
        callback(options);
    }
    const loadMoreOptions = async () => {
        if (!hasMore || loader) return;
        setPage(prevPage => prevPage + 1);
        await fetchAccountData("", page + 1);
    }
    useEffect(() => {
        fetchAccountData("", 1);
    }, []);
    const fetchAccountData = async (inputValue = "", pageNumber = 1) => {
        if (loader) return;
        setLoader(true);
        try {
            const apiService = new ApiService();
            const params = new URLSearchParams();
            params.append('PageNo', pageNumber || 1);
            params.append('PageSize', 20);
            params.append('AccountName', inputValue || '');

            const response = await apiService.getApiParamater('GET_ACCOUNTS', `?${params.toString()}`);
            const fetchedAccounts = response.data.result.map(account => ({
                label: account.accountName,
                value: account,
            }));
            if (fetchedAccounts.length < 20) setHasMore(false);

            setAccounts(prevAccounts => {
                const staticNoneOption = { label: 'None', value: null };
                if (pageNumber === 1) {
                    return [staticNoneOption, ...fetchedAccounts];
                }
                return [...prevAccounts, ...fetchedAccounts];
            });
            setLoader(false);
            return fetchedAccounts;
        } catch (error) {
            console.error("Error fetching account data:", error);
            setLoader(false);
            return [];
        }
    };
    const handleIssueAccountChange = (selectedOption) => {
        setFormData({ ...formData, issueAccount: selectedOption });
    }
    const handleCategoryChange = (selectedOption) => {
        setFormData({ ...formData, category: selectedOption });
    }
    const handleWIPAccountChange = (selectedOption) => {
        setFormData({ ...formData, wipAccount: selectedOption });
    }
    const handleInvoiceAccountChange = (selectedOption) => {
        setFormData({ ...formData, invoiceAccount: selectedOption });
    }
    const handleAdjustmentAccountChange = (selectedOption) => {
        setFormData({ ...formData, adjustmentAccount: selectedOption });
    }
    const handleGRNAccountChange = (selectedOption) => {
        setFormData({ ...formData, grnAccount: selectedOption });
    }
    const handleCGSAccountChange = (selectedOption) => {
        setFormData({ ...formData, cgsAccount: selectedOption });
    }
    const handleWastageAccountChange = (selectedOption) => {
        setFormData({ ...formData, wastageAccount: selectedOption });
    }
    const handleInventoryAccountChange = (selectedOption) => {
        setFormData({ ...formData, InventoryAccounts: selectedOption });
    }
    const fetchCategories = async () => {
        try {
            let param = `?IsActive=${true}`
            const response = await apiService.getApiParamater('GET_CATEGORY', param);
            const AccountResponse = await apiService.get('GET_ACCOUNTS');
            setCategories(response?.data?.result);
            setAccountsList(AccountResponse?.data?.result)
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    }
    const fetchSubCategories = async (newPageNo = pagination?.currentPage) => {
        try {
            const apiService = new ApiService()
            const params = new URLSearchParams();
            params.append('PageNo', newPageNo || 1);
            params.append('PageSize', pageSize || 20);
            const response = await apiService.getApiParamater('GET_SUB_CATEGORY', `?${params.toString()}`);
            const Code = await apiService.get('GET_SUB_CATEGORY_CODE');
            setCatCode(Code.data.subcatcode)
            setPagination(response.data.paginationData)
            setLoading(false);
            setSubCategories(response?.data?.result);
        } catch (error) {
            setLoading(false);
            console.error('Error fetching Sub categories:', error);
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoad(true)

        if (!formData.category || !formData?.subcatname) {
            if (!formData.category) { toast.warning("Enter Category Name") }
            if (!formData.subcatname) { toast.warning("Enter Sub Category Name") }
            setLoad(false)
            return;
        }
        try {
            let data = {
                recordid: 0,
                subcatcode: catCode,
                subcatname: formData?.subcatname,
                catCode: formData?.category?.value?.catCode,
                catName: formData?.category?.label,
                addUser: userName,
                inventoryAccountCode: formData?.InventoryAccounts?.value?.accountCode,
                grnAccountCode: formData?.grnAccount?.value?.accountCode,
                invoiceAccountCode: formData?.invoiceAccount?.value?.accountCode,
                cgsAccountCode: formData?.cgsAccount?.value?.accountCode,
                adjustmentAccountCode: formData?.adjustmentAccount?.value?.accountCode,
                wastageAccountCode: formData?.wastageAccount?.value?.accountCode,
                workInProgressAccountCode: formData?.wipAccount?.value?.accountCode,
                issueAccountCode: formData?.issueAccount?.value?.accountCode,
                isActive: true,
            }
            const response = await apiService.post('CREATE_UPDATE_SUB_CATEGORY', data);
            if (response?.data?.statusCode === 201 || response?.data?.statusCode === 200) {
                toast.success(response?.data?.message)
                fetchSubCategories()
                setFormData({
                    recordId: "",
                    subcatcode: "",
                    subcatname: "",
                    category: [],
                    catcode: "",
                    active: 0,
                    adduser: "",
                    InventoryAccounts: [],
                    grnAccount: [],
                    wipAccount: [],
                    invoiceAccount: [],
                    adjustmentAccount: [],
                    cgsAccount: [],
                    issueAccount: [],
                    wastageAccount: [],
                })
                window.history.replaceState({}, '')
                setCatCode()
                setLoad(false)
            }
            else if (response?.data?.statusCode === 208) {
                toast.warning(response?.data?.message)
                setLoad(false)
            }

        } catch (error) {
            console.error('Error creating subcategory:', error);
            setLoad(false)
        }
    }
    const updateFormData = async () => {
        setLoad(true)
        try {
            const apiService = new ApiService();
            let body = {
                recordId: formData?.recordId,
                subcatcode: catCode,
                subcatname: formData?.subcatname,
                catCode: formData?.category?.value?.catCode,
                catName: formData?.category?.label,
                addUser: userName,
                inventoryAccountCode: formData?.InventoryAccounts?.value?.accountCode,
                grnAccountCode: formData?.grnAccount?.value?.accountCode,
                invoiceAccountCode: formData?.invoiceAccount?.value?.accountCode,
                cgsAccountCode: formData?.cgsAccount?.value?.accountCode,
                adjustmentAccountCode: formData?.adjustmentAccount?.value?.accountCode,
                wastageAccountCode: formData?.wastageAccount?.value?.accountCode,
                workInProgressAccountCode: formData?.wipAccount?.value?.accountCode,
                issueAccountCode: formData?.issueAccount?.value?.accountCode,
            }
            const res = await apiService.put(`UPDATE_SUB_CATEGORY`, body);
            if (res?.data?.statusCode === 201) {
                toast.success(`${catCode} updated successfully`);
                setFormData({
                    recordId: "",
                    subcatcode: "",
                    subcatname: "",
                    category: [],
                    catcode: "",
                    active: 0,
                    adduser: "",
                    InventoryAccounts: [],
                    grnAccount: [],
                    wipAccount: [],
                    invoiceAccount: [],
                    adjustmentAccount: [],
                    cgsAccount: [],
                    issueAccount: [],
                    wastageAccount: [],
                })
                setCatCode()
                window.history.replaceState({}, '')
                setSelectedRow(null)
                fetchCategories()
                fetchSubCategories()
                setLoad(false)
            } else {
                console.error('Failed to update form data');
                setLoad(false)
            }
        } catch (error) {
            console.error('Error updating form data:', error);
            setLoad(false)
        }
    }
    const handleClearFilters = () => {
        setFormData({
            recordId: "",
            subcatcode: "",
            subcatname: "",
            category: [],
            catcode: "",
            active: 0,
            adduser: "",
            InventoryAccounts: [],
            grnAccount: [],
            wipAccount: [],
            invoiceAccount: [],
            adjustmentAccount: [],
            cgsAccount: [],
            issueAccount: [],
            wastageAccount: [],
        })
        setSelectedRow(null)
        fetchCategories()
        fetchSubCategories()
    };
    return (
        <div className='px-4 pt-3'>

            <div className='py-lg-2'>
                <div className='gridBg p-3' style={{ borderRadius: "16px 16px 16px 16px" }}>
                    <div className='d-flex justify-contant-between mb-4'>
                        <h5 className='col mainInvHead'>Sub Category</h5>
                        <div className='d-flex justify-content-end gap-1'>
                            <div
                                onClick={handleEdit}
                                className={`mainbtnSecondary flex-end gap-2 ${!selectedRow ? 'disabled' : ''}`}
                                style={{ cursor: selectedRow ? 'pointer' : 'not-allowed' }}
                            >
                                <GrEdit color='#fff' className='center mt-1 mx-2' />
                                <div className='center'>Edit</div>
                            </div>
                            <div className=''>
                                {selectedRow ? (
                                    <>{load === true ?
                                        <button className='mainbtn '><ClipLoader size={30} color='#fff' /></button> :
                                        <>
                                            {canEditItemMaster && (
                                                <button className='mainbtn ' onClick={updateFormData}>
                                                    <FaSave className='center mt-1 mx-2' /> <div>Update</div></button>
                                            )}
                                        </>
                                    }
                                    </>
                                ) : (
                                    <>{load === true ?
                                        <button className='mainbtn '><ClipLoader size={30} color='#fff' /></button> :
                                        <>
                                            {canCreateItemMaster === true ?
                                                <button className='mainbtn ' onClick={handleSubmit}>
                                                    <FaSave className='center mt-1 mx-2' /> <div>Save</div></button> :
                                                <button className='mainbtn disabled' style={{ cursor: "not-allowed" }}>
                                                    <FaSave className='center mt-1 mx-2' /> <div>Save</div>
                                                </button>
                                            }
                                        </>
                                    }
                                    </>
                                )}
                            </div>
                            <button className='mainbtnRed' onClick={handleClearFilters}><RiRefreshLine className='center mt-1 mx-2' /><div>Reset</div></button>
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-md-3 col-lg-4 d-flex justify-content-between">
                            <label htmlFor="subcatcode" className="form-label  pt-2 start">Sub Code</label>
                            <input
                                type="text"
                                className="form-Input w-70"
                                id="validationCustom01"
                                name="catCode"
                                disabled
                                value={catCode}
                                required
                            />
                        </div>
                        <div className="col-md-3 col-lg-4 d-flex justify-content-between">
                            <label htmlFor="category" className="form-label  pt-2 start">Select Category *</label>
                            <Select
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 5,
                                    padding: 9
                                })}
                                menuPortalTarget={document.body}
                                styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                        color: state.isFocused ? '#000 ' : '#000',
                                        ':hover': {
                                            backgroundColor: '#F5F5F5',
                                            color: '#000 ',
                                        },
                                    }),
                                }}
                                className="w-70 p-0"
                                onChange={(selectedOption) => handleCategoryChange(selectedOption)}
                                isDisabled={canCreateItemMaster === true ? false : true}
                                value={formData?.category}
                                options={categories?.map(category => ({ value: category, label: category.catName }))}
                            />
                        </div>
                        <div className="col-md-3 col-lg-4 d-flex justify-content-between">
                            <label htmlFor="subcatname" className="form-label  pt-2 start">Sub Name *</label>
                            <input
                                type="text"
                                className="form-Input w-70"
                                id="validationCustom01"
                                disabled={canCreateItemMaster === true ? false : true}
                                name="subcatname"
                                onChange={(e) => setFormData({ ...formData, subcatname: e.target.value })}
                                value={formData.subcatname}
                                required
                            />
                        </div>
                    </div>
                </div>
                <div className="cost-center-section">
                    <div className="d-flex mt-3 cursor-pointer slide-header gap-4"
                        onClick={() => setCostTab(!CostTab)}>
                        <div className='h6 pt-1'>GL Accounts</div>
                    </div>
                    <div className={`slide-content ${CostTab ? 'open' : 'closed'}`}>
                        <div className="border-box2 p-3">
                            <div className="form-group row d-flex justify-content-between pb-3">
                                <div className="col-md-3 col-lg-3 d-flex justify-content-between">
                                    <label htmlFor="validationCustom01" className="form-label  pt-2 start">Inventory Acc</label>
                                    <AsyncSelect
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        className="w-70 p-0"
                                        isDisabled={!canCreateItemMaster}
                                        value={formData.InventoryAccounts}
                                        onChange={handleInventoryAccountChange}
                                        loadOptions={loadOptions}
                                        defaultOptions={accounts}
                                        cacheOptions
                                        onMenuScrollToBottom={loadMoreOptions}
                                    />
                                </div>
                                <div className="col-md-3 col-lg-3 d-flex justify-content-between">
                                    <label htmlFor="validationCustom01" className="form-label  pt-2 start">GRN Acc</label>
                                    <AsyncSelect
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        className="w-70 p-0"
                                        isDisabled={!canCreateItemMaster}
                                        value={formData.grnAccount}
                                        onChange={handleGRNAccountChange}
                                        loadOptions={loadOptions}
                                        defaultOptions={accounts}
                                        cacheOptions
                                        onMenuScrollToBottom={loadMoreOptions}
                                    />
                                </div>
                                <div className="col-md-3 col-lg-3 d-flex justify-content-between">
                                    <label htmlFor="validationCustom01" className="form-label  pt-2 start">Adjustment Acc</label>
                                    <AsyncSelect
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        className="w-70 p-0"
                                        isDisabled={!canCreateItemMaster}
                                        value={formData.adjustmentAccount}
                                        onChange={handleAdjustmentAccountChange}
                                        loadOptions={loadOptions}
                                        defaultOptions={accounts}
                                        cacheOptions
                                        onMenuScrollToBottom={loadMoreOptions}
                                    />
                                </div>
                                <div className="col-md-3 col-lg-3 d-flex justify-content-between">
                                    <label htmlFor="validationCustom01" className="form-label  pt-2 start">Work In Progress</label>
                                    <AsyncSelect
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        className="w-70 p-0"
                                        isDisabled={!canCreateItemMaster}
                                        value={formData.wipAccount}
                                        onChange={handleWIPAccountChange}
                                        loadOptions={loadOptions}
                                        defaultOptions={accounts}
                                        cacheOptions
                                        onMenuScrollToBottom={loadMoreOptions}
                                    />
                                </div>
                            </div>
                            <div className="form-group row d-flex justify-content-between">
                                <div className="col-md-3 col-lg-3 d-flex justify-content-between">
                                    <label htmlFor="validationCustom01" className="form-label  pt-2 start">Invoice Acc</label>
                                    <AsyncSelect
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        className="w-70 p-0"
                                        isDisabled={!canCreateItemMaster}
                                        value={formData.invoiceAccount}
                                        onChange={handleInvoiceAccountChange}
                                        loadOptions={loadOptions}
                                        defaultOptions={accounts}
                                        cacheOptions
                                        onMenuScrollToBottom={loadMoreOptions}
                                    />
                                </div>
                                <div className="col-md-3 col-lg-3 d-flex justify-content-between">
                                    <label htmlFor="validationCustom01" className="form-label  pt-2 start">CGS Acc</label>
                                    <AsyncSelect
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        className="w-70 p-0"
                                        isDisabled={!canCreateItemMaster}
                                        value={formData.cgsAccount}
                                        onChange={handleCGSAccountChange}
                                        loadOptions={loadOptions}
                                        defaultOptions={accounts}
                                        cacheOptions
                                        onMenuScrollToBottom={loadMoreOptions} />
                                </div>
                                <div className="col-md-3 col-lg-3 d-flex justify-content-between">
                                    <label htmlFor="validationCustom01" className="form-label  pt-2 start">Wastage Acc</label>
                                    <AsyncSelect
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        className="w-70 p-0"
                                        isDisabled={!canCreateItemMaster}
                                        value={formData.wastageAccount}
                                        onChange={handleWastageAccountChange}
                                        loadOptions={loadOptions}
                                        defaultOptions={accounts}
                                        cacheOptions
                                        onMenuScrollToBottom={loadMoreOptions} />
                                </div>
                                <div className="col-md-3 col-lg-3 d-flex justify-content-between">
                                    <label htmlFor="validationCustom01" className="form-label  pt-2 start">Issue Acc</label>
                                    <AsyncSelect
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        className="w-70 p-0"
                                        isDisabled={!canCreateItemMaster}
                                        value={formData.issueAccount}
                                        onChange={handleIssueAccountChange}
                                        loadOptions={loadOptions}
                                        defaultOptions={accounts}
                                        cacheOptions
                                        onMenuScrollToBottom={loadMoreOptions} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='gridBg mt-3'>
                <SubCategoryGridMaster rowData={subcategories} canEditItemMaster={canEditItemMaster}
                    setSelectedRow={setSelectedRow}
                    fetchSubCategories={fetchSubCategories} pagination={pagination} loading={loading} handleEdit={handleEdit} />
            </div>
        </div>
    )
}

export default SubCategoryMaster;
