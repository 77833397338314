import React, { useEffect, useState, useRef } from 'react';
import Select from 'react-select';
import "./priceComp.css"
import ApiService from '../../services/apiService';
import ApiService2 from '../../../POS/services/apiService';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { RxCross2 } from "react-icons/rx";
import { clearComparison, setComparison } from '../../../Redux/actions/comparisonAction';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getCompany, getComparison, getName, getUserId, getUserName } from '../../../Redux/selecters';
import { RiRefreshLine } from "react-icons/ri";
import { FaAngleLeft } from "react-icons/fa6";
import { FaSave } from "react-icons/fa";
import { ClipLoader } from 'react-spinners';
import Modal from 'react-bootstrap/Modal';
import AuthorizationList from './authorization';
import MessageList from './messageList';

const PriceCompCreate = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState(0);
    const [gridVisibility, setGridVisibility] = useState([true, false, false]);
    const compare = useSelector(state => state.comparison.rows);
    const CompareData = location.state || compare;
    const userId = useSelector(getUserId);
    const userName = useSelector(getUserName);
    const Company = useSelector(getCompany);
    const apiService2 = new ApiService2()
    const [suppliersTotals, setSuppliersTotals] = useState({});
    const [loader, setLoader] = useState(false);
    const [quotationData, setQuotationData] = useState([]);
    const topDivRef = useRef(null);
    const bottomDivRef = useRef(null);
    const [suppliers, setSupplier] = useState([]);
    const [attachmentModal, setAttachmentModal] = useState(false);
    const [attachmentList, setAttachmentList] = useState([{ id: Date.now(), fileName: '', remarks: '' }]);
    const handleClose = () => setAttachmentModal(false);
    const handleOpen = () => setAttachmentModal(true);
    const [previewModal, setPreviewModal] = useState(false);
    const [previewImage, setPreviewImage] = useState(null);
    const [usersDetails, setUser] = useState([]);
    let navigate = useNavigate();
    const [payments, setPayments] = useState([]);
    const [formData, setFormData] = useState({
        QuoteNo: "",
        RequisitionNo: "",
        remarks: "",
        costCenter1: "",
        costCenter2: "",
        costCenter3: "",
        costCenter4: "",
        quotation: [
            { refNo: "", paymentTerms: "", supplier: "", remarks: "" },
            { refNo: "", paymentTerms: "", supplier: "", remarks: "" },
            { refNo: "", paymentTerms: "", supplier: "", remarks: "" },
        ]
    });
    const initialQuotations = [
        { qty: "", unitPrice: "", amount: "", supplier: { label: "Select Supplier", value: "Select Supplier" }, paymentTerms: "" },
        { qty: "", unitPrice: "", amount: "", supplier: { label: "Select Supplier", value: "Select Supplier" }, paymentTerms: "" },
        { qty: "", unitPrice: "", amount: "", supplier: { label: "Select Supplier", value: "Select Supplier" }, paymentTerms: "" }
    ];
    const initialRow = {
        srno: '',
        ItemNo: '',
        itemDescription: '',
        Specs: '',
        qty: '',
        uom: '',
        baseQty: '',
        baseAmount: '',
        amount: '',
        Delivery: '',
        bol: '',
        quotations: initialQuotations,
    };
    useEffect(() => {
        if (topDivRef.current) {
            topDivRef.current.scrollLeft = topDivRef.current.scrollWidth;
        }
    }, [formData.quotation.length]);
    const [rows, setRows] = useState([initialRow]);
    const [RequisitionNo, setRequisitionNo] = useState();
    const [QuotationNo, setQuotationNo] = useState();
    const [SupplierTab, setSupplierTab] = useState(true);
    const [CostTab, setCostTab] = useState(false);
    const apiService = new ApiService()
    useEffect(() => {
        const topDiv = topDivRef.current;
        const bottomDiv = bottomDivRef.current;
        const syncScroll = (source, target) => {
            target.scrollLeft = source.scrollLeft;
        };
        const handleTopScroll = () => syncScroll(topDiv, bottomDiv);
        const handleBottomScroll = () => syncScroll(bottomDiv, topDiv);
        if (topDiv && bottomDiv) {
            topDiv.addEventListener('scroll', handleTopScroll);
            bottomDiv.addEventListener('scroll', handleBottomScroll);
        }
        return () => {
            if (topDiv && bottomDiv) {
                topDiv.removeEventListener('scroll', handleTopScroll);
                bottomDiv.removeEventListener('scroll', handleBottomScroll);
            }
        };
    }, []);
    useEffect(() => {
        addRow();
        addQuotation()
        fetchData()
    }, []);
    const fetchData = async () => {
        try {
            let param = `?DocType=Quotation`;
            const apiService = new ApiService();

            if (CompareData?.compare === true) {
                let docParam = `?TypeOfDoc=PC&DocId=Price Comparison`
                const docRes = await apiService.getApiParamater("GET_DOC_ID", docParam);
                const approvalList = await apiService.get('GET_APPROVAL_USER');
                const approvals = approvalList?.data.result?.filter(dep => dep.active === 1)
                const approvalslist = approvals?.filter(dep => dep.docType === "Price Comparison")
                setUser(approvalslist)
                setQuotationNo(docRes?.data.result[0].docId)
            } else {
                let docParam = `?TypeOfDoc=QT&DocId=Quotation`
                const docRes = await apiService.getApiParamater("GET_DOC_ID", docParam);
                const approvalList = await apiService.get('GET_APPROVAL_USER');
                const approvals = approvalList?.data.result?.filter(dep => dep.active === 1)
                const approvalslist = approvals?.filter(dep => dep.docType === "Quotation")
                setUser(approvalslist)
                if (CompareData?.qotRecordId) {
                    setQuotationNo(CompareData?.qotRecordId)
                } else {
                    setQuotationNo(docRes?.data.result[0].docId)
                }
            }

            const response = await apiService.get('GET_SUPPLIER');
            const payResponse = await apiService.get('GET_EPAYMENT');
            setPayments([{ value: null, description: 'None' }, ...payResponse?.data?.result]);
            setSupplier([{ value: null, supplierName: 'None' }, ...response?.data?.result]);
        } catch (error) {
            console.error("Error fetching Currency data:", error);
        }
    };
    useEffect(() => {
        if (CompareData?.qotRecordId) {
            const fetchCompareData = async () => {
                try {
                    const response = await apiService.get('GET_QUOTATION');
                    const data = response?.data?.result.find(data => data.requisitionNo === CompareData.requisitionNo);
                    setQuotationData(data)
                    const transformedRows = data.tempQuotationDetails.map(item => {
                        const quotations = [];
                        for (let i = 1; i <= 6; i++) {
                            let supplier = item[`supp${i}`].trim();
                            if (item[`price${i}`] && item[`price${i}`] !== 0 && supplier !== "string") {
                                quotations.push({
                                    itemCode: item.itemCode,
                                    qty: item.qty,
                                    selected: item[`selected${i}`],
                                    unitPrice: item[`price${i}`],
                                    amount: item.qty * item[`price${i}`],
                                    supplier: supplier,
                                    paymentTerms: item[`paymentTerms${i}`],
                                    refNo: item[`ref${i}`],
                                    remarks: item[`remarks${i}`]
                                });
                            }
                        }
                        return {
                            srno: item.srno,
                            ItemNo: item.itemCode,
                            itemDescription: item.itemDescription,
                            Specs: item.specification,
                            qty: item.qty,
                            uom: item.uom,
                            baseQty: item.qty,
                            baseAmount: item.qty * item.price1,
                            amount: item.qty * item.price1,
                            Delivery: "",
                            bol: '',
                            quotations: quotations
                        };
                    });
                    const transformedFormData = {
                        QuoteNo: data.quoteNo,
                        RequisitionNo: data.requisitionNo,
                        remarks: "",
                        costCenter1: data?.costCenter1,
                        costCenter2: data?.costCenter2,
                        costCenter3: data?.costCenter3,
                        costCenter4: data?.costCenter4,
                        quotation: []
                    };

                    const selectedQuotationsMap = new Map();

                    transformedRows.forEach(row => {
                        row.quotations.forEach(quote => {
                            const key = `${quote.itemCode}-${quote.supplier.trim()}`;
                            if (!selectedQuotationsMap.has(key) || quote.selected === 1) {
                                selectedQuotationsMap.set(key, quote);
                            }
                        });
                    });
                    let quotations = Array.from(selectedQuotationsMap.values());
                    const supplierSet = new Set();
                    const filteredQuotations = [];
                    quotations.forEach(quote => {
                        if (!supplierSet.has(quote.supplier.trim())) {
                            supplierSet.add(quote.supplier.trim());
                            filteredQuotations.push(quote);
                        }
                    });
                    transformedFormData.quotation = filteredQuotations;
                    setFormData(transformedFormData);
                    setRows(transformedRows);
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            };
            fetchCompareData();
        } else if (!CompareData) {
            const initialFormData = {
                recordId: CompareData?.requisitionNo,
                QuoteNo: "",
                remarks: CompareData?.remarks,
                costCenter1: CompareData?.costCenter1,
                costCenter2: CompareData?.costCenter2,
                costCenter3: CompareData?.costCenter3,
                costCenter4: CompareData?.costCenter4,
                quotation: [
                    { refNo: 0, paymentTerms: [], supplier: [], remarks: "" },
                    { refNo: 0, paymentTerms: [], supplier: [], remarks: "" },
                    { refNo: 0, paymentTerms: [], supplier: [], remarks: "" },
                ],
            };
            setFormData(initialFormData);
        }
        setRequisitionNo(CompareData?.requisitionNo);
        setQuotationNo(CompareData?.qotRecordId || compare?.qotRecordId)
        dispatch(setComparison(CompareData));
        debugger
    }, [location?.state, CompareData, compare]);
    const handleCheckboxChange = (rowIndex, quotationIndex, checked) => {
        const updatedRows = [...rows];
        updatedRows[rowIndex].quotations.forEach((quotation, index) => {
            quotation.selected = 0;
        });
        if (checked) {
            updatedRows[rowIndex].quotations[quotationIndex].selected = 1;
        }
        setRows(updatedRows);
    };
    const handleTableChange = (rowIndex, quotationIndex, field, value) => {
        setRows(prevRows => {
            const updatedRows = [...prevRows];
            const updatedQuotations = [...updatedRows[rowIndex].quotations];
            updatedQuotations[quotationIndex] = {
                ...updatedQuotations[quotationIndex],
                [field]: value
            };

            if (field === 'unitPrice') {
                const qty = updatedQuotations[quotationIndex].qty;
                updatedQuotations[quotationIndex].amount = value * qty;
            }

            updatedRows[rowIndex] = {
                ...updatedRows[rowIndex],
                quotations: updatedQuotations
            };

            return updatedRows;
        });
    };
    const addQuotation = () => {
        const updatedRows = rows?.map(row => ({
            ...row,
            quotations: [
                ...row.quotations,
                {
                    qty: "",
                    unitPrice: "",
                    amount: "",
                    supplier: "",
                    paymentTerms: ""
                },
            ]
        }));
        setRows(updatedRows);
    };
    const addRow = () => {
        const newRow = {
            srno: '',
            ItemNo: '',
            itemDescription: '',
            Specs: '',
            qty: '',
            uom: '',
            baseQty: '',
            baseAmount: '',
            amount: '',
            Delivery: '',
            bol: '',
            quotations: initialQuotations,
        };
        const newRows = [...rows, newRow];
        setRows(newRows);
    };
    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
        const newGridVisibility = gridVisibility.map((_, index) => index === tabIndex);
        setGridVisibility(newGridVisibility);
    };
    useEffect(() => {
        if (CompareData?.prDetails) {
            const formattedRows = CompareData.prDetails.map(detail => ({
                srno: detail.srno,
                ItemNo: detail.itemCode,
                recordId: detail.itemCode,
                comparisonStatus: CompareData.comparisonStatus,
                itemDescription: detail.itemDescription,
                Specs: detail.specification,
                qty: detail.qty,
                uom: detail.uom,
                baseQty: detail.baseQty,
                baseAmount: detail.baseAmount,
                amount: detail.amount,
                deliveryDate: detail.deliveryDate,
                bol: '',
                quotations: [{
                    itemCode: detail.itemCode,
                    qty: detail.qty,
                    unitPrice: "",
                    amount: "",
                    supplier: "",
                    paymentTerms: ""
                },
                {
                    itemCode: detail.itemCode,
                    qty: detail.qty,
                    unitPrice: "",
                    amount: "",
                    supplier: "",
                    paymentTerms: ""
                },
                {
                    itemCode: detail.itemCode,
                    qty: detail.qty,
                    unitPrice: "",
                    amount: "",
                    supplier: "",
                    paymentTerms: ""
                }]
            }));
            setRows(formattedRows);

        }
    }, [location.state]);
    const handleQuotationChange = (index, e) => {
        const { id, value } = e.target;
        setFormData(prevFormData => {
            const updatedQuotations = [...prevFormData.quotation];
            updatedQuotations[index] = {
                ...updatedQuotations[index],
                [id.replace(/\d+/, '')]: value
            };
            return { ...prevFormData, quotation: updatedQuotations };
        });

        setRows(prevRows => {
            const updatedRows = [...prevRows];
            updatedRows.forEach(row => {
                if (row.quotations[index]) {
                    row.quotations[index][id.replace(/\d+/, '')] = value;
                }
            });
            return updatedRows;
        });
    };
    const handleSupChange = (selectedOption, index) => {
        const selectedSupplier = selectedOption.value;
        const supplierData = suppliers.find(sup => sup.supplierName === selectedSupplier);
        const defaultPaymentTerm = payments.find(pay => pay.description === supplierData?.paymentTerm);
        const supplierAlreadySelected = formData.quotation.some(quotation => quotation.supplier === selectedOption.label);
        if (supplierAlreadySelected) {
            toast.warning('Supplier already selected');
            return;
        }
        setFormData(prevFormData => {
            const updatedQuotations = [...prevFormData.quotation];
            updatedQuotations[index] = {
                ...updatedQuotations[index],
                supplier: selectedOption?.label,
                paymentTerms: defaultPaymentTerm?.description || null
            };
            return { ...prevFormData, quotation: updatedQuotations };
        });
        setRows(prevRows => {
            const updatedRows = [...prevRows];
            updatedRows.forEach(row => {
                if (row.quotations[index]) {
                    row.quotations[index].supplier = selectedOption?.label;
                    row.quotations[index].paymentTerms = defaultPaymentTerm?.description || null;
                }
            });
            return updatedRows;
        });
    };
    const handleAddRow = () => {
        setFormData(prevFormData => ({
            ...prevFormData,
            quotation: [...prevFormData.quotation, { refNo: '', payment: '', supplier: '', remarks: '' }],
        }));

        setRows(prevRows => prevRows.map(row => ({
            ...row,
            quotations: [...row.quotations, {
                itemCode: row.itemCode,
                qty: row.qty,
                unitPrice: "",
                amount: "",
                supplier: "",
                paymentTerms: ""
            }]
        })));
    };
    const handleDeleteQuotation = (index) => {
        setFormData(prevFormData => {
            const updatedQuotations = prevFormData.quotation.filter((_, i) => i !== index);
            return { ...prevFormData, quotation: updatedQuotations };
        });

        setRows(prevRows => prevRows.map(row => ({
            ...row,
            quotations: row.quotations.filter((_, i) => i !== index)
        })));
    };
    const handlePaymentTermChange = (selectedOption, index) => {
        setFormData(prevFormData => {
            const updatedQuotations = [...prevFormData.quotation];
            updatedQuotations[index] = {
                ...updatedQuotations[index],
                paymentTerms: selectedOption?.label
            };
            return { ...prevFormData, quotation: updatedQuotations };
        });

        setRows(prevRows => {
            const updatedRows = [...prevRows];
            updatedRows.forEach(row => {
                if (row.quotations[index]) {
                    row.quotations[index].paymentTerms = selectedOption?.label;
                }
            });
            return updatedRows;
        });

    };
    const CreateQuotation = async () => {
        try {
            setLoader(true)
            const maxQuotations = 6;
            let validSuppliers = 0;
            for (const supplier of formData.quotation) {
                if (supplier.supplier) {
                    validSuppliers++;
                }
            }
            if (validSuppliers < 3) {
                toast.warning(`Please add at least 3 valid suppliers before creating the quotation.`);
                setLoader(false)
                return;
            }
            const itemDetails = rows?.map((item, index) => {
                const itembody = {
                    srno: index + 1,
                    itemCode: item.ItemNo,
                    itemDescription: item.itemDescription,
                    uom: item.uom,
                    qty: item.qty,
                };
                for (const items of item?.quotations) {
                    if (!items.unitPrice || parseFloat(items.unitPrice) <= 0) {
                        toast.warning(`${item.itemDescription || 'Item'}: Unit Price must be greater than 0.`);
                        setLoader(false);
                        return;
                    }
                }
                item?.quotations.forEach((quotation, index) => {
                    if (index >= maxQuotations) {
                        toast.error(`Quotation limit exceeded for item ${item.ItemNo}. Maximum allowed is ${maxQuotations}.`);
                        setLoader(false)
                        return;
                    }
                    const suffix = index + 1;
                    itembody[`supp${suffix}`] = quotation.supplier || "string";
                    itembody[`ref${suffix}`] = quotation?.refNo || "string";
                    itembody[`remarks${suffix}`] = quotation?.remarks || "string";
                    itembody[`paymentTerms${suffix}`] = quotation.paymentTerms || "string";
                    itembody[`price${suffix}`] = quotation.unitPrice ? parseFloat(quotation.unitPrice) : 0;
                    itembody[`selected${suffix}`] = 0;
                });
                for (let i = item.quotations.length + 1; i <= maxQuotations; i++) {
                    itembody[`supp${i}`] = "string";
                    itembody[`ref${i}`] = "string";
                    itembody[`remarks${i}`] = "string";
                    itembody[`paymentTerms${i}`] = "string";
                    itembody[`price${i}`] = 0;
                    itembody[`selected${i}`] = 0;
                }
                return itembody;
            });
            const requestBody = {
                quoteDate: new Date().toISOString(),
                quoteNo: QuotationNo,
                requisitionNo: RequisitionNo,
                status: 0,
                isActive: true,
                costCenter1: CompareData?.costCenter1,
                costCenter2: CompareData?.costCenter2,
                costCenter3: CompareData?.costCenter3,
                costCenter4: CompareData?.costCenter4,
                isEditable: 0,
                tempQuotationDetails: itemDetails
            };
            const response = await apiService.post("CREATE_QUOTATION", requestBody);
            if (response?.data?.statusCode === 201) {
                toast.success(` ${QuotationNo} Created successfully`);
                const userData2 = {
                    docType: "Quotation",
                    docId: QuotationNo,
                    department: "IT",
                    company: Company?.selectedCompany?.companyNo,
                    userCode: userId,
                    userName: userName,
                }
                const docBody = {
                    docId: "Quotation",
                    typeOfDoc: "QT",
                };
                const response2 = await apiService.post("CREATE_DOC_AUTHORIZATION", userData2);
                const paramsGet = new URLSearchParams();
                paramsGet.append('docType', "Quotation");
                paramsGet.append('docId', QuotationNo);
                paramsGet.append('srno', 1);
                const response3 = await apiService.getApiParamater('GET_DOC_APPROVAL_USER', `?${paramsGet.toString()}`);
                if (response3?.data?.result.length > 0) {
                    const toEmails = response3?.data?.result?.map(user => user.userEmail);
                    const emailbody = {
                        toEmails: toEmails,
                        subject: "Quotation",
                        body: "",
                        docNo: QuotationNo,
                        srNo: 1,
                        docType: "Quotation"
                    };
                    const emailres = await apiService.post("DOC_APPROVAL_EMAIL_POST", emailbody);
                }
                dispatch(clearComparison());
                navigate("/inventory/PriceComparison/List");
                const docRes = await apiService.put("UPDATE_DOC_ID", docBody);
                setLoader(false)
            }
        } catch (error) {
            setLoader(false)
            console.error("Error Creating Quotation:", error);
        }
    };
    const UpdateQuotation = async () => {
        try {
            setLoader(true)
            const currentDatetimeUTC = new Date().toISOString();
            const maxQuotations = 6;
            if (formData.quotation[0].supplier.length === 0) {
                toast.error("Please select suppliers before creating the quotation.");
                setLoader(false)
                return;
            }
            const itemDetails = rows?.map((item, index) => {
                const itembody = {
                    srno: index + 1,
                    itemCode: item.ItemNo,
                    itemDescription: item.itemDescription,
                    uom: item.uom,
                    qty: item.qty,
                }
                item?.quotations.forEach((quotation, index) => {
                    if (index >= maxQuotations) {
                        toast.error(`Quotation limit exceeded for item ${item.ItemNo}. Maximum allowed is ${maxQuotations}.`);
                        setLoader(false)
                        return;
                    }
                    const suffix = index + 1;
                    itembody[`supp${suffix}`] = quotation.supplier;
                    itembody[`ref${suffix}`] = quotation?.refNo;
                    itembody[`remarks${suffix}`] = quotation?.remarks;
                    itembody[`paymentTerms${suffix}`] = quotation.paymentTerms;
                    itembody[`price${suffix}`] = parseInt(quotation.unitPrice);
                    itembody[`selected${suffix}`] = 0;
                });
                for (let i = item.quotations.length + 1; i <= maxQuotations; i++) {
                    itembody[`supp${i}`] = "string";
                    itembody[`ref${i}`] = "string";
                    itembody[`remarks${i}`] = "string";
                    itembody[`paymentTerms${i}`] = "string";
                    itembody[`price${i}`] = 0;
                    itembody[`selected${i}`] = 0;
                }
                return itembody;
            });
            const requestBody = {
                quoteDate: new Date().toISOString(),
                requisitionNo: RequisitionNo,
                quoteNo: quotationData.quoteNo,
                status: 0,
                isActive: true,
                costCenter1: CompareData?.costCenter1,
                costCenter2: CompareData?.costCenter2,
                costCenter3: CompareData?.costCenter3,
                costCenter4: CompareData?.costCenter4,
                tempQuotationDetails: itemDetails
            };
            const response = await apiService.put("UPDATE_QUOTATION", requestBody);
            
            if (response?.data?.statusCode === 201) {
                const userData2 = {
                    docType: "Quotation",
                    docId: QuotationNo,
                    status: 0,
                };
                const response2 = await apiService.put("UPDATE_DOC_AUTHORIZATION_STATUS", userData2);
                const paramsGet = new URLSearchParams();
                paramsGet.append('docType', "Quotation");
                paramsGet.append('docId', QuotationNo);
                paramsGet.append('srno', 1);
                const response3 = await apiService.getApiParamater('GET_DOC_APPROVAL_USER', `?${paramsGet.toString()}`);
                if (response3?.data?.result.length > 0) {
                    const toEmails = response3?.data?.result?.map(user => user.userEmail);
                    const emailbody = {
                        toEmails: toEmails,
                        subject: "Quotation",
                        body: "",
                        docNo: QuotationNo,
                        srNo: 1,
                        docType: "Quotation"
                    };
                    const emailres = await apiService.post("DOC_APPROVAL_EMAIL_POST", emailbody);
                }
                navigate("/inventory/PriceComparison/List");
                setLoader(false)
                dispatch(clearComparison());
            }
        } catch (error) {
            setLoader(false)
            console.error("Error Creating Quotation:", error);
        }
    };
    const CreateComaprison = async () => {
        try {
            setLoader(true)
            const currentDatetimeUTC = new Date().toISOString();
            const maxQuotations = 6;
            if (formData.quotation[0].supplier.length === 0) {
                toast.error("Please select suppliers before creating the quotation.");
                setLoader(false)
                return;
            }
            const itemDetails = rows?.map((item, index) => {
                const itembody = {
                    srno: index + 1,
                    itemCode: item.ItemNo,
                    itemDescription: item.itemDescription,
                    uom: item.uom,
                    qty: item.qty,
                }
                item?.quotations.forEach((quotation, index) => {
                    if (index >= maxQuotations) {
                        toast.error(`Quotation limit exceeded for item ${item.ItemNo}. Maximum allowed is ${maxQuotations}.`);
                        setLoader(false)
                        return;
                    }
                    const suffix = index + 1;
                    itembody[`supp${suffix}`] = quotation.supplier;
                    itembody[`ref${suffix}`] = quotation?.refNo;
                    itembody[`remarks${suffix}`] = quotation?.remarks;
                    itembody[`paymentTerms${suffix}`] = quotation.paymentTerms;
                    itembody[`price${suffix}`] = quotation.unitPrice;
                    itembody[`selected${suffix}`] = quotation.selected;
                });
                for (let i = item.quotations.length + 1; i <= maxQuotations; i++) {
                    itembody[`supp${i}`] = "string";
                    itembody[`ref${i}`] = "string";
                    itembody[`remarks${i}`] = "string";
                    itembody[`paymentTerms${i}`] = "string";
                    itembody[`price${i}`] = 0;
                    itembody[`selected${i}`] = 0;
                }
                return itembody;
            });
            const requestBody = {
                pcDate: new Date().toISOString(),
                pcNo: QuotationNo,
                requisitionNo: RequisitionNo,
                quoteNo: CompareData?.qotRecordId,
                status: 0,
                // status: 1,
                isActive: true,
                costCenter1: CompareData?.costCenter1,
                costCenter2: CompareData?.costCenter2,
                costCenter3: CompareData?.costCenter3,
                costCenter4: CompareData?.costCenter4,
                priceComparisonDetails: itemDetails
            };
            const response = await apiService.post("CREATE_PC", requestBody);
            if (response?.data?.statusCode === 201) {
                dispatch(clearComparison());
                const userData2 = {
                    docId: QuotationNo,
                    department: "IT",
                    docType: "Price Comparison",
                    company: Company?.selectedCompany?.companyNo,
                    userCode: userId,
                    userName: userName,
                };
                const docBody = {
                    docId: "Price Comparison",
                    typeOfDoc: "PC"
                }
                const response2 = await apiService.post("CREATE_DOC_AUTHORIZATION", userData2);
                const paramsGet = new URLSearchParams();
                paramsGet.append('docType', "Price Comparison");
                paramsGet.append('docId', QuotationNo);
                paramsGet.append('srno', 1);
                const response3 = await apiService.getApiParamater('GET_DOC_APPROVAL_USER', `?${paramsGet.toString()}`);
                if (response3?.data?.result.length > 0) {
                    const toEmails = response3?.data?.result?.map(user => user.userEmail);
                    const emailbody = {
                        toEmails: toEmails,
                        subject: "Price Comparison",
                        body: "",
                        docNo: QuotationNo,
                        srNo: 1,
                        docType: "Price Comparison"
                    };
                    const emailres = await apiService.post("DOC_APPROVAL_EMAIL_POST", emailbody);
                }
                const docRes = await apiService.put("UPDATE_DOC_ID", docBody);
                toast.success(` ${QuotationNo} Created successfully`);
                setLoader(false)
                navigate("/inventory/PriceComparison/List");
            }
        } catch (error) {
            setLoader(false)
            console.error("Error Creating Quotation:", error);
        }
    };
    const calculateSubtotals = () => {
        const totals = {};
        rows.forEach(row => {
            row.quotations.forEach(quotation => {
                if (quotation.selected === 1) {
                    if (!totals[quotation.supplier]) {
                        totals[quotation.supplier] = 0;
                    }
                    totals[quotation.supplier] += quotation.amount;
                }
            });
        });
        setSuppliersTotals(totals);
    };
    useEffect(() => {
        calculateSubtotals();
    }, [rows]);
    const supplierTotals = {};
    const staticSupplier = [];
    rows?.forEach(row => {
        row.quotations.forEach(quotation => {
            const supplierName = quotation.supplier || "staticSupplier";
            if (!supplierTotals[supplierName]) {
                supplierTotals[supplierName] = { qty: 0, amount: 0 };
            }
            supplierTotals[supplierName].qty += quotation.qty;
            supplierTotals[supplierName].amount += quotation.amount;
        });
        const quotationsWithoutSupplier = row.quotations.filter(quotation => !quotation.supplier);
        quotationsWithoutSupplier.forEach(quotation => {
            staticSupplier.push({ qty: quotation.qty, amount: quotation.amount });
        });
    });
    if (staticSupplier.length > 0) {
        supplierTotals.staticSupplier = staticSupplier;
    }
    const handleBackClick = () => {
        window.history.back();
    };
    const handleFileSelect = (event, id) => {
        const file = event.target.files[0];
        if (file) {
            setAttachmentList((prevList) => [
                ...prevList.map((attachment) =>
                    attachment.id === id ? { ...attachment, fileName: file.name, file: file } : attachment
                ),
                { id: Date.now(), fileName: '', remarks: '' },
            ]);
        }
    };
    const handleView = (file) => {
        if (file && file instanceof File) {
            setPreviewImage(URL.createObjectURL(file));
            setPreviewModal(true);
        } else {
            console.error("No valid file to preview");
        }
    };
    const handleDelete = (id) => {
        const updatedList = attachmentList.filter((attachment) => attachment.id !== id);
        setAttachmentList(updatedList);
    };
    return (
        <div className='p-4'>
            <div>
                <div className='d-flex gap-3 end'>
                    <div className=''>
                        {CompareData?.found === true ?
                            <>{CompareData?.compare === true ?
                                <>{loader === true ?
                                    <button className='mainbtn'><ClipLoader size={25} color='#fff' /></button> :
                                    <button className='mainbtn' type="submit" onClick={CreateComaprison}>
                                        <FaSave className='center mt-1 mx-2' /><div>Save</div>
                                    </button>
                                }</> :
                                <>{loader === true ?
                                    <button className='mainbtn '><ClipLoader size={25} color='#fff' /></button> :
                                    <button className='mainbtn ' type="submit" onClick={UpdateQuotation}>
                                        <RiRefreshLine className='center mt-1 mx-2' /><div>Update</div>
                                    </button>
                                }</>
                            }</>
                            :
                            <>{CompareData?.compare === true ? " " :
                                <>{loader === true ?
                                    <button className='mainbtn '><ClipLoader size={25} color='#fff' /></button> :
                                    <button className='mainbtn ' type="submit" onClick={CreateQuotation}>
                                        <FaSave className='center mt-1 mx-2' /><div>Save</div>
                                    </button>
                                }</>
                            }</>}
                    </div>
                    <div className=''>
                        <button onClick={handleBackClick} className='mainbtnSecondary'>
                            <FaAngleLeft className='center mt-1 mx-2' /><div>Back</div>
                        </button>
                    </div>
                </div>
                <div className="cost-center-section mb-2">
                    <div className="d-flex mt-3 cursor-pointer slide-header gap-4"
                        onClick={() => setSupplierTab(!SupplierTab)}>
                        <div className='h6 pt-1'>Comparison Details</div>
                    </div>
                    <div className={`slide-content ${SupplierTab ? 'open' : 'closed'}`}>
                        <div className="border-box2 p-3">
                            <div className='row'>
                                <div className='col-5'>
                                    <div className=" d-flex justify-content-between">
                                        <label htmlFor="QuoteNo" className="form-label  pt-2 start">Quote No</label>
                                        <input
                                            className="form-Input w-70"
                                            value={QuotationNo ? `${QuotationNo}` : " "}
                                            disabled
                                        />
                                    </div>
                                    <div className="py-2 d-flex justify-content-between">
                                        <label htmlFor="RequisitionNo" className="form-label  pt-2 start">Requisition No</label>
                                        <input className="form-Input w-70" disabled value={RequisitionNo} />
                                    </div>
                                    <div className=" d-flex justify-content-between">
                                        <label htmlFor="PropertyGroup" className="form-label  pt-2 start">Property Group</label>
                                        <input className="form-Input w-70" disabled value={CompareData?.costCenter1 || formData?.costCenter1} />
                                    </div>
                                    <div className="py-2 d-flex justify-content-between">
                                        <label htmlFor="Property" className="form-label  pt-2 start">Property</label>
                                        <input className="form-Input w-70" disabled value={CompareData?.costCenter2 || formData?.costCenter2} />
                                    </div>
                                    <div className=" d-flex justify-content-between">
                                        <label htmlFor="Department" className="form-label  pt-2 start">Department</label>
                                        <input className="form-Input w-70" disabled value={CompareData?.costCenter3 || formData?.costCenter3} />
                                    </div>
                                    <div className="pt-2 d-flex justify-content-between">
                                        <label htmlFor="Outlet" className="form-label  pt-2 start">Outlet</label>
                                        <input className="form-Input w-70" disabled value={CompareData?.costCenter4 || formData?.costCenter4} />
                                    </div>
                                </div>
                                <div className='col-7'>
                                    <div className='d-flex gap-3'>
                                        <div className="brderbox px-3 center" onClick={() => handleOpen()} >
                                            {'Attachment'}
                                        </div>
                                        <div className="">
                                            {CompareData?.found === true ?
                                                <>{CompareData?.compare === true ?
                                                    <button className="mainbtn" disabled>Add More</button> :
                                                    <button className="mainbtn" onClick={handleAddRow}>Add More</button>
                                                }</>
                                                :
                                                <>{CompareData?.compare === true ?
                                                    <button className="mainbtn" disabled>Add More</button> :
                                                    <button className="mainbtn" onClick={handleAddRow}>Add More</button>
                                                }</>}
                                        </div>
                                    </div>
                                    <div className="d-flex mt-2 gap-3 ovrflo" ref={topDivRef} >
                                        {formData?.quotation?.map((quotation, index) => (
                                            <div key={index} className="col-6 quotationbox py-3 px-2">
                                                <div className='d-flex justify-content-between pb-1'>
                                                    <label className="form-label">Supplier</label>
                                                    {CompareData?.found === true ?
                                                        <>{CompareData?.compare === true ? " " :
                                                            <RxCross2 onClick={() => handleDeleteQuotation(index)} />
                                                        }</>
                                                        :
                                                        <>{CompareData?.compare === true ? " " :
                                                            <RxCross2 onClick={() => handleDeleteQuotation(index)} />
                                                        }</>}
                                                </div>
                                                <div className='d-flex justify-content-between'>
                                                    {CompareData?.found === true ?
                                                        <>{CompareData?.compare === true ?
                                                            <Select theme={(theme) => ({
                                                                ...theme,
                                                                borderRadius: 5,
                                                                padding: 9
                                                            })}
                                                                menuPortalTarget={document.body}
                                                                styles={{
                                                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                                    option: (provided, state) => ({
                                                                        ...provided,
                                                                        backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                                        color: state.isFocused ? '#000 ' : '#000',
                                                                        ':hover': {
                                                                            backgroundColor: '#F5F5F5',
                                                                            color: '#000 ',
                                                                        },
                                                                    }),
                                                                }}
                                                                className="p-0 w-100 "
                                                                value={quotation?.supplier ? { value: quotation?.supplier, label: quotation?.supplier } : { label: "Select Supplier", value: "Select Supplier" }} isDisabled
                                                            /> :
                                                            <Select theme={(theme) => ({
                                                                ...theme,
                                                                borderRadius: 5,
                                                                padding: 9
                                                            })}
                                                                menuPortalTarget={document.body}
                                                                styles={{
                                                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                                    option: (provided, state) => ({
                                                                        ...provided,
                                                                        backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                                        color: state.isFocused ? '#000 ' : '#000',
                                                                        ':hover': {
                                                                            backgroundColor: '#F5F5F5',
                                                                            color: '#000 ',
                                                                        },
                                                                    }),
                                                                }}
                                                                className="p-0 w-100 "
                                                                value={quotation?.supplier ? { value: quotation?.supplier, label: quotation?.supplier } : { label: "Select Supplier", value: "Select Supplier" }}
                                                                onChange={(selectedOption) => handleSupChange(selectedOption, index)}
                                                                options={suppliers?.map((sup) => ({ value: sup?.supplierName, label: sup?.supplierName }))}
                                                            />
                                                        }</>
                                                        :
                                                        <>{CompareData?.compare === true ?
                                                            <Select theme={(theme) => ({
                                                                ...theme,
                                                                borderRadius: 5,
                                                                padding: 9
                                                            })}
                                                                menuPortalTarget={document.body}
                                                                styles={{
                                                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                                    option: (provided, state) => ({
                                                                        ...provided,
                                                                        backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                                        color: state.isFocused ? '#000 ' : '#000',
                                                                        ':hover': {
                                                                            backgroundColor: '#F5F5F5',
                                                                            color: '#000 ',
                                                                        },
                                                                    }),
                                                                }}
                                                                className="p-0 w-100 "
                                                                value={quotation?.supplier ? { value: quotation?.supplier, label: quotation?.supplier } : { label: "Select Supplier", value: "Select Supplier" }} isDisabled
                                                            /> :
                                                            <Select theme={(theme) => ({
                                                                ...theme,
                                                                borderRadius: 5,
                                                                padding: 9
                                                            })}
                                                                menuPortalTarget={document.body}
                                                                styles={{
                                                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                                    option: (provided, state) => ({
                                                                        ...provided,
                                                                        backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                                        color: state.isFocused ? '#000 ' : '#000',
                                                                        ':hover': {
                                                                            backgroundColor: '#F5F5F5',
                                                                            color: '#000 ',
                                                                        },
                                                                    }),
                                                                }}
                                                                className="p-0 w-100 "
                                                                value={quotation?.supplier ? { value: quotation?.supplier, label: quotation?.supplier } : { label: "Select Supplier", value: "Select Supplier" }}
                                                                onChange={(selectedOption) => handleSupChange(selectedOption, index)}
                                                                options={suppliers?.map((sup) => ({ value: sup?.supplierName, label: sup?.supplierName }))}
                                                            />
                                                        }</>}
                                                </div>
                                                <div className="my-2 d-flex justify-content-between">
                                                    {/* <label className="form-label  pt-2 start">Payment Term </label> */}
                                                    {CompareData?.found === true ?
                                                        <>{CompareData?.compare === true ?
                                                            <Select isDisabled theme={(theme) => ({
                                                                ...theme,
                                                                borderRadius: 5,
                                                                padding: 9
                                                            })}
                                                                menuPortalTarget={document.body}
                                                                styles={{
                                                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                                    option: (provided, state) => ({
                                                                        ...provided,
                                                                        backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                                        color: state.isFocused ? '#000 ' : '#000',
                                                                        ':hover': {
                                                                            backgroundColor: '#F5F5F5',
                                                                            color: '#000 ',
                                                                        },
                                                                    }),
                                                                }}
                                                                className="p-0 w-100 "
                                                                value={quotation?.paymentTerms ? { value: quotation?.paymentTerms, label: quotation?.paymentTerms } : { label: "Select Payment Term", value: "Select Supplier" }}
                                                            /> :
                                                            <Select theme={(theme) => ({
                                                                ...theme,
                                                                borderRadius: 5,
                                                                padding: 9
                                                            })}
                                                                menuPortalTarget={document.body}
                                                                styles={{
                                                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                                    option: (provided, state) => ({
                                                                        ...provided,
                                                                        backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                                        color: state.isFocused ? '#000 ' : '#000',
                                                                        ':hover': {
                                                                            backgroundColor: '#F5F5F5',
                                                                            color: '#000 ',
                                                                        },
                                                                    }),
                                                                }}
                                                                className="p-0 w-100 "
                                                                options={payments?.map((pay) => ({ value: pay?.description, label: pay?.description }))}
                                                                onChange={(selectedOption) => handlePaymentTermChange(selectedOption, index)}
                                                                value={quotation?.paymentTerms ? { value: quotation?.paymentTerms, label: quotation?.paymentTerms } : { label: "Select Payment Term", value: "Select Supplier" }}
                                                            />
                                                        }</>
                                                        :
                                                        <>{CompareData?.compare === true ?
                                                            <Select isDisabled theme={(theme) => ({
                                                                ...theme,
                                                                borderRadius: 5,
                                                                padding: 9
                                                            })}
                                                                menuPortalTarget={document.body}
                                                                styles={{
                                                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                                    option: (provided, state) => ({
                                                                        ...provided,
                                                                        backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                                        color: state.isFocused ? '#000 ' : '#000',
                                                                        ':hover': {
                                                                            backgroundColor: '#F5F5F5',
                                                                            color: '#000 ',
                                                                        },
                                                                    }),
                                                                }}
                                                                className="p-0 w-100 "
                                                                value={quotation?.paymentTerms ? { value: quotation?.paymentTerms, label: quotation?.paymentTerms } : { label: "Select Payment Term", value: "Select Supplier" }}
                                                            /> :
                                                            <Select theme={(theme) => ({
                                                                ...theme,
                                                                borderRadius: 5,
                                                                padding: 9
                                                            })}
                                                                menuPortalTarget={document.body}
                                                                styles={{
                                                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                                    option: (provided, state) => ({
                                                                        ...provided,
                                                                        backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                                        color: state.isFocused ? '#000 ' : '#000',
                                                                        ':hover': {
                                                                            backgroundColor: '#F5F5F5',
                                                                            color: '#000 ',
                                                                        },
                                                                    }),
                                                                }}
                                                                className="p-0 w-100 "
                                                                options={payments?.map((pay) => ({ value: pay?.description, label: pay?.description }))}
                                                                onChange={(selectedOption) => handlePaymentTermChange(selectedOption, index)}
                                                                value={quotation?.paymentTerms ? { value: quotation?.paymentTerms, label: quotation?.paymentTerms } : { label: "Select Payment Term", value: "Select Supplier" }}
                                                            />
                                                        }</>}
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    {/* <label htmlFor={`refNo${index}`} className="form-label  pt-2 start">Quotation Ref No</label> */}
                                                    {CompareData?.found === true ?
                                                        <>{CompareData?.compare === true ?
                                                            <input
                                                                type="number" disabled
                                                                className="form-Input w-100 "
                                                                placeholder="Quotation Ref No"
                                                                id={`refNo${index}`}
                                                                value={quotation.refNo}
                                                            // placeholder="refNo"
                                                            /> :
                                                            <input
                                                                type="number" onWheel={(e) => e.target.blur()}
                                                                className="form-Input w-100 "
                                                                id={`refNo${index}`}
                                                                value={quotation.refNo}
                                                                onChange={(e) => handleQuotationChange(index, e)}
                                                                placeholder="Quotation Ref No"
                                                            // placeholder="refNo"
                                                            />
                                                        }</>
                                                        :
                                                        <>{CompareData?.compare === true ?
                                                            <input
                                                                type="number"
                                                                className="form-Input w-100 "
                                                                id={`refNo${index}`}
                                                                value={quotation.refNo} disabled
                                                                placeholder="Quotation Ref No"
                                                            // placeholder="refNo"
                                                            /> :
                                                            <input
                                                                type="number" onWheel={(e) => e.target.blur()}
                                                                className="form-Input w-100 "
                                                                id={`refNo${index}`}
                                                                value={quotation.refNo}
                                                                onChange={(e) => handleQuotationChange(index, e)}
                                                                placeholder="Quotation Ref No"
                                                            // placeholder="refNo"
                                                            />
                                                        }</>}
                                                </div>
                                                <div className="d-flex justify-content-between mt-2">
                                                    {/* <label htmlFor={`remarks${index}`} className="form-label  pt-2 start">Remarks</label> */}
                                                    {CompareData?.found === true ?
                                                        <>{CompareData?.compare === true ?
                                                            <input
                                                                className="form-Input w-100 "
                                                                id={`remarks${index}`} disabled
                                                                placeholder="Remarks"
                                                                value={quotation.remarks}
                                                            /> :
                                                            <input
                                                                className="form-Input w-100 "
                                                                id={`remarks${index}`}
                                                                onChange={(e) => handleQuotationChange(index, e)}
                                                                placeholder="Remarks"
                                                                value={quotation.remarks}
                                                            />
                                                        }</>
                                                        :
                                                        <>{CompareData?.compare === true ?
                                                            <input
                                                                className="form-Input w-100 "
                                                                id={`remarks${index}`} disabled
                                                                placeholder="Remarks"
                                                                value={quotation.remarks}
                                                            /> :
                                                            <input
                                                                className="form-Input w-100 "
                                                                id={`remarks${index}`}
                                                                onChange={(e) => handleQuotationChange(index, e)}
                                                                placeholder="Remarks"
                                                                value={quotation.remarks}
                                                            />
                                                        }</>}

                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`cost-center-section`}>
                    <div className="mt-3 cursor-pointer slide-header">
                        <div className="nav nav-pills overslid d-flex gap-4">
                            {/* Nav tabs */}
                            <div
                                className={`dashitems sidebar-text center ${activeTab === 0 ? 'sideActive' : ''}`}
                                onClick={() => handleTabClick(0)}
                            >
                                Price Comparison
                            </div>
                            <div
                                className={`dashitems sidebar-text center  ${activeTab === 1 ? 'sideActive' : ''}`}
                                onClick={() => handleTabClick(1)}
                            >
                                Authorization
                            </div>
                            <div
                                className={`dashitems sidebar-text center  ${activeTab === 2 ? 'sideActive' : ''}`}
                                onClick={() => handleTabClick(2)}
                            >
                                Message
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`slide-content open`}>
                    <div className={`grid-1 border-box2 p-3`}>
                        {gridVisibility[0] && (
                            <div className="grid-1">
                                <div className='table-container mb-1' ref={bottomDivRef} style={{ overflowX: 'auto', height: "auto" }}>
                                    <table>
                                        <thead className='dynoTable' >
                                            <tr>
                                                <th>Sr no</th>
                                                <th>Item No</th>
                                                <th>Description</th>
                                                <th>Item Specs</th>
                                                <th>UOM</th>
                                                <th>Delivery Date</th>
                                                {rows[0]?.quotations.map((_, index) => (
                                                    <React.Fragment key={index}>
                                                        <th></th>
                                                        <th>Qty</th>
                                                        <th>Unit Price</th>
                                                        <th>Amount</th>
                                                    </React.Fragment>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {rows
                                                ?.sort((a, b) => (a.srno || 0) - (b.srno || 0))
                                                .map((row, index) => (
                                                    <>
                                                        <tr key={index}>
                                                            <td className='m-0 p-0'>
                                                                <input
                                                                    style={{ width: "58px" }}
                                                                    className='p-1'
                                                                    type="number"
                                                                    disabled
                                                                    value={row.srno || index + 1}
                                                                />
                                                            </td>
                                                            <td className='m-0 p-0'>
                                                                <input
                                                                    style={{ width: "78px" }}
                                                                    className='p-1'
                                                                    type="text"
                                                                    disabled
                                                                    value={row.ItemNo}
                                                                />
                                                            </td>
                                                            <td className='m-0 p-0'>
                                                                <input
                                                                    className='p-1'
                                                                    type="text"
                                                                    disabled
                                                                    value={row.itemDescription}
                                                                />
                                                            </td>
                                                            <td className='m-0 p-0'>
                                                                <input
                                                                    style={{ width: "101px" }}
                                                                    className='p-1'
                                                                    type="text"
                                                                    disabled
                                                                    value={row.Specs}
                                                                />
                                                            </td>
                                                            <td className='m-0 p-0'>
                                                                <input
                                                                    style={{ width: "90px" }}
                                                                    className='p-1'
                                                                    type="text"
                                                                    disabled
                                                                    value={row.uom}
                                                                />
                                                            </td>
                                                            <td className='m-0 p-0'>
                                                                <input
                                                                    style={{ width: "118px" }}
                                                                    className='p-1'
                                                                    disabled
                                                                    value={CompareData?.deliveryDate}
                                                                />
                                                            </td>
                                                            {row?.quotations.map((q, quotationIndex) => (
                                                                <React.Fragment key={quotationIndex}>
                                                                    <td>
                                                                        <div className='center'>
                                                                            {CompareData?.found === true ?
                                                                                <>{CompareData?.compare === true ?
                                                                                    <input
                                                                                        className='px-2 form-check-input'
                                                                                        type="checkbox"
                                                                                        style={{ width: "20px", height: "20px" }}
                                                                                        checked={q.selected === 1}
                                                                                        onChange={(e) => handleCheckboxChange(index, quotationIndex, e.target.checked)}
                                                                                    /> :
                                                                                    <input
                                                                                        className='px-2 form-check-input'
                                                                                        type="checkbox" disabled
                                                                                        style={{ width: "20px", height: "20px" }}
                                                                                    />}
                                                                                </> :
                                                                                <>{CompareData?.compare === true ?
                                                                                    <input
                                                                                        className='px-2 form-check-input'
                                                                                        type="checkbox"
                                                                                        style={{ width: "20px", height: "20px" }}
                                                                                        checked={q.selected === 1}
                                                                                        onChange={(e) => handleCheckboxChange(index, quotationIndex, e.target.checked)}
                                                                                    /> :
                                                                                    <input
                                                                                        className='px-2 form-check-input'
                                                                                        type="checkbox" disabled
                                                                                        style={{ width: "20px", height: "20px" }}
                                                                                    />}
                                                                                </>
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <input
                                                                            style={{ width: "55px" }}
                                                                            className='p-1'
                                                                            type="number" onWheel={(e) => e.target.blur()}
                                                                            disabled
                                                                            value={q.qty}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        {CompareData?.found === false ?
                                                                            <>{CompareData?.compare === false ?
                                                                                <input
                                                                                    style={{ width: "88px" }}
                                                                                    className='p-1' type="number" onWheel={(e) => e.target.blur()}
                                                                                    value={q.unitPrice}
                                                                                    onChange={(e) => handleTableChange(index, quotationIndex, 'unitPrice', e.target.value)}
                                                                                /> :
                                                                                <input
                                                                                    style={{ width: "88px" }}
                                                                                    className='p-1' disabled
                                                                                    type="number" value={q.unitPrice}
                                                                                />}
                                                                            </> :
                                                                            <>{CompareData?.compare === false ?
                                                                                <input
                                                                                    style={{ width: "88px" }}
                                                                                    className='p-1' type="number" onWheel={(e) => e.target.blur()}
                                                                                    value={q.unitPrice}
                                                                                    onChange={(e) => handleTableChange(index, quotationIndex, 'unitPrice', e.target.value)}
                                                                                /> :
                                                                                <input
                                                                                    style={{ width: "88px" }}
                                                                                    className='p-1' disabled
                                                                                    type="number" value={q.unitPrice}
                                                                                />}
                                                                            </>
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        <input
                                                                            style={{ width: "88px" }}
                                                                            className='p-1'
                                                                            type="text"
                                                                            disabled
                                                                            value={q.amount}
                                                                            onChange={(e) => handleTableChange(index, quotationIndex, 'amount', e.target.value)}
                                                                        />
                                                                    </td>
                                                                </React.Fragment>
                                                            ))}
                                                        </tr>
                                                    </>
                                                ))}
                                            <tr>
                                                <td colSpan="6" className='m-0 p-0'><input className='p-1 w-100' disabled value={"Total"} /></td>
                                                {Object.keys(supplierTotals).map((supplier, index) => (
                                                    <React.Fragment key={index}>
                                                        <td><input style={{ width: "35px" }} className='p-1' type="text" disabled value={""} /></td>
                                                        <td><input style={{ width: "55px" }} className='p-1' type="text" disabled value={supplierTotals[supplier].qty || ""} /></td>
                                                        <td><input style={{ width: "88px" }} className='p-1' type="text" disabled value={"--"} /></td>
                                                        <td><input style={{ width: "88px" }} className='p-1' type="text" disabled value={supplierTotals[supplier].amount || "0"} /></td>
                                                    </React.Fragment>
                                                ))}
                                                {[...Array(Math.max(3 - Object.keys(supplierTotals).length, 0))].map((_, index) => (
                                                    <React.Fragment key={`static-${index}`}>
                                                        <td><input style={{ width: "35px" }} className='p-1' type="text" disabled value={""} /></td>
                                                        <td><input style={{ width: "55px" }} className='p-1' type="text" disabled value={""} /></td>
                                                        <td><input style={{ width: "88px" }} className='p-1' type="text" disabled value={"--"} /></td>
                                                        <td><input style={{ width: "88px" }} className='p-1' type="text" disabled value={"0"} /></td>
                                                    </React.Fragment>
                                                ))}
                                            </tr>
                                            <tr>
                                                <td colspan="6" className='m-0 p-0'><input className='p-1 w-100' disabled value={"Supplier Total"} /></td>
                                                {Object.keys(supplierTotals).map((supplier, index) => (
                                                    <React.Fragment key={index}>
                                                        <td><input style={{ width: "35px" }} className='p-1' type="text" disabled value={""} /></td>
                                                        <td><input style={{ width: "55px" }} className='p-1' type="text" disabled value={""} /></td>
                                                        <td><input style={{ width: "88px" }} className='p-1' type="text" disabled value={""} /></td>
                                                        <td><input style={{ width: "88px" }} className='p-1' type="text" disabled value={suppliersTotals[supplier] || "0"} /></td>
                                                    </React.Fragment>
                                                ))}
                                                {[...Array(Math.max(3 - Object.keys(supplierTotals).length, 0))].map((_, index) => (
                                                    <React.Fragment key={`static-${index}`}>
                                                        <td><input style={{ width: "35px" }} className='p-1' type="text" disabled value={""} /></td>
                                                        <td><input style={{ width: "55px" }} className='p-1' type="text" disabled value={""} /></td>
                                                        <td><input style={{ width: "88px" }} className='p-1' type="text" disabled value={"--"} /></td>
                                                        <td><input style={{ width: "88px" }} className='p-1' type="text" disabled value={"0"} /></td>
                                                    </React.Fragment>
                                                ))}
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )}
                        {gridVisibility[1] && (
                            <AuthorizationList
                                usersDetails={usersDetails}
                                setUser={setUser}
                            />
                        )}
                        {gridVisibility[2] && (
                            <MessageList />
                        )}
                    </div>
                </div>
                <div>
                    <Modal show={attachmentModal} size="lg" onHide={handleClose} >
                        <Modal.Header closeButton>
                            <Modal.Title>Attachments</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="docNp" className="form-label  pt-2 start">Document No</label>
                                <input disabled value={QuotationNo} className="form-Input w-70" />
                            </div>
                            <div className="pt-2">
                                <table>
                                    <thead className="dynoTable">
                                        <tr>
                                            <th>File Name</th>
                                            <th>Remarks</th>
                                            <th>Upload</th>
                                            <th>View</th>
                                            <th>Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {attachmentList?.map((attachment, index) => (
                                            <tr key={attachment.id}>
                                                <td className='m-0 p-0'><input className='p-0' type='text' disabled value={attachment.fileName} style={{ width: "320px" }} /></td>
                                                <td>
                                                    <input type="text" value={attachment.remarks}
                                                        onChange={(e) => {
                                                            const updatedList = attachmentList?.map((item) =>
                                                                item.id === attachment.id ? { ...item, remarks: e.target.value } : item
                                                            );
                                                            setAttachmentList(updatedList);
                                                        }}
                                                        style={{ width: '345px' }}
                                                    />
                                                </td>
                                                <td>
                                                    <button className='mainbtn ' onClick={() => document.getElementById(`fileInput-${attachment.id}`).click()} type="submit"><div>Upload</div></button>
                                                    <input
                                                        type="file" accept="image/*"
                                                        style={{ display: 'none' }}
                                                        onChange={(e) => handleFileSelect(e, attachment.id)}
                                                        id={`fileInput-${attachment.id}`}
                                                    />
                                                </td>
                                                <td><button className='mainbtnRed ' onClick={() => handleView(attachment.file)} disabled={!attachment.fileName}>
                                                    <div>View</div></button></td>
                                                <td>
                                                    <button onClick={() => handleDelete(attachment.id)} disabled={!attachment.fileName} className='mainbtnSecondary'>
                                                        <div>Delete</div></button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className='mainbtn ' onClick={handleClose}>Attach</button>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={previewModal} size="lg" onHide={() => setPreviewModal(false)}
                        backdrop="static"
                        keyboard={false}>
                        <Modal.Header closeButton>
                            <Modal.Title>Image Preview</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {previewImage && <img src={previewImage} alt={previewImage} style={{ width: '100%', objectFit: 'cover' }} />}
                        </Modal.Body>
                    </Modal>
                </div>
            </div>
        </div >
    )
}

export default PriceCompCreate