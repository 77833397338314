import React, { useState } from 'react';
import "../Styles/recipeMaster.css";
import { FaUpload } from 'react-icons/fa6';
import { GrTrash, GrView } from 'react-icons/gr';

const RecipeImages = ({ data, onChange }) => {
    const [images, setImages] = useState(data);

    // Updates images locally and calls onChange to sync with parent
    const updateImages = (updatedImages) => {
        setImages(updatedImages);
        onChange(updatedImages); // Sync with parent
    };

    const handleAddMore = () => {
        updateImages([...images, { remarks: '', file: null }]);
    };

    const handleFileChange = (index, file) => {
        const updatedImages = [...images];
        updatedImages[index].file = file;
        updateImages(updatedImages);
    };

    const handleRemarksChange = (index, remarks) => {
        const updatedImages = [...images];
        updatedImages[index].remarks = remarks;
        updateImages(updatedImages);
    };

    const handleDelete = (index) => {
        const updatedImages = images.filter((_, i) => i !== index);
        updateImages(updatedImages);
    };

    const handleView = (file) => {
        if (file) {
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL, '_blank');
        }
    };

    return (
        <div className="bg-white m-0">
            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr className="bg-purple text-white">
                            <th>Remarks</th>
                            <th>Upload</th>
                            <th>View</th>
                            <th>Delete</th>
                        </tr>
                    </thead>
                    <tbody className="p-2">
                        {images.map((image, index) => (
                            <tr className="p-2" key={index}>
                                <td>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Remarks"
                                        value={image.remarks}
                                        onChange={(e) =>
                                            handleRemarksChange(
                                                index,
                                                e.target.value
                                            )
                                        }
                                    />
                                </td>
                                <td>
                                    <div>
                                        <label className="mainbtn">
                                            <FaUpload className="center mt-1 mx-2" />
                                            Upload
                                            <input
                                                type="file"
                                                className="d-none"
                                                onChange={(e) =>
                                                    handleFileChange(
                                                        index,
                                                        e.target.files[0]
                                                    )
                                                }
                                            />
                                        </label>
                                        {image.file && (
                                            <span className="ms-2">
                                                {image.file.name}
                                            </span>
                                        )}
                                    </div>
                                </td>
                                <td>
                                    <button
                                        className="mainbtnSecondary"
                                        onClick={() => handleView(image.file)}
                                        disabled={!image.file}
                                    >
                                        <GrView color="#fff" className="center mt-1 mx-2" />
                                        View
                                    </button>
                                </td>
                                <td>
                                    <button
                                        className="mainbtnRed"
                                        onClick={() => handleDelete(index)}
                                    >
                                        <GrTrash color="#fff" className="center mt-1 mx-2" />
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="end p-3">
                <div className="subactive p-2" onClick={handleAddMore}>
                    Add More
                </div>
            </div>
        </div>
    );
};

export default RecipeImages;
