import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import ApiService2 from '../../../POS/services/apiService';
import ApiService from '../../services/apiService';
import ItemGrid from './itemList';
import Authorization from './authorization';
import MessageGrid from '../purchaseRequisition/messageGrid';
import { useSelector } from 'react-redux';
import { getCompany, getName, getUserId, getUserName } from '../../../Redux/selecters';
import "../../Assets/styles/addmenu.css"
import { RiRefreshLine } from "react-icons/ri";
import { FaAngleLeft } from "react-icons/fa6";
import { FaSave } from "react-icons/fa";
import { ClipLoader } from 'react-spinners';
import Modal from 'react-bootstrap/Modal';

const CreatePurchaseOrder = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [SupplierTab, setSupplierTab] = useState(false);
    const [POTab, setPOTab] = useState(true);
    const [CostTab, setCostTab] = useState(false);
    const [gridVisibility, setGridVisibility] = useState([true, false, false]);
    const [messageData, setMessageData] = useState([]);
    const [authorizationData, setAuthorizationData] = useState([]);
    const [ItemsData, setRequisitionData] = useState([]);
    const [suppliers, setSupplier] = useState([]);
    const [payments, setPayments] = useState([]);
    const [SelectedCurrency, setSelectedCurency] = useState([]);
    const [usersDetails, setUser] = useState([]);
    const [Supplier, setSupplierData] = useState([]);
    const [SupplierID, setSupplierID] = useState([]);
    const [isVatExcluded, setIsVatExcluded] = useState(false);
    const [isDiscountPercentage, setIsDiscountPercentage] = useState(true);
    const [SupplierContact, setSupplierContact] = useState();
    const [Payment, setPayment] = useState([]);
    const apiService2 = new ApiService2()
    const [poNumber, setPONumber] = useState([]);
    const [cost1, setCost1] = useState([]);
    const [cost2, setCost2] = useState([]);
    const [cost3, setCost3] = useState([]);
    const [cost4, setCost4] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);

    const userId = useSelector(getUserId);
    const userName = useSelector(getUserName);
    const Company = useSelector(getCompany);
    const itemData = location.state;
    const [attachmentFile, setAttachmentFile] = useState(null);
    const fileInputRef = useRef(null);
    const currentDatetimeUTC = new Date().toISOString().slice(0, 16);
    const [attachmentModal, setAttachmentModal] = useState(false);
    const [attachmentList, setAttachmentList] = useState([{ id: Date.now(), fileName: '', remarks: '' }]);
    const handleClose = () => setAttachmentModal(false);
    const handleOpen = () => setAttachmentModal(true);
    const [previewModal, setPreviewModal] = useState(false);
    const [previewImage, setPreviewImage] = useState(null);
    const getCurrentLocalDatetime = () => {
        const localDate = new Date();
        const offset = localDate.getTimezoneOffset() * 60000;
        const localISOTime = new Date(localDate - offset).toISOString().slice(0, 16);
        return localISOTime;
    };
    useEffect(() => {
        fetchData()
        getApprovalUser()
    }, []);
    useEffect(() => {
        const item = location.state;
        const selectedpaymentTerm = payments?.find(payment => payment?.description === item?.paymentTerms);

        if (selectedpaymentTerm) {
            setPayment({ label: selectedpaymentTerm?.description, value: selectedpaymentTerm })
        }
        const selectedSuplier = suppliers?.find(sup => sup?.supplierName === item?.vendorName);
        if (selectedSuplier) {
            setSupplierData({ label: selectedSuplier?.supplierName, value: selectedSuplier })
            setSupplierID({ label: selectedSuplier?.record_Id, value: selectedSuplier })
            setSupplierContact(selectedSuplier?.phoneNo1)
        }
    }, [payments, suppliers]);
    const [formData, setFormData] = useState({
        PayOrderNo: "",
        Remarks: "",
        DocumentDate: getCurrentLocalDatetime(),
        SupplierContact: "",
        deliveryDate: getCurrentLocalDatetime(),
        deliveryPoint: "",
        location: "",
        contactPerson: "",
        requisitionNo: "",
        RequisitionType: "",
        ContactNo: "",
        costCenter1: "",
        costCenter2: "",
        costCenter3: "",
        costCenter4: "",
        CostCentre5: "",
        currencyCode: "",
        QuotationReq: "",
        exchangeRate: "",
        Description: "",
        BuyerName: "",
        vendorId: "",
        vendorName: "",
        OrderType: [],
        paymentTerm: [],
        SupplierDep: [],
    });
    useEffect(() => {
        const item = location.state;
        const fetchPC = async () => {
            try {
                const apiService = new ApiService();
                const response = await apiService.get('GET_PR');
                const SelectedReq = response?.data?.result?.find(req => req.requisitionNo === item.requisitionNo)
                const costCenter1 = cost1?.find(dep => dep.description === SelectedReq?.costCenter1)
                const costCenter2 = cost2?.find(dep => dep.description === SelectedReq?.costCenter2)
                const costCenter3 = cost3?.find(dep => dep.description === SelectedReq?.costCenter3)
                const costCenter4 = cost4?.find(dep => dep.description === SelectedReq?.costCenter4)
                setIsVatExcluded(SelectedReq?.costCenter5)
                setFormData({
                    requisitionNo: item?.requisitionNo,
                    Remarks: item?.remarks,
                    requestor: SelectedReq?.requestor,
                    requisitionDate: SelectedReq?.requisitionDate || currentDatetimeUTC,
                    deliveryDate: SelectedReq?.deliveryDate || currentDatetimeUTC,
                    Department: SelectedReq?.department,
                    deliveryPoint: SelectedReq?.deliveryPoint,
                    contactPerson: SelectedReq?.contactPerson,
                    ContactNo: SelectedReq?.contatNo,
                    location: SelectedReq?.location,
                    QuotationReq: item?.pcNo,
                    costCenter1: { label: costCenter1?.description, value: costCenter1 },
                    costCenter2: { label: costCenter2?.description, value: costCenter2 },
                    costCenter3: { label: costCenter3?.description, value: costCenter3 },
                    costCenter4: { label: costCenter4?.description, value: costCenter4 },
                });
            } catch (error) {
                console.error("Error fetching  data:", error);
            }
        };
        if (item) {
            fetchPC();
        }
    }, [location.state, cost1, cost2, cost3, cost4]);
    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
        const newGridVisibility = gridVisibility.map((_, index) => index === tabIndex);
        setGridVisibility(newGridVisibility);
    };
    const getApprovalUser = async () => {
        try {
            const apiService = new ApiService();
            let docParam = `?TypeOfDoc=Pur&DocId=POrder`
            let DocId = await apiService.getApiParamater('GET_DOC_ID', docParam)
            setPONumber(DocId?.data.result[0].docId);
            const supplierResponse = await apiService.get('GET_SUPPLIER');
            setSupplier([
                { value: null, supplierName: 'None' },
                { value: null, record_Id: 'None' },
                ...supplierResponse?.data?.result]);
            if (location.state) {
                const params = new URLSearchParams();
                params.append('DocNo', itemData?.pOrderNo);
                params.append('DocumentType', "POrder");
                const response = await apiService.getApiParamater('GET_DOC_STATUS_EDIT', `?${params.toString()}`);
                setUser(response.data.result);
            } else {
                const approvalList = await apiService.get('GET_APPROVAL_USER');
                const approvals = approvalList?.data.result?.filter(dep => dep.active === 1)
                const approvalslist = approvals?.filter(dep => dep.docType === "POrder")
                setUser(approvalslist)
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const handleMessage = (data) => {
        setMessageData(data);
        console.log(data, 'reqData')
    };
    const handleRequisitionData = (data) => {
        setRequisitionData(data);
        console.log(data, 'reqData')
    };
    const handleAuthorization = (data) => {
        setAuthorizationData(data);
        console.log(data, 'reqData')
    };
    const handlecurChange = (selectedOption) => {
        setFormData({ ...formData, currencyCode: selectedOption?.value?.code });
    };
    const handleVatChange = () => {
        setIsVatExcluded(!isVatExcluded);
    };
    const handleDiscountChange = () => {
        setIsDiscountPercentage(!isDiscountPercentage);
    }
    const orderType = [
        { value: 'None', label: 'None' },
        { value: 'Local Purchase', label: 'Local Purchase' },
        { value: 'Import Purchase', label: 'Import Purchase' },
        { value: 'Fixed Assist Purchase', label: 'Fixed Assist Purchase' },
        { value: 'Non Inventory Purchase', label: 'Non Inventory Purchase' },
        { value: 'Hire Purchase', label: 'Hire Purchase' },
        { value: 'Internal Pay Order', label: 'Internal Pay Order' },
        { value: 'Open Pay Order', label: 'Open Pay Order' },
    ];
    let param = `?isActive=true`
    const fetchData = async () => {
        try {
            const apiService = new ApiService();
            const response = await apiService.get('GET_EPAYMENT');
            setPayments([{ value: null, description: 'None' }, ...response?.data?.result]);

            let res = await apiService.getApiParamater('GET_CURRENCY_MASTER', param)
            const filteredDenominations = res?.data?.result?.find(currency => currency?.isDefault === true);
            if (filteredDenominations) {
                setSelectedCurency({ label: filteredDenominations.code, value: filteredDenominations });
            }
            setFormData({
                exchangeRate: filteredDenominations?.conversionRate
            })
            let cost1 = await apiService.get('GET_COST_ONE')
            setCost1([{ value: null, description: 'None' }, ...cost1?.data?.result]);
            let cost2 = await apiService.get('GET_COST_TWO')
            setCost2([{ value: null, description: 'None' }, ...cost2?.data?.result]);
            let cost3 = await apiService.get('GET_COST_THREE')
            setCost3([{ value: null, description: 'None' }, ...cost3?.data?.result]);
            let cost4 = await apiService.get('GET_COST_FOUR')
            setCost4([{ value: null, description: 'None' }, ...cost4?.data?.result]);
        } catch (error) {
            console.error("Error fetching Currency data:", error);
        }
    }
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if ((name === 'ContactNo' || name === 'SupplierContact') && value.length > 16) {
            return;
        }
        if (name === 'deliveryDate' || name === 'DocumentDate') {
            const dateParts = e.target.value.split("-");
            if (dateParts[0].length > 4) {
                return;
            }
        }
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    const handlePayChange = (selectedOption) => {
        setPayment(selectedOption)
    }
    const handleSupplierChange = (selectedOption) => {
        setSupplierData({ label: selectedOption?.value?.supplierName, value: selectedOption })
        setSupplierID({ label: selectedOption?.value?.record_Id, value: selectedOption })
        setSupplierContact(selectedOption?.value?.phoneNo1)
        setPayment({ label: selectedOption?.value?.paymentTerm, value: selectedOption })
    }
    const handleDepChange = (selectedOption) => {
        setFormData({ ...formData, SupplierDep: selectedOption });
    }
    const handlePyorderChange = (selectedOption) => {
        setFormData({ ...formData, OrderType: selectedOption });
    }
    const CreatePO = async () => {
        setLoader(true)
        if (ItemsData.length === 0) {
            toast.warning("Please add items");
            setLoader(false)
            return;
        } else if (!Supplier.label) {
            toast.warning("Please Select Supplier");
            setLoader(false)
            return;
        }
        for (const item of ItemsData) {
            if (!item.itemDescription) {
                toast.warning(`Please Select Item`);
                setLoader(false);
                return;
            } else if (!item.qty) {
                toast.warning(`Please enter ${item.itemDescription} quantity`);
                setLoader(false);
                return;
            } else if (!item.qty || parseFloat(item.qty) <= 0) {
                toast.warning(`${item.itemDescription} quantity must be greater than 0`);
                setLoader(false);
                return;
            } else if (!item.baseAmount || parseFloat(item.baseAmount) <= 0) {
                toast.warning(`${item.itemDescription} unit price must be greater than 0`);
                setLoader(false);
                return;
            }
        }
        const totalAmount = ItemsData.reduce((sum, row) => sum + (parseFloat(row?.amount) || 0), 0)
        try {
            const apiService = new ApiService()
            const currentDatetimeUTC = new Date().toISOString();
            const prDetailsData = ItemsData.map((item, index) => ({
                pOrderNo: poNumber,
                deliveryDate: formData.deliveryDate,
                status: 0,
                isActive: true,
                vatPerc: item?.vat,
                vatDesc: item?.vatDec,
                vatAmount: item.vatAmount,
                vatUnitPrice: item.vatAmount / item.qty,
                vatBaseAmount: item.vatAmount,
                requisitionNo: formData?.requisitionNo || poNumber,
                srno: index + 1,
                rsrno: index + 1,
                itemCode: item.ItemNo || item.recordId,
                itemDescription: item.itemDescription,
                uom: item.uom,
                qty: parseInt(item.qty),
                baseUOM: item?.uom,
                baseQty: parseInt(item.baseQty),
                specification: item.specification,
                convValue: parseInt(item?.baseQty),
                unitPrice: parseFloat(item.baseAmount),
                amount: parseFloat(item.amount),
                baseAmount: parseFloat(item.amount),
                discount: parseFloat(item.discount),
                discountPerc: isDiscountPercentage === true ? 1 : 0,
                cancelledQty: 0,
                returnQty: 0,
            }));
            const userData = {
                pOrderNo: poNumber,
                // docId: formData.RequisitionType,
                docId: "Inventory",
                terms: Payment.label,
                vendorId: SupplierID?.label.toString(),
                vendorName: Supplier?.label,
                docDate: currentDatetimeUTC,
                attention: SupplierContact,
                location: formData?.location,
                currencyCode: SelectedCurrency?.label,
                exchangeRate: SelectedCurrency?.value.conversionRate,
                requisitionNo: formData?.requisitionNo || poNumber,
                department: formData?.Department,
                deliveryPoint: formData?.deliveryPoint,
                contactPerson: formData?.contactPerson,
                status: 0,
                description: formData?.Description,
                // modifiedBy: formData.BuyerName,
                modifiedBy: userName,
                remarks: formData?.Remarks,
                costCenter1: formData?.costCenter1?.label,
                costCenter2: formData?.costCenter2?.label,
                costCenter3: formData?.costCenter3?.label,
                costCenter4: formData?.costCenter4?.label,
                poType: "POrder",
                isActive: true,
                deliveryDate: formData.deliveryDate,
                poDetails: prDetailsData,
                amount: totalAmount,
                costCenter5: isVatExcluded === true ? "excluded" : "Included",
                amount: totalAmount,
                baseAmount: totalAmount,
                quotationRef: formData?.QuotationReq
            };
            const response = await apiService.post("CREATE_PO", userData);
            if (response?.data?.statusCode === 201) {
                const userData2 = {
                    docType: "POrder",
                    docId: poNumber,
                    department: "IT",

                    company: Company?.selectedCompany?.companyNo,
                    userCode: userId,
                    userName: userName,
                };
                const AttachmentData = {
                    docType: "Purchase Requisition",
                    docNo: poNumber ? poNumber.toString() : poNumber,
                    fileName: "IT",
                };
                const docBody = {
                    docId: "POrder",
                    typeOfDoc: "Pur",
                };
                const attachment = await apiService.post("CREATE_ATTACHMENT", AttachmentData);
                const response2 = await apiService.post("CREATE_DOC_AUTHORIZATION", userData2);
                const paramsGet = new URLSearchParams();
                paramsGet.append('docType', "POrder");
                paramsGet.append('docId', poNumber);
                paramsGet.append('srno', 1);
                const response3 = await apiService.getApiParamater('GET_DOC_APPROVAL_USER', `?${paramsGet.toString()}`);
                if (response3?.data?.result.length > 0) {
                    const toEmails = response3?.data?.result?.map(user => user.userEmail);
                    const emailbody = {
                        toEmails: toEmails,
                        subject: "Purchase Order",
                        body: "",
                        docNo: poNumber,
                        srNo: 1,
                        docType: "POrder"
                    };
                    const emailres = await apiService.post("DOC_APPROVAL_EMAIL_POST", emailbody);
                }
                const docRes = await apiService.put("UPDATE_DOC_ID", docBody);
                setLoader(false)
                toast.success(`${poNumber} Created successfully`);
                navigate("/inventory/Purchase/Order")
            }
        } catch (error) {
            setLoader(false)
            console.error("Error Creating PO:", error);
        }
    }
    const handleCost1 = (selectedOption) => {
        setFormData({ ...formData, costCenter1: selectedOption });
    }
    const handleCost2 = (selectedOption) => {
        setFormData({ ...formData, costCenter2: selectedOption });
    }
    const handleCost3 = (selectedOption) => {
        setFormData({ ...formData, costCenter3: selectedOption });
    }
    const handleCost4 = (selectedOption) => {
        setFormData({ ...formData, costCenter4: selectedOption });
    }
    const handleBackClick = () => {
        window.history.back();
    }
    const handleFileSelect = (event, id) => {
        const file = event.target.files[0];
        if (file) {
            setAttachmentList((prevList) => [
                ...prevList.map((attachment) =>
                    attachment.id === id ? { ...attachment, fileName: file.name, file: file } : attachment
                ),
                { id: Date.now(), fileName: '', remarks: '' },
            ]);
        }
    };
    const handleView = (file) => {
        if (file && file instanceof File) {
            setPreviewImage(URL.createObjectURL(file));
            setPreviewModal(true);
        } else {
            console.error("No valid file to preview");
        }
    };
    const handleDelete = (id) => {
        const updatedList = attachmentList.filter((attachment) => attachment.id !== id);
        setAttachmentList(updatedList);
    };
    return (
        <div className='px-4'>
            <div className='d-flex justify-content-between pt-3'>
                <h5 className='col mainInvHead'>Purchase Order</h5>
                <div className='d-flex gap-3'>
                    {loader === true ?
                        <button className='mainbtn ' type="submit"><ClipLoader color="#ffffff" size={25} /></button> :
                        <button className='mainbtn ' type="submit" onClick={CreatePO}><FaSave className='center mt-1 mx-2' /><div>Save</div></button>
                    }
                    <button className='mainbtnRed' ><RiRefreshLine className='center mt-1 mx-2' /><div>Reset</div></button>
                    <button onClick={handleBackClick} className='mainbtnSecondary'><FaAngleLeft className='center mt-1 mx-2' /><div>Back</div></button>
                </div>
            </div>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header"
                    onClick={() => setPOTab(!POTab)}>
                    <div className='h6 pt-1'>Purchase Order Details</div>
                </div>
                <div className={`slide-content ${POTab ? 'open' : 'closed'}`}>
                    <div className={`border-box2 p-3 ${POTab ? 'slide-in' : 'slide-out'}`}>
                        <div className="form-group justify-content-between row pb-3">
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="InvoiceNO" className="form-label center">Purchase Order No</label>
                                <input
                                    className="form-Input w-70"
                                    value={poNumber}
                                    name="poNumber"
                                    disabled
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="DeliveryDate" className="form-label center">Delivery Date</label>
                                <input
                                    type="datetime-local"
                                    className="form-Input w-70"
                                    id="DeliveryDate" min={currentDatetimeUTC}
                                    value={formData.deliveryDate}
                                    onChange={handleInputChange}
                                    name="deliveryDate"
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="Currency" className="form-label center">Currency </label>
                                <Select
                                    isSearchable={false}
                                    value={SelectedCurrency}
                                    className="w-70 p-0"
                                    onChange={(e) => handlecurChange(e)}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                />
                            </div>
                        </div>
                        <div className="form-group row justify-content-between pb-3">
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="DocumentDate" className="form-label center">Document Date</label>
                                <input
                                    className='form-Input w-70'
                                    id="DocumentDate"
                                    value={formData.DocumentDate}
                                    type='datetime-local' min={currentDatetimeUTC}
                                    defaultValue={new Date().toISOString().substr(0, 10)}
                                    name="DocumentDate"
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="deliveryPoint" className="form-label center">Delivery Point</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    id="deliveryPoint"
                                    value={formData?.deliveryPoint}
                                    onChange={handleInputChange}
                                    name="deliveryPoint"
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="ExchangeRate" className="form-label center">Exchange Rate </label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    disabled
                                    id="exchangeRate"
                                    value={formData.exchangeRate}
                                    name="exchangeRate"
                                />
                            </div>
                        </div>
                        <div className="form-group row justify-content-between pb-3">
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="requisitionNo" className="form-label center">Requisition No</label>
                                <input
                                    className="form-Input w-70" name='requisitionNo'
                                    id="requisitionNo" disabled
                                    value={formData.requisitionNo}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="location" className="form-label center">Location</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    id="location"
                                    value={formData.location}
                                    onChange={handleInputChange}
                                    name="location"
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="Description" className="form-label center">Description</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    id="Description"
                                    value={formData.Description}
                                    onChange={handleInputChange}
                                    name="Description"
                                />
                            </div>
                        </div>
                        <div className="form-group row justify-content-between pb-3">
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="RequisitionType" className="form-label center">Requisition Type</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    id="RequisitionType"
                                    value={formData.RequisitionType}
                                    onChange={handleInputChange}
                                    name="RequisitionType"
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="contactPerson" className="form-label center">Contact Person</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    id="contactPerson"
                                    value={formData.contactPerson}
                                    onChange={handleInputChange}
                                    name="contactPerson"
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="Remarks" className="form-label center">Remarks</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    id="Remarks"
                                    value={formData.Remarks}
                                    onChange={handleInputChange}
                                    name="Remarks"
                                />
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="QuotationReq" className="form-label center">Quotation Req</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    id="QuotationReq" disabled
                                    value={formData.QuotationReq}
                                    onChange={handleInputChange}
                                    name="QuotationReq"
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="ContactNo" className="form-label center">Contact No</label>
                                <input
                                    type="number" onWheel={(e) => e.target.blur()}
                                    className="form-Input w-70"
                                    id="ContactNo"
                                    value={formData.ContactNo}
                                    onChange={handleInputChange}
                                    name="ContactNo"
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="Description" className="form-label center">Pay OrderType</label>
                                <Select
                                    options={orderType} value={formData.OrderType}
                                    className="w-70 p-0"
                                    onChange={(selectedOption) => handlePyorderChange(selectedOption)}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 8, padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                />
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="col-4 d-flex justify-content-between">
                                <label htmlFor="BuyerName" className="form-label center">Buyer Name</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    id="BuyerName"
                                    value={formData.BuyerName}
                                    onChange={handleInputChange}
                                    name="BuyerName"
                                />
                            </div>
                            <div className='col-1 px-5 mx-3'></div>
                            <div className="col-2 brderbox center" onClick={() => handleOpen()} >
                                {'Attachment'}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header"
                    onClick={() => setSupplierTab(!SupplierTab)}>
                    <div className='h6 pt-1'>Supplier Details</div>
                </div>
                <div className={`slide-content ${SupplierTab ? 'open' : 'closed'}`}>
                    <div className="border-box2 p-3">
                        <div className="form-group row  pb-3">
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="inventoryAccountCode" className="form-label center">Vendor ID</label>
                                <Select
                                    value={SupplierID}
                                    isDisabled={itemData?.vendorName ? true : false}
                                    options={suppliers?.map((sup) => ({ value: sup, label: sup?.record_Id }))}
                                    onChange={(selectedOption) => handleSupplierChange(selectedOption)}
                                    className="w-70 p-0"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="inventoryAccountCode" className="form-label center">Vendor Name</label>
                                <Select
                                    value={Supplier}
                                    className="w-70 p-0"
                                    isDisabled={itemData?.vendorName ? true : false}
                                    options={suppliers?.map((sup) => ({ value: sup, label: sup?.supplierName }))}
                                    onChange={(selectedOption) => handleSupplierChange(selectedOption)}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="inventoryAccountCode" className="form-label center">Payment terms</label>
                                <Select
                                    className="w-70 p-0"
                                    value={Payment}
                                    isDisabled={itemData?.vendorName ? true : false}
                                    onChange={(selectedOption) => handlePayChange(selectedOption)}
                                    options={payments?.map((sup) => ({ value: sup, label: sup?.description }))}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                />
                            </div>
                        </div>
                        <div className="form-group row  pb-3">
                            <div className="col-4 d-flex justify-content-between">
                                <label htmlFor="inventoryAccountCode" className="form-label center">Vendor Dept</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    isDisabled={itemData?.vendorName ? true : false}
                                    value={formData.SupplierDep}
                                    // options={department?.map((dep) => ({ value: dep, label: dep?.description }))}
                                    onChange={(selectedOption) => handleDepChange(selectedOption)}
                                />
                            </div>
                            <div className="col-4 d-flex justify-content-between">
                                <label htmlFor="Property" className="form-label center">Vendor Contact</label>
                                <input
                                    type="number" onWheel={(e) => e.target.blur()}
                                    className="form-Input w-70"
                                    id="SupplierContact"
                                    disabled={itemData?.vendorName ? true : false}
                                    value={SupplierContact}
                                    onChange={handleInputChange}
                                    name="SupplierContact"
                                />
                            </div>
                            <div className="col-4 pt-1 d-flex justify-content-between">
                                <div className="d-flex justify-content-between">
                                    <div className="form-check">
                                        <input
                                            style={{ width: "26px", height: "26px" }}
                                            type="checkbox"
                                            className="form-check-input"
                                            checked={isVatExcluded}
                                            onChange={handleVatChange}
                                        />
                                        <label className="form-check-label mx-2 mt-1">
                                            Vat Excluded
                                        </label>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <div className="form-check">
                                        <input
                                            style={{ width: "26px", height: "26px" }}
                                            type="checkbox"
                                            className="form-check-input"
                                            checked={isDiscountPercentage}
                                            onChange={handleDiscountChange}
                                        />
                                        <label className="form-check-label mx-2 mt-1">
                                            Discount in %
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header" onClick={() => setCostTab(!CostTab)}>
                    <div className="h6 pt-1">Cost Center Details</div>
                </div>
                <div className={`slide-content ${CostTab ? 'open' : 'closed'}`}>
                    <div className="border-box2 p-3">
                        <div className="form-group row justify-content-between pb-3">
                            <div className="col-md-4 d-flex align-items-center">
                                <label htmlFor="costCenter1" className="form-label me-2">Property Group</label>
                                <Select
                                    id="costCenter1" value={formData.costCenter1} isDisabled={!!itemData?.vendorName}
                                    onChange={handleCost1}
                                    options={cost1?.map((sup) => ({ value: sup, label: sup?.description }))}
                                    className="flex-grow-1"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 8, padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    menuPlacement="auto"
                                    menuShouldScrollIntoView={false}
                                />
                            </div>
                            <div className="col-md-4 d-flex align-items-center">
                                <label htmlFor="costCenter2" className="form-label me-2">Property</label>
                                <Select
                                    id="costCenter2" value={formData.costCenter2}
                                    isDisabled={!!itemData?.vendorName} onChange={handleCost2}
                                    options={cost2?.map((sup) => ({ value: sup, label: sup?.description }))}
                                    className="flex-grow-1"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    menuPlacement="auto"
                                    menuShouldScrollIntoView={false}
                                />
                            </div>
                            <div className="col-md-4 d-flex align-items-center">
                                <label htmlFor="costCenter3" className="form-label me-2">Department</label>
                                <Select
                                    id="costCenter3" value={formData.costCenter3}
                                    isDisabled={!!itemData?.vendorName} onChange={handleCost3}
                                    options={cost3?.map((sup) => ({ value: sup, label: sup?.description }))}
                                    className="flex-grow-1"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    menuPlacement="auto"
                                    menuShouldScrollIntoView={false}
                                />
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="col-md-4 d-flex align-items-center">
                                <label htmlFor="costCenter4" className="form-label me-2">Outlet</label>
                                <Select
                                    id="costCenter4" value={formData.costCenter4}
                                    isDisabled={!!itemData?.vendorName} onChange={handleCost4}
                                    options={cost4?.map((sup) => ({ value: sup, label: sup?.description }))}
                                    className="flex-grow-1"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    menuPlacement="auto"
                                    menuShouldScrollIntoView={false}
                                />
                            </div>
                            <div className="col-md-4 d-flex align-items-center">
                                <label htmlFor="costCenter5" className="form-label me-2">Cost Centre 5</label>
                                <Select
                                    id="costCenter5" isDisabled={!!itemData?.vendorName} className="flex-grow-1"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    menuPlacement="auto"
                                    menuShouldScrollIntoView={false}
                                />
                            </div>
                            <div className="col-md-4"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="slide-container p-2 mt-3">
                <div className="container-fluid">
                    <div className="nav nav-pills overslid d-flex gap-4">
                        {/* Nav tabs */}
                        <div
                            className={`dashitems sidebar-text center ${activeTab === 0 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(0)}
                        >
                            Items
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 1 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(1)}
                        >
                            Authorization
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 2 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(2)}
                        >
                            Terms
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 3 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(3)}
                        >
                            Recommendation
                        </div>
                    </div>
                </div>
            </div>
            <div className="grid-1 brderbox2 p-4 mb-3">
                {gridVisibility[0] && (
                    <ItemGrid onRequisitionData={handleRequisitionData} itemData={itemData} selectedCheck={isVatExcluded} handleVatChange={handleVatChange}
                        isDiscountPercentage={isDiscountPercentage} />
                )}
                {gridVisibility[1] && (
                    <Authorization
                        handleAuthorization={handleAuthorization}
                        usersDetails={usersDetails}
                        setUser={setUser}
                        itemData={itemData}
                    />
                )}
                {gridVisibility[2] && (
                    <div className="grid-3 border">
                        <MessageGrid handleMessage={handleMessage} />
                    </div>
                )}
                {gridVisibility[3] && (
                    <div className="grid-3 border">
                        <MessageGrid handleMessage={handleMessage} />
                    </div>
                )}

            </div>
            <div>
                <Modal show={attachmentModal} size="lg" onHide={handleClose} >
                    <Modal.Header closeButton>
                        <Modal.Title>Attachments</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="col d-flex justify-content-between">
                            <label htmlFor="docNp" className="form-label  pt-2 start">Document No</label>
                            <input disabled value={poNumber} className="form-Input w-70" />
                        </div>
                        <div className="pt-2">
                            <table>
                                <thead className="dynoTable">
                                    <tr>
                                        <th>File Name</th>
                                        <th>Remarks</th>
                                        <th>Upload</th>
                                        <th>View</th>
                                        <th>Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {attachmentList?.map((attachment, index) => (
                                        <tr key={attachment.id}>
                                            <td className='m-0 p-0'><input className='p-0' type='text' disabled value={attachment.fileName} style={{ width: "320px" }} /></td>
                                            <td>
                                                <input type="text" value={attachment.remarks}
                                                    onChange={(e) => {
                                                        const updatedList = attachmentList?.map((item) =>
                                                            item.id === attachment.id ? { ...item, remarks: e.target.value } : item
                                                        );
                                                        setAttachmentList(updatedList);
                                                    }}
                                                    style={{ width: '345px' }}
                                                />
                                            </td>
                                            <td>
                                                <button className='mainbtn ' onClick={() => document.getElementById(`fileInput-${attachment.id}`).click()} type="submit"><div>Upload</div></button>
                                                <input
                                                    type="file" accept="image/*"
                                                    style={{ display: 'none' }}
                                                    onChange={(e) => handleFileSelect(e, attachment.id)}
                                                    id={`fileInput-${attachment.id}`}
                                                />
                                            </td>
                                            <td><button className='mainbtnRed ' onClick={() => handleView(attachment.file)} disabled={!attachment.fileName}>
                                                <div>View</div></button></td>
                                            <td>
                                                <button onClick={() => handleDelete(attachment.id)} disabled={!attachment.fileName} className='mainbtnSecondary'>
                                                    <div>Delete</div></button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className='mainbtn' onClick={handleClose} >Attach</button>
                    </Modal.Footer>
                </Modal>
                <Modal show={previewModal} size="lg" onHide={() => setPreviewModal(false)}
                    backdrop="static"
                    keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Image Preview</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {previewImage && <img src={previewImage} alt={previewImage} style={{ width: '100%', objectFit: 'cover' }} />}
                    </Modal.Body>
                </Modal>
            </div>
        </div >
    )
}

export default CreatePurchaseOrder
const selectStyle = {
    padding: '0px',
}