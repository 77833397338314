import React, { useEffect, useState, useRef } from 'react';
import Select from 'react-select';
import '../Styles/recipeMaster.css'
const RecipePreparations = () => {
    const [NotesTab, setNotesTab] = useState(false);
    const [NotesTab2, setNotesTab2] = useState(false);


    return (
        <div>

            <div className="cost-center-section mb-2">
                <div
                    className="d-flex mt-3 cursor-pointer slide-header gap-4"
                    onClick={() => setNotesTab(!NotesTab)}
                >
                    <div className="h6 pt-1">Preparation Steps</div>
                </div>
                <div className={`slide-content ${NotesTab ? 'open' : 'closed'}`}>
                    <div className="border-box2 p-3">
                        <div className="mb-3 form-group d-flex justify-content-center">
                        <textarea className='textArea' id="w3review" name="w3review" rows="9" cols="158">
                        </textarea>
                        </div>


                    </div>

                </div>
            </div>
            <div className="cost-center-section mb-2">
                <div
                    className="d-flex mt-3 cursor-pointer slide-header gap-4"
                    onClick={() => setNotesTab2(!NotesTab2)}
                >
                    <div className="h6 pt-1">Serving Instructon</div>
                </div>
                <div className={`slide-content ${NotesTab2 ? 'open' : 'closed'}`}>
                    <div className="border-box2 p-3">
                        <div className="mb-3 form-group d-flex  justify-content-center">

                            <textarea className='textArea' id="w3review" name="w3review" rows="9" cols="158">
                            </textarea>
                        </div>


                    </div>

                </div>
            </div>
        </div>
    )
}
export default RecipePreparations