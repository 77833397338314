import React, { useState, useEffect } from "react";
import 'react-pro-sidebar/dist/css/styles.css';
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { NavLink, useLocation } from "react-router-dom";
import YourLogo from "../../Assets/images/Pro_Logo.png";
import { MdOutlineInventory2 } from "react-icons/md";
import { FaUncharted } from "react-icons/fa";
import { LuLayoutDashboard } from "react-icons/lu";
import "../../Assets/styles/style.css"
import "./sideBar.css"
import { RxHamburgerMenu } from "react-icons/rx";
import { LuFileCog } from "react-icons/lu";
import { useSelector } from "react-redux";
import { getCompany } from "../../../Redux/selecters";

const InventorySideBar = () => {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [showMasterSegment, setMasterSegment] = useState(false);
  const [showTransectionSegment, setTransectionSegment] = useState(false);
  const [showReportSegment, setReportSegment] = useState(false);
  const companies = useSelector(getCompany);

  // master
  const itemMasters = location.pathname === '/Inventory/Master/Item' || location.pathname === '/Inventory/Master/Item/Create';
  const catMasters = location.pathname.startsWith('/Inventory/Master/category');
  const subCatMasters = location.pathname.startsWith('/Inventory/Master/SubCategory');
  const UomMasters = location.pathname.startsWith('/Inventory/Master/UnitMeasurement');
  const DepMasters = location.pathname.startsWith('/Inventory/Master/Department');
  const StockMasters = location.pathname.startsWith('/Inventory/Master/Stock');
  const packingMasters = location.pathname.startsWith('/Inventory/Master/Packing');
  const companyMasters = location.pathname.startsWith('/Inventory/Master/Company');
  const branchMaster = location.pathname.startsWith('/Inventory/Master/Branch');
  const currencyMaster = location.pathname.startsWith('/Inventory/Master/Currency');
  const paymentMaster = location.pathname.startsWith('/Inventory/Master/Payment');
  const bankMaster = location.pathname.startsWith('/Inventory/Master/Bank');
  const supplierMaster = location.pathname.startsWith('/Inventory/Master/supplier_Type');
  const locationMaster = location.pathname.startsWith('/Inventory/Master/LocationMaster');
  const locationCategoryMaster = location.pathname.startsWith('/Inventory/Master/Location/CategoryMaster');
  // transection
  const PrMaster = location.pathname.startsWith('/Inventory/Requisition/');
  const PcMaster = location.pathname.startsWith('/Inventory/PriceComparison/');
  const PoMaster = location.pathname.startsWith('/Inventory/Purchase/');
  const Grn = location.pathname.startsWith('/Inventory/GRN/');
  const invoice = location.pathname.startsWith('/Inventory/invoice/');
  const PReturn = location.pathname.startsWith('/Inventory/PurchaseReturn/');
  const DirectPurchase = location.pathname.startsWith('/Inventory/DirectPurchase/');
  const approval = location.pathname === ('/Inventory/Approval');
  const approvalUser = location.pathname.startsWith('/Inventory/ApprovalUser');
  const stockTake = location.pathname.startsWith('/Inventory/StockTake');
  const posting = location.pathname.startsWith('/Inventory/Posting');
  const userStore = location.pathname.startsWith('/Inventory/UserStore/');
  const issueItem = location.pathname.startsWith('/Inventory/IssueItems/');
  const stockAdjustment = location.pathname.startsWith('/Inventory/Stock/Adjustment');
  const IssueReceived = location.pathname.startsWith('/Inventory/Issue/Received');
  const issuereturn = location.pathname.startsWith('/Inventory/Issue/Return');
  const issueDirect = location.pathname.startsWith('/Inventory/Issue/Direct');
  const itemReport = location.pathname.startsWith('/Inventory/Reports/Items');
  const RequsitionReport = location.pathname.startsWith('/Inventory/Reports/PurchaseRequisition');
  const POreport = location.pathname.startsWith('/Inventory/Reports/PurchaseOrder');
  const GRNRep = location.pathname.startsWith('/Inventory/Reports/grn');
  const PRRep = location.pathname.startsWith('/Inventory/Reports/PurchaseReturn');
  const InvRep = location.pathname.startsWith('/Inventory/Reports/Invoice');
  const StockRep = location.pathname.startsWith('/Inventory/Reports/storeTransection');
  const StockVarRep = location.pathname.startsWith('/Inventory/Reports/stockVarience');
  const lease = location.pathname.startsWith('/Inventory/Reports/Lease');
  const storedPermissions = JSON.parse(localStorage.getItem('RoleAccess')) || [];
  const accessibleForms = storedPermissions.filter((form) => form.canView);
  const [permissions, setPermission] = useState(accessibleForms);

  // useEffect(() => {
  //   const storedPermissions = JSON.parse(localStorage.getItem('RoleAccess')) || [];
  //   const accessibleForms = storedPermissions.filter((form) => form.canView);
  //   setPermission(accessibleForms);
  // }, [storedPermissions]);

  const handleToggle = () => {
    setCollapsed(!collapsed);
  };
  return (
    <div>
      <ProSidebar collapsed={collapsed}>
        {collapsed ? (
          <a href="/Inventory" className="navbar-brand start">
            <img
              src={companies?.selectedCompany?.logo || YourLogo}
              alt="Logo"
              style={{ height: "40px", paddingTop: "8px", paddingBottom: "8px" }}
            />
          </a>
        ) : (
          <a href="/Inventory" className="navbar-brand start">
            <img
              src={companies?.selectedCompany?.logo || YourLogo}
              alt="Logo"
              style={{ height: "35px" }}
            />
            <span className="logoName" style={{ marginLeft: "8px" }}>
              {companies?.selectedCompany?.logo
                ?.split('/')
                .pop()
                ?.split('.')
                .slice(0, -1)
                .join('.')
                ?.charAt(0)
                .toUpperCase() +
                companies?.selectedCompany?.logo
                  ?.split('/')
                  .pop()
                  ?.split('.')
                  .slice(0, -1)
                  .join('.')
                  ?.slice(1)
                  .toLowerCase()}
            </span>
          </a>
        )}
        <Menu className="mt-2">
          {permissions?.some(permission => permission.formName === "Dashboard" && permission.canView) && (
            <MenuItem
              icon={<LuLayoutDashboard size={21} />}
              active={location.pathname.startsWith('/Inventory/Dashboard')}
            >
              <NavLink to="/Inventory/Dashboard">
                <div>Dashboard</div>
              </NavLink>
            </MenuItem>
          )}
          {permissions?.some(permission => permission.formName === "Masters" && permission.canView) && (
            <SubMenu
              title="Masters"
              icon={<LuFileCog size={21} />}
              active={location.pathname.startsWith('/Inventory/Master/')}
              open={showMasterSegment}
              onOpenChange={() => setMasterSegment(!showMasterSegment)}
            >
              {permissions?.some(permission => permission.formName === "Item Master" && permission.canView) && (
                <MenuItem><NavLink to="/Inventory/Master/Item" className="mt-2"><div className={`submenu ${itemMasters ? 'subactive' : ''}`}>- Item Master</div></NavLink></MenuItem>
              )}
              {permissions?.some(permission => permission.formName === "UOM Master" && permission.canView) && (
                <MenuItem><NavLink to="/Inventory/Master/UnitMeasurement" className="mt-2"><div className={`submenu ${UomMasters ? 'subactive' : ''}`}>-  UOM Master</div></NavLink></MenuItem>
              )}
              {permissions?.some(permission => permission.formName === "Category" && permission.canView) && (
                <MenuItem><NavLink to="/Inventory/Master/category" className="mt-2"><div className={`submenu ${catMasters ? 'subactive' : ''}`}>- Category</div></NavLink></MenuItem>
              )}
              {permissions?.some(permission => permission.formName === "SubCategory" && permission.canView) && (
                <MenuItem><NavLink to="/Inventory/Master/SubCategory"><div className={`submenu ${subCatMasters ? 'subactive' : ''}`}>- SubCategory</div></NavLink></MenuItem>
              )}
              {permissions?.some(permission => permission.formName === "Department" && permission.canView) && (
                <MenuItem><NavLink to="/Inventory/Master/Department"><div className={`submenu ${DepMasters ? 'subactive' : ''}`}> - Department</div></NavLink></MenuItem>
              )}
              {permissions?.some(permission => permission.formName === "Stock Type" && permission.canView) && (
                <MenuItem><NavLink to="/Inventory/Master/Stock"><div className={`submenu ${StockMasters ? 'subactive' : ''}`}>- Stock Type</div></NavLink></MenuItem>
              )}
              {permissions?.some(permission => permission.formName === "Packing Type" && permission.canView) && (
                <MenuItem><NavLink to="/Inventory/Master/Packing"><div className={`submenu ${packingMasters ? 'subactive' : ''}`}> - Packing Type</div></NavLink></MenuItem>
              )}
              {permissions?.some(permission => permission.formName === "Company Master" && permission.canView) && (
                <MenuItem><NavLink to="/Inventory/Master/Company"><div className={`submenu ${companyMasters ? 'subactive' : ''}`}> - Company Master</div></NavLink></MenuItem>
              )}
              {permissions?.some(permission => permission.formName === "Branch Master" && permission.canView) && (
                <MenuItem><NavLink to="/Inventory/Master/Branch"><div className={`submenu ${branchMaster ? 'subactive' : ''}`}>- Branch Master</div></NavLink></MenuItem>
              )}
              {permissions?.some(permission => permission.formName === "Currency Master" && permission.canView) && (
                <MenuItem><NavLink to="/Inventory/Master/Currency"><div className={`submenu ${currencyMaster ? 'subactive' : ''}`}>- Currency Master</div></NavLink></MenuItem>
              )}
              {permissions?.some(permission => permission.formName === "Payment Master" && permission.canView) && (
                <MenuItem><NavLink to="/Inventory/Master/Payment"><div className={`submenu ${paymentMaster ? 'subactive' : ''}`}>- Payment Master</div></NavLink></MenuItem>
              )}
              {permissions?.some(permission => permission.formName === "Bank Master" && permission.canView) && (
                <MenuItem><NavLink to="/Inventory/Master/Bank"><div className={`submenu ${bankMaster ? 'subactive' : ''}`}>- Bank Master</div></NavLink></MenuItem>
              )}
              {permissions?.some(permission => permission.formName === "Supplier Take Master" && permission.canView) && (
                <MenuItem><NavLink to="/Inventory/Master/supplier_Type"><div className={`submenu ${supplierMaster ? 'subactive' : ''}`}>- Supplier Type Master</div></NavLink></MenuItem>
              )}
              {permissions?.some(permission => permission.formName === "Location Master" && permission.canView) && (
                <MenuItem><NavLink to="/Inventory/Master/LocationMaster"><div className={`submenu ${locationMaster ? 'subactive' : ''}`}>- Location Master</div></NavLink></MenuItem>
              )}
              {permissions?.some(permission => permission.formName === "Location Category Master" && permission.canView) && (
                <MenuItem><NavLink to="/Inventory/Master/Location/CategoryMaster"><div className={`submenu ${locationCategoryMaster ? 'subactive' : ''}`}>- Location Category Master</div></NavLink></MenuItem>
              )}
            </SubMenu>
          )}
          {permissions?.some(permission => permission.formName === "Transaction" && permission.canView) && (
            <SubMenu className="mt-2"
              title="Transaction"
              icon={<MdOutlineInventory2 size={21} />}
              active={location.pathname.startsWith('/Inventory/')}
              open={showTransectionSegment}
              onOpenChange={() => setTransectionSegment(!showTransectionSegment)}
            >
              {permissions?.some(permission => permission.formName === "Purchase Requisition" && permission.canView) && (

                <MenuItem><NavLink to="/Inventory/Requisition/Listing"><div className={`submenu ${PrMaster ? 'subactive' : ''}`}>- Purchase Requisition</div></NavLink></MenuItem>

              )}

              {permissions?.some(permission => permission.formName === "Price Comparison" && permission.canView) && (
                <MenuItem><NavLink to="/Inventory/PriceComparison/List"><div className={`submenu ${PcMaster ? 'subactive' : ''}`}>- Price Comparison</div></NavLink></MenuItem>

              )}
              {permissions?.some(permission => permission.formName === "Purchase Order" && permission.canView) && (
                <MenuItem><NavLink to="/Inventory/Purchase/Order"><div className={`submenu ${PoMaster ? 'subactive' : ''}`}>- Purchase Order</div></NavLink></MenuItem>

              )}
              {permissions?.some(permission => permission.formName === "GRN" && permission.canView) && (
                <MenuItem><NavLink to="/Inventory/GRN/Listing"><div className={`submenu ${Grn ? 'subactive' : ''}`}>- GRN</div></NavLink></MenuItem>

              )}
              {permissions?.some(permission => permission.formName === "Invoice" && permission.canView) && (
                <MenuItem><NavLink to="/Inventory/invoice/List"><div className={`submenu ${invoice ? 'subactive' : ''}`}>- Invoice</div></NavLink></MenuItem>

              )}
              {permissions?.some(permission => permission.formName === "Purchase Return" && permission.canView) && (
                <MenuItem><NavLink to="/Inventory/PurchaseReturn/List"><div className={`submenu ${PReturn ? 'subactive' : ''}`}>- Purchase Return</div></NavLink></MenuItem>

              )}
              {permissions?.some(permission => permission.formName === "Direct Purchase" && permission.canView) && (
                <MenuItem><NavLink to="/Inventory/DirectPurchase/List"><div className={`submenu ${DirectPurchase ? 'subactive' : ''}`}>- Direct Purchase</div></NavLink></MenuItem>

              )}
              {permissions?.some(permission => permission.formName === "Approval" && permission.canView) && (
                <MenuItem><NavLink to="/Inventory/Approval"><div className={`submenu ${approval ? 'subactive' : ''}`}>- Approval</div></NavLink></MenuItem>

              )}
              {permissions?.some(permission => permission.formName === "Approval User" && permission.canView) && (
                <MenuItem><NavLink to="/Inventory/ApprovalUser/List"><div className={`submenu ${approvalUser ? 'subactive' : ''}`}>- Approval User</div></NavLink></MenuItem>

              )}
              {permissions?.some(permission => permission.formName === "Stock Take" && permission.canView) && (
                <MenuItem><NavLink to="/Inventory/StockTake"><div className={`submenu ${stockTake ? 'subactive' : ''}`}>- Stock Take</div></NavLink></MenuItem>

              )}
              {permissions?.some(permission => permission.formName === "Posting" && permission.canView) && (
                <MenuItem><NavLink to="/Inventory/Posting"><div className={`submenu ${posting ? 'subactive' : ''}`}>- Posting</div></NavLink></MenuItem>

              )}
              {permissions?.some(permission => permission.formName === "User Store Request" && permission.canView) && (
                <MenuItem><NavLink to="/Inventory/UserStore/List"><div className={`submenu ${userStore ? 'subactive' : ''}`}>- User Store Request</div></NavLink></MenuItem>

              )}
              {permissions?.some(permission => permission.formName === "Item Issue" && permission.canView) && (
                <MenuItem><NavLink to="/Inventory/IssueItems/List"><div className={`submenu ${issueItem ? 'subactive' : ''}`}>- Item Issue</div></NavLink></MenuItem>

              )}
              {permissions?.some(permission => permission.formName === "Stock Adjustment" && permission.canView) && (
                <MenuItem><NavLink to="/Inventory/Stock/Adjustment"><div className={`submenu ${stockAdjustment ? 'subactive' : ''}`}>- Stock Adjustment</div></NavLink></MenuItem>

              )}
              {permissions?.some(permission => permission.formName === "Issue Inventory Direct" && permission.canView) && (
                <MenuItem><NavLink to="/Inventory/Issue/Direct"><div className={`submenu ${issueDirect ? 'subactive' : ''}`}>- Issue Inventory Direct</div></NavLink></MenuItem>

              )}
              {permissions?.some(permission => permission.formName === "Issue Return" && permission.canView) && (
                <MenuItem><NavLink to="/Inventory/Issue/Return"><div className={`submenu ${issuereturn ? 'subactive' : ''}`}>- Issue Return</div></NavLink></MenuItem>

              )}
              {permissions?.some(permission => permission.formName === "Issue Received" && permission.canView) && (
                <MenuItem><NavLink to="/Inventory/Issue/Received"><div className={`submenu ${IssueReceived ? 'subactive' : ''}`}>- Issue Received</div></NavLink></MenuItem>

              )}
            </SubMenu>
          )}
          {permissions?.some(permission => permission.formName === "Reports" && permission.canView) && (
            <SubMenu className="mt-2"
              title="Reports"
              icon={<FaUncharted size={22} />}
              open={showReportSegment}
              onOpenChange={() => setReportSegment(!showReportSegment)}
            >
              {permissions?.some(permission => permission.formName === "Items" && permission.canView) && (
                <MenuItem><NavLink to="/Inventory/Reports/Items"><div className={`submenu ${itemReport ? 'subactive' : ''}`}>- Items</div></NavLink></MenuItem>
              )}
              {permissions?.some(permission => permission.formName === "Purchase Order" && permission.canView) && (
                <MenuItem><NavLink to="/Inventory/Reports/PurchaseOrder"><div className={`submenu ${POreport ? 'subactive' : ''}`}>- Purchase Order</div></NavLink></MenuItem>
              )}
              {permissions?.some(permission => permission.formName === "Requisition" && permission.canView) && (
                <MenuItem><NavLink to="/Inventory/Reports/PurchaseRequisition"><div className={`submenu ${RequsitionReport ? 'subactive' : ''}`}>- Requisition</div></NavLink></MenuItem>
              )}
              {permissions?.some(permission => permission.formName === "GRN" && permission.canView) && (
                <MenuItem><NavLink to="/Inventory/Reports/grn"><div className={`submenu ${GRNRep ? 'subactive' : ''}`}>- GRN</div></NavLink></MenuItem>
              )}
              {permissions?.some(permission => permission.formName === "Purchase Return" && permission.canView) && (
                <MenuItem><NavLink to="/Inventory/Reports/PurchaseReturn"><div className={`submenu ${PRRep ? 'subactive' : ''}`}>- Purchase Return</div></NavLink></MenuItem>
              )}
              {permissions?.some(permission => permission.formName === "Invoice" && permission.canView) && (
                <MenuItem><NavLink to="/Inventory/Reports/Invoice"><div className={`submenu ${InvRep ? 'subactive' : ''}`}>- Invoice</div></NavLink></MenuItem>
              )}
              {permissions?.some(permission => permission.formName === "Stock Transection Report" && permission.canView) && (
                <MenuItem><NavLink to="/Inventory/Reports/StockTransection"><div className={`submenu ${StockRep ? 'subactive' : ''}`}>- Stock Transection Report</div></NavLink></MenuItem>
              )}
              {permissions?.some(permission => permission.formName === "Stock Varience Report" && permission.canView) && (
                <MenuItem><NavLink to="/Inventory/Reports/stockVarience"><div className={`submenu ${StockVarRep ? 'subactive' : ''}`}>- Stock Varience Report</div></NavLink></MenuItem>
              )}
              <MenuItem><NavLink to="/Inventory/Reports/Lease"><div className={`submenu ${lease ? 'subactive' : ''}`}>- Lease Report</div></NavLink></MenuItem>
            </SubMenu>
          )}
        </Menu>
        <div
          onClick={handleToggle}
          className="sidebar-toggle-btn"
          style={{ left: collapsed ? '100px' : '300px' }}
        >
          <RxHamburgerMenu size={24} />
        </div>
      </ProSidebar>
    </div>
  );
};

export default InventorySideBar;

