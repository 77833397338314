import React, { useState, useEffect, Component } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import Plus from "./../../Assets/images/plusIcon.png"
import { useNavigate } from 'react-router-dom';
import { FiPrinter } from "react-icons/fi";
import ApiService from '../../services/apiService';
import ApiService2 from '../../../POS/services/apiService';
import { useSelector } from 'react-redux';
import { getName } from '../../../Redux/selecters';

const AuthorizationList = ({ usersDetails, itemData }) => {
    const userName = useSelector(getName);
    const [selectedRec, setSelectedRec] = useState();
    const [approvalUsers, setApprovalUsers] = useState([]);
    const apiService = new ApiService();
    const apiService2 = new ApiService2();

    useEffect(() => {
        fetchApprovalData();
    }, [usersDetails]);
    const fetchApprovalData = async () => {
        try {
            // let param = `?UserName=${userName}&From=22-Jun-2024&To=22-Jun-2025`
            // let parameters = `?Status=${itemData?.status}&DocType=${usersDetails[0]?.docType}&UserName=${usersDetails[0]?.userNo}`
            // const response = await apiService.getApiParamater('GET_DOC_AUTHORIZATION_STATUS', param);
            // if (itemData.docno) {
            //     const selectedRec = response.data.result?.find(rec => rec.docno === itemData?.docNo)
            //     setSelectedRec(selectedRec)
            // } else {
            //     const selectedRec = response.data.result?.find(rec => rec.docno === itemData?.requisitionNo)
            //     setSelectedRec(selectedRec)
            // }
        } catch (error) {
            console.error("Error fetching requsition data:", error);
        }
    };
    function getStatusButton(status) {
        if (status === 0 || status === null) {
            return (<input className="p-2 w-100" value={"Pending"} readOnly />);
        }
        else if (status === 1 || status === null) {
            return (<input value={"Approved"} className="p-2 w-100" readOnly />);
        }
        else if (status === 5 || status === null) {
            return (<input value={"Hold"} className="p-2 w-100" readOnly />);
        }
        else if (status === 2 || status === null) {
            return (<input value={"Rejected"} className="p-2 w-100" readOnly />);
        } else {
            return (<input className="p-2 w-100" value={"Pending"} readOnly />);
        }
    }

    return (
        <div className='table-container mb-5 p-2' style={{ overflowX: 'auto' }}>
            <table className='w-100'>
                <thead className='dynoTable' >
                    <tr>
                        <th>Sr No</th>
                        <th>User Code</th>
                        <th>User Name</th>
                        <th>Approval Date</th>
                        <th>Remarks</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {usersDetails?.map((user, index) => (
                        <tr key={user.rcid}>
                            <td className='m-0 p-0'><input type="text" className='p-2 w-100' value={index + 1} disabled /></td>
                            <td className='m-0 p-0'><input type="text" className='p-2 w-100' value={user?.userCode} disabled /></td>
                            <td className='m-0 p-0'><input type="text" className='p-2 w-100' value={user?.userNo} disabled /></td>
                            <td className='m-0 p-0'><input type="text" className='p-2 w-100 ' value={selectedRec?.docdate} disabled /></td>
                            {itemData?.eye === true ?
                                <td className='m-0 p-0'><input type="text" className='p-2 w-100' value={itemData?.remarks} disabled /></td>
                                :
                                <td className='m-0 p-0'>
                                    <input
                                        type="text"
                                        className='p-2 w-100'
                                        disabled
                                        value={itemData?.remarks || ''}
                                    />
                                </td>}
                            {/* <td className='m-0 p-0'><input className='p-2 w-100' value={itemData?.status} disabled />
                            </td> */}
                            <td className='m-0 p-0'>
                                {getStatusButton(itemData?.status)}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};
export default AuthorizationList; 