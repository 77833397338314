import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import moment from 'moment';
import Select from 'react-select';
import ApiService from '../../services/apiService';
import ApiService2 from '../../../POS/services/apiService';
import { GetCountries, GetState, GetCity, GetLanguages, } from "react-country-state-city";
import { RiRefreshLine } from "react-icons/ri";
import { FaSave } from "react-icons/fa";
import { hasPermission } from "../../services/roleService"
import { FaAngleLeft } from "react-icons/fa6";

const CreateSupplier = () => {
    const { state } = useLocation();
    let navigate = useNavigate();
    const location = useLocation();
    const [payments, setPayments] = useState([]);
    const [suppliertype, setSuppliertype] = useState([]);
    const [Credit, setCredit] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [countriesList, setCountries] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [SupplierTab, setSupplierTab] = useState(true);
    const [paymentTab, setPaymentTab] = useState(false);
    const [GlAccountTab, setGlTab] = useState(false);
    const [permissions, setPermissions] = useState([]);
    const [vat, setvat] = useState([]);
    const apiService = new ApiService();

    useEffect(() => {
        const item = location.state;
        // const selectedDepartment = department?.find(dep => dep.description === item?.department)
        // if (selectedDepartment) {
        //     setSelectedDepartment({ label: selectedDepartment.description, value: selectedDepartment })
        // }
        const selectedPayment = payments?.find(dep => dep.description === item?.paymentTerm)
        setFormData({
            id: state?.id || '',
            SupplierID: item?.supplier_ID,
            record_Id: item?.record_Id,
            Phoneno: item?.phoneNo1,
            MobileNo: item?.mobileNo1,
            SupplierType: item?.supplierType,
            VATRegNo: item?.vatRegNo,
            Fax: item?.faxNo1,
            Email: item?.email1,
            ChequeName: "",
            Name: item?.supplierName,
            Address: item?.address1,
            Web: item?.web,
            Discount: item?.supplierName,
            Address1: item?.address1,
            Address2: item?.address2,
            CreditDays: item?.creditDays,
            CreditLimit: item?.creditLimit,
            SubAccount: item?.supplierName,
            AccountName: item?.accountName,
            paymentTerm: [{ label: selectedPayment?.description, value: selectedPayment }],
            AccountCode: item?.supplierName,
        });
    }, [location?.state, payments]);
    useEffect(() => {
        if (state && state.employee_Name) {
            const [first, ...rest] = state.employee_Name.split(' ');
            setFormData(prevState => ({
                ...prevState,
                firstName: first,
                lastName: rest.join(' '),
            }));
        }
    }, [state]);
    useEffect(() => {
        GetCountries().then((result) => {
            setCountries(result);
        });
    }, []);
    useEffect(() => {
        GetState(selectedCountry?.value?.id).then((result) => {
            setStateList(result);
        });
    }, [selectedCountry]);
    useEffect(() => {
        GetCity(selectedCountry?.value?.id, selectedState?.value?.id).then((result) => {
            setCityList(result);
        });
    }, [selectedState]);
    useEffect(() => {
        // Set form data with state data if available
        setFormData(prevState => ({
            ...prevState,
            userName: state?.employee_Name || "",
            email: state?.email || "",
            role: state?.roleId || "",
            branch: state?.branchId || "",
            dateOfBirth: state?.exp2 ? moment(state.exp2).format('YYYY-MM-DD') : "",
            gender: state?.exp3 || "",
        }));
    }, [state]);
    useEffect(() => {
        fetchPayment()
        const storedPermissions = JSON.parse(localStorage.getItem('RoleAccess')) || [];
        setPermissions(storedPermissions);
    }, []);

    const checkPermission = (moduleName, action) => {
        return hasPermission(moduleName, action, permissions);
    };
    // const canEditMaster = checkPermission('Supplier', 'edit');
    // const canCreateMaster = checkPermission('Supplier', 'create');
    const canEditMaster = true;
    const canCreateMaster = true;
    const fetchPayment = async () => {
        try {
            
            let param = `?isActive=true`
            const response = await apiService.get('GET_EPAYMENT');
            setPayments(response?.data?.result);
            const vat = await apiService.getApiParamater('GET_INV_TAX', param);
            setvat(vat.data?.result);
            const SupResponse = await apiService.get('GET_SUPPLIER_TYPE');
            setSuppliertype(SupResponse?.data?.result);
        } catch (error) {
            console.error("Error fetching  data:", error);
        }
    };
    const [formData, setFormData] = useState({
        id: state?.id || '',
        SupplierID: "",
        ChequeName: "",
        Phoneno: "",
        MobileNo: "",
        SupplierType: [],
        VATRegNo: "",
        Vat: [],
        Fax: "",
        Email: "",
        Name: "",
        Address: "",
        Web: "",
        Discount: "",
        Address1: "",
        Address2: "",
        CreditDays: 0,
        CreditLimit: 0,
        SubAccount: "",
        AccountName: "",
        paymentTerm: [],
        AccountCode: "",
    });
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if ((name === 'Phoneno' || name === 'MobileNo') && value.length > 16) {
            return;
        }
        setFormData({ ...formData, [name]: value });
    };
    const createUser = async () => {
        try {
            if (!formData?.Name) {
                toast.error("Please Enter Name ");
            } else if (!formData.MobileNo) {
                toast.error("Please Enter Mobile Number ");
            } else if (!selectedCountry) {
                toast.error("Please Select Country ");
            } else if (!selectedCity) {
                toast.error("Please Select State & City ");
            } else if (!formData.paymentTerm) {
                toast.error("Please Select Payment Term ");
            } else if (!formData.SupplierType) {
                toast.error("Please Select Supplier Type ");
            }
            else {
                const userData = {
                    supplier_ID: formData?.SupplierID,
                    supplierType: formData?.SupplierType.label,
                    supplierName: formData?.Name,
                    accountCode: formData?.AccountCode,
                    accountName: formData?.AccountName,
                    address1: formData?.Address1,
                    address2: formData?.Address2,
                    city: selectedCity?.label,
                    country: selectedCountry?.label,
                    phoneNo1: formData?.Phoneno,
                    faxNo1: formData?.Fax,
                    mobileNo1: formData?.MobileNo,
                    email1: formData?.Email,
                    web: formData?.Web,
                    contactPerson1: formData?.Name,
                    cP1ContactNo1: formData?.Name,
                    chequePrintName: formData?.ChequeName,
                    creditLimit: formData?.CreditLimit,
                    paymentTerm: formData.paymentTerm.label,
                    creditDays: formData?.CreditDays,
                    vatRegNo: formData?.VATRegNo,
                    exp4: "1"
                };
                const response = await apiService.post("CREATE_SUPPLIER", userData);
                if (response?.data?.statusCode === 201) {
                    toast.success("Supplier Created successfully");
                    setFormData({
                        id: state?.id || '',
                        SupplierID: "",
                        ChequeName: "",
                        Phoneno: "",
                        MobileNo: "",
                        SupplierType: "",
                        VATRegNo: "",
                        Fax: "",
                        Email: "",
                        Name: "",
                        Address: "",
                        Web: "",
                        Discount: "",
                        Address1: "",
                        Address2: "",
                        CreditDays: "",
                        CreditLimit: "",
                        SubAccount: "",
                        AccountName: "",
                        AccountCode: "",
                    });
                    navigate("/Inventory/Supplier_Master/list");
                }

            }
        } catch (error) {
            console.error("Error Creating Supplier:", error);
            toast.error("Error Creating Supplier. Please try again later.");
        }
    };
    const UpdateUser = async () => {
        try {
            if (!formData?.Name) {
                toast.error("Please Enter Name ");
            }
            else {
                const userData = {
                    record_Id: formData?.record_Id,
                    supplier_ID: formData?.SupplierID.toString(),
                    supplierType: formData?.SupplierType.label,
                    supplierName: formData?.Name,
                    accountCode: formData?.AccountCode,
                    accountName: formData?.AccountName,
                    address1: formData?.Address1,
                    address2: formData?.Address2,
                    city: selectedCity?.label,
                    country: selectedCountry?.label,
                    phoneNo1: formData?.Phoneno,
                    faxNo1: formData?.Fax,
                    mobileNo1: formData?.MobileNo,
                    email1: formData?.Email,
                    web: formData?.Web,
                    contactPerson1: formData?.Name,
                    cP1ContactNo1: formData?.Name,
                    chequePrintName: formData?.ChequeName,
                    creditLimit: formData?.CreditLimit,
                    paymentTerm: formData.paymentTerm.label,
                    creditDays: formData?.CreditDays,
                    vatRegNo: formData?.VATRegNo,
                };
                const response = await apiService.put("UPDATE_SUPPLIER", userData);
                if (response?.data?.statusCode === 201) {
                    toast.success("Supplier Created successfully");
                    setFormData({
                        id: state?.id || '',
                        SupplierID: "",
                        ChequeName: "",
                        Phoneno: "",
                        MobileNo: "",
                        SupplierType: "",
                        VATRegNo: "",
                        Fax: "",
                        Email: "",
                        Name: "",
                        Address: "",
                        Web: "",
                        Discount: "",
                        Address1: "",
                        Address2: "",
                        CreditDays: "",
                        CreditLimit: "",
                        SubAccount: "",
                        AccountName: "",
                        AccountCode: "",
                    });
                    navigate("/Inventory/Supplier_Master/list");
                }

            }
        } catch (error) {
            console.error("Error Creating Supplier:", error);
            toast.error("Error Creating Supplier. Please try again later.");
        }
    };
    const handleCreditSelection = (e) => {
        setCredit(e);
    };
    const handlePayChange = (selectedOption) => {
        setFormData({ ...formData, paymentTerm: selectedOption });
    };
    const handleSupChange = (selectedOption) => {
        setFormData({ ...formData, SupplierType: selectedOption });
    };
    const handleCountryChange = (selectedOption) => {
        setSelectedCountry(selectedOption);
        setSelectedCity(null);
        setSelectedState(null)
    };
    const handleStateChange = (selectedOption) => {
        setSelectedState(selectedOption);
        setSelectedCity(null);
    };
    const handlevatChange = (selectedOption) => {
        setFormData({ ...formData, Vat: selectedOption });
    };
    const handleCityChange = (selectedOption) => {
        setSelectedCity(selectedOption);
    };
    const handleBackClick = () => {
        window.history.back();
      };
    return (
        <div className='container'>
            <div className='d-flex justify-contant-between '>
                <h5 className='col mainInvHead'>Supplier Master</h5>
                <div className='d-flex justify-content-end gap-1'>
                    <div className='col center'>
                        {location.state ? (
                            <button className='mainbtn ' onClick={UpdateUser}>Update</button>
                        ) : (
                            <>{canCreateMaster === true ?
                                <button className='mainbtn ' type="submit" onClick={() => createUser()}>
                                    <FaSave className='center mt-1 mx-2' /> <div>Save</div></button>
                                :
                                <button className='mainbtn ' type="submit" style={{ cursor: "not-allowed" }}>
                                    <FaSave className='center mt-1 mx-2' /> <div>Save</div></button>
                            } </>
                        )}
                    </div>
          <button className='mainbtnRed'><RiRefreshLine className='center mt-1 mx-2' /><div>Reset</div></button>
          <button onClick={handleBackClick} className='mainbtn'><FaAngleLeft className='center mt-1 mx-2' /><div>Back</div></button>
                </div>
            </div>
            <div className="cost-center-section mb-2">
                <div className="d-flex mt-3 cursor-pointer slide-header gap-4"
                    onClick={() => setSupplierTab(!SupplierTab)}>
                    <div className='h6 pt-1'>Supplier Details</div>
                </div>
                <div className={`slide-content ${SupplierTab ? 'open' : 'closed'}`}>
                    <div className="border-box2 p-3">
                        <div className="form-group row d-flex justify-content-between pb-3">
                            <div className="col-md-3 col-lg-3 d-flex justify-content-between ">
                                <label htmlFor="validationCustom01" className="form-label  pt-2 start">Supplier ID</label>
                                <input
                                    type="number" onWheel={(e) => e.target.blur()}
                                    className="form-Input w-70"
                                    id="validationCustom01"
                                    disabled
                                    name="SupplierID"
                                    value={formData?.SupplierID}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col-md-3 col-lg-3 d-flex justify-content-between ">
                                <label htmlFor="validationCustom01" className="form-label  pt-2 start">Name *</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    disabled={canCreateMaster === true ? false : true}
                                    id="validationCustom01"
                                    name="Name"
                                    value={formData?.Name}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col-md-3 col-lg-3 d-flex justify-content-between ">
                                <label htmlFor="validationCustom03" className="form-label  pt-2 start">Phone no </label>
                                <input
                                    type="number" onWheel={(e) => e.target.blur()}
                                    className="form-Input w-70"
                                    disabled={canCreateMaster === true ? false : true}
                                    id="validationCustom03"
                                    name="Phoneno"
                                    value={formData?.Phoneno}
                                    onChange={handleInputChange}
                                    maxLength={16} max={16}
                                    min={0}
                                />
                            </div>
                            <div className="col-md-3 col-lg-3 d-flex justify-content-between ">
                                <label htmlFor="validationCustom03" className="form-label  pt-2 start">Mobile No * </label>
                                <input
                                    type="number" onWheel={(e) => e.target.blur()}
                                    className="form-Input w-70"
                                    disabled={canCreateMaster === true ? false : true}
                                    id="validationCustom03"
                                    name="MobileNo"
                                    value={formData?.MobileNo}
                                    onChange={handleInputChange}
                                    max={16}
                                    min={0}
                                />
                            </div>
                        </div>
                        <div className="form-group row justify-content-between pb-3">
                            <div className="col-md-3 col-lg-3 d-flex justify-content-between ">
                                <label htmlFor="validationCustom01" className="form-label  pt-2 start">Supplier Type *</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    isDisabled={canCreateMaster === true ? false : true}
                                    value={formData?.SupplierType}
                                    onChange={(selectedOption) => handleSupChange(selectedOption)}
                                    options={suppliertype?.map((sup) => ({ value: sup, label: sup?.description }))}
                                />
                            </div>
                            <div className="col-md-3 col-lg-3 d-flex justify-content-between ">
                                <label htmlFor="validationCustom03" className="form-label  pt-2 start">Fax</label>
                                <input
                                    type="number" onWheel={(e) => e.target.blur()}
                                    className="form-Input w-70"
                                    disabled={canCreateMaster === true ? false : true}
                                    id="validationCustom03"
                                    name="Fax"
                                    value={formData?.Fax}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col-md-3 col-lg-3 d-flex justify-content-between ">
                                <label htmlFor="validationCustom03" className="form-label  pt-2 start">Email</label>
                                <input
                                    type="email"
                                    className="form-Input w-70"
                                    disabled={canCreateMaster === true ? false : true}
                                    id="validationCustom03"
                                    name="Email"
                                    value={formData?.Email}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col-md-3 col-lg-3 d-flex justify-content-between ">
                                <label htmlFor="validationCustom03" className="form-label  pt-2 start">Web</label>
                                <input
                                    type="url"
                                    className="form-Input w-70"
                                    disabled={canCreateMaster === true ? false : true}
                                    id="validationCustom03"
                                    name="Web"
                                    value={formData?.Web}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className="form-group row justify-content-between pb-3">
                            <div className="col-md-3 col-lg-3 d-flex justify-content-between ">
                                <label htmlFor="validationCustom03" className="form-label  pt-2 start">Address</label>
                                <input
                                    type="taxt"
                                    className="form-Input w-70"
                                    disabled={canCreateMaster === true ? false : true}
                                    id="validationCustom03"
                                    name="Address"
                                    value={formData?.Address}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col-md-3 col-lg-3 d-flex justify-content-between ">
                                <label htmlFor="validationCustom03" className="form-label  pt-2 start">Address 1</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    disabled={canCreateMaster === true ? false : true}
                                    id="validationCustom03"
                                    name="Address1"
                                    value={formData?.Address1}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col-md-3 col-lg-3 d-flex justify-content-between ">
                                <label htmlFor="validationCustom03" className="form-label  pt-2 start">Address 2</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    disabled={canCreateMaster === true ? false : true}
                                    id="validationCustom03"
                                    name="Address2"
                                    value={formData?.Address2}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col-md-3 col-lg-3 d-flex justify-content-between ">
                                <label htmlFor="validationCustom03" className="form-label  pt-2 start">Country *</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    value={selectedCountry}
                                    isDisabled={canCreateMaster === true ? false : true}
                                    onChange={handleCountryChange}
                                    options={countriesList?.map(country => ({ value: country, label: country?.name }))}
                                />
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="col-3  d-flex justify-content-between ">
                                <label htmlFor="validationCustom19" className="form-label  pt-2 start">State</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    value={selectedState}
                                    isDisabled={canCreateMaster === true ? false : true}
                                    onChange={handleStateChange}
                                    options={stateList ? stateList?.map(state => ({ value: state, label: state?.name })) : []}
                                />
                            </div>
                            <div className="col-3  d-flex justify-content-between ">
                                <label htmlFor="validationCustom03" className="form-label  pt-2 start">City *</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    value={selectedCity}
                                    isDisabled={canCreateMaster === true ? false : true}
                                    onChange={handleCityChange}
                                    options={cityList ? cityList?.map(city => ({ value: city, label: city?.name })) : []}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cost-center-section mb-2">
                <div className="d-flex mt-3 cursor-pointer slide-header gap-4"
                    onClick={() => setPaymentTab(!paymentTab)}>
                    <div className='h6 pt-1'>Payment Details</div>
                </div>
                <div className={`slide-content ${paymentTab ? 'open' : 'closed'}`}>
                    <div className="border-box2 p-3">
                        <div className="form-group row d-flex justify-content-between pb-3">
                            <div className="col-md-3 col-lg-3 d-flex justify-content-between ">
                                <label htmlFor="validationCustom01" className="form-label  pt-2 start">Payment Term</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    isDisabled={canCreateMaster === true ? false : true}
                                    value={formData?.paymentTerm}
                                    onChange={(selectedOption) => handlePayChange(selectedOption)}
                                    options={payments?.map((sup) => ({ value: sup, label: sup?.description }))}
                                />
                            </div>
                            <div className="col-md-3 col-lg-3 d-flex justify-content-between ">
                                <label htmlFor="validationCustom2" className="form-label  pt-2 start">Cheque Name</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    disabled={canCreateMaster === true ? false : true}
                                    id="validationCustom2"
                                    name="ChequeName"
                                    value={formData?.ChequeName}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col-md-3 col-lg-3 d-flex justify-content-between ">
                                <label htmlFor="validationCustom2" className="form-label  pt-2 start">VAT Reg No</label>
                                <input
                                    type="number" onWheel={(e) => e.target.blur()}
                                    className="form-Input w-70"
                                    disabled={canCreateMaster === true ? false : true}
                                    id="validationCustom2"
                                    name="VATRegNo"
                                    value={formData?.VATRegNo}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col-md-3 col-lg-3 d-flex justify-content-between ">
                                <label htmlFor="validationCustom2" className="form-label  pt-2 start">Tax Code</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    isDisabled={canCreateMaster === true ? false : true}
                                    value={formData?.Vat}
                                    onChange={(selectedOption) => handlevatChange(selectedOption)}
                                    options={vat?.map((vat) => ({ value: vat, label: vat?.description }))}
                                />
                            </div>
                        </div>
                        <div className="form-group row d-flex justify-content-between pb-3">
                            <div className="col-md-3 col-lg-3 d-flex justify-content-between">
                                <label htmlFor="validationCustom03" className="form-label  pt-2 start">Credit Limit</label>
                                <input
                                    type="number" onWheel={(e) => e.target.blur()}
                                    className="form-Input w-70"
                                    disabled={canCreateMaster === true ? false : true}
                                    id="validationCustom03"
                                    name="CreditLimit"
                                    value={formData?.CreditLimit}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col-md-3 col-lg-3 d-flex justify-content-between ">
                                <label htmlFor="validationCustom03" className="form-label  pt-2 start">Credit Days</label>
                                <input
                                    type="number" onWheel={(e) => e.target.blur()}
                                    className="form-Input w-70"
                                    disabled={canCreateMaster === true ? false : true}
                                    id="validationCustom03"
                                    name="CreditDays"
                                    value={formData?.CreditDays}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className='col-lg-3 center d-flex gap-3 pt-2'>
                                <input className="form-input"
                                    type="checkbox"
                                    id="Credit" style={{ width: "20px", height: "20px" }}
                                    name="Credit"
                                    checked={Credit}
                                    onChange={(e) => handleCreditSelection(e.target.checked)}
                                />
                                <label className="form-label checkItem center" htmlFor="Credit">
                                    Credit Facility
                                </label>
                            </div>
                            <div className="col-md-3 col-lg-3 d-flex justify-content-between ">
                                <label htmlFor="validationCustom2" className="form-label  pt-2 start">Discount %</label>
                                <input
                                    type="number" onWheel={(e) => e.target.blur()}
                                    className="form-Input w-70"
                                    disabled={canCreateMaster === true ? false : true}
                                    id="validationCustom2"
                                    name="Discount"
                                    value={formData?.Discount}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header gap-4"
                    onClick={() => setGlTab(!GlAccountTab)}>
                    <div className='h6 pt-1'>GL Account</div>
                </div>
                <div className={`slide-content ${GlAccountTab ? 'open' : 'closed'}`}>
                    <div className="border-box2 p-3">
                        <div className="form-group row  pb-3">
                            <div className="col-md-3 col-lg-3 d-flex justify-content-between">
                                <label htmlFor="validationCustom01" className="form-label  pt-2 start">Sub Account</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    disabled={canCreateMaster === true ? false : true}
                                    id="validationCustom01"
                                    name="SubAccount"
                                    value={formData?.SubAccount}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col-md-3 col-lg-3 d-flex justify-content-between">
                                <label htmlFor="validationCustom01" className="form-label  pt-2 start">Account Name</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    disabled={canCreateMaster === true ? false : true}
                                    id="validationCustom01"
                                    name="AccountName"
                                    value={formData?.AccountName}
                                    onChange={handleInputChange}
                                />
                                <div className="valid-feedback">
                                    Looks good!
                                </div>
                            </div>
                            <div className="col-md-3 col-lg-3 d-flex justify-content-between">
                                <label htmlFor="validationCustom01" className="form-label  pt-2 start">Account Code</label>
                                <input
                                    type="number" onWheel={(e) => e.target.blur()}
                                    className="form-Input w-70"
                                    disabled={canCreateMaster === true ? false : true}
                                    id="validationCustom01"
                                    name="AccountCode"
                                    value={formData?.AccountCode}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="start col">
                                <div className='d-flex gap-3 pt-2'>
                                    <input className="form-input"
                                        type="checkbox"
                                        id="ledger" style={{ width: "20px", height: "20px" }}
                                        name="ledger"
                                        checked={Credit}
                                        onChange={(e) => handleCreditSelection(e.target.checked)}
                                    />
                                    <label className="form-label checkItem center" htmlFor="ledger">
                                        Create ledger
                                    </label>
                                </div>
                            </div>
                            <div className='col brderbox center'><a href=''>Pick Existing Account</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CreateSupplier