import React from 'react';
import { useLocation } from 'react-router-dom';
import RecipeSideBar from '../Component/Sidebar/RecipeSideBar';
const RecipeSideLayout = ({ children }) => {
  const location = useLocation();
  const isSidebarEnabled = location.pathname.startsWith('/Inventory');
  
  return (
    <div className="sidebar-layout">
      <div className={`sidebar ${isSidebarEnabled ? 'fixed-lefty' : ''}`}>
         <RecipeSideBar currentPath={location.pathname} />
      </div>
      <main className="main-content">{children}</main>
    </div>
  )
}

export default RecipeSideLayout;