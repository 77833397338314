import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { MdKeyboardDoubleArrowLeft } from "react-icons/md";
import { IoMdMail } from "react-icons/io";
import "../purchaseOrder/print.css"
import { FiPrinter } from "react-icons/fi";
import { PiDownloadThin } from "react-icons/pi";
import moment from 'moment';
import Logo from "../../Assets/images/Pro_Logo.png"
import ApiService from '../../services/apiService';
import { toWords } from 'number-to-words';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { useCurrency } from '../../Layouts/currencyContext';
import { useSelector } from 'react-redux';
import { getCompany } from '../../../Redux/selecters';
const ReturnPrint = ({ }) => {
    const location = useLocation();
    const CompareData = location.state;
    const [Items, setItems] = useState([]);
    const [suppliers, setSupplier] = useState();
    const currentDatetimeUTC = new Date().toISOString();
    const totalAmount = Items?.reduce((sum, row) => sum + (row?.amount || 0), 0);
    const { currencyPrecision } = useCurrency();
    const companies = useSelector(getCompany);
    debugger
    useEffect(() => {
        fetchSupplierData()
        setItems(CompareData?.purchaseReturnDetails)

    }, [location?.state]);
    const fetchSupplierData = async () => {
        try {
            const apiService = new ApiService()
            let param = `?SupplierName=${CompareData?.vendorName}`
            const response = await apiService.getApiParamater('GET_SUPPLIER', param);
            setSupplier(response?.data?.result[0]);
        } catch (error) {
            console.error("Error fetching Supplier data:", error);
        }
    };
    const handleBackClick = () => {
        window.history.back();
    };
    const handlePrintClick = async () => {
        const input = document.getElementById('mainboxdsn');
        if (input) {
            const canvas = await html2canvas(input, { scale: 2 });
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
            pdf.save(`Purchase-Return-${CompareData?.prNo}.pdf`);
        } else {
            console.error("Element not found: mainboxdsn");
        }
    };
    return (
        <div className='container p-lg-5'>
            <div className='d-flex justify-content-between py-5'>
                <div className='' onClick={handleBackClick} ><button className='prntbtn'> <MdKeyboardDoubleArrowLeft size={20} /> Back</button></div>
                <div className=''><button className='prntbtn'><IoMdMail size={20} /> Send To Mail</button></div>
                <div className=''><button className='prntbtn' onClick={handlePrintClick}> <FiPrinter size={20} /> Print</button></div>
                <div className=''><button className='downbtn'> <PiDownloadThin size={20} /></button></div>
            </div>
            <div className=''>
                <div className='printhead'>
                    <div className='d-flex justify-content-between'>
                        <div className='center'>
                            <img
                                src={companies?.selectedCompany?.logo || Logo}
                                alt="Logo"
                                style={{ height: "35px" }}
                            />
                            <span className="logoName" style={{ marginLeft: "8px" }}>
                                {companies?.selectedCompany?.logo
                                    ?.split('/')
                                    .pop()
                                    ?.split('.')
                                    .slice(0, -1)
                                    .join('.')
                                    ?.charAt(0)
                                    .toUpperCase() +
                                    companies?.selectedCompany?.logo
                                        ?.split('/')
                                        .pop()
                                        ?.split('.')
                                        .slice(0, -1)
                                        .join('.')
                                        ?.slice(1)
                                        .toLowerCase()}
                            </span>
                        </div>
                        <div className='mainhead'>
                            <u>Purchase Return</u>
                        </div>
                        <div className='headtx'>
                            <div className='py-1'>Order No: {CompareData?.prNo}</div>
                            <div className='pt-1'>Date: {moment(CompareData?.docDate).format('ll')}</div>
                        </div>
                    </div>
                </div>
                <div className='printboxIn p-3 d-flex gap-3'>
                    <div className='printsupbox w-50'>
                        <div className='d-flex justify-content-between'>
                            <div>GRN NO</div>
                            <div>{CompareData?.grnNo}</div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div>PO NO</div>
                            <div>{CompareData?.pOrderNo}</div>
                        </div>

                        <div className='d-flex justify-content-between'>
                            <div>Invoice No</div>
                            <div>{CompareData?.invoiceNo || "---"}</div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div>Invoice Date </div>
                            <div>{CompareData?.invoiceDate}</div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div>Delivery Note No</div>
                            <div>{CompareData?.contactPerson || "---"}</div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div>Ware House</div>
                            <div>{CompareData?.requisitionNo || "---"}</div>
                        </div>
                    </div>
                    <div className='printsupbox w-50'>
                        <div className='d-flex justify-content-between'>
                            <div>Supplier</div>
                            <div>{suppliers?.supplierName}</div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div>Address</div>
                            <div>{suppliers?.address1}</div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div>{CompareData?.status === 1 ?
                                <div>Delivery Date</div>
                                :
                                <div>Expected Delivery Date</div>
                            }</div>
                            <div>{moment(CompareData?.deliveryDate).format('ll')}</div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div>Phone</div>
                            <div>{suppliers?.phoneNo1}</div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div>Status</div>
                            <div>{CompareData?.status === 1 ?
                                <div>Approved</div> : <>
                                    {CompareData?.status === 0 ?
                                        <div>Pending</div> : <>
                                            {CompareData?.status === 5 ?
                                                <div>Hold</div> :
                                                <>{CompareData?.status === 3 ?
                                                    <div>Rejected</div> : <div>Pending</div>
                                                }</>
                                            }
                                        </>
                                    }</>
                            }</div>
                        </div>
                    </div>

                </div>
                <div className='printhead2 mb-5'>
                    <table>
                        <thead className='dynoTable' style={{ backgroundColor: '#5B93FF', color: '#fff' }}>
                            <tr>
                                <th>Sr no</th>
                                <th>Item No</th>
                                <th>Description</th>
                                <th>Qty</th>
                                <th>UOM</th>
                                <th>Unit Price</th>
                                <th>Discount</th>
                                <th>VAT %</th>
                                <th>VAT Amount</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Items?.map((row, index) => (
                                <tr key={index}>
                                    <td className='m-0 p-0'><input style={{ width: "100px" }} className='p-2' type="text" value={index + 1} disabled /></td>
                                    <td className='m-0 p-0'><input style={{ width: "110px" }} className='p-2' disabled value={row?.itemCode} /></td>
                                    <td className='m-0 p-0'><input style={{ width: "140px" }} className='p-2' disabled value={row?.itemDescription} /></td>
                                    <td className='m-0 p-0'><input style={{ width: "80px" }} className='p-2' disabled value={row?.qty} /></td>
                                    <td className='m-0 p-0'><input style={{ width: "150px" }} className='p-2' disabled value={row?.uom} /></td>
                                    <td className='m-0 p-0'><input style={{ width: "120px" }} className='p-2' disabled value={row?.unitPrice?.toFixed(currencyPrecision) || 0} /></td>
                                    <td className='m-0 p-0'><input style={{ width: "110px" }} className='p-2' type="text" value={row?.stock} disabled /></td>
                                    <td className='m-0 p-0'><input className='p-2' style={{ width: "100%" }} type="text" value={row?.vatPerc} disabled /></td>
                                    <td className='m-0 p-0'><input className='p-2 w-100' type="text" value={row?.vatAmount} disabled /></td>
                                    <td className='m-0 p-0'><input style={{ width: "100%" }} className='p-2' disabled value={(row?.amount)?.toFixed(currencyPrecision) || 0} /></td>
                                </tr>
                            ))}
                            <tr>
                                <td colspan="2" className='m-0 p-0'><input className='p-2 w-100' disabled value={"Total"} /></td>
                                <td colspan="6" className='m-0 p-0'><input className='p-2 w-100' disabled value={""} /></td>
                                <td colspan="2" className='m-0 p-0'><input className='p-2 w-100' disabled value={totalAmount?.toFixed(currencyPrecision)} /></td>
                            </tr>
                            <tr>
                                <td colspan="2" className='m-0 p-0'><input className='p-2 w-100' disabled value={"Amount In Words"} /></td>
                                <td colspan="6" className='m-0 p-0'><input className='p-2 w-100' disabled value={toWords(totalAmount)} /></td>
                                <td colspan="2" className='m-0 p-0'><input className='p-2 w-100' disabled value={totalAmount?.toFixed(currencyPrecision)} /></td>
                            </tr>
                            <tr>
                                <td colspan="2" className='m-0 p-0'><input className='p-2 w-100' disabled value={"Payment Terms"} /></td>
                                <td colspan="6" className='m-0 p-0'><input className='p-2 w-100' disabled value={CompareData?.terms} /></td>
                                <td colspan="2" className='m-0 p-0'><input className='p-2 w-100' disabled value={"---"} /></td>
                            </tr>
                        </tbody>
                    </table>
                    <div className='p-5 row'>
                        <div className=' row d-flex justify-content-between'>
                            <div className='col-2'>
                                <div className='itmKIns center'>Admin</div>
                                <hr className='' />
                                <div className='itmKIns center'>Prepared By</div>
                            </div>
                            <div className='col-2'>
                                <div className='itmKIns center'>Admin</div>
                                <hr className='' />
                                <div className='itmKIns center'>Approved By</div>
                            </div>
                        </div>
                        <div className='center'>
                            <div className='col-3'>
                                <div className='itmKIns center'>{moment(currentDatetimeUTC).format('DD-MMM-YYYY')}</div>
                                <hr className='' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReturnPrint;
