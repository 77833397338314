import React, { useEffect, useState, useRef } from 'react';
import Select from 'react-select';
import RequisitionGrid from './itemGrid';
import ApiService2 from '../../../POS/services/apiService';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { FaAngleLeft } from "react-icons/fa6";
import { RiRefreshLine } from "react-icons/ri";
import { FaSave } from "react-icons/fa";
const IngredientCreate = () => {
    const apiService2 = new ApiService2()
    const [SelectedCurrency, setSelectedCurency] = useState([]);
    const [Location, setLocation] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState([]);
    const [SupplierTab, setSupplierTab] = useState(true);
    const [CostTab, setCostTab] = useState(false);
    let navigate = useNavigate();
    const location = useLocation();
    const itemData = location.state;
    const [ReqNo, setReqNo] = useState([]);
    const currentDatetimeUTC = new Date().toISOString().slice(0, 16);
    const getCurrentLocalDatetime = () => {
        const localDate = new Date();
        const offset = localDate.getTimezoneOffset() * 60000;
        const localISOTime = new Date(localDate - offset).toISOString().slice(0, 16);
        return localISOTime;
    };
    const [formData, setFormData] = useState({
        recordId: "",
        requisitionNo: "",
        itemType: "",
        quatotionNo: "",
        remarks: "",
        requestor: "",
        requisitionDate: getCurrentLocalDatetime(),
        deliveryDate: getCurrentLocalDatetime(),
        currencyCode: "",
        exchangeRate: "",
        costCenter1: "",
        costCenter2: "",
        costCenter3: "",
        costCenter4: "",
        deliveryPoint: "",
        location: "",
        contactPerson: "",
        contatNo: "",
        taskCode: "",
        subcatname: "",
    });

    const staticOptions = [];
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if ((name === 'contatNo') && value.length > 16) {
            return;
        }
        if (name === 'requisitionDate' || name === 'deliveryDate') {
            const dateParts = e.target.value.split("-");
            if (dateParts[0].length > 4) {
                return;
            }
        }
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handlelocChange = (selectedOption) => {
        setSelectedLocation(selectedOption)
    };
    const handleBackClick = () => {
        window.history.back();
    };
    const handleReset = () => {
        setFormData({
            itemType: "",
            quatotionNo: "",
            remarks: "",
            requestor: "",
            currencyCode: "",
            department: "",
            costCenter1: "",
            costCenter2: "",
            costCenter3: "",
            costCenter4: "",
            deliveryPoint: "",
            location: "",
            contactPerson: "",
            contatNo: "",
            taskCode: "",
        });
        setSelectedDepartment([])
        setSelectedLocation([])
    }
    return (
        <div className='p-4'>
            <div className='d-flex justify-contant-between '>
                <h5 className='col mainInvHead'>Purchase Requisition</h5>
                <div className='d-flex justify-content-end gap-3'>
                    <div className='col center'>
                        <button className='mainbtn '  type="submit">
                            <FaSave className='center mt-1 mx-2' /> <div>Save</div>
                        </button>
                    </div>
                    <button className='mainbtnRed' onClick={() => handleReset()}><RiRefreshLine className='center mt-1 mx-2' /><div>Reset</div></button>
                    <button onClick={handleBackClick} className='mainbtnSecondary'><FaAngleLeft className='center mt-1 mx-2' /><div>Back</div></button>
                </div>
            </div>
            <div className="cost-center-section mb-2">
                <div className="d-flex mt-3 cursor-pointer slide-header gap-4"
                    onClick={() => setSupplierTab(!SupplierTab)}>
                    <div className='h6 pt-1'>Ingredients Details</div>
                </div>
                <div className={`slide-content ${SupplierTab ? 'open' : 'closed'}`}>
                    <div className="border-box2 p-3">
                        <div className="form-group row pb-3">
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="ReqNo" className="form-label  pt-2 start">Name *</label>
                                <input
                                    className="form-Input w-70"
                                    value={ReqNo}
                                    name="ReqNo"
                                    type='text'
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="deliveryDate" className="form-label  pt-2 start">Group</label>
                                {itemData?.eye === true ? (
                                    <Select
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        isDisabled
                                        value={selectedLocation}
                                        className="w-70 p-0"
                                    />
                                ) : (
                                    <Select
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        value={selectedLocation}
                                        onChange={(selectedOption) => handlelocChange(selectedOption)}
                                        options={Location?.map((loc) => ({ value: loc, label: loc?.description }))}
                                        className="w-70 p-0"
                                    />
                                )}
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="deliveryDate" className="form-label  pt-2 start">Category</label>
                                {itemData?.eye === true ? (
                                    <Select
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        isDisabled
                                        value={selectedLocation}
                                        className="w-70 p-0"
                                    />
                                ) : (
                                    <Select
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        value={selectedLocation}
                                        onChange={(selectedOption) => handlelocChange(selectedOption)}
                                        options={Location?.map((loc) => ({ value: loc, label: loc?.description }))}
                                        className="w-70 p-0"
                                    />
                                )}
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-2 start">Sub Category</label>
                                {itemData?.eye === true ?
                                    <Select
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        className="w-70 p-0" isDisabled value={SelectedCurrency} /> :
                                    <Select
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        className="w-70 p-0"
                                        isSearchable={false}
                                        value={SelectedCurrency}
                                    />
                                }
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="location" className="form-label  pt-2 start">Description</label>
                                {itemData?.eye === true ?
                                    <input disabled value={formData.location} className="form-Input w-70" /> :
                                    <input
                                        type="text"
                                        className="form-Input w-70"
                                        id="location"
                                        value={formData.location}
                                        onChange={handleInputChange}
                                        name="location"
                                    />
                                }
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-2 start">Margin</label>
                                <input
                                    type="number"
                                    className="form-Input w-70"
                                    disabled
                                    onWheel={(e) => e.target.blur()}
                                    id="exchangeRate"
                                    value={formData.exchangeRate}
                                    onChange={handleInputChange}
                                    name="exchangeRate"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cost-center-section mb-2">
                <div className="d-flex mt-3 cursor-pointer slide-header gap-4"
                    onClick={() => setSupplierTab(!SupplierTab)}>
                    <div className='h6 pt-1'>Ingredients Details</div>
                </div>
                <div className={`slide-content ${SupplierTab ? 'open' : 'closed'}`}>
                    <div className="border-box2 p-3">
                        <RequisitionGrid itemData={itemData} selectedLocation={selectedLocation} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IngredientCreate